<template>
  <ModuleBox title="应用案例统计" width="100%" height="29.5vh" class="application">
    <div class="body">
      <div id="application-box" style="height:23vh" class="application-box  "></div>
    </div>
  </ModuleBox>
</template>

<script>
import { Chart, registerTheme } from "@antv/g2";
import highcharts from "highcharts";
import API from "@/api";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
export default {
  components: {
    ModuleBox
  },
  data() {
    return {
      data: [
      ],
      categories: ['维稳处突', '安保', '违章建筑', '消防救援', '应急救援', '城市规划', '道路改造']
    }
  },
  methods: {
    async activityTotalByTypeFn() {
      let res = await API.MODEL.activityTotalByType()
      if (res.code == 200) {
        let categories = []
        let data = []
        res.data.forEach(element => {
          categories.push(element.name)
          data.push(element.activityCount)
        });
        this.data=[{
        name: '总量',
        data: data,
        stack: 'value',
        color: {
          linearGradient: {
            y1: 1,
            y2: 1
          },
          stops: [
            [0, '#FFD21F'],
            [1, '#FFD21F']]
        }
      }]
        this.categories = categories
        this.$forceUpdate()
      }
    },
    updataflyValue() {
      console.log('updataflyValue');
    },
    init() {
      const data = [
        { name: '维稳处突', status: '总量', num: 20 },
        { name: '安保', status: '总量', num: 5 },
        { name: '违章建筑', status: '总量', num: 15 },
        { name: '消防救援', status: '总量', num: 3 },
        { name: '应急救援', status: '总量', num: 2 },
        { name: '城市规划', status: '总量', num: 2 },
        { name: '道路改造', status: '总量', num: 5 }
      ];

      const chart = new Chart({
        container: 'application-box',
        autoFit: true,
        height: 220,
      });

      registerTheme('newTheme', { minColumnWidth: 8, maxColumnWidth: 8, }); //柱状体大小
      chart.theme('newTheme')

      chart.data(data);
      chart.scale('num', {
        nice: true,
      });
      chart.axis('num', {
        title: null,
        label: {
          style: {
            fill: '#fff',
          }
        }
      });
      chart.axis('name', {
        title: null,
        label: {
          style: {
            fill: '#fff',
          }
        }
      });
      chart.tooltip({
        showMarkers: false,
        shared: true,
      });

      chart
        .interval()
        .position('name*num')
        .color('name', (name) => {
          if (name == '打架斗殴') {
            return "l(90) 0:#FFCD92 0.3:#E8BB5F 0.6:#D39D2D 1:rgba(174,158,24,0)"
          }
          if (name == '河道巡查') {
            return "l(90) 0:#92FFFD 0.3:#5FB2E8 0.6:#2D72D3 1:rgba(31,24,174,0)"
          }
          if (name == '黄土裸露') {
            return "l(90) 0:#ADFFFD 0.3:#5FE8A6 0.6:#2DD3B5 1:rgba(24,162,174,0)"
          }
          if (name == 'put巡查') {
            return "l(90) 0:#FF9292 0.3:#E85F5F 0.6:#D32D2D 1:rgba(174,24,24,0)"
          }
          if (name == '河道污染') {
            return "l(90) 0:#FFCD92 0.3:#E8BB5F 0.6:#D39D2D 1:rgba(174,158,24,0)"
          }
          if (name == '空气质量') {
            return "l(90) 0:#92FFFD 0.3:#5FB2E8 0.6:#2D72D3 1:rgba(31,24,174,0)"
          }
          if (name == '河道垃圾') {
            return "l(90) 0:#ADFFFD 0.3:#5FE8A6 0.6:#2DD3B5 1:rgba(24,162,174,0)"
          }
        })
        .adjust([
          {
            type: 'dodge',
            marginRatio: 0.6,
          },
        ]);
      chart.legend(false); // 关闭图例
      chart.option('scrollbar', {
        categorySize: 40,
        style: {
          trackColor: 'rgba(0,255,255,0.2)', //滚动条背景
          thumbColor: '#00BBFF' // 滚动条滑块背景
        }
      }); // 开启滚动条组件  设置间距

      chart.interaction('active-region');

      chart.render();
    },
    initA() {
      console.log(this.categories,'categories');
      const chart = highcharts.chart('application-box', {

        chart: {

          type: 'column',
          options3d: {
            enabled: true,//显示图表是否设置为3D， 我们将其设置为 true
            alpha: 0,  //图表视图旋转角度
            beta: 0,  //图表视图旋转角度
            viewDistance: 20,   //图表的合计深度，默认为100
            depth: 14 //定义图表的浏览长度
          },
          marginTop: 10,
          marginRight: 0,
          marginLeft: 30,
          backgroundColor: '#203c749e',
          color: '#ffffff'
        },

        credits: {
          enabled: false //不显示LOGO 
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: this.categories,
          gridLineWidth: 1,
          gridLineColor: '',
          labels: {
            style: {
              color: '#ffffff'
            },
          }
        },

        title: null,
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: null,
          gridLineWidth: 1,
          gridLineDashStyle: 'Dash',
          gridLineColor: '#ddd',
          labels: {
            style: {
              color: '#ffffff'
            }
          }
        },
        style: {
          color: '#ffffff'
        },
        tooltip: {
          headerFormat: '<b>{point.key}</b><br>',
          pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
        },
        plotOptions: {
          series: {
            stacking: '默认normal，百分比percent', /*默认normal，百分比percent*/
            dataLabels: {
              enabled: false,
              style: {
                color: '#ffffff',//字体颜色
                textOutline: 'none' //去掉文字阴影
              }
            },

            enableMouseTracking: true,
            events: {
              //控制图标的图例legend不允许切换
              legendItemClick: function (event) {
                return false;//return true则表示允许切换
              }
            }
          }
        },
        series: this.data
      });
    },
  },
  created() {

  },
  async mounted() {
    await this.activityTotalByTypeFn()
    this.initA()
   
  }
}
</script>

<style lang="scss" scoped>
.application {}
</style>