<template>
  <Box width="470px" height="250px" class="cpt-organize-box">
    <div class="organize-header">
      <span class="iconfont icon-biaoti"></span>
      <span class="title">政务量运行概览</span>
    </div>
    <div class="organize-list">
      <div class="list-item" v-for="item in list" :key="item.id" :title="item.value">
        <div class="title-box">
          <div class="title">{{ item.title }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
        <div class="progress">
          <div
            class="progress-inner"
            :style="`width:${(item.value / item.total) * 100}%`"
          ></div>
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
import Box from "../common/box";
import API from "@/api"
export default {
  components: { Box },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async init_viewer() {
      const list = await API.HOME.runAnOverview()
      const dataList = list.data
      dataList.map( (item, index) => this.list.push({id: index, title: item.name, value: item.count, total : 3000}) )
    },
    
  },
  mounted() {
    this.init_viewer();
  }
};
</script>

<style lang="scss" scoped>
.cpt-organize-box {
  position: absolute;
  left: 480px;
  bottom: 38px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  .organize-header {
    height: 40px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-size: 18px;
    color: #e0e6f2;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    box-sizing: border-box;
    position: relative;
    &::before {
      content: " ";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0px;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(3, 233, 244, 0.5), transparent);
      animation: animate1 4s linear infinite;
    }
    .title {
        margin-left: 9px;
    }
  }
  .organize-list {
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #fff;
    .list-item {
      width: calc(50% - 20px);
      margin-bottom: 12px;
      &:nth-of-type(odd) {
        margin-right: 40px;
      }
      .title-box {
        display: flex;
        justify-content: space-between;
        line-height: 1.8;
        .title {
          font-size: 14px;
          color: #a2c4fd;
          letter-spacing: 0;
          font-weight: 400;
        }
        .value {
          font-size: 14px;
          color: #a4c6ff;
          letter-spacing: 0;
          text-align: right;
          font-weight: 400;
        }
      }
      .progress {
        width: 100%;
        height: 10px;
        background: rgba(24, 73, 152, 0.5);
        .progress-inner {
          height: 100%;
          width: 0%;
          background: #1a7fea;
        }
      }
    }
  }
}
</style>