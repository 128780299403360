<template>
    <div style="height:100%;">
        <LeftTop v-if="haves.includes(1)"/>
        <LeftMiddle v-if="haves.includes(2)"/>
        <LeftBottom v-if="haves.includes(3)"/>
    </div>
</template>

<script>
import LeftTop from "./left-top.vue";
import LeftMiddle from "./left-middle.vue";
import LeftBottom from "./left-bottom.vue";
export default {
    components:{LeftTop,LeftMiddle,LeftBottom},
    data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:detectionCountermeasures:detectionData") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:detectionCountermeasures:proportionOfIntruders") > -1) {
      this.haves.push(2)
    }
    if (permissionList.indexOf("hn:detectionCountermeasures:intrusionEventAnalysis") > -1) {
      this.haves.push(3)
    }
    }
}
</script>

<style>

</style>