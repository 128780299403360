<template>
  <ModuleBox title="三维应用" width="100%" height="28vh" class="home-model">
    <div class="out">
      <div style="width: 100%">
        <!-- 头部 -->
        <div class="top  hwb-flex">
          <!-- 左侧任务次数统计 -->
          <div class="left  hwb-flex f11 w290 ml40 mb7">
            <div class="text f14 pl30">标注数量</div>
            <div class="num hwb-flex">
              <div class="one-num" v-for="(item,index) in total" :key="index">{{item}} </div>
              <span class="cf mr30">项</span>
            </div>
            <!-- <div class="text">项</div> -->
          </div>
          <!-- 右侧时间筛选 -->
          <div class="right">
            <!-- <TimeSelect @on-selectTime="selectTime"/> -->
          </div>
        </div>
        <!-- 下面 -->
        <div class="bottom">
          <!-- 一条样式 -->
          <div class="slider-list hwb-flex-column jcsb" v-for="item in data" :key="item.name">
            <div class="slider jcsb">
              <span class=" f12 mr15 mt10" style="color:#889ac9"> {{item.name}}</span>
              <div class="tiao mt13">
                <div class="have" :style="{width: (item.counts/item.sum)*100 + '%'}"></div>
                <!-- 圆点 -->
                <div class="dec-circular" :style="{left:'calc(' +(item.counts/item.sum)*100 + '% - 4px)'}"></div>
              </div>
              <div class="one-scroll-text-right ml10">{{item.counts}}<span
                  style="color:#DAE9FF;font-size:16px;font-weight:400">
                  项</span></div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="showDetails" :title="title" :width="900" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValue" />
    </Dialog>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import API from '@/api'
export default {
  components: { ModuleBox, TimeSelect, Table, Dialog },
  data() {
    return {
      timeHasSelect: 1, // 时间选择
      data: [
        { name: '设备部署', num: 134, sum: 150 },
        { name: '违章乱停', num: 94, sum: 150 },
        { name: '占压', num: 79, sum: 150 },
        { name: '消防器', num: 64, sum: 150 },
        { name: '违章建筑', num: 55, sum: 150 },
        { name: '车辆', num: 38, sum: 150 },
        { name: '无人机', num: 24, sum: 150 },
        { name: '河道漂浮物', num: 21, sum: 150 },
        { name: '人员', num: 19, sum: 150 },
      ],
      dataList: [
        { title: '机构名称', name: 'name', },
        { title: '性别', name: 'a' },
        { title: '证件等级', name: 'b' },
        { title: '手机号', name: 'c' },
        { title: '登记日期', name: 'd' },
      ],
      dataValue: [],
      showDetails: false,
      title: '',
      value: '',
      total: 0
    };
  },

  methods: {
    details(title, value) {
      this.title = title
      this.value = value
      this.showDetails = true
    },
    closeDetails() {
      this.showDetails = false
    },
    selectTime(time) {
      this.timeHasSelect = time
      if (time === 1) {
        this.data = [
          { name: '土地污染', num: 0, sum: 0 },
          { name: '河道漂浮物', num: 0, sum: 0 },
          { name: '违章建筑', num: 0, sum: 0 },
          { name: '违章乱停', num: 0, sum: 0 },
        ]
      } else if (time === 2) {
        this.data = [
          { name: '土地污染', num: 0, sum: 1 },
          { name: '河道漂浮物', num: 1, sum: 1 },
          { name: '违章建筑', num: 0, sum: 1 },
          { name: '违章乱停', num: 0, sum: 1 },
        ]
      } else if (time === 3) {
        this.data = [
          { name: '土地污染', num: 0, sum: 3 },
          { name: '河道漂浮物', num: 2, sum: 3 },
          { name: '违章建筑', num: 0, sum: 3 },
          { name: '违章乱停', num: 1, sum: 3 },
        ]
      } else if (time === 4) {
        this.data = [
          { name: '土地污染', num: 0, sum: 5 },
          { name: '河道漂浮物', num: 3, sum: 5 },
          { name: '违章建筑', num: 0, sum: 5 },
          { name: '违章乱停', num: 2, sum: 5 },
        ]
      }

    },
    async async_get_list() {
      let res = await API.TASK.getMarkerCount({ type: 'year' })
       
      if (res.code == 200) {
        let data = res.data.data
        data.sort(this.sortArray('counts')) //排序
        data.forEach(element => { //设置线段最大值
          data[0].counts
          if (data[0].counts) {
            element.sum = data[0].counts
          }
        });
        this.total = String(data.reduce((sum, value) => sum += value.counts, 0)).padStart(5, 0).split('') || 0 //获取总数 
        this.data = data
      } else {

      }
    },
    sortArray(prop) {//数组排序
      return (a, b) => {
        let value1 = a[prop]
        let value2 = b[prop]
        return value2 - value1
      }
    }


  },
  mounted() {
  },
  created() {
    this.async_get_list()
  }
}
</script>

<style lang="scss" scoped>
.home-model {
  width: 100%;

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .top {
      .left {
        padding-left: 4px;
        height: 40px;
        width: 294px;
        background-image: url('~@/assets/img/titleBox.png');
        padding: 0 9px;
        background-size: 100% 100%;

        .text {
          font-size: 16px;
          font-family: AppleSystemUIFont;
          color: #DAE9FF;
        }
        .num {
          width: 140px;
          .one-num {
            padding-left: 1px;
            padding-right: 1px;
            font-size: 22px;
            height: 26px;
            background: linear-gradient(180deg, #01a0ff 0%, #01a0ff 50%, #1784FA 50%, #1784FA 100%);
            font-family: Rajdhani-SemiBold, Rajdhani;
            border-radius: 1px;
            color: #FFFFFF;
            line-height: 26px;
            text-align: center;
          }
        }
      }

      .right {
        text-align: center;
      }
    }

    .bottom {
      margin: 4px 4px 0 6px;
      padding: 0 14px 0 0;
      height: 16.5vh;
      flex: 1;
      overflow: auto;

      .slider-list {
        width: 100%;

        .slider {

          // padding-bottom: 12px;
          .tiao {
            background: #011d2a;
            height: 9px;
            width: 197px;
            position: relative;
            border-radius: 10px;

            .dec-circular {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: transparent;
              position: absolute;
              top: -1.5px;
              // box-shadow: 0px 0px 16px #fff;
            }

            .have {
              height: 100%;
              border-radius: 10px;
              background: linear-gradient(270deg, #92FFFD 0%, #5FB2E8 31%, #2D72D3 66%, #0183c3 100%);
            }
          }
        }

        .one-scroll-text-right {
          font-size: 18px;
          // font-family: 'DINAlternate-Bold', 'DINAlternate';
          color: #00FFFF;
          line-height: 40px;
          font-weight: bold;
        }

        .one-scroll-text {
          padding: 0 0 10px 0;
          width: 100%;

          .one-scroll-text-left {
            font-size: 14px;
            font-family: AppleSystemUIFont;
            color: #FFFFFF;
          }


        }
      }
    }
  }
}
</style>