<template>
  <!-- <ModuleBox
    title="AI预警运行概览"
    width="100%"
    height="44%"
    class="cpt-ai-module-box"
  >
    <div class="list-wrap">
      <div class="list-hd">
        <div class="hd-item">预警算法</div>
        <div class="hd-item">预警次数</div>
        <div class="hd-item">目标数量</div>
      </div>
      <div class="list-bd">
        <div class="bd-item" v-for="item in list" :key="item.id">
          <div class="item-id">{{ item.name }}</div>
          <div class="item-status">{{ item.count }}</div>
          <div class="item-value">{{ item.num }}</div>
        </div>
      </div>
    </div>
  </ModuleBox> -->
  <ModuleBox
    title="预警触发概览"
    width="100%"
    height="30%"
    class="cpt-ai-module-box"
  >
    <div class="container">
      <div class="list-wrap">
        <div class="list-hd">
          <div class="hd-item">预警算法</div>
          <div class="hd-item">预警次数</div>
          <div class="hd-item">目标数量</div>
        </div>
        <div class="list-bd">
          <div class="bd-item" v-for="item in list" :key="item.id">
            <div class="item-id">{{ item.name }}</div>
            <div class="item-status">{{ item.count }}</div>
            <div class="item-value">{{ item.num }}</div>
          </div>
        </div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
// import ModuleBox from "@/components/home/common/module-box";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import API from "@/api"
export default {
  components: { ModuleBox },
  data() {
    return {
      list: [
        {
          name: '车流统计',
          count: 253,
          num: 432
        },
        {
          name: '黄土裸露',
          count: 325,
          num: 325
        },
        {
          name: '车辆识别',
          count: 310,
          num: 421
        },
        {
          name: '火焰识别',
          count: 20,
          num: 20
        },
        {
          name: '烟雾识别',
          count: 15,
          num: 15
        },
        {
          name: '沿街晾挂',
          count: 223,
          num: 421
        },
      ],
    };
  },
  methods: {
    async init_viewer() {
      const list = await API.HOME.statistics()
      this.list = list.data
    }
  }, 
  mounted() {
    // this.init_viewer();
  }
};
</script>

<style lang="scss" scoped>
.cpt-ai-module-box {
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .list-wrap {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      .list-hd {
        height: 32px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: left top url(~@/assets/home/list-border.png) no-repeat,
          left bottom url(~@/assets/home/list-border.png) no-repeat;
        background-size: 100% 2px;
        flex-shrink: 0;
        margin-top: 20px;
        .hd-item {
          font-size: 16px;
          color: #0096ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 500;
          color: #0096ff;
          flex: 1;
        }
      }
      .list-bd {
        max-height: 200px;
        overflow: auto;
        .bd-item {
          height: 32px;
          font-size: 16px;
          color: #c4d6ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:nth-of-type(even) {
            background: rgba(29, 82, 167, 0.3);
          }
          .item-id {
            flex: 1;
            text-align: center;
          }
          .item-status {
            flex: 1;
            text-align: center;
          }
          .item-value {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
  }
  
}
</style>