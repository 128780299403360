<template>
<div style="height:100%">
    <Tagging v-if="haves.includes(1)"/>
    <Statistics v-if="haves.includes(2)"/>
</div>

</template>

<script>
import Tagging from "./tagging.vue"
import Statistics from "./statistics.vue"
export default {
    components: {
        Tagging,
        Statistics
    },
    data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:3DApplication:MapAnnotation") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:3DApplication:3DDataStatistics") > -1) {
      this.haves.push(2)
    }
    }
}
</script>

<style>

</style>