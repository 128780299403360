<template>
  <div class="cpt-header pr">
    <img class="wih100 pa top0 h140" style="object-fit: cover;" src="~@/assets/home/header-bg5.png" alt="" />
    <img class="pa top bgtitle " src="~@/assets/home/bgtitle.svg" />
    <!-- 四个按钮 -->
    <div v-if="selectData === 2 && haves.includes(1)" class="itemf cpt_header_button_o itenNav"
      @click="$router.push('/new/flightManagement')">飞行管理
    </div>
    <div v-if="selectData === 2 && haves.includes(2)" class="itemf cpt_header_button_tw itenNav"
      @click="$router.push('new/task')">飞行指挥</div>
    <div v-if="selectData === 2 && haves.includes(3)" class="itemf cpt_header_button_th itenNav"
      @click="$router.push('new/dispatch')">飞行调度</div>
    <div v-if="selectData === 2 && haves.includes(4)" class="itemf cpt_header_button_f itenNav"
      @click="$router.push('new/control')">飞行控制</div>

    <div v-if="selectData === 3" class="itemf cpt_header_button_o  itenNav" @click="flightService(1)"> 设备管理</div>
    <div v-if="selectData === 3" class="itemf   cpt_header_button_th itenNav" @click="flightService(3)">报飞备案</div>
    <div v-if="selectData === 3" class="itemf cpt_header_button_tw itenNav" @click="flightService(2)">
      证照管理
    </div>
    <div v-if="selectData === 3" class="itemf cpt_header_button_f itenNav" @click="flightService(4)">信息管理</div>

    <div v-if="selectData === 5" class="itemf cpt_header_button_tw itenNav" @click="toRouter">
      三维标注
    </div>
    <!-- <div v-if="selectData === 5" class="itemf cpt_header_button_th" @click="$router.push('/application/3D')">电子沙盘</div> -->

    <!-- <el-tooltip class="item" effect="dark" content="视频" placement="bottom">
      <div @click="videoBtn" class="videoBtn cp top13 right40 pa w53 h53   f25  tc">
        <img @click="videoBtn" src="./img/3.png" class="dib wih100 ht100 top13 " alt="" srcset="">
      </div>
    </el-tooltip> -->
    <div class="cpt-left-top-box">
      <div class="item-name">{{ date }}</div>
      <div class="item-name-val">{{ min }}</div>
      <div class="item-name-middle">{{ weekList[week] }}</div>
    </div>
    <!-- <div class="hd-inner">
      <span class="title"> 海宁政务 </span>
      <img class="logo" src="~@/assets/logo.svg" alt="logo" />
      <span class="title"> 航空平台 </span>
    </div> -->
    <div class="hd-user">
      <!-- <span class="iconfont icon-gerenzhongxin"></span> -->
      <div class="actions">
        <!-- <div
              :key="index"
              class="action-route"

              @click="$router.push('new')"
            >
              飞行任务
            </div> -->
        <!-- <div class="actions-item" @click="Notice" v-view="{ sign: 'hn:safeFlight' }">
          <el-tooltip class="item" effect="dark" content="公告" placement="bottom">
            <div class="check-image ">
              <img src="./img/2.png" class="w75 h75" alt="" srcset="">
            </div>
          </el-tooltip>
        </div> -->
        <div v-if="modelApplication" class="actions-item" @click="safeflyModel">
          <el-tooltip class="item" effect="dark" content="三维模型申请" placement="bottom">
            <img src="./img/9.png" class="w53 h53" alt="" srcset="">
          </el-tooltip>
        </div>
        <!-- <div class="actions-item" @click="safefly1" v-view="{ sign: 'hn:safeFlight' }">
          <el-tooltip class="item" effect="dark" content="飞行检查" placement="bottom">
            <img src="./img/6.png" class="w53 h53" alt="" srcset="">
          </el-tooltip>
        </div> -->
        <div class="actions-item" @click="safefly" v-view="{ sign: 'hn:flightCheck' }">
          <el-tooltip class="item" effect="dark" content="安全飞行" placement="bottom">
            <img src="./img/7.png" class="w53 h53" alt="" srcset="">
          </el-tooltip>
        </div>
        <div class="actions-item pr" v-if="routeMessage">
          <el-tooltip class="item" effect="dark" content="公告消息" placement="bottom">
            <img src="./img/5.png" @click="$emit('openNotice', true);" class="w53 h53" alt="" srcset="">
          </el-tooltip>
          <!-- <img src="./img/5.png" class="w53 h53" alt="" srcset="">
          <div class="dropdown-active pa ">
            <div class="action-route-dropdown-list  ">
              <template v-for="(item, index) in message_list">
                <div class="action-route-dropdown" :class="`${item.path == $route.path ? 'active' : ''}`" :key="index"
                  @click="Notice">
                  {{ item.name }}
                </div>
              </template>
            </div>
          </div> -->
        </div>
        <!-- <HeaderMessage :message_list="message_list" :contentState="'home'"/> -->
      </div>
      <div class="personal">
        <el-tooltip class="item" effect="dark" content="后台管理 " placement="bottom">
          <img v-show='managementIslogin' @click="managementSystem" src="./img/1.png" class="w53 h53  mt27" alt="" />
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="退出登录 " placement="bottom">
          <img @click="action" src="./img/4.png" class="w53 h53  mt27" alt="" />
        </el-tooltip>
    
      </div>
    </div>

    <!--  muted-->
    <el-dialog class="ofh" :append-to-body="true" title="视频" fullscreen :visible.sync="dialogVisible"
      :before-close="handleClose">
      <video id="videoPLay" autoplay controls class="w53 h53">
        <!-- <source src="./video/video.mp4" type="video/mp4"> -->
        <source :src="VUE_APP_VIDEOIP + '/haining/ai/show.mp4'" type="video/mp4">
        您的浏览器不支持 video 标签。
      </video>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import img1 from "@/assets/home/headImg1.png";
import HeaderMessage from '@/components/dashboard/header-message'
import flightParameterSimulationVue from "../../fiexdright/flightSimulation/flightParameterSimulation.vue";
export default {
  components: {
    HeaderMessage
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  props: {
    selectData: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      img: img1,
      managementIslogin: false,//是否展示 免登录后台管理按钮  权限
      modelApplication: false,//'三维模型申请是否展示 权限'
      dialogVisible: false,
      VUE_APP_VIDEOIP: null,
      message_list: [],
      message_list1: [
        // { name: "预警消息", path: "/platform/message/warning", type: 0.5 },
        { name: "需求消息", path: "/platform/message/order", type: 60 },
        // { name: "审批消息", path: "/platform/message/task", type: 70 },
      ],
      routeMessage: false, // 判断是否有消息通知
      week: null,
      date: null,
      min: null,
      getdate: null,
      weekList: {
        0: '星期天',
        1: '星期一',
        2: '星期二',
        3: '星期三',
        4: '星期四',
        5: '星期五',
        6: '星期六',
      },
      haves: []
    };
  },
  created() {
    this.getTime()
    const { VUE_APP_VIDEOIP } = process.env;
    this.VUE_APP_VIDEOIP = VUE_APP_VIDEOIP
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:message:Announcement") > -1) {
      this.routeMessage = true;
    }
    if (permissionList.indexOf("management:islogin") > -1) {
      this.managementIslogin = true
    }
    const modelApplication = permissionList.filter((item) => { return item == 'hn:modelApplication' })
    if (modelApplication.length > 0) {
      this.modelApplication = true
    }
    this.message_list.push({ name: "公告消息", type: 0.5 })
    // if (permissionList.indexOf("hn:sky:historyWaringMessage") > -1) {
    //   this.message_list.push({ name: "历史预警", path: "/platform/message/warning", type: 0.5 })
    // }
    // if (permissionList.indexOf("hn:sky:needMessage") > -1) {
    //   this.message_list.push({ name: "需求消息", path: "/platform/message/order", type: 60 })
    // }
    // if (permissionList.indexOf("hn:sky:approvalMessage") > -1) {
    //   this.message_list.push({ name: "审批消息", path: "/platform/message/task", type: 70 })
    // }
    if (permissionList.indexOf("hn:tasks:manager") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:tasks:command") > -1) {
      this.haves.push(2)
    }
    if (permissionList.indexOf("hn:tasks:schedle") > -1) {
      this.haves.push(3)
    }
    if (permissionList.indexOf("hn:tasks:control") > -1) {
      this.haves.push(4)
    }
  },
  methods: {
    ...mapActions("user", ["Logout"]),
    toRouter() {
      this.$router.push({ path: '/home', query: { id: 9 } })
    },
    managementSystem() {
      const { VUE_APP_WS_IP: WS_IP } = process.env;
      let code = localStorage.getItem('user_key')
      window.open(`${WS_IP}/hawk_back/#/?code=${code}`, '_blank')
      // window.open(`http://192.168.1.112:8081/#/?code=${code}`, '_blank')
    },
    flightService(num) {
      if (num == 1) {
        this.$router.push({ path: '/civilAircraft' })
      } else if (num == 2) {
        this.$router.push({ path: '/civilAircraft/certificate' })
      } else if (num == 3) {
        this.$router.push({ path: '/civilAircraft/record' })
      } else if (num == 4) {
        this.$router.push({ path: '/civilAircraft/information' })
      }
    },
    Notice() {//公告弹框
      this.$emit("openNotice", true);
    },
    safeflyModel() {//三维模型申请弹框
      this.$emit("safeflyModel", true);
    },
    handleClose() {

      let elevideo = document.getElementById("videoPLay");
      console.log('暂停视频');
      elevideo.pause();
      this.dialogVisible = false
    },
    videoBtn() {
      this.dialogVisible = true
      let _this = this
      setTimeout(function () {
        let elevideo = document.getElementById("videoPLay");
        try {
          elevideo.play();
        } catch (error) {
        }
        if (elevideo) {
          elevideo.addEventListener("ended", function () {
            setTimeout(() => {
              _this.dialogVisible = false
            }, 1500);
            elevideo.addEventListener('ended', function (event) {
            }, false)
          })
        }
      }, 2500);
    },
    action() {
      this.$confirm("确定要退出登录么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.Logout();
        })
        .catch(() => { });
    },
    safefly() {
      this.$emit("sadeflyshow", true);
    },
    safefly1() {
      this.$emit("sadeflyshow2", true);
    },
    getTime() {
      this.getdate = setInterval(() => {
        this.week = dayjs().day()
        this.date = dayjs().format('YYYY-MM-DD');
        this.min = dayjs().format('HH:mm');
      }, 1000)
    }
  },
  destroyed() {
    if (this.getdate) {
      clearInterval(this.getdate)
      this.getdate = null
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-header {
  height: 66px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // background: center -2px url('@/assets/home/header-bg5.png') no-repeat;
  // background-size: 100%;
  // background-size: cover;
  z-index: 2;

  .bgtitle {
    left: 50%;
    margin-left: -274px;
    margin-top: 10px;
  }

  /* 四个按钮的样式 */
  .itemf {
    font-size: 18px;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
    width: 100px;
    height: 36px;
    z-index: 1;
    position: absolute;
    top: 68px;
    cursor: pointer;
  }

  .itenNav {
    background: linear-gradient(180deg, #0389FF 0%, #0137A2 100%);
    border-radius: 100px 100px 100px 100px;
    opacity: 1;
  }

  .cpt_header_button_o {
    // background: url('./img/jx1.png') no-repeat;
    background-size: 100% 100%;
    left: calc(50% - 610px);

    &:hover {
      // background: url('./img/jx3.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .cpt_header_button_tw {
    // background: url('./img/jx1.png') no-repeat;
    // background-size: 100% 100%;
    left: calc(50% - 495px);

    &:hover {
      // background: url('./img/jx3.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .cpt_header_button_th {
    // background: url('./img/jx4.png') no-repeat;
    background-size: 100% 100%;
    left: calc(50% + 358px);

    &:hover {
      // background: url('./img/jx2.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .cpt_header_button_f {
    // background: url('./img/jx4.png') no-repeat;
    // background-size: 100% 100%;
    left: calc(50% + 470px);

    &:hover {
      // background: url('./img/jx2.png') no-repeat;
      background-size: 100% 100%;
    }
  }


  .cpt-left-top-box {
    display: flex;
    align-items: center;
    height: 48px;
    position: absolute;
    left: 55px;
    top: 24px;
    font-family: 'SemiBold';

    .item-name {
      width: 124px;
      height: 25px;
      font-size: 22px;
      color: #C2DCFFFF;
      line-height: 26px;
      font-weight: 400;

    }

    .item-name-middle {
      width: 84px;
      height: 22px;
      font-size: 18px;
      color: #C2DCFFFF;
      line-height: 28px;
      font-weight: 600;
      margin: -4px 9px 0;
    }

    .item-name-val {
      margin-top: 2px;
      width: 60px;
      height: 22;
      font-size: 22px;
      color: #C2DCFFFF;
      line-height: 26px;
      // font-family: 'UnidreamLED';
    }

  }

  .hd-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 74px;

    .title {
      font-family: PangMenZhengDao;
      font-size: 44px;
      color: #e0e6f2;
      letter-spacing: 6.29px;
      /* text-shadow: 0 2px 5px rgba(8, 27, 63, 0.5); */
      font-weight: 400;
      background-image: linear-gradient(180deg, #ffffff 0%, #93ceff 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
    }

    .logo {
      height: 82px;
      width: 82px;
    }
  }

  .hd-user {
    // width: 300px;
    height: 50px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #ecf2ff;
    letter-spacing: 0;
    font-weight: lighter;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0px;
    top: 2%;
    cursor: pointer;
    margin-right: 44px;
    z-index: 10;
    // background: url("~@/assets/home/user_dt.png") no-repeat;

    .iconfont {
      width: 20px;
      font-size: 26px;
      color: #2083e7;
      margin-left: 7px;

      &:hover {
        color: #0280ff;
      }
    }

    .personal {
      // width: 103px;
      text-align: center;
      margin-left: 12px;

      .text {
        font-size: 15px;
        color: #ecf2ff;
      }

      .imgIcon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .text {
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ecf2ff;
          margin-top: 0px;
          transform: scale(0.833);
          -webkit-transform: scale(0.833);
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .action-route {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 170px;
        margin-top: 10px;
        width: 196px;
        height: 42px;
        background: linear-gradient(180deg, #5298DF 0%, #25518F 60%, #0F3275 100%);
        box-shadow: 0px 2px 4px 0px rgba(3, 5, 49, 0.5), inset 0px 0px 23px 0px #69E6FF, inset 0px -2px 2px 0px #120D59, inset 0px 3px 4px 0px #A7D8FF;
        border-radius: 6px;
        border: 2px solid;
        border-image: linear-gradient(180deg, rgba(219, 242, 255, 1), rgba(132, 210, 255, 0)) 2 2;
        transform: skew(-20deg, 0);


        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        line-height: 31px;

        &:hover {
          background: linear-gradient(180deg, #FFCA00 0%, rgba(255, 175, 0, 0.2) 51%, rgba(255, 226, 0, 0.5) 100%);
          box-shadow: 0px 2px 4px 0px rgba(49, 20, 3, 0.5), inset 0px 0px 23px 0px #FFE400, inset 0px -2px 2px 0px #59330D, inset 0px 3px 4px 0px #FFD8A7;
          border-image: linear-gradient(180deg, rgba(255, 239, 188, 1), rgba(255, 229, 132, 0)) 2 2;
        }
      }

      .actions-item {
        width: 50px;
        height: 30px;
        margin-left: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .safefly-image {
          color: #fff;
          // width: 50px;
          // height: 24px;
          // background-image: url(~@/assets/platform/security.png);
          // background-repeat: no-repeat;
          // background-position: center;
        }

        .messageIcon {
          color: #fff;
          width: 24px;
          height: 40px;
          // background-image: url(~@/assets/platform/message.png);
          // background-repeat: no-repeat;
          // background-position: center;
          // position: relative;
        }

        &:hover {
          .dropdown-active {
            display: block;
            width: 140px;
            background: rgba(12, 34, 67, 0.5);
            text-align: center;
            // padding: 10px 0;
            min-width: auto;
            box-sizing: border-box;
            border: 1px solid #488cff;
            border-radius: 0;
            position: absolute;
            top: 50px;
            left: -39px;
            z-index: 100000;

            .action-route-dropdown-list {
              .action-route-dropdown {
                text-align: center;
                font-size: 14px;
                color: #cce8ff;
                letter-spacing: 0;
                font-weight: 400;
                line-height: 30px;
                cursor: pointer;

                &:hover,
                &.active {
                  color: #2cacff;
                }
              }
            }
          }
        }

        .check-image {
          color: #fff;
          // width: 50px;
          // height: 24px;
          // background-image: url(~@/assets/platform/inspect.png);
          // background-repeat: no-repeat;
          // background-position: center;
        }

        .text {
          font-size: 10px;
          color: #ecf2ff;
          width: 50px;
          text-align: center;
          transform: scale(0.833);
          -webkit-transform: scale(0.833);
        }
      }
    }
  }
}

.videoBtn {
  color: #fff;
}
</style>
<style lang="scss">
.action-route-popover {
  background: rgba(12, 34, 67, 0.5);
  min-width: auto;
  box-sizing: border-box;
  border: 1px solid #488cff;
  border-radius: 0;

  .popper__arrow {
    display: none;
  }

  .action-route-dropdown-list {
    .action-route-dropdown {
      text-align: center;
      font-size: 14px;
      color: #cce8ff;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 30px;
      cursor: pointer;

      &:hover,
      &.active {
        color: #2cacff;
      }
    }
  }
}

.dropdown-active {
  display: none;
}

.iconfont {
  cursor: pointer;
  font-size: 18px;
  color: #9fc4ff;
  margin-right: 4px;

  &:last-child {
    margin-right: 0px;
  }
}
</style>
