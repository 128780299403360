<template>
  <ModuleBox
    title="任务运行概览"
    width="100%"
    height="38%"
    class="cpt-device-module-box"
  >
    <div class="out">
      <div class="container">
        <div class="top-container hwb-flex">
          <div class="left identical">
            <div class="num">234</div>
            <div class="desc">常态任务</div>
          </div>
          <div class="right identical">
            <div class="num">156</div>
            <div class="desc">临时紧急任务</div>
          </div>
        </div>
        <div class="bot-container hwb-flex-column">
          <div class="li hwb-flex">
            <div class="shenpi-button">
              <div class="inner_circle1 circle1"></div>
            </div>
            <div>待执行</div>
            <div>78</div>
          </div>
          <div class="li hwb-flex">
            <div class="shenpi-button">
              <div class="inner_circle1 circle3"></div>
            </div>
            <div>执行中</div>
            <div>156</div>
          </div>
          <div class="li hwb-flex">
            <div class="shenpi-button">
              <div class="inner_circle1 circle2"></div>
            </div>
            <div>已完成</div>
            <div>310</div>
          </div>
        </div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import API from "@/api";

export default {
  components: { ModuleBox },
  data() {
    return {
      list: [],
      status_list: [],
    };
  },
  methods: {
   
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.cpt-device-module-box {
  .out{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .container{
    width: 100%;
    .top-container {
      width: 100%;
      height: 100px;
      padding: 0 60px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .identical{
        width: 120px;
        height: 100px;
        position: relative;
        margin-top: -20px;
        background: 50% url(./img/12.png) no-repeat;
        .num{
          width: 49px;
          height: 32px;
          font-size: 32px;
          font-family: 'UnidreamLED';
          color: #D8E7FF;
          position: absolute;
          top: 26px;
          right: 16px;
        }
        .desc{
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #C1D9FF;
          position: absolute;
          top: 96%;
          width: 100%;
          text-align: center;
        }
      }
    }
    .bot-container{
      width: 100%;
      .li{
        width: 100%;
        box-sizing: border-box;
        padding: 0 76px 0 100px;
        background: 50% url(./img/17.png) no-repeat;
        background-size:90%;
        height: 34px;
        margin-bottom: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #E3F3FF;
        position: relative;
        .shenpi-button{
          position: absolute;
          top: 8px;
          left: 70px;
          width: 20px;
          height: 20px;
          background: 50% url(./img/13.png) no-repeat;
          cursor: pointer;
          .inner_circle1 {
            content: '';
            position: absolute;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            z-index: -2;
            left: -2px;
            top: -2px;
          }
          .circle1{
            background-image: url(./img/circle1.png);
          }
          .circle2{
            background-image: url(./img/circle2.png);
          }
          .circle3{
            background-image: url(./img/circle3.png);
          }
        }
        div{
          line-height: 26px;
          height: 20px;
        }
      }
    }
  }
  
}
</style>