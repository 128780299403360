<template>
  <div>
    <Dialog
      :create="true"
      @on-close="closeIDsPopup"
      title="图层"
      :left="140"
      :top="105"
      :width="438"
    >
      <div class="layerBox">
        <div v-for="item in listByTypeData">
          <div class="titleBox h34 jcsb pl16 pr16">
            <span class="title cf f16 fw700"
              ><span class="cp iconfont cf icon-daipaidan f16 dib"> </span>
              <span class="ml5">{{ item.name }}</span></span
            >
          </div>
          <div
            class="jcsb pl16 pr16 f14 mt7 mb7 cf h34 lh34"
            v-for="(k, i) in item.list"
            :key="i"
          >
            <span>{{ k.name }}</span>
            <span
              v-if="k.isShow"
              @click="showArea(k)"
              class="cp iconfont icon-yanjing_yincang_o "
            ></span>
            <span
              v-else
              title="隐藏"
              class="iconfont f18 icon-yulanhangxian"
              @click="showArea(k)"
            ></span>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
let modelList = [];
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Dialog from "../dialog/index.vue";
import API from "@/api";
export default {
  components: { ModuleBox, Dialog },
  data() {
    return {
      listByTypeData: [],
    };
  },
  created() {
    this.listByTypeFn();
  },
  beforeDestroy() {
    // modelList.forEach(element => {
    //     viewer.scene.primitives.remove(element)
    // });
  },
  methods: {
    showArea(k) {
      k.isShow = !k.isShow;
      this.$forceUpdate();
      this.flyTo3D(k, true);
    },
    // 三维模型跳转
    flyTo3D(data, isShow) {
      // 显示
      if (isShow) {
        // 根据id判断modelList中是否已经创建实例，是就改变属性显示并跳转，否就创建模型实例
        if (modelList[data.id]) {
          // 隐藏模型实例
          viewer.scene.primitives.remove(modelList[data.id]);
          modelList.splice(data.id, 1);
        } else {
          let resource = new Cesium.Resource({
            url: data.url,
            retryCallback: (resource, error) => {
              if (!error.statusCode || error.statusCode === 404) {
                this.$message.error("服务数据加载失败");
              }
              return false;
            },
            retryAttempts: 1,
          });

          let tileSetModel = new Cesium.Cesium3DTileset({
            id: data.id,
            url: resource, //数据路径
            maximumScreenSpaceError: 2, //最大的屏幕空间误差
            maximumNumberOfLoadedTiles: 1000, //最大加载瓦片个数
            show: true,
          });
          let entitie = viewer.scene.primitives.add(tileSetModel);
          tileSetModel.readyPromise.then((tileset) => {
            //3dtile模型的边界球体
            var boundingSphere = tileset.boundingSphere;
            //迪卡尔空间直角坐标=>地理坐标（弧度制）
            var cartographic_original = Cesium.Cartographic.fromCartesian(
              boundingSphere.center
            );
            //地理坐标（弧度制）=>迪卡尔空间直角坐标
            var Cartesian3_original = Cesium.Cartesian3.fromRadians(
              cartographic_original.longitude,
              cartographic_original.latitude,
              cartographic_original.height
            );
            var Cartesian3_offset = Cesium.Cartesian3.fromRadians(
              cartographic_original.longitude,
              cartographic_original.latitude,
              1
            );
            //获得地面和offset的转换
            var translation = Cesium.Cartesian3.subtract(
              Cartesian3_offset,
              Cartesian3_original,
              new Cesium.Cartesian3()
            );
            //修改模型矩阵
            tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
            // viewer.scene.primitives.add(tileset);
            viewer.zoomTo(
              tileset,
              new Cesium.HeadingPitchRange(
                0.5,
                -0.2,
                tileset.boundingSphere.radius * 1.0
              )
            );
          });
          if (data) {
            modelList[data.id] = entitie;
            viewer.flyTo(modelList[data.id]);
            modelList[data.id].viewFrom = new Cesium.Cartesian3(0, 0, 1000);
          }
        }
      }
    },
    closeIDsPopup() {
      this.$emit("closeDemand");
    },
    async listByTypeFn() {
      let res = await API.TASK.listByType();
      if (res.code == 200) {
        this.listByTypeData = res.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.layerBox {
  height: 77vh;
  overflow-y: auto;

  .titleBox {
    height: 34px;
    line-height: 34px;
    background: rgba(138, 152, 177, 0.18);
  }
}
</style>
