<template>
  <div class="box">
    <div class="box-wrap w200 f12 h100" v-if="data.type =='model'">
      <div class="close" @click="closeClick">X</div>
      <div class="content cf ">
        <div class=" mt10 tc fw700" style="color:#d8a235">
          {{ data.data.name }}
        </div>
        <div class="ml10 mt3">
          缓冲区: <span class="bfe">{{ data.data.radius }}</span>m
        </div>
        <div class="ml10 mt3">
          资源数量:<span  class="bfe">{{ data.data.resourceCount }}</span>
        </div>
        <div class="ml10 mt3">
          类型:<span  class="bfe">{{ data.data.typeName }}</span>
        </div>
        <div class="ml10 mt3">
          规划时间:<span class="bfe">  {{data.data.planTime}}</span> 
        </div>
     
      </div>
    </div>
    <div class="box-wrap w200 f12" v-else-if="data.type =='resources'"> 
      <div class="close" @click="closeClick">X</div>
      <div class="content cf ">
        <div class=" mt10 tc" style="color:#d8a235">
          {{ data.data.name }}
        </div>
        <div class="ml10 mt3">
          状态: <span class="bfe">{{ data.data.status==0? '待命':'执行中' }}</span>
        </div>
        <div class="ml10 mt3">
          添加时间: <span class="bfe">{{ data.data.addtime }}</span>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
import FloatPlayer from "@/pages/platform/visual/view/uav/float-player/index2.vue";
import API from "@/api";

export default {
  components: { FloatPlayer },
  name: "DynamicLabel",
  data() {
    return {
      videoPostion: true,
      show: true,
      float_videos: []
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    id: {
      type: String,
      default: "001",
    },
    state: {
      type: String,
      default: "001",
    },
    data: {
      type: Object,
      default: null,
    },
    Read: {
      type: Function,
      default: () => { },
    },
  },
  methods: {
    closeClick() {
      if (this.closeEvent) {
        this.closeEvent();
      }
    },


  },
  mounted() {
  },
  destroyed() {
    window.this = null
  }
};
</script>


<style lang="scss" scoped>
.bfe{
  color:#42bfe5
}
.box {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 0;


  ::v-deep {
    .pine {
      position: absolute;
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      line-height: 120px;
      text-indent: 5px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40%;
        height: 60px;
        box-sizing: border-box;
        // border-bottom: 1px solid #38e1ff;
       
        // filter: drop-shadow(1px 0px 2px #03abb4);
      }
    }

    .address_info {
      width: 170px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //用省略号显示
      white-space: nowrap
    }

    .pine1 {
      position: absolute;
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      line-height: 120px;
      text-indent: 5px;

      &::before {
        content: "";
        position: absolute;
        left: 56px;
        bottom: -83px;
        width: 40%;
        height: 60px;
        box-sizing: border-box;
        // border-bottom: 1px solid #38e1ff;
        transform-origin: bottom center;
        transform: rotateZ(135deg) scale(1.5);
        animation: slash 0.5s;
        filter: drop-shadow(1px 0px 2px #03abb4);
      }
    }

    .type-28 {
      width: 140% !important;
    }

    .box-wrap {
      position: absolute;
      left: -8%;
      top: 265px;
      height: 83px;
      background: rgba(0,0,0,0.7);
      backdrop-filter: blur(4px);

      .close {
        position: absolute;
        color: #fff;
        top: 1px;
        right: 10px;
        text-shadow: 2px 2px 2px #022122;
        cursor: pointer;
        animation: fontColor 1s;
      }

      .area {
        position: absolute;
        top: 20px;
        right: 0;
        width: 95%;
        height: 30px;

        &-title {
          text-align: center;
          line-height: 30px;
        }

        .fontColor {
          font-size: 16px;
          font-weight: 800;
          color: #ffffff;
          text-shadow: 1px 1px 5px #002520d2;
          animation: fontColor 1s;
        }
      }

      .textColor {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        text-shadow: 1px 1px 5px #002520d2;
        animation: fontColor 1s;
      }

      .yellowColor {
        font-size: 14px;
        font-weight: 600;
        color: #f09e28;
        text-shadow: 1px 1px 5px #002520d2;
        animation: fontColor 1s;
      }
    }
  }
}
</style>