<template>
  <div style="height: 100%">
    <RealName v-if="haves.includes(1)"/>
    <Personnel v-if="haves.includes(2)"/>
    <Manufacturer v-if="haves.includes(3)"/>
  </div>
</template>

<script>
import Personnel from "./personnel.vue"
import RealName from "./realName.vue"
import Manufacturer from "./manufacturer.vue"
export default {
    components:{
        Personnel, RealName, Manufacturer
    },
        data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:civilUAV:newDataToday") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:civilUAV:personnelStatistics") > -1) {
      this.haves.push(2)
    }
    if (permissionList.indexOf("hn:civilUAV:aircraftStatistics") > -1) {
      this.haves.push(3)
    }
    }
}
</script>

<style>

</style>