<template>
  <ModuleBox title="飞手活跃度" width="100%" height="33%" class="civilFly">
    <div class="top-box mt20">
      <div class="left">
        <div v-for="item in list" :key="item.id" @click="isClickBox(item)" class="item-box"
          :class="item.isClick ? 'isSelect' : ''">{{ item.name }}</div>
      </div>
      <TimeSelect @on-selectTime="selectTimeFn" />
    </div>
    <div class="bottom Carousel" v-if="showList">
      <!-- 一条样式 -->
      <div class="slider-list" v-for="(item, index) in data" :key="item.name">
        <div class="item-list-left" :style="{ color: item.col }">
          <img v-if="index <= 2" :src="icon(index)">
          {{ index > 2 ? index + 1 : 'NO.' + (index + 1) }}
        </div>
        <div style="width:85%;height:100%">
          <div class="one-scroll-text hwb-flex">
            <div class="one-scroll-text-left">{{ item.name }}</div>
            <div class="one-scroll-text-right" style="color:#24c6e8">{{ item.num }}<span
                style="font-size:14px;color:#24c6e8"> 次</span> </div>
          </div>
          <div class="slider ">
            <div class="tiao">
              <div class="have" :style="{ width: (item.num / item.sum) * 10000 + '%', background: item.bgc }"></div>
              <!-- 圆点 -->
              <div class="dec-circular" :style="{ left: 'calc(' + (item.num / item.sum) * 10000 + '% - 4px)' }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height:100%;" v-else>
      <div class="out">
        <div style="width: 100%">
          <!-- 表头 -->
          <div class="header hwb-flex">
            <div class="mc">排名</div>
            <div class="lx">姓名</div>
            <div class="zt">手机号</div>
            <div class="cz">飞行架次(架次)</div>
          </div>
          <!-- 内容 -->
          <div class="content Carousel">
            <div class="hwb-flex list" :class="index % 2 === 0 ? '' : 'list2'" v-for="(item, index) in data"
              :key="index">
              <div class="mc item-list-box" :style="{ color: item.col }"><img v-if="index <= 2" :src="icon(index)">{{
                  index > 2 ? index + 1 : 'NO.' + (index + 1)
              }}</div>
              <div class="lx item-list-box" :style="{ color: item.col }">{{ item.name.length > 7 ?
                  item.name.slice(0, 8) + '...' : item.name
              }}</div>
              <div class="zt item-list-box" :style="{ color: item.col }">{{ item.phone }}</div>
              <div class="cz item-list-box" :style="{ color: item.col }">{{ item.num }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="showDetails" :title="title" :width="900" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValue" />
    </Dialog>
  </ModuleBox>
</template>

<script>
let chart;
import { Chart } from "@antv/g2";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import images_1 from '@/assets/home/pm-1.png'
import images_2 from '@/assets/home/pm-2.png'
import images_3 from '@/assets/home/pm-3.png'
import API from "@/api";

export default {
  components: { ModuleBox, TimeSelect, Table, Dialog },
  data() {
    return {
      selectTime: 4,
      typeValue: 1,
      isClick: '',
      list: [
        {
          id: 1,
          name: '图表',
          isClick: true
        },
        {
          id: 2,
          name: '列表',
          isClick: false
        },
      ],
      data: [
        // { name: '顾晓凯', num: 7, sum: 3684, col: '#F5CF41', bgc: 'linear-gradient(270deg, #FFE992 0%, #E8C75F 31%, #D3AE2D 66%, #AE6518 100%)' },
        // { name: '周宏宇', num: 5, sum: 3684, col: '#FBFBFB', bgc: 'linear-gradient(270deg, #FFFFFF 0%, #BCF7FF 31%, #E2EDFF 66%, #C7C5FF 100%)' },
        // { name: '陈杰', num: 4, sum: 3684, col: '#EB9C35', bgc: 'linear-gradient(270deg, #FFDB92 0%, #E8A95F 31%, #D3812D 66%, #EB6335 100%)' },
        // { name: '杨振涛', num: 4, sum: 3684 },
        // { name: '丁亚福', num: 4, sum: 3684 },
        // { name: '凌星', num: 3, sum: 3684 },
      ],
      data1: [
        // {
        //   name: '顾晓凯',
        //   type: '15534826533',
        //   status: '7',
        //   col: '#F5CF41'
        // },
        // {
        //   name: '周宏宇',
        //   type: '15545646873',
        //   status: '5',
        //   col: '#FBFBFB'
        // },
        // {
        //   name: '陈杰',
        //   type: '15578046854',
        //   status: '4',
        //   col: '#EB9C35'
        // },
        // {
        //   name: '杨振涛',
        //   type: '15534826533',
        //   status: '4',
        // }
        // ,
        // {
        //   name: '丁亚福',
        //   type: '15534826533',
        //   status: '4',
        // },
        // {
        //   name: '凌星',
        //   type: '15534826533',
        //   status: '3',
        // }
      ],
      showList: true,
      dataList: [
        { title: '排名', name: 'name', },
        { title: '区域', name: 'type' },
        { title: '报飞次数', name: 'status' },
      ],
      dataValue: [],
      showDetails: false,
      title: '',
      value: '',
      timer: null,
      moverStatus: false
    }
  },
  methods: {
    async getFlightFn() {
      let res = await API.AIRCRAFT.getFlight({
        type: this.selectTime
      })

      if (res.code == 200) {
        console.log(res, 'res');
        let data = []
        for (let index = 0; index < res.data.length; index++) {
          if (index == 0) {
            data.push({
              name: res.data[index].name,
              num: res.data[index].count,
              phone:res.data[index].phone,
              sum: 3684,
              col: '#F5CF41',
              bgc: 'linear-gradient(270deg, #FFE992 0%, #E8C75F 31%, #D3AE2D 66%, #AE6518 100%)'
            })
          } else if (index == 1) {
            data.push({
              name: res.data[index].name,
              num: res.data[index].count,
              phone:res.data[index].phone,

              sum: 3684,
              col: '#F5CF41',
              bgc: 'linear-gradient(270deg, #FFE992 0%, #E8C75F 31%, #D3AE2D 66%, #AE6518 100%)'
            })
          } else if (index == 2) {
            data.push({
              name: res.data[index].name,
              phone:res.data[index].phone,

              num: res.data[index].count,
              sum: 3684,
              col: '#EB9C35',
              bgc: 'linear-gradient(270deg, #FFDB92 0%, #E8A95F 31%, #D3812D 66%, #EB6335 100%)'

            })
          } else {
            data.push({
              name: res.data[index].name,
              num: res.data[index].count,
              phone:res.data[index].phone,
              sum: 3684,
            })
          }
          this.data = data
        }
        //    name: '顾晓凯', num: 7, sum: 3684, col: '#F5CF41', bgc: 'linear-gradient(270deg, #FFE992 0%, #E8C75F 31%, #D3AE2D 66%, #AE6518 100%)' },
        //     { name: '周宏宇', num: 5, sum: 3684, col: '#FBFBFB', bgc: 'linear-gradient(270deg, #FFFFFF 0%, #BCF7FF 31%, #E2EDFF 66%, #C7C5FF 100%)' },
        //     { name: '陈杰', num: 4, sum: 3684, col: '#EB9C35', bgc: 'linear-gradient(270deg, #FFDB92 0%, #E8A95F 31%, #D3812D 66%, #EB6335 100%)' },
        //     { name: '杨振涛', num: 4, sum: 3684 },
      }
    },
    CarouselBind() {
      document.querySelector('.Carousel').addEventListener('mouseout', () => {
        this.moveStatus = false
        this.Carousel()
      })
      document.querySelector('.Carousel').addEventListener('mouseover', () => {
        this.moveStatus = true
        this.CarouselStop()
      })
    },
    Carousel() {
      if (this.timer) {
        this.CarouselStop()
      }
      this.timer = setInterval(() => {
        document.querySelector('.Carousel').scrollTop++
        if (this.showList) {
          if (document.querySelector('.Carousel').scrollTop > (this.data.length - 3) * 50 && !this.moveStatus) {
            document.querySelector('.Carousel').scrollTop = 0
          }
        } else {


          if (document.querySelector('.Carousel').scrollTop == (this.data.length - 3) * 40 && !this.moveStatus) {
            document.querySelector('.Carousel').scrollTop = 0
          }
        }
      }, 60);
    },
    CarouselStop() {
      clearInterval(this.timer)
    },

    details(title, value) {
      this.title = title
      this.value = value
      this.showDetails = true
      console.log(this.showDetails);
    },
    closeDetails() {
      this.showDetails = false
    },
    icon(index) {
      console.log(index);
      switch (index) {
        // case 0:
        //   return 'iconfont icon-jinpai'
        // case 1:
        //   return 'iconfont icon-yinpai'
        // case 2:
        //   return 'iconfont icon-tongpai'
        //   default:''
        case 0:
          return images_1
        case 1:
          return images_2
        case 2:
          return images_3
        default: ''
      }
    },
    selectTimeFn(v) {
      this.selectTime = v
      this.getFlightFn()

    },
    isClickBox(item) {
      this.list.forEach(v => {
        v.isClick = false
      })
      item.isClick = true
      this.list.forEach(v => {
        if (v.isClick && v.id == 1) {
          this.showList = true
        } else if (v.isClick && v.id == 2) {
          this.showList = false
        }
      })
    },
    ChangeTaskType() {
      console.log('typeValue', this.typeValue);
    },
  },
  mounted() {
    this.ChangeTaskType()
    this.CarouselBind()
    this.Carousel()
    this.getFlightFn()
  },
  beforeDestroy() {
    this.CarouselStop()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select {
    .el-input__inner {
      width: 167px;
      height: 32px;
      background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
      backdrop-filter: blur(4px);
      font-size: 16px;
      font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
      font-weight: 400;
      color: #65ABE9;
      line-height: 24px;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }

}

.civilFly {
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;

      .item-box {
        width: 72px;
        height: 26px;
        background: linear-gradient(270deg, #032251 0%, rgba(10, 58, 129, 0.88) 46%, rgba(0, 0, 0, 0) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: rgba(148, 179, 194, 0.7);
        line-height: 26px;
        border: 1px solid #47ABF4;
        text-align: center;
        cursor: pointer;
      }

      .isSelect {
        background: linear-gradient(90deg, #37A5EE 0%, #265AD5 50%, #266EF5 100%);
        box-shadow: 0px 3px 6px 1px rgba(54, 86, 148, 0.35);
        border-radius: 4px 0px 0px 4px;
        opacity: 0.95;
        border: 1px solid #4DCEFF;
        color: #FFFFFF;
      }
    }
  }

  .bottom {
    margin-top: 20px;
    padding: 0 14px 0 0;
    height: 250px;
    
    // flex: 1;
    overflow: auto;
    .slider-list {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item-list-left {
        flex: 1;
        display: flex;
        color: #FFFFFF;
        align-items: center;
        justify-content: center;
      }

      .slider {
        padding-bottom: 12px;
        width: 100%;

        .tiao {
          background: #0080C2FF;
          height: 5px;
          position: relative;

          .dec-circular {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #DFFFFE;
            position: absolute;
            top: -1.5px;
            box-shadow: 0px 0px 16px #fff;
          }

          .have {
            height: 100%;
            background: linear-gradient(270deg, #92FFFD 0%, #5FB2E8 31%, #2D72D3 66%, #1F18AE 100%);
          }
        }
      }

      .one-scroll-text {
        // padding: 0 0 10px 0;
        width: 100%;

        .one-scroll-text-left {
          font-size: 14px;
          font-family: AppleSystemUIFont;
          color: #FFFFFF;
        }

        .one-scroll-text-right {
          font-size: 26px;
          font-family: 'DINAlternate-Bold', 'DINAlternate';
          font-weight: bold;
          color: #00FFFF;
          line-height: 30px;
        }
      }
    }
  }

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .mc {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .lx {
      width: 27%;
    }

    .zt {
      width: 25%;
    }

    .cz {
      width: 28%;
    }

    .header {
      font-size: 14px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #AAB7C3;
      text-align: center;
      background: rgba(3, 24, 66, 0.4);
      padding: 5px 0 5px 0;

    }

    .content {
      // height: 164px;
      height: 120px;
      overflow: auto;

      .list {
        height: 20px;
        padding: 10px 0;
        background: linear-gradient(270deg, rgba(132, 179, 242, 0) 0%, rgba(132, 179, 242, 0.3) 52%, rgba(132, 179, 242, 0) 100%);

        .item-list-box {
          color: #DAE4FF;
        }
      }

      .list2 {
        background: rgba(3, 24, 66, 0.4);
      }
    }
  }
}
</style>