<template>
  <ModuleBox
    title="待办事项"
    width="100%"
    height="50%"
    class="number-of-tasks"
  >
    <div class="out">
      <div style="width: 100%;height:100%">
        <!-- 状态选择 -->
        <div class="status-select hwb-flex">
          <div class="button" :class="status === 1?'button-active':''"  @mouseenter="changeStatus(1)">待审批</div>
          <!-- 权限对应后台-飞行任务-飞行调度-任务单调度-派单-单任务&多任务 -->
          <div class="button" v-if="haves.includes(1)" :class="status === 2?'button-active':''" @mouseenter="changeStatus(2)">待派单</div>
        </div>
        <!-- 表头 -->
        <div class="header hwb-flex">
          <div class="mc">任务名称</div>
          <div class="lx">任务类型</div>
          <div class="zt">{{status === 1 ? '审批状态':'派单状态'}}</div>
          <div class="cz">操作</div>
        </div>
        <!-- 内容 -->
        <VirtualList
          id="scrollval"
          class="content"
          style="overflow-y: auto;"
          :extra-props="{approvalDistribution}"
          :data-key="'id'"
          :keeps="14"
          ref="VirtualList"
          @tobottom="listToBottom"
          :data-sources="dataIsShow"
          :data-component="oneTodoCom"
          :estimate-size="40"
        />
      </div>
    </div>
    <!-- 需求详情单弹框  点待派单 -->
    <Dialog v-if="dialogIsShow" @on-close="coloseNeedPopup" title="需求单审批" :width="713">
      <div class="dispatch">
        <!-- 需求详情单 -->
        <div class="need-top-title hwb-flex">
          <div class="left"><div class="dec"></div>需求单审批</div>
          <div class="right">{{Details_List.addTime}}</div>
        </div>
        <div class="need-bottom">
          <div class="one-info"><span>需求单名称：</span><span :title="Details_List.taskTitle" style="color:#fff">{{Details_List.taskTitle.length>8?Details_List.taskTitle.slice(0,8) + '...':Details_List.taskTitle}}</span></div>
          <div class="one-info"><span>任务类别：</span><span style="color:#6AFF64">{{Details_List.taskCateName}}</span></div>
          <div class="one-info"><span>任务场景：</span><span style="color:#fff">{{Details_List.taskTypeName}}</span></div>

          <div class="one-info"><span>审核状态：</span><span :style="getStatusColor.getApplyColor(Details_List.papplyStatus)">{{getStatusColor.getApplyWord(Details_List.papplyStatus)}}</span></div>
          <div class="one-info"><span>调度状态：</span><span :style="getStatusColor.getDispatchColor(Details_List.disposeStatus)">{{getStatusColor.getDispatchWord(Details_List.disposeStatus)}}</span></div>
          <div class="one-info"><span>直属上级审批状态：</span><span :style="getStatusColor.getApplyColor(Details_List.papplyStatusByUser)">{{getStatusColor.getApplyWord(Details_List.papplyStatusByUser)}}</span></div>

          <div class="one-info"><span>发起单位：</span><span style="color:#fff">{{Details_List.organizationName}}</span></div>
          <div class="one-info"><span>发起人姓名：</span><span style="color:#fff">{{Details_List.userName}}</span></div>
          <div class="one-info"><span>联系方式：</span><span style="color:#fff">{{Details_List.phone}}</span></div>

          <div class="info-add">
            <div class="one-info"><span>开始时间：</span><span style="color:#fff">{{Details_List.startedAt}}</span></div>
            <div class="one-info"><span>结束时间：</span><span style="color:#fff">{{Details_List.endAt}}</span></div>
            <div class="one-info"><span class="Task_Name">任务区域：</span><span class="Task_Value" :style="Details_List.locationArea ? 'cursor: pointer;color: #265AD5;' : ''" @click="Selection(Details_List)">{{Details_List.locationArea ? '点击查看' : '暂无' }}</span></div>
          </div>

          <div class="one-info-long"><span>任务目标位置:</span><span style="color:#fff">{{`${Details_List.destination}`}}</span></div>
          <div class="one-info-long"><span>详细位置说明：</span><span style="color:#fff">{{`${Details_List.origin}`}}</span></div>

        </div>
        <div style="color:#AAB7C3">需求单任务内容描述：</div>
        <div class="detail">
          {{Details_List.remark}}
        </div>
        <div v-if="user_info_hn.departmentId === 60" class="need-task-type hwb-flex">
          <div>需求单是否需要创建子任务：</div>
          <div>
            <el-radio v-model="radio" label="1">是</el-radio>
            <el-radio v-model="radio" label="2">否</el-radio>
          </div>
        </div>
        <!-- 审批按钮 -->
        <div class="button hwb-flex">
          <div @click="needNoPass" class="no">不通过</div>
          <div @click="submitApproval(1)" class="yes">通过</div>
        </div>
      </div>
    </Dialog>
    <!-- 审批不通过输入原因的弹框 -->
    <Dialog v-if="notPassPopupIsShow" @on-close="closeNotPassPopup" title="审批不通过" :width="520" :top="280">
      <el-input
        type="textarea"
        :rows="6"
        placeholder="请输入不通过原因"
        v-model="reasonForFailure">
      </el-input>
      <!-- 审批按钮 -->
      <div class="button-no-pass hwb-flex">
        <div class="no" @click="closeNotPassPopup">取消</div>
        <div class="yes" @click="submitApproval(2)">确定</div>
      </div>
    </Dialog>

    <Dialog v-if="DetailsVisible" title="调度详情单" :width="713" @on-close="CloseDetails">
    <div class="Details_Box">
    <div class="Details_Item">
      <div class="Details_Item_Heard">
        <div class="Details_Item_Left">
          <span class="Left_Item_Icon"></span>
          <span class="Left_Item_Title">需求详情单</span>
        </div>
        <div class="Details_Item_Right">
          <span class="Right_Item_Time">{{Details_List.addTime}}</span>
        </div>
      </div>
      <div class="Details_Item_Body">
        <div class="Details_Item_Body_List">
          <div class="Body_Item_Box"><span class="Task_Name">需求单名称：</span><span :title="Details_List.taskTitle" class="Task_Value">{{Details_List.taskTitle.length>8?Details_List.taskTitle.slice(0,8) + '...':Details_List.taskTitle}}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">任务类别：</span><span class="Task_Value">{{Details_List.taskCateName}}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">任务场景：</span><span class="Task_Value">{{Details_List.taskTypeName}}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">审核状态：</span><span class="Task_Value">{{this.mapList.apply[Details_List.papplyStatus]}}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">调度状态：</span><span class="Task_Value">{{Details_List.taskCateName == '临时紧急任务' ? '' : this.mapList.status[Details_List.taskStatus] }}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">直属上级审批状态：</span><span class="Task_Value" :style="{color:Details_List.taskCateName == '临时紧急任务'? '#265AD5' :''}">{{Details_List.taskCateName == '临时紧急任务' ? '默认通过' : this.mapList.applystatus[Details_List.papplyStatusByUser]}}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">发起单位：</span><span class="Task_Value">{{Details_List.organizationName}}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">发起人姓名：</span><span class="Task_Value">{{Details_List.userName}}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">联系方式：</span><span class="Task_Value">{{Details_List.phone}}</span></div>
        </div>
          <div class="Details_Item_Body_List_Second">
            <div class="Body_Item_Box"><span class="Task_Name">开始时间：</span><span class="Task_Value">{{Details_List.startedAt}}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">结束时间：</span><span class="Task_Value">{{Details_List.endAt}}</span></div>
          <div class="Body_Item_Box"><span class="Task_Name">任务区域：</span><span class="Task_Value" :style="Details_List.locationArea ? 'cursor: pointer;color: #265AD5;' : ''" @click="Selection(Details_List)">{{Details_List.locationArea ? '点击查看' : '暂无' }}</span></div>
          </div>
          <div class="Details_Item_Body_List_Second Bottom-Margin"><span class="Task_Name">任务目标位置：</span><span class="Task_Value">{{`${Details_List.destination}`}}</span></div>
          <div class="Details_Item_Body_List_Second Bottom-Margin"><span class="Task_Name">详细位置说明：</span><span class="Task_Value">{{`${Details_List.origin}`}}</span></div>
          <div class="Body_Item_Bottom_Box">
            <div class="Bottom_Task_Name Task_Name">需求单任务内容描述:</div>
            <div class="Task_Details">
                {{Details_List.remark}}
            </div>
          </div>
      </div>
    </div>

    <div class="Details_Item">
      <div class="Details_Item_Heard">
        <div class="Details_Item_Left">
          <span class="Left_Item_Icon"></span>
          <span class="Left_Item_Title">所需资源</span>
        </div>
      </div>
      <div class="Resources_Item_Body">
        <!-- :rules="rules.edit_form"
        :model="edit_form" -->
          <el-form
            ref="formlist"
            class="linear-form vertical"
            label-width="80px"
            :rules="rules.form"
            :model="form">
            <el-form-item label="无人机" prop="deviceType" ref="Typedevice">
              <!-- <el-select
              v-model="form.deviceTypeId"
              placeholder="无人机类型"
              clearable
            >
              <el-option
                v-for="item in drd.uav_type"
                :key="item.id"
                :label="item.title"
                :value="item.id"
                :disabled="item.disabled"
              />
            </el-select>
              <span class="Symbol"></span> -->
              <div
            v-for="(item, index) in form.deviceType"
            :key="item.key"
            style="display: flex; align-items: center; margin-bottom: 6px"
          >
            <el-select
              v-model="item.deviceTypeId"
              placeholder="无人机类型"
              clearable
              ref="selectChange1"
            >
              <el-option
                v-for="item in drd.uav_type"
                :key="item.id"
                :label="item.title"
                :value="item.id"
                :disabled="item.disabled"
              />
            </el-select>
             <span class="Symbol"></span> 
            <el-input-number
              v-model="item.count"
              placeholder="无人机数量"
              clearable
              :min="1"
            />
            <span
              v-if="index === 0"
              @click="
               form.deviceType.length < drd.uav_type.length? form.deviceType.push({
                  deviceTypeId: null,
                  count: null,
                  key: nanoid(),
                }):$message({message: `最多只能选择${drd.uav_type.length}种无人机类型`,type: 'warning'})
              "
              class="fields-icon el-icon-plus"
            ></span>
            <span
              v-else
              @click="onetwo(index)"
              class="fields-icon icon-delete el-icon-minus"
            ></span>
          </div>
              <!-- <el-select></el-select> -->
            </el-form-item> 
            <el-form-item label="挂载" prop="mountType">
               <div
            v-for="(item, index) in form.mountType"
            :key="item.key"
            style="display: flex; align-items: center; margin-bottom: 6px"
          >
            <el-select
              v-model="item.mountTypeId"
              placeholder="挂载类型"
              clearable
              ref="selectChange2"
            >
              <el-option
                v-for="item in drd.mount_type"
                :key="item.id"
                :label="item.title"
                :value="item.id"
                :disabled="item.disabled"
              />
            </el-select>
            <span class="Symbol"></span>
            <el-input-number
              v-model="item.count"
              placeholder="挂载数量"
              clearable
              :min="1"
            />
            <span
              v-if="index === 0"
              @click="
               form.mountType.length < drd.mount_type.length ? form.mountType.push({
                  mountTypeId: null,
                  count: null,
                  key: nanoid(),
                }):$message({message: `最多只能选择${drd.mount_type.length}种挂载类型`,type: 'warning'})
              "
              class="fields-icon el-icon-plus"
            ></span>
            <span
              v-else
              @click="form.mountType.splice(index, 1)"
              class="fields-icon icon-delete el-icon-minus"
            ></span>
          </div>
            </el-form-item>
            <el-form-item label="车辆" prop="name">
              <el-cascader
              ref="elCascader"
              style="width:445px ;height:30px"
              class="input-orgname-new"
              @change="logArr"
              placeholder="请选择要分配的车辆"
              v-model="form.vehicleArray"
              :options="vehicleArrayOptions"
              :show-all-levels="true"
              :props="{
                children: 'vehicles',
                label: 'carNumber',
                value: 'id',
                multiple: true,
              }"
              clearable
            >
            </el-cascader>
            <!-- <span class="Symbol"></span> -->
             <!-- <el-input-number
                v-model="form.provideCarCount"
                placeholder="请输入配备车辆数量"
                clearable
                :min="0"
              /> -->
            </el-form-item>
            <el-form-item label="电池" prop="batteryNumbers">
              <el-select ref="selectChange3"  v-model="form.batteryNumbers" placeholder="请选择电池编号" multiple  clearable class="Battery" :multiple-limit="2">
                <el-option
                      v-for="item in drd.BatteryList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.title"
                    ></el-option>
              </el-select>
              <!-- <span class="Symbol"></span> -->
            </el-form-item>
          </el-form>
      </div>
    </div>

    <div class="Details_Item">
       <div class="Details_Item_Heard">
        <div class="Details_Item_Left">
          <span class="Left_Item_Icon"></span>
          <span class="Left_Item_Title">飞行航线</span>
        </div>
      </div>
      <div class="Route_Item_Body">
         <el-form
            class="linear-form vertical"
            label-width="80px">
            <el-form-item label="航线选择" prop="name">
            <el-select ref="selectChange4" v-model="form.flightLineId"  placeholder="请选择航线" clearable>
            <el-option
                v-for="item in drd.flightLine"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
            </el-form-item>
         </el-form>
      </div>
    </div>

    <div class="Details_Item">
      <div class="Details_Item_Heard">
        <div class="Details_Item_Left">
          <span class="Left_Item_Icon"></span>
          <span class="Left_Item_Title">服务人员</span>
        </div>
      </div>

      <div class="Service_Item_Body">
      <Table
      :columns="columns"
      :dataSource="dataSource"
      style="max-height: 400px"
    >
      <template #free="{ row }">
        <div class="action">
          <span title="是否正在作业" class="iconfont">{{
            row.free === 1 ? "空闲" : "非空闲"
          }}</span>
        </div>
      </template>
      <template #status="{ row }">
        <div class="action">
          <span title="分配情况" class="iconfont">{{
            statusList[row.status]
          }}</span>
        </div>
      </template>
      <template #action="{ row }">
        <el-checkbox
          v-model="dataSource[row.index].checked"
          @change="rows(row.id)"
        ></el-checkbox>
      </template>
    </Table>
      </div>
    </div>
    <div  class="Details-footer">
    <div class="Button Left_Button" @click="CloseDetails">取 消</div>
    <div class="Button Right_Button" type="primary" @click="SubmitDetails">确 定</div>
    </div>
  </div>
    </Dialog>
    <RegionalSelection 
    :ShowSelection="ShowSelection" 
    :cancelSaveArea="cancelSaveArea" 
    :addallairArray="addallairArray"
    :leftOnclick="leftOnclick"
    :rigthOnclick="rigthOnclick"
    :move="move"
    :rowList="rowList"
    ></RegionalSelection>
  </ModuleBox>
</template>

<script>
import RegionalSelection from '@/components/common/regionalSelection/index.vue'
import Table from "@/components/common/table";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Dialog from '@/components/common/new-dialog'; // 弹框组件
import API from "@/api";
import VirtualList from 'vue-virtual-scroll-list'  // 无限滚动插件
import oneTodo from './one-to-do.vue'
import { nanoid } from "nanoid";
import { mapGetters } from "vuex";
import getStatusColor from "@/api/statusColorGet.js"; // 获取颜色函数
export default {
  components: { ModuleBox, Dialog , Table, VirtualList, oneTodo , RegionalSelection},
  data() {
        let editDevtype = (rule, value, callback) => {
      value.forEach((v)=>{
        if (v.deviceTypeId==null || v.deviceTypeId=='') {
            callback(new Error('请选择无人机类型'));
        }
        if (!v.count) {
          callback(new Error('无人机数量不能为0'));
        }
      })
      callback()
    };
    let editMountType = (rule, value, callback) => {
      value.forEach((v)=>{
        if (v.mountTypeId==null || v.mountTypeId=='') {
            callback(new Error('请选择挂载类型'));
        }
        if (!v.count) {
          callback(new Error('无人机数量不能为0'));
        }
      })
      callback()
    };
    let batteryNumbersValidator = (rule, value, callback) => {
      if(value.length == 0){
        callback(new Error("请选择电池"));
      } else if (value.length == 1) {
        callback(new Error("请选择两块电池"));
      }
      callback();
    };
    return {
      haves:[],//权限
      getStatusColor,
      oneTodoCom: oneTodo, // 列表一条的组件
      value:'',
      isReq: false, // 是否正在请求数据
      notPassPopupIsShow:false, // 控制是否展示审批不通过弹框
      reasonForFailure: '', // 不通过原因
      radio:"1",//审批的时候确定它是单任务或者多任务
      pageSize: 12, // 每次请求数据量
      status: 1, // 待派发或待审批  1：待审批 2：待派发
      dialogIsShow: false,   // 审批弹框状态
      dataIsShow: [], // 最终要展示列表
      data: [], // 待审批列表信息
      dataTotalCount: 0,  // 待审批列表数量
      data2: [], // 待派发列表信息
      data2TotalCount: 0,  // 待派发列表数量
      DetailsVisible:false,
      columns: [
        { prop: "name", label: "运维人员" },
        { prop: "phone", label: "联系手机号" },
        { prop: "free", slot: true, label: "是否正在作业" },
        { prop: "status", slot: true, label: "分配情况" },
        { prop: "organizationName", label: "所属单位" },
        {
          prop: "action",
          slot: true,
          label: "操作",
          width: "100px",
        },
      ],
      mapList: { //列表数据过滤
        status: {
          [-1]: "已取消",
          0: "待执行",
          1: "已完成",
          3: "执行中",
        },
        apply: {
          [-1]: "暂无",
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
        applystatus:{
          0:"待审批",
          1:"审批通过",
          2:"审批不通过",
          3:"默认通过",
          null:'/'
        }
      },
      dataSource: [],
      selsectId:[],
      statusList: {
        0: "未分配",
        1: "已分配",
        2: "其他任务分配",
      },
      drd:{
        flightLine:[],
        uav_type:[],
        mount_type:[],
        BatteryList: []
      },
      form:{
        //航线
        flightLineId:null,
        //无人机
        deviceType: [{ deviceTypeId: null, count: null, key: nanoid() }],
        //挂载
        mountType: [{ mountTypeId: null, count: null, key: nanoid() }],
        //车辆
        vehicleArray: [],
        //电池
        batteryNumbers: [],
      },
      vehicleArrayOptions: [], // 车辆选择下拉列表
      Details_List:null, //任务数据
      rules: { //验证规则
        form: {
          batteryNumbers: [
            { required: true, validator: batteryNumbersValidator, trigger: "change" },
          ],
          deviceType: [
            { required: true, validator: editDevtype, trigger: "change" },
          ],
          mountType: [
            { required: true, validator: editMountType, trigger: "change" },
          ],
        },
      },
      timer:null,
      moveTable:false,
      //区域选择
      ShowSelection:false,
      areaPointList: null, // 发起需求区域绘制点对象
      rowList:null,//点击区域任务
    };
  },
  computed: {
    ...mapGetters([
      "user_info_hn",
    ]),
  },
  methods: {
    leftOnclick(data) {
          console.log(data);
    },
    rigthOnclick(data) {
       console.log(data);
    },
    move(data) {
       console.log(data);
    },
    cancelSaveArea(){ //关闭区域选择
      this.rowList = null
      this.ShowSelection = false
    },
    // 将子组件获取的禁飞区传递出来
    addallairArray(value) {
      this.allairArray = value
    },
    Selection(row){
      console.log(row.locationArea);
      if (row.locationArea) {
      this.rowList = row
      this.ShowSelection = true
      }
    },
    init_haves(){
          const permissions = JSON.parse(localStorage.getItem("permissions"));
          const permissionList = permissions.map((item) => item.permissionSign);
          if (permissionList.indexOf("hn:tasks:handle") > -1) {
            this.haves.push(1)
          }
    },
    scrollBind(){
      document.getElementById('scrollval').addEventListener('mouseout',()=>{
        this.onscroll()
        this.moveTable=false
      })
      document.getElementById('scrollval').addEventListener('mouseover',()=>{
        this.rollStop()
        this.moveTable=true
      })
    },
    rollStop(){
    clearInterval(this.timer);
    },
    onscroll(e,n){
        this.rollStop()
     this.timer = setInterval(() => {
      document.getElementById('scrollval').scrollTop++
      if (this.status == 1) {
        if (document.getElementById('scrollval').scrollTop==(this.dataTotalCount-6)*40) {
        this.listToBottom()
      }
      } else {
        if (document.getElementById('scrollval').scrollTop==(this.data2TotalCount-6)*40) {
        this.listToBottom()
      }
      }
     }, 60);
    },
    listToBottom() { // 滚到底了做一些事
      if(this.status == 1){
        if(this.dataTotalCount>this.data.length){
          this.getDataList(0,(this.data.length/this.pageSize) + 1)
        }else if(!this.moveTable){
            document.getElementById('scrollval').scrollTop=0
        }
      } else {
        if(this.data2TotalCount>this.data2.length){
          this.getDataList(1,(this.data2.length/this.pageSize) + 1)
        }else if(!this.moveTable){
            document.getElementById('scrollval').scrollTop=0
        }
      }
    },
    async getDataList(status, pageNum) {  // 获取数据  0：待审批 1：待派单   pageNum: 第几页
      if(this.isReq){  // 如果是正在请求数据 就不请求了
        return
      }
      this.isReq = true
      let res
      if(status === 0) {
        status='0'
        res = await API.TASK.msgList({approvalStatus:status,pageSize:this.pageSize,pageNo:pageNum})
        if(res.status === 1){
          this.data = [...this.data,...res.data.data]
          this.dataTotalCount = res.data.totalCount
          if(this.status === 1){
            this.dataIsShow = this.data
          }
        }else{
          // this.$message.error('待办事项列表请求失败!');
        }
      } else {
        res = await API.TASK.intuitive({
          isSubtasks: 2,
          disposeStatus: status,
          pageNum,
          pageSize: this.pageSize
        })
        if(res.status === 1) {
          this.data2 = [...this.data2,...res.data.data.task]
          this.data2TotalCount = res.data.totalCount
          if(this.status === 2){
            this.dataIsShow = this.data2
          }
          
        }else{
          // this.$message.error('待办事项列表请求失败!');
        }
      }
      this.isReq = false
    },
    // ----------------------------------------------------------------------------------------------------审批函数开始
    async submitApproval(num) { //审批通过与不通过 1通过 2不通过
      let obj = {
        id: this.Details_List.id,
        status: num,
      };
      if (num == 2) {  // 审批不通过
        if(!this.reasonForFailure){
          this.$message.warning("请输入审批不通过原因");
        } else{
          obj.approvalRemark = this.reasonForFailure;
          const res = await API.TASK.approvalRequirements(obj);
          if(res.status == 1){
            this.$message.success("处理成功，该需求审批不通过！");
            this.reasonForFailure = null
            this.notPassPopupIsShow = false
            this.dialogIsShow = false
            this.data = [] 
            this.getDataList(0,1)  // 审批后重新请求数据  把之前的数据清空
            this.$refs.VirtualList.reset();  // 切换列表时把滚动条重置到顶部
          } else{
            this.$message.error(res.message);
          }
        }
      } else { // 审批通过
        obj.isSubtasks = this.radio;
        const res = await API.TASK.approvalRequirements(obj);
        if (res.status == 1) {
          this.$message.success("处理成功，该需求审批通过！");
          this.dialogIsShow = false
          this.data = [] 
          await this.getDataList(0,1)  // 审批后重新请求数据  把之前的数据清空
          this.$refs.VirtualList.reset();  // 切换列表时把滚动条重置到顶部
          this.data2 = [] 
          this.getDataList(1,1)  // 派单成功后重新请求数据  把之前的数据清空
        } else{
          this.$message.error(res.message);
        }
      }
    },
    closeNotPassPopup() { // 关闭不通过原因弹框
      this.notPassPopupIsShow = false
      this.reasonForFailure=null
    },
    needNoPass() { // 打开审批不通过弹框
      this.notPassPopupIsShow = true
    },
    coloseNeedPopup() { // 关闭处理需求弹框
      this.dialogIsShow = false
    },
    // -------------------------------------------------------------------------------------------------------审批函数结束
   async approvalDistribution(item) { // 审批或者派发
      this.Details_List = JSON.parse(JSON.stringify(item))
      if(item.papplyStatus === 0 && !item.disposeStatus){
        const {data} = await API.TASK.getOneSubtaskNew(item.id)
        this.Details_List = data
        this.dialogIsShow = true
      }else{
        await this.operatorList(item.id);
        await this.drd_uav_type()
        await this.drd_mount_type();
        await this.VehicleList();
        await this.getBatteryList();
        const {data} = await API.TASK.approveMsgInfo(item.id)
        console.log(data);
        this.Details_List = data
        this.DetailsVisible = true
      }
    },
    SubmitDetails(){ //调度详情保存
     // 保存处理需求
      if (this.selsectId.length <= 0) {
        this.$message.warning("未分配运维人员");
        return;
      }else{
        this.$refs["formlist"].validate(async (valid,object) => {
          if (valid) {
            const id = this.Details_List.id;
            const { vehicleArray, ...rest } = this.form;
            let vehicleId = [];
            if (vehicleArray.length > 0) {
              vehicleArray.map((item) => {
                vehicleId.push(item[1] + "");
              });
            }
            vehicleId = "[" + vehicleId.toString() + "]";
            if (vehicleId == "[]") {
              vehicleId = null;
            }
            const params = {
              ...rest,
              deviceType:
                rest.deviceType.length >= 1 && rest.deviceType[0].deviceTypeId
                  ? rest.deviceType
                  : [],
              mountType:
                rest.mountType.length >= 1 && rest.mountType[0].mountTypeId
                  ? rest.mountType
                  : [],
              id,
              operatorIds: this.selsectId, // 运维人员
              vehicleId,
              processingStatus: 1,
            };
            const res = await API.TASK.newhandle(params);
            if (res.status == 1) {
              this.$el_message("处理成功", () => {
                  this.DetailsVisible = false
                  this.data2 = [] 
                  this.getDataList(1,1)  // 派单成功后重新请求数据  把之前的数据清空
                  this.form={
                      flightLineId:null,
                      deviceType: [{ deviceTypeId: null, count: null, key: nanoid() }],
                      mountType: [{ mountTypeId: null, count: null, key: nanoid() }],
                      vehicleArray: [],
                      batteryNumbers: [],
                    }
              });
            } else {
              this.$el_message(res.message);
            }
          } else {
            setTimeout(() => {  // 如果不通过验证，跳到没有通过验证的输入框并获取焦点
              let isError = document.getElementsByClassName("is-error");
              if (isError.length > 0) {
                isError[0].querySelector('input').focus()
              }
            }, 100)
          }
        });
      }
    },
    CloseDetails(){  //关闭调度详情
      this.DetailsVisible = false
      this.form={
                      flightLineId:null,
                      deviceType: [{ deviceTypeId: null, count: null, key: nanoid() }],
                      mountType: [{ mountTypeId: null, count: null, key: nanoid() }],
                      vehicleArray: [],
                      batteryNumbers: [],
                    }
      if(this.selsectId.length) this.selsectId = []
    },
    changeStatus(num){  // 鼠标移上去改变列表展示的东西
      this.status = num
      if(num === 1){
        this.dataIsShow = this.data
      } else {
        this.dataIsShow = this.data2
      }
      this.$refs.VirtualList.reset();  // 切换列表时把滚动条重置到顶部
      if(this.status == 1){
        if(this.dataTotalCount>this.data.length || this.data.length>6){
          this.onscroll()
        }else{
          this.rollStop()
        }
      } else {
        if(this.data2TotalCount>this.data2.length || this.data2.length>6){
          this.onscroll()
        }else{
          this.rollStop()
        }
      }
    },
        async operatorList(id) {// 获取飞手排班列表
      const { data } = await API.TASK.flyingUser({ taskId: id });
      data.map((item, index) => {
        item.checked = false;
        item.index = index;
        if (this.selsectId.length > 0 && this.selsectId[0] == item.id) {
          item.checked = true;
        }
      });
      this.dataSource = data;
    },
        async drd_mount_type() { //获取挂载类型列表
      try {
        let res = await API.MOUNT.GetDrdType();
        res = res.data
        
        this.drd.mount_type = res.map((item) => ({
          ...item,
          title: item.name,
          disabled: false
        }));
      } catch (e) {
        console.error(e);
      }
    },
    rows(id) {
      console.log(id);
      // 存储选择的运维
      if (this.selsectId.indexOf(id) > -1) {
        this.selsectId = [];
      } else {
        this.selsectId = [id];
      }
    },
    async FlightLine() {  // 获取航线下拉数据
      const { data } = await API.TASK.Drd()
      this.drd.flightLine = data
    },
    async drd_uav_type() {
      try {
        let res = await API.UAV.GetDrdType();
        res = res.data
        this.drd.uav_type = res.map((item) => ({
          ...item,
          title: item.name,
          disabled: false
        }));
      } catch (e) {
        console.error(e);
      }
    },
    nanoid() {
      return nanoid();
    },
    onetwo(index){
      this.form.deviceType.splice(index, 1)
      this.form.deviceType.forEach((v)=>{
        
        if (v.deviceTypeId==null || v.deviceTypeId=='' || !v.count) {
            
        }else{
          this.$refs.Typedevice.clearValidate()
        }
      })
    },
    logArr(){
      console.log(this.form.vehicleArray);
    },
    async VehicleList() {  // 车辆列表获取
      try {
        let res = await API.TASK.VehicleList();
        this.vehicleArrayOptions = res.data
        this.vehicleArrayOptions.map(item=>{
          item.carNumber = item.carTypeName
          if(!item.vehicles){
            item.disabled = true
          }
        })
      } catch (e) {
        console.error(e);
      }
    },
    async getBatteryList() { //电池列表
      const { data } = await API.TASK.BatteryList()
      this.drd.BatteryList = data
    },
  },
 async mounted() {
    this.init_haves()
    await this.getDataList(0, 1)
    if(this.haves.includes(1)) await this.getDataList(1, 1)
    this.dataIsShow = this.data
    await this.FlightLine()
    // this.onscroll()
    this.changeStatus(1)
    this.scrollBind()
  },
beforeDestroy() {
      if (this.timer) clearInterval(this.timer);
},
    watch: {
    $route() {
      this.operatorList();
    },
    "fromRef.flightUser"(val) {
      if (val && val.length > 0) {
        this.selsectId = val.map((item) => {
          return item.id;
        });
      }
    },
    selsectId(val) {
      if (val.length > 0) {
        this.dataSource.map((item) => {
          if (item.id == val[0]) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
      }
    },
        "form.deviceType": {  // 配备无人机下拉限制
      handler: function(val){
        let indexarr = []
        val.forEach(item=>{
          if(item.deviceTypeId) {
            indexarr.push(item.deviceTypeId)
          }
        })
        this.drd.uav_type.forEach(itemIn=>{
            itemIn.disabled = false
        })

        indexarr.forEach(item=>{
          this.drd.uav_type.forEach(itemIn=>{
            if(itemIn.id == item) {
              itemIn.disabled = true
            }
              
          })
        })
      },
      deep:true,
      immediate:true
    },
        'form.mountType': {   // 配备挂载下拉选择限制
      handler: function(val){
        let indexarr = []
        val.forEach(item=>{
          if(item.mountTypeId) {
            indexarr.push(item.mountTypeId)
          }
        })
        
        this.drd.mount_type.forEach(itemIn=>{
            itemIn.disabled = false
        })

        indexarr.forEach(item=>{
          this.drd.mount_type.forEach(itemIn=>{
            if(itemIn.id == item) {
              itemIn.disabled = true
            }
              
          })
        })
      },
      deep:true,
      immediate:true
    },
  },
};
</script>

<style lang="scss" scoped>
/* 审批不通过样式 */
::v-deep{
  .el-textarea__inner{
    background: rgba(0,0,0,0.6);
    border: 0;
    border-radius: 6px;
    padding: 16px 24px;
    color: #fff;
  }
}
.button-no-pass{
  margin-top: 40px;
  padding: 0 80px;
  color: #fff;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  .no{
    width: 126px;
    height: 40px;
    background: rgba(219,27,27,0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,0,0,0.5);
    border-radius: 6px;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    cursor: pointer;
  }
  .yes{
    width: 126px;
    height: 40px;
    background: rgba(38,71,238,0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,18,55,0.5);
    border-radius: 6px;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  }
}
/* 调度详情单样式 */
.dispatch{
  /* height: 456px; */
  width: 100%;
  overflow: auto;
  .need-task-type{
    margin-top: 16px;
    width: 300px;
    color: rgb(170, 183, 195);
    ::v-deep{
      .el-radio__label{
        color: #fff;
      }
    }
  }
  .need-top-title{
    height: 34px;
    .left{
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      padding-left: 10px;
      position: relative;
      .dec{
        position: absolute;
        top: 2px;
        left: 0;
        width: 4px;
        height: 16px;
        background: #FFBD36;
        border-radius: 2px;
      }
    }
    .right{
      font-size: 14px;
      color: #AAB7C3;
    }
  }
  .need-bottom{
    margin-top: 28px;
    font-size: 14px;
    color: #AAB7C3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .info-add{
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
    }
    .one-info{
      width: 33.3%;
      margin-bottom: 12px;
    }
    .one-info-long{
      width: 100%;
      margin-bottom: 12px;
    }
  }
  .detail{
    height: 107px;
    margin-top: 12px;
    background: rgba(0,0,0,0.6);
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 24px;
  }
  .button{
    margin-top: 40px;
    padding: 0 140px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    .no{
      width: 126px;
      height: 40px;
      background: rgba(219,27,27,0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,0,0,0.5);
      border-radius: 6px;
      border: 1px solid;
      cursor: pointer;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }
    .yes{
      width: 126px;
      height: 40px;
      background: rgba(38,71,238,0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,18,55,0.5);
      border-radius: 6px;
      border: 1px solid;
      cursor: pointer;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }
  }
}
//调度详情单
::v-deep{
  .Resources_Item_Body,.Route_Item_Body{
    .linear-form.vertical .el-form-item {
      .fields-icon{
        width: 34px;
        height: 28px;
        background: rgba(0,0,0,0.5);
        border: 1px solid #768094;
        text-align: center;
        line-height: 30px;
        margin-left: 10px;
        color: #fff;
        cursor: pointer;
        }
    .Symbol{
      margin: 0 5px;
      display: inline-block;
      width: 10px;
      height: 1px;
      background: #D8D8D8;
      vertical-align: middle;
    }
    .el-form-item__label {
    line-height: 32px;
    float: left;
    text-align: right;
    color: #AAB7C3;
  }
  .el-form-item__content {
    .el-select{
    width: 210px;
    height: 30px;
    background: rgba(0,0,0,0.5);
  }
    .el-select.Battery{
    width: 443px;
    height: 30px;
    background: rgba(0,0,0,0.5);
  }
  .el-input-number {
    //添加数据按钮右侧
    width: 212px;
    line-height: 38px;
    .el-input-number__increase{
    border-left: 1px solid #768094;
    height: 30px;
    line-height: 32px;
    margin-top: 2px;
  }
    //添加数据按钮左侧
    .el-input-number__decrease{
    border-right: 1px solid #768094;
    height: 30px;
    line-height: 32px;
    margin-top: 2px;
  }
  }
  }
  
  .el-input {
    .el-input__inner{
    border: 1px solid #768094;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #AAB7C3;
    line-height: 20px;
  }
  .el-input__suffix .el-input__suffix-inner .el-input__icon{
    line-height: 30px;
  }
  }
  }
  }
  
}
  .Details_Box{
    height: 700px;
    width: calc(100% - 6px);
    padding-right: 10px;
    overflow: auto;
    .Details_Item{
      margin-bottom: 32px;
      .Details_Item_Heard{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 13px;
        border-bottom: 1px solid rgba(170,183,195,0.15);
        margin-bottom: 15px;
        .Details_Item_Left{
          display: flex;
          align-items: center;
          .Left_Item_Icon{
            display: inline-block;
            width: 4px;
            height: 16px;
            background: #FFBD36;
            border-radius: 2px;
          }
          .Left_Item_Title{
            padding-left: 5px;
            width: 90px;
            height: 24px;
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 24px;
          }
        }
        .Details_Item_Right{
          .Right_Item_Time{
            width: 116px;
            height: 16px;
            font-size: 14px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #AAB7C3;
            line-height: 16px;
          }
        }
      }
      .Details_Item_Body{
        width: 100%;
        .Details_Item_Body_List{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
        }
        .Details_Item_Body_List_Second{
          display: flex;
          align-items: center;
          &.Bottom-Margin{
            margin-bottom: 11px;
          }
        }
        .Body_Item_Box{
          width: 33%;
          margin-bottom: 11px;
        }
        .Body_Item_Bottom_Box{
          .Bottom_Task_Name{
            margin-bottom: 11px;
          }
          .Task_Details{
            padding: 16px 24px;
            height: 75px;
            background: rgba(0,0,0,0.6);
            font-size: 14px;
            font-family: SourceHanSansSC-Regular, SourceHanSansSC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            overflow: auto;
          }
        }
        .Task_Name{
            // width: 70px;
            // height: 20px;
            font-size: 14px;
            font-family: SourceHanSansSC-Regular, SourceHanSansSC;
            font-weight: 400;
            color: #AAB7C3;
            line-height: 20px;
          }
          .Task_Value{
            // width: 56px;
            // height: 20px;
            font-size: 14px;
            font-family: SourceHanSansSC-Regular, SourceHanSansSC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
          }
      }
  }
  .Details-footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .Button{
      text-align: center;
      width: 126px;
      height: 40px;
      border-radius: 6px;
      // border: 1px solid;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 40px;
      cursor: pointer;
    }
    .Left_Button{
      // width: 126px;
      // height: 40px;
      // border-radius: 6px;
      // border: 1px solid;
      background: rgba(38,71,238,0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,18,55,0.5);
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-right: 20px;
    }
    .Right_Button{
      background: rgba(219,27,27,0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,0,0,0.5);
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      margin-left: 20px;
    }
  }
  }
.number-of-tasks{
  width: 100%;
  .out{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: top;
    justify-content: center;
    text-align: center;
    .status-select{
      cursor: pointer;
      // width: 170px;
      height: 30px;
      margin-bottom: 16px;
      border: 1px solid #4DCEFF;
      border-radius: 4px;
      .button{
        // background: rgba(0,0,0,0.7);
        // color: #94B3C2;
        // font-size: 20px;
        // text-align: center;
        // width: 50%;
        // height: 100%;
        // line-height: 26px;
        // background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
        // border: 1px solid;
        // border-image: linear-gradient(180deg, rgba(113, 153, 242, 0.44), rgba(60, 91, 166, 0)) 1 1;
        // width: 50%;
        flex: 1;
        height: 100%;
        line-height: 30px;
        background: linear-gradient(90deg, #05348B 0%, #002363 50%, #05348B 100%);
        box-shadow: 0px 3px 6px 1px rgba(54,86,148,0.35);
        // border-radius: 0px 4px 4px 0px;
        opacity: 1;
        // border: 1px solid #47ABF4;
        color: #fff;
      }
      .button-active{
        // width: 50%;
        flex: 1;
        height: 100%;
        line-height: 30px;
        background: linear-gradient(90deg, #37A5EE 0%, #265AD5 50%, #266EF5 100%);
        box-shadow: 0px 3px 6px 1px rgba(54,86,148,0.35);
        // border-radius: 4px 0px 0px 4px;
        opacity: 0.95;
        // border: 1px solid #4DCEFF;
        // color: #fff;
        // background: rgba(38,71,238,0.71);
        // box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0,18,55,0.5);
        // border: 1px solid;
        // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        // background-color: #1F4DCB;
      }
    }
    .mc{
      width: 35%;
    }
    .lx{
      width: 27%;
    }
    .zt{
      width: 25%;
    }
    .cz{
      width: 13%;
    }
    .header{
      font-size: 16px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #C4E5FF;
      text-align: center;
      // background: rgba(3,24,66,0.4);
      padding:10px 0 10px 0;
      
    }
    .content{
      margin-top: 20px;
      height: 280px;
      // height: calc(100% - 100px);
      overflow: auto;
      .list{
        padding: 10px 0;
        background: linear-gradient(270deg, rgba(132,179,242,0) 0%, rgba(132,179,242,0.3) 52%, rgba(132,179,242,0) 100%);
        
      }
      .list2{
        background: rgba(3,24,66,0.4);
      }
    }
  }
}
</style>