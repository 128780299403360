<template>
  <ModuleBox title="三维资源共享" width="100%" height="23vh" class="resources">
    <div class="body">
      <div class="top">
        <div class="left">
          <div :class="type === 2 ? 'btnSelect ' : 'btn'" @click="handle_search(2)">
            累计
          </div>
          <div :class="type === 1 ? 'btnSelect' : 'btn'" @click="handle_search(1)">
            局办
          </div>
        </div>
        <div class="right cp" v-if="type === 1">
          <div :class="seleltType === 2 ? 'btnSelect ' : 'btn'" @click="selelt(2)">
            调用次数
          </div>
          <div :class="seleltType === 1 ? 'btnSelect' : 'btn'" @click="selelt(1)">
            申请次数
          </div>
        </div>
      </div>
      <div class="bottom" >
        <div class="cumulative" v-if="type === 2">
          <div style="width:25%" @click="Details(item.id)" v-for="(item,index) in list" :key="item.id">
            <div style="text-align: center;"><span class="num">{{ item.num }}</span></div>
            <div style="cursor: pointer;" :class="getImg(item.id)"></div>
            <div style="text-align: center;"><span class="text">{{ item.text }}</span></div>
          </div>

        </div>
        <div class="bureauoffice" v-else>
          <!-- 一条样式 -->
          <div class="slider-list hwb-flex-column" v-for="item in deptCountData" :key="item.name">
            <div class="one-scroll-text hwb-flex">
              <div class="one-scroll-text-left">{{item.name}}</div>
              <div class="one-scroll-text-right" v-if="seleltType==2">{{item.num1}}次</div>
              <div class="one-scroll-text-right" v-else>{{item.num}}次</div>

            </div>
            <div class="slider ">
              <div class="tiao" v-if="seleltType==2">
                <div class="have" :style="{width: (item.num1/item.sum)*1000 + '%'}"></div>
                <div class="dec-circular" :style="{left:'calc(' +(item.num1/item.sum)*1000 + '% - 4px)'}"></div>
              </div>
              <div class="tiao" v-else>
                <div class="have" :style="{width: (item.num/item.sum)*1000 + '%'}"></div>
                <div class="dec-circular" :style="{left:'calc(' +(item.num/item.sum)*1000 + '% - 4px)'}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog class="Details" v-if="showDetails" :title="title" :width="widthVal" @on-close="closeDetails">
      <Table :cellName="cellName" :dataSource="dataValue">
        <template #status="{row}">
          {{ApprovalFilter(row.status)}}
        </template>
      </Table>
  
      <!-- <div class="DemandList_Footer" v-if="dataValue.length">
        <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper" :total="tableTotal" :page-size="size || 10" />
      </div> -->
    </Dialog>
  </ModuleBox>
</template>

<script>
import API from "@/api"
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
export default {
  components: {
    ModuleBox,
    Table,
    Dialog
  },
  created() {
    this.deptCountFn()
    this.serviceShareCountFn()
  },
  data() {
    return {
      cellName:[],
      dataValue:[],
      showDetails:false,
      currentPage:1,
      tableTotal:0,
      size:1000,
      title:'三维资源共享',
      widthVal:0,
      type: 2,
      seleltType: 2,
      list: [
        {
          id: 1,
          num: 5,
          text: '共享申请单位(个)'
        },
        {
          id: 2,
          num: 52,
          text: '申请次数(次)'
        },
        {
          id: 3,
          num: 30,
          text: '共享地图数量(张)'
        },
        {
          id: 4,
          num: 52,
          text: '调用次数(次)'
        }
      ],
      data: [
        { name: '公安局', num: 25, sum: 3684 },
        { name: '综合执法局', num: 15, sum: 3684 },
        { name: '应急管理局', num: 5, sum: 3684 },
        { name: '自然资源和规划局', num: 2, sum: 3684 },
        { name: '交通局', num: 5, sum: 3684 },
      ],
      deptCountData: []
    }
  },
  methods: { 
          ApprovalFilter(status){//筛选状态并返回
        switch (status) {
          case 0:
            return '待审批'
            break;
          case 1:
            return '同意'
            break;
          case 2:
            return '拒绝'
            break;
        
          default:
            break;
        }
      },
    onChangePage(val){
      this.currentPage=val
      if (this.id==2) {
        this.deptCountFn()
      } else {
        this.get_list()  
      }
    },
    closeDetails(){
      this.showDetails=false
      this.currentPage=1
      this.tableTotal=0
      this.size=1230
    },
    Details(id){
      this.id=id
      this.showDetails=true
      if (id==2) {
        this.deptCountFn()
        this.widthVal=624
        this.cellName=[
        { title: '序号', name: 'i'},
        { title: '申请单位名称', name: 'name' },
        { title: '申请次数', name: 'num' },
        { title: '调用次数', name: 'num1' },
        { title: '共享地图张数', name: 'num2' },
        ]
        this.deptCountData.forEach((v,i)=>v.i=i+1)
        this.dataValue=this.deptCountData
      } else {
        this.widthVal=951
        this.cellName=[
        { title: '序号', name: 'i' },
        { title: '申请单位名称', name: 'organizationName' },
        { title: '共享时间', name: 'addtime' },
        { title: '共享地图名称', name: 'name' },
        { title: '共享状态', name: 'status' , slot:true },
        ]
        this.get_list()
      }
    },
   async get_list(){
      let obj = {
        pageNo:this.currentPage,
        pageSize:this.size
      }

      console.log(obj,'objxx');
      let res = await API.DEVICE.threeDimensionalList(obj)
        if (res.code==200) {
          this.tableTotal=res.data.total
          res.data.records.forEach((v,i)=>v.i=i+1)
          this.dataValue=res.data.records
        } else {
          this.$message.error(res.message)
        }
    },
    async serviceShareCountFn() {
      let res = await API.MODEL.serviceShareCount()
      if (res.code == 200) {
        let data = []
        for (const key in res.data) {
          if (key == 'applyCount') {
            data.push(
              {
                id: 2,
                num: res.data[key],
                text: '申请次数(次)'
              },
            )
          } else if (key == 'mapCount') {
            data.push(
              {
                id: 3,
                num: res.data[key],
                text: '共享地图数量(张)'
              },
            )
          } else if (key == 'useCount') {
            data.push(
              {
                id: 4,
                num: res.data[key],
                text: '调用次数(次)'
              },
            )
          } else {
            data.push(
              {
                id: 1,
                num: res.data[key],
                text: '共享申请单位(个)'
              },
            )
          }
        }
        this.list = data
      }
      //          {
      //     "code": 200,
      //     "message": "成功",
      //     "data": {
      //         "applyCount": 5,
      //         "organizitionCount": 3,
      //         "useCount": 1,
      //         "mapCount": 3
      //     },
      //     "ok": true
      // }
    },
    // 局办申请或调用次数
    async deptCountFn() {
      let res = await API.MODEL.deptCount()
      if (res.code == 200) {
         
        let data = []
        res.data.forEach(element => {
          data.push({
            name: element.anotherName,
            num: element.distCode,
            num1: element.grade,
            sum: 1000,
            num2:element.online
          })
        });
        this.tableTotal=res.data.length
        this.deptCountData = data
      }
    },
    handle_search(data) {
      this.type = data
      this.seleltType = 2
      this.deptCountFn()
    },
    selelt(value) {
      this.seleltType = value
    },
    getImg(value) {
      switch (value) {
        case 1:
          return 'company';
        case 2:
          return 'frequency';
        case 3:
          return 'map';
        case 4:
          return 'call';
        default:
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.resources {
  .body {
    .top {
      display: flex;
      justify-content: space-between;

      .left,
      .right {
        display: flex;
      }

      .btnSelect {
        width: 72px;
        height: 26px;
        background: #1F4DCB;
        text-align: center;

        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 26px;
      }

      .btn {
        width: 72px;
        height: 26px;
        background: rgba(0, 0, 0, 0.7);

        text-align: center;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: rgba(148, 179, 194, 0.7);
        line-height: 26px;
      }

    }

    .bottom {
      margin-top: 20px;
      
      .cumulative {
        display: flex;

        .num {
          width: 29px;
          height: 31px;
          font-size: 28px;
          font-family: UniDreamLED;
          color: #FFCC00FF;
          line-height: 32px;
          letter-spacing: 1px;
        }

        .call {
          width: 90px;
          height: 43px;
          background: url("~@/assets/three/call.png") no-repeat;
          background-size: 100%;
        }

        .company {
          width: 90px;
          height: 43px;
          background: url("~@/assets/three/company.png") no-repeat;
          background-size: 100%;
        }

        .frequency {
          width: 90px;
          height: 43px;
          background: url("~@/assets/three/frequency.png") no-repeat;
          background-size: 100%;
        }

        .map {
          width: 90px;
          height: 43px;
          background: url("~@/assets/three/map.png") no-repeat;
          background-size: 100%;

        }

        .text {
          width: 108px;
          height: 20px;
          font-size: 14px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #DAE9FF;
          line-height: 20px;
        }
      }

      .bureauoffice {
        margin: 4px 4px 0 6px;
        padding: 0 14px 0 0;
        height: 138px;
        // flex: 1;
        overflow: auto;

        .slider-list {
          // width: 100%;
          padding-left: 10px;

          .slider {
            padding-bottom: 12px;
            width: 100%;

            .tiao {
              background: rgba(76, 118, 193, 0.38);
              height: 5px;
              position: relative;

              .dec-circular {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #DFFFFE;
                position: absolute;
                top: -1.5px;
                box-shadow: 0px 0px 16px #fff;
              }

              .have {
                height: 100%;
                background: linear-gradient(270deg, #92FFFD 0%, #5FB2E8 31%, #2D72D3 66%, #1F18AE 100%);
              }
            }
          }

          .one-scroll-text {
            padding: 0 0 10px 0;
            width: 100%;

            .one-scroll-text-left {
              font-size: 14px;
              font-family: AppleSystemUIFont;
              color: #FFFFFF;
            }

            .one-scroll-text-right {
              font-size: 26px;
              font-family: 'DINAlternate-Bold', 'DINAlternate';
              font-weight: bold;
              color: #00FFFF;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}

.cpt-table-pagination {
  //右下分页组件样式
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: right;

  ::v-deep {
    button {
      background: transparent;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      // border: 1px solid #488cff;
      font-size: 18px;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      border-radius: 4px;
      line-height: 40px;
      margin-right: 8px;
      box-sizing: border-box;
      color: #b6d4ff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      &:disabled {
        background: transparent;
        color: #b6d4ff;
        opacity: 0.3;
      }
    }

    .el-pagination__total {
      font-size: 18px;
      color: #b6d4ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;

      text-align: center;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      margin: 0px;
    }

    .el-pager {
      height:30px;

      .number {
        background: transparent;
        font-size: 12px;
        color: #b6d4ff;
        text-align: center;
        font-weight: lighter;
        width: 25px;
        height: 25px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        border-radius: 4px;
        margin-right: 8px;
        box-sizing: border-box;

        &.active {
          opacity: 0.8;
          // background-image: linear-gradient(
          //   180deg,
          //   rgba(44, 123, 255, 0.5) 0%,
          //   rgba(72, 140, 255, 0) 100%
          // );
          background-color: #2647ee;
          color: #ffffff;
        }
      }

      .more {
        background: transparent;
        // line-height: 40px;
        width: 40px;
        // height: 40px;

        box-sizing: border-box;
        // border: 1px solid #629fff;
        border-radius: 4px;
        margin-right: 8px;
        font-size: 20px;
        color: #b6d4ff;
      }
    }

    .el-pagination__jump {
      font-size: 18px;
      color: #b6d4ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      width: 150px;
      height: 40px;
      margin-left: 6px;
      line-height: 40px;
      display: flex;
      align-items: center;

      .el-input {
        box-sizing: border-box;
        height: 100%;
        width: 84px;

        .el-input__inner {
          width: 60px;
          height: 30px;
          border-radius: 2px;
          border: 1px solid #b6d4ff;
          margin-top: 8px;
          // border: 1px solid #629fff;
          border-radius: 4px;
          background: transparent;
          // height: 100%;
          box-sizing: border-box;
          padding: 0;
          font-size: 18px;
          color: #90b2ec;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }

  &.mini {
    height: 100%;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        height: 20px;
        width: 20px;
        line-height: 20px;
        background: transparent;
        border: none;
        color: #4d7592;
        min-width: auto;

        &.btn-next {
          margin-left: 8px;
        }
      }

      .el-pagination__total {
        font-size: 14px;
        color: #4d7592;
      }

      .el-pagination__sizes {
        display: none;
      }

      .el-pager {
        height:30px;


        .number {
          height: 100%;
          width: auto;
          line-height: 20px;
          background: transparent;
          border: none;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 500;
          min-width: 20px;
          margin-right: 0;

          &.active {
            opacity: 1;
            color: #0099ff;
          }
        }

        .more {
          height: 100%;
          width: 20px;
          line-height: 20px;
          font-size: 12px;
          border: none;
          min-width: auto;
          margin-right: 0px;

          &::before {
            line-height: 20px;
          }
        }
      }

      .el-pagination__jump {
        height: 100%;
        font-size: 14px;
        color: #4d7592;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        .el-input {
          .el-input__inner {
            border: 1px solid #4d7592;
            border-radius: 2px;
            width: auto;
            min-width: 20px;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>