<template>
  <ModuleBox title="人员统计" width="100%" height="300px" class="civilFly">
    <div class="big-box-value ">
      <div class="jcsb w134  ">
        <div class="tc">
          <img class="w134 h64 mt6" src="~@/assets/home/me.png" alt="">
          <span class="f14 tc" style="color:#C8DFFFFF"> 用户数量统计</span>
        </div>
        <div class="w224  itemImg"></div>
        <div class="bottomBox">
          <div class="slider-list " v-for="item in data" :key="item.name">
            <div style="width:85%">
              <div class="one-scroll-text jcsb w200">
                <div class="one-scroll-text-left">{{ item.name }}</div>
                <div class="one-scroll-text-right mr10" :style="`color:${item.col}`">{{ item.num }}<span
                    style="font-size:14px"> 人</span> </div>
              </div>
              <div class="slider ">
                <div class="tiao">
                  <div class="have" :style="{ width: (item.num / item.sum) * 100 + '%', background: item.bgc }"></div>
                  <div class="dec-circular" :style="{ left: 'calc(' + (item.num / item.sum) * 100 + '% - 4px)' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="jcsb w134  mt15 ">
        <div class="tc">
          <img class="w134 h64 mt20" src="~@/assets/home/car.png" alt="">
          <span class="f14 tc" style="color:#C8DFFFFF"> 驾驶情况统计</span>
        </div>
        <div class="w224  itemImg"></div>
        <div class="bottomBox pt30">
          <div class="slider-list " v-for="item in data1" :key="item.name">
            <div style="width:85%">
              <div class="one-scroll-text jcsb w200">
                <div class="one-scroll-text-left">{{ item.name }}</div>
                <div class="one-scroll-text-right mr10" :style="`color:${item.col}`">{{ item.num }}<span
                    style="font-size:14px"> 人</span> </div>
              </div>
              <div class="slider ">
                <div class="tiao">
                  <div class="have" :style="{ width: (item.num / item.sum) * 100 + '%', background: item.bgc }"></div>
                  <div class="dec-circular" :style="{ left: 'calc(' + (item.num / item.sum) * 100 + '% - 4px)' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="showDetails" :title="title" :width="1200" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValue" />
      <div class="DemandList_Footer" v-if="dataValue.length">
        <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper" :total="total || 0" :page-size="size || 10" />
      </div>
    </Dialog>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import zhucedata from '../home/JSONVAL/zhucedata.json'
import yongyoudata from '../home/JSONVAL/yongyoudata.json'
import API from "@/api";

export default {
  components: {
    ModuleBox,
    Table,
    Dialog
  },
  data() {
    return {
      data: [
        { name: '个人用户数', num: 2111, sum: 3684, col: '#FDD029', bgc: 'linear-gradient(270deg, #FFEDA7 0%, #FFC90A 30%, #D3762D 66%, #FF850E 100%)' },
        // { name: '企业用户数', num: 54, sum: 3684, col: '#00BFFF', bgc: 'linear-gradient(270deg, #92FFFD 0%, #5FB2E8 31%, #2D72D3 66%, #1F18AE 100%)' },
      ],
      data1: [
        // { name: '驾驶员登记数', num: 1780, sum: 3684, col: '#00FFDA', bgc: 'linear-gradient(270deg, #EFFFF2 0%, #5FE8C0 31%, #2DCED3 67%, #058396 100%)' },
        { name: '驾驶证登记数', num: 162, sum: 3684, col: '#927BFF', bgc: 'linear-gradient(270deg, #8AC7FF 0%, #817AEE 39%, #6C4CD0 65%, #651C9A 100%)' },
      ],
      dataList: [
      ],
      dataValue: [],
      showDetails: false,
      title: '',
      value: '',
      currentPage: 1,
      total: 0,
      size: 10
    };
  },
  created() {
    this.getPeopleFn()
  },
  methods: {
    onChangePage(val) {
      console.log(val);
      let sm = (val - 1) * 10 - 1 < 0 ? 0 : (val - 1) * 10
      let bi = val * 10
      if (this.title == '用户数量统计') {
        this.dataValue = zhucedata.Sheet1.slice(sm, bi)
      } else if (this.title == '驾驶情况统计') {
        this.dataValue = yongyoudata.Sheet1.slice(sm, bi)
      }
    },
    details(title, value) {
      if (title == '个人用户数') {
        console.log(1);
        this.dataList = [
          { title: '姓名', name: 'name', },
          { title: '登记地区', name: 'type' },
          { title: '性别', name: 'status' },
          { title: '手机号', name: 'biaoshi' },
        ]
      } else if (title == '企业用户数') {
        this.dataList = [
          { title: '姓名', name: 'name', },
          { title: '登记地区', name: 'type' },
          { title: '性别', name: 'status' },
          { title: '手机号', name: 'c' },
        ]
      } if (title == '驾驶员登记数') {
        this.dataList = [
          { title: '姓名', name: 'name', },
          { title: '性别', name: 'type' },
          { title: '证件等级', name: 'status' },
          { title: '手机号', name: 'b' },
          { title: '登记日期', name: 'c' },
        ]
      } else if (title == '驾驶证登记数') {
        this.dataList = [
          { title: '姓名', name: 'name', },
          { title: '性别', name: 'type' },
          { title: '证件等级', name: 'status' },
          { title: '手机号', name: 'b' },
          { title: '登记日期', name: 'c' },
        ]
      } else if (title == '用户数量统计') {
        this.dataList = [
          { title: '个人/单位', name: 'gr', },
          { title: '注册时间', name: 'zc' },
          { title: '注册品牌', name: 'pingpai' },
          { title: '注册型号', name: 'xh' },
          { title: '审核时间', name: 'day' },
          { title: '状态', name: 'status' },
        ]
        this.dataValue = zhucedata.Sheet1.slice(0, 10)
        this.total = zhucedata.Sheet1.length
      } else if (title == '驾驶情况统计') {
        this.dataList = [
          { title: '个人/单位', name: 'gr', },
          { title: '驾驶人员', name: 'js' },
          { title: '身份证号', name: 'sf' },
          { title: '联系方式', name: 'lx' },
          { title: '品牌', name: 'pingpai' },
          { title: '型号', name: 'xh' },
          { title: '用途', name: 'yt' },
        ]
        this.dataValue = yongyoudata.Sheet1.slice(0, 10)
        this.total = yongyoudata.Sheet1.length
      }

      this.title = title
      this.value = value
      this.showDetails = true

      console.log(this.showDetails);
    },
    closeDetails() {
      this.showDetails = false
    },
    async getPeopleFn() {
      let res = await API.AIRCRAFT.getPeople()
      if (res.code == 200) {
        this.data[0].num = res.data.userCount
        this.data1[0].num = res.data.certificateCount
        this.$forceUpdate()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.DemandList_Footer {
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 50px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
        height: 30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background-color: #2647ee;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          // line-height: 40px;
          width: 40px;
          // height: 40px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 20px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height: 30px;

          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.big-box-value {
  color: #fff;
  // display: flex;
  // flex-direction: column;

  .itemImg {
    margin-top: 30px;
    display: flex;

    .item-box-left {
      cursor: pointer;
      position: relative;
      width: 134px;
      background: url('~@/assets/home/myjgl1.gif') no-repeat 0 0px;
      transform: scale(1.7);
      background-size: 100% 100%;
      text-align: center;

      &.bottom-box {
        position: relative;
        width: 134px;
        text-align: center;
        transform: scale(1.7);
        background: url('~@/assets/home/myjgl2.gif') no-repeat 0 0px;
        background-size: 100% 100%;
        background-position-y: -3px;
      }

      .text-label {
        // line-height: 165px;
        font-size: 14px;
        color: #C8DFFFFF;
      }

      .line {
        position: absolute;
        right: 0;
        display: inline-block;
        width: 1px;
        // height: 130px;
        background: linear-gradient(180deg, rgba(54, 124, 255, 0) 0%, #C6DAFF 51%, rgba(54, 124, 255, 0) 100%);
      }
    }

    .item-box-right {
      flex: 1;
      // background-color: blue;
    }
  }


  .bottomBox {
    background: #1e4783;
    background-size: 100% 100%;
    padding: 9px;

    .slider-list {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .slider {
        padding-bottom: 2px;
        width: 100%;

        .tiao {
          background: rgba(76, 118, 193, 0.38);
          height: 5px;
          position: relative;

          .dec-circular {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #DFFFFE;
            position: absolute;
            top: -1.5px;
            box-shadow: 0px 0px 16px #fff;
          }

          .have {
            height: 100%;
            background: linear-gradient(270deg, #92FFFD 0%, #5FB2E8 31%, #2D72D3 66%, #1F18AE 100%);
          }
        }
      }

      .one-scroll-text {
        width: 100%;
        line-height: 30px;

        .one-scroll-text-left {
          font-size: 14px;
          font-family: AppleSystemUIFont;
          color: #FFFFFF;
        }

        .one-scroll-text-right {
          font-size: 24px;
          font-weight: bold;
          color: #00FFFF;
          line-height: 30px;
        }
      }
    }
  }
}
</style>