
import API from "@/api";
import jcIMage from "@/assets/images/xiaoxuanfengwurenji2.svg";
import liuxuanyi from "@/assets/images/liuxuanyi.svg";

const { VUE_APP_MTQQ } = process.env;
import { nanoid } from "nanoid";
import mqtt from 'mqtt'
let entities = []
export default {
  async initJCmodel() {
    let { data } = await API.POI.List();
    data.forEach(item => {
      item.longitude *= 1
      item.latitude *= 1
      if (item.latitude && item.longitude) {
        let position = Cesium.Cartesian3.fromDegrees(
          item.longitude * 1,
          item.latitude * 1,
          0
        );
        let data = {
          deviceSerial: item.deviceSerial,
          positions: [position],
          position,
          name: item.elementName,
          entity_id: item.deviceSerial + item.id
        }
        if (!this.jcDeviceList[item.deviceSerial]) {
          this.jcDeviceList[item.deviceSerial] = data
          this.$forceUpdate()
          this.jc_add_jcdata(this.jcDeviceList[item.deviceSerial])
          // 订阅飞机数据
          let topic = `PX4/RECEIVE/${item.deviceSerial}`
          this.mqttClient.subscribe(topic);
        }
      }

    });
  },
  removeEntities() {
    if (entities.length > 0) {
      entities.forEach(element => {
        window.viewer.entities.remove(element)
      })
    }
  },
  add_jcdata(data, type) {
    const entitieList = window.viewer.entities
    let model = entitieList.getById(`${data.entity_id}model`)
    let line = entitieList.getById(`${data.entity_id}line`)
    if (!line && !model) {
      let entitie = window.viewer.entities.add({
        id: data.entity_id + 'model',
        position: new Cesium.CallbackProperty(() => data.position, false), // 图标的经纬度位置
        billboard: {
          image: type === 'wrj' ? liuxuanyi : jcIMage, // 图标的路径
          scale: 1,
        },
        label: {
          text: data.name || '-',
          font: '13px sans-serif',
          // fillColor: Cesium.Color.fromCssColorString('#1ecbee'),
          fillColor: Cesium.Color.WHITE,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 1,
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -20)
        }
      });
      let entitieline = window.viewer.entities.add({
        id: data.entity_id + 'line',
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return data.positions
          }, false),
          width: 1,
          material: Cesium.Color.RED
        }
      });
      entities.push(entitieline)
      entities.push(entitie)
    }

  },
  connectMqtt() {
    // 在这里可以订阅主题
    this.mqttOptions.clientId = 'jc_key' + nanoid()
    this.mqttClient = mqtt.connect(VUE_APP_MTQQ, this.mqttOptions);
    this.mqttClient.on('connect', () => {
      console.log('Connected to MQTT server');
    });

    this.mqttClient.on('message', (topic, payload) => {
      const data = JSON.parse(payload.toString());
      let newtopic = topic
      let deviceId = newtopic.replace('PX4/RECEIVE/', '')
      if (data.type == 258) {
        let online = this.jcDeviceList[deviceId]
        let homeLocation = data.data.homeLocation || data.data.locationCoordinate3D
        if (homeLocation) {
          let position = Cesium.Cartesian3.fromDegrees(
            homeLocation.longitude * 1,
            homeLocation.latitude * 1,
            homeLocation.altitude * 1
          );
          // this.$set(online, 'position', position)
          online.position = position
          online.positions.push(JSON.parse(JSON.stringify(position)))
        }


      }

    });

    this.mqttClient.on('error', (error) => {
      console.error('MQTT Error:', error);
      // 在连接出现错误时处理错误
    });

    this.mqttClient.on('close', () => {
      console.log('MQTT Connection closed');
      // 连接关闭时触发，可以在这里进行重连
      // this.reconnect();
    });
  },
  disconnectMqtt() {
    if (this.mqttClient) {
      this.mqttClient.end();
    }
  },
}