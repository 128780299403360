<template>
  <ModuleBox title="任务审批统计" width="100%" height="28vh" class="approval">
    <div class="container">
      <div class="box">
        <!-- 头部 -->
        <div class="top hwb-flex">
          <!-- 左侧任务次数统计 -->
          <div class="left hwb-flex">
            <div class="top-text" style="color:#FFBD36">
              <div style="background-color:#FFBD36" class="dec"></div>待审批
            </div>
            <div class="top-text" style="color:#1EF4F7">
              <div style="background-color:#1EF4F7" class="dec"></div>默认通过
            </div>
            <div class="top-text" style="color:#4799F3">
              <div style="background-color:#4799F3" class="dec"></div>审批通过
            </div>
            <div class="top-text" style="color:#FF3636">
              <div style="background-color:#FF3636" class="dec"></div>审批不通过
            </div>
          </div>
          <!-- 右侧时间筛选 -->
          <div class="right">
            <TimeSelect @on-selectTime="selectTime" />
          </div>
        </div>
        <!-- 图表 -->
        <div id="status-chart" class="status-chart"></div>
      </div>
    </div>
    <Dialog class="Details" v-if="showDetails" :title="title" :width="1050" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValueList" />
      <div class="DemandList_Footer" v-if="dataValueList.length">
        <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper" :total="totalCount || 0" :page-size="size || 10" />
      </div>
    </Dialog>
  </ModuleBox>
</template>

<script>
let chart
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import { Chart, registerTheme } from "@antv/g2";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import API from "@/api";
import highcharts from 'highcharts'
import highstock from 'highcharts/modules/stock'
highstock(highcharts);


export default {
  components: { ModuleBox, TimeSelect, Table, Dialog },
  data() {
    return {
      currentPage:1,
      totalCount:0,
      size:10,
      type: {//	day日, week周, month月，year年
        1: "day",
        2: "week",
        3: "month",
        4: "year",
      },
      timeHasSelect: 4,
      data: [
        // { name: '市公安局', status: '待审批', num: 12 },
        // { name: '市公安局', status: '审批通过', num: 465 },
        // { name: '市公安局', status: '默认通过', num: 199 },
        // { name: '市公安局', status: '审批不通过', num: 8 },
        // { name: '市综合执法局', status: '待审批', num: 1 },
        // { name: '市综合执法局', status: '审批通过', num: 92 },
        // { name: '市综合执法局', status: '默认通过', num: 32 },
        // { name: '市综合执法局', status: '审批不通过', num: 1 },
        // { name: '市应急管理局', status: '待审批', num: 2 },
        // { name: '市应急管理局', status: '审批通过', num: 36 },
        // { name: '市应急管理局', status: '默认通过', num: 15 },
        // { name: '市应急管理局', status: '审批不通过', num: 1 },
        // { name: '市水利局', status: '待审批', num: 0 },
        // { name: '市水利局', status: '审批通过', num: 10 },
        // { name: '市水利局', status: '默认通过', num: 3 },
        // { name: '市水利局', status: '审批不通过', num: 0 },
        // { name: '市自然资源和规划局', status: '待审批', num: 1 },
        // { name: '市自然资源和规划局', status: '审批通过', num: 138 },
        // { name: '市自然资源和规划局', status: '默认通过', num: 64 },
        // { name: '市自然资源和规划局', status: '审批不通过', num: 1 },
        // { name: '市生态办', status: '待审批', num: 1 },
        // { name: '市生态办', status: '审批通过', num: 38 },
        // { name: '市生态办', status: '默认通过', num: 48 },
        // { name: '市生态办', status: '审批不通过', num: 1 },
        // { name: '市交通局', status: '待审批', num: 1 },
        // { name: '市交通局', status: '审批通过', num: 8 },
        // { name: '市交通局', status: '默认通过', num: 5 },
        // { name: '市交通局', status: '审批不通过', num: 1 },
        // { name: '市环保局', status: '待审批', num: 2 },
        // { name: '市环保局', status: '审批通过', num: 6 },
        // { name: '市环保局', status: '默认通过', num: 1 },
        // { name: '市环保局', status: '审批不通过', num: 1 },
      ],
      list: [],
      status_list: [],
      // dataList: [
      //   { title: '机构名称', name: 'name', },
      //   { title: '待审批', name: 'type' },
      //   { title: '审批通过', name: 'status' },
      //   { title: '默认通过', name: 'biaoshi' },
      //   { title: '审批不通过', name: 'btg' },
      // ],
      dataList: [
        { title: '需求单名称', name: 'taskTitle', width: '200px' },
        { title: '任务类别', name: 'taskCateName', width: '200px' },
        { title: '任务场景', name: 'taskTypeName', width: '200px' },
        { title: '开始日期', name: 'startedAt', width: '200px' },
        { title: '结束日期', name: 'endAt', width: '200px' },
        { title: '任务目标位置', name: 'destination', width: '200px' },
        { title: '需求描述', name: 'remark', width: '200px' },
      ],
      dataValue: [
        { name: '市公安局', type: 12, status: 465, biaoshi: 199, btg: 8 },
        { name: '市综合执法局', type: 1, status: 92, biaoshi: 32, btg: 1 },
        { name: '市应急管理局', type: 2, status: 36, biaoshi: 15, btg: 1 },
        { name: '市水利局', type: 0, status: 10, biaoshi: 3, btg: 0 },
        { name: '市自然资源和规划局', type: 1, status: 138, biaoshi: 64, btg: 1 },
        { name: '市生态办', type: 1, status: 38, biaoshi: 48, btg: 1 },
        { name: '市交通局', type: 1, status: 8, biaoshi: 5, btg: 1 },
        { name: '市环保局', type: 2, status: 6, biaoshi: 1, btg: 1 },
      ],
      dataValueList:[],
      showDetails: false,
      title: '',
      value: '',
      selectList: [],
      organId: null,
      toBeApproved:[],
      DefaultPass:[],
      Approved:[],
      ApprovalFailed:[],
      organName:[],
    };
  },
  created() {

  },
  methods: {
    onChangePage(val) {
      this.currentPage = val
      this.taskCountList()
    },
    updataflyValue() {

    },
    async taskApplyCount(type) {
      let res = await API.TASK.taskApplyCount({ type: this.type[type] })
      if (res.code == 200) {
        let arr = JSON.parse(JSON.stringify(res.data))
        let newArr = []
        let tableList = []
        for (let i = 0; i < arr.length; i++) {
          let type = 0;
            let biaoshi = 0;
            let btg = 0
            let a = 0
          for (let j = 0; j < arr[i].statusList.length; j++) {
            //     { title: '机构名称', name: 'name', },
            // { title: '待审批', name: 'type' },
            // { title: '审批通过', name: 'status' },a
            // { title: '默认通过', name: 'biaoshi' },
            // { title: '审批不通过', name: 'btg' },
            if (arr[i].statusList[j].applyStatus == 0) {
              newArr.push({
                name: arr[i].organizationName, status: '待审批', num: arr[i].statusList[j].count
              })
              type = arr[i].statusList[j].count
            } else if (arr[i].statusList[j].applyStatus == 1) {
              newArr.push({
                name: arr[i].organizationName, status: '审批通过', num: arr[i].statusList[j].count
              })
              a = arr[i].statusList[j].count
            } else if (arr[i].statusList[j].applyStatus == 2) {
              newArr.push({
                name: arr[i].organizationName, status: '审批不通过', num: arr[i].statusList[j].count
              })
              btg = arr[i].statusList[j].count
            } else if (arr[i].statusList[j].applyStatus == 3) {
              newArr.push({
                name: arr[i].organizationName, status: '默认通过', num: arr[i].statusList[j].count
              })
              biaoshi = arr[i].statusList[j].count
            }
          }
           
          tableList.push(
            { name: arr[i].organizationName, type, status: a, biaoshi, btg ,id:arr[i].organizationId})

        }
        this.toBeApproved = newArr.filter(v=>v.status == "待审批").map(v=>v.num)
        this.DefaultPass = newArr.filter(v=>v.status == "默认通过").map(v=>v.num)
        this.Approved = newArr.filter(v=>v.status == "审批通过").map(v=>v.num)
        this.ApprovalFailed = newArr.filter(v=>v.status == "审批不通过").map(v=>v.num)
        this.organName=tableList.map(v=>v.name)
        this.data = newArr
        this.dataValue = tableList
        if(chart) chart.destroy(); // 清理所有图形
        // this.init_chart()
        this.init_chart_list()
      }
    },
    getAllOrganization() {
      // let data  = this.dbox_data
      let data = [
        {
          child: [
            { id: 8, name: "公关办" },
            { id: 9, name: "情指中心" },
            { id: 10, name: "科通科" },
            { id: 11, name: "政治安全保卫大队" },
            { id: 12, name: "刑事侦查大队" },
            { id: 13, name: "巡特警大队" },
            { id: 14, name: "食药环侦大队" },
            { id: 15, name: "交通警察大队" },
            { id: 16, name: "禁毒大队" },
          ],
          id: 2,
          name: "公安局",
        },
        {
          child: [],
          id: 3,
          name: "综合执法局",
        },
        {
          child: [],
          id: 4,
          name: "应急管理局",
        },
        {
          child: [],
          id: 5,
          name: "自然资源和规划局",
        },
        {
          child: [
            { id: 17, name: "河湖中心" },
            { id: 18, name: "水行政执法" },
          ],
          id: 6,
          name: "水利局",
        },
        {
          child: [],
          id: 7,
          name: "生态办",
        },
      ];
      this.selectList = this.getTreeData(data);
      // this.organId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    details(title, value) {
      this.getAllOrganization()
      this.title = title
      this.value = value
      this.showDetails = true
    },
    closeDetails() {
      this.currentPage = 1
      this.showDetails = false
    },
   async selectTime(data) {
      this.timeHasSelect = data
      await this.taskApplyCount(data)
    },
    // async init_chart() {
    //   chart = new Chart({
    //     container: 'status-chart',
    //     autoFit: true,
    //     height: 210,
    //   });

    //   registerTheme('newTheme', { minColumnWidth: 8, maxColumnWidth: 8, }); //柱状体大小
    //   chart.theme('newTheme')
    //   chart.axis('num', {
    //     title: null,
    //     label: {
    //       style: {
    //         fill: '#fff',
    //       }
    //     }
    //   });
    //   chart.data(this.data);
    //   chart.scale('num', {
    //     nice: true,
    //   });
    //   chart.tooltip({
    //     showMarkers: false,
    //     shared: true,
    //   });

    //   chart
    //     .interval()
    //     .position('name*num')
    //     .color('status', (status) => {
    //       if (status == '待审批') {
    //         return "l(90) 0:#FFCD92 0.3:#E8BB5F 0.6:#D39D2D 1:rgba(174,158,24,0)"
    //       }
    //       if (status == '审批通过') {
    //         return "l(90) 0:#92FFFD 0.3:#5FB2E8 0.6:#2D72D3 1:rgba(31,24,174,0)"
    //       }
    //       if (status == '默认通过') {
    //         return "l(90) 0:#ADFFFD 0.3:#5FE8A6 0.6:#2DD3B5 1:rgba(24,162,174,0)"
    //       }
    //       if (status == '审批不通过') {
    //         return "l(90) 0:#FF9292 0.3:#E85F5F 0.6:#D32D2D 1:rgba(174,24,24,0)"
    //       }
    //     })
    //     .adjust([
    //       {
    //         type: 'dodge',
    //         marginRatio: 0.6,
    //       },
    //     ]);
    //   // 格式化文字，超过长度添加省略号
    //   chart.axis('name', {
    //     tickLine: null,
    //     fontSize: 40,
    //     label: {
    //       style: {
    //         fill: '#fff'
    //       },
    //       autoRotate: false,
    //       autoHide: false, // 取消自动隐藏label
    //       formatter(text) {
    //         // 字符太长添加省略号
    //         return text.length > 7 ? `${text.slice(0, 7)}...` : text;
    //       }
    //     },
    //   });
    //   chart.legend(false); // 关闭图例
    //   chart.option('scrollbar', {
    //     categorySize: 100,
    //     style: {
    //       trackColor: 'rgba(0,255,255,0.2)', //滚动条背景
    //       thumbColor: '#00BBFF', // 滚动条滑块背景
    //       thumbHighlightColor: '#00BBFF'
    //     }
    //   }); // 开启滚动条组件  设置间距

    //   chart.interaction('active-region');
    //   chart.on('plot:click', (ev) => {
    //     this.details('任务数统计', '07752')
    //   });
    //   chart.render();
    // },
    init_chart_list(){
      //获取最大值，然后限制左侧的y轴最大值
     let maxArr = this.dataValue.map(v=>{
        return {
          btg: v.btg,
          biaoshi: v.biaoshi, 
          status: v.status, 
          type: v.type
        }
      })
      let maxVal = []
    for (let index = 0; index < Object.keys(maxArr[0]).length; index++) {
      const element = Object.keys(maxArr[0])[index];
    Math.max.apply(null,maxArr.map(v=>v[element]))
    maxVal.push(Math.max.apply(null,maxArr.map(v=>v[element])))
    }
     let max = Math.max.apply(null,maxVal)

      let _this = this
      // let pieClick=()=>{
      //   console.log(this);
      //   this.details('任务审批统计', '07752')
      // }
  chart = highcharts.chart('status-chart', {
	chart: {
		type: 'column',
		options3d: {
			enabled: true,
			alpha: 5,
			beta: 15,
			viewDistance: 25,
			depth: 40
		},
		marginTop: 0,
		marginRight: 0,
    marginLeft:0,
    marginBottom:110,
    backgroundColor:'transparent',
    color:'#92C6F5',
    // events: { // 点击事件-调用自定义的函数
  	// 		click: function (e) {
    //       console.log(e);
    //        pieClick()
  	// 		}
    // }
	},
  credits: { 
enabled: false //不显示LOGO 
},
 legend: {                                                                    
            enabled: false                                                           
        } ,
	// title: {
	// 	text: '以性别划分的水果消费总量'
	// },
	xAxis: {
    max:1,// x轴多于30个出现滚动条
    min:0,
		categories: this.organName,
    gridLineColor:null,
    labels: {
					formatter: function() {
						//获取到刻度值
						var labelVal = this.value;
						//实际返回的刻度值
						var reallyVal = labelVal;
						//判断刻度值的长度
						if(labelVal.length > 3) {
							//截取刻度值
							reallyVal = labelVal.substr(0, 2) + labelVal.substring(2, labelVal.length);
						}
						return reallyVal;
	          },
           style: { 
              color: '#92C6F5' 
              } ,
              autoRotationLimit: 0,// 设置倾斜的点
            autoRotation: [0], // 向右倾斜45度，默认为[-45]
    },
    scrollbar:{// 设置滚动条样式
        enabled: true,
        height: 8,
        barBackgroundColor: "#007aff",
        barBorderColor: "#007aff",
        rifleColor: "#007aff",
        barBorderRadius: 0,
        buttonBorderWidth: 'none',
        buttonArrowColor: "none",
        trackBackgroundColor:'rgba(1, 18, 67, 0.9)',
        trackBorderColor:'rgba(1, 18, 67, 0.9)'
      },
    },

	title:null,
	// yAxis: {
	// 	allowDecimals: false,
	// 	min: 0,
	// 	title: {
	// 		text: '水果数量'
	// 	}
	// },
	yAxis: {
		allowDecimals: false,
		min: 0,
    max:max,//限定左侧y轴最大值
		title: null,
    gridLineColor:'#92C6F5',
    labels:{
      style: { 
              color: '#92C6F5' 
              } 
    }
    
	},
  
	tooltip: {
		headerFormat: '<b>{point.key}</b><br>',
		pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
	},
	plotOptions: {
		column: {
			stacking: 'normal',
			depth: 40,
      
		},
    series:{
      events: {
                    //控制图标的图例legend不允许切换
                    // legendItemClick: function (event) {
                    //   return false;//return true则表示允许切换
                    // },
                    click(event) {
                      console.log(event, 'event');
                      let index = event.point.category
                      let num = event.point.options.y
                      _this.handClickDetails(index, num)
                    }
          }
    }
    
	},
	series: [{
		name: '待审批',
		data: this.toBeApproved,
		stack: 'toBeApproved',
		color:{
      linearGradient: {
         y1: 0.2,
         y2: 1
       },
       stops: [
         [0, '#FFD21F'],
         [1, '#E28C1B']],
    },
	}, {
		name: '默认通过',
		data: this.DefaultPass,
		stack: 'DefaultPass',
    color:{
      linearGradient: {
         y1: 0.2,
         y2: 1
       },
       stops: [
         [0, '#1FF7FF'],
         [1, '#1BE2C0']]
    }
	}, {
		name: '审批通过',
		data: this.Approved,
		stack: 'Approved',
    color:{
      linearGradient: {
         y1: 0.2,
         y2: 1
       },
       stops: [
         [0, '#1FCDFF'],
         [1, '#1B70E2']]
    }
	}, {
		name: '审批不通过',
		data: this.ApprovalFailed,
		stack: 'ApprovalFailed',
    color:{
      linearGradient: {
         y1: 0.2,
         y2: 1
       },
       stops: [
         [0, '#F98989'],
         [1, '#C10E0E']]
    }
	}
  ]
});
    },
      async handClickDetails(key, num) {
        console.log(this.dataValue);
    let data =  this.dataValue.filter((v=>{
      return  key ==  v.name
    }))
      if (data.length > 0) {

        let p_apply_status = null
        let title = null
        if (data[0].type == num) {
          p_apply_status = 0
          title = "待审批"
        } else if (data[0].status == num) {
          p_apply_status = 1
          title = "审批通过"

        }else if (data[0].btg == num) {
          p_apply_status = 2
          title = "审批不通过"

        }else if (data[0].biaoshi == num) {
          p_apply_status = 3
          title = "默认通过"

        }
        this.title = key + '-' + title
        this.organization_id = data[0].id
        this.p_apply_status = p_apply_status


       await this.taskCountList(1, 10, data[0].id, p_apply_status)
        this.showDetails = true
      } else {
        this.$message.info('暂无数据')
      }

    },
        async taskCountList(page, size, organization_id, p_apply_status) {
      if (!page && !size) {
        page = this.currentPage
        size = this.size
      }
      let res = await API.TASK.taskCountList({ page, size, type: this.type[this.timeHasSelect], organization_id:organization_id || this.organization_id, p_apply_status: p_apply_status || this.p_apply_status })
      if (res.code == 200) {
        this.dataValueList = res.data.data
        this.totalCount = res.data.totalCount
      }
    },
  },
  mounted() {
    this.taskApplyCount(this.timeHasSelect)
    // this.selectTime(4)
    // this.init_chart();
    this.init_chart_list()
  },
};
</script>

<style lang="scss" scoped>
.Details {
  .select {
    display: flex;
    align-items: center;

    .selectBtn {
      cursor: pointer;
      width: 86px;
      height: 30px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
    }
  }

  ::v-deep .el-cascader .el-input {
    .el-input__inner {
      background-color: #000;
      border: 0;
      height: 30px;
      line-height: 30px;
      color: #fff;
      margin-bottom: 10px;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }
}

.approval {
  height: 100%;

  .container {
    width: 100%;
    height: calc(100% - 44px);
    display: flex;
    align-items: center;
    justify-content: center;
font-size: 12px;
    .box {
      position: relative;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      .top {
        padding: 0 0 10px 0px;

        .left {
          .top-text {
            position: relative;
            padding-left: 24px;

            .dec {
              position: absolute;
              width: 8px;
              height: 8px;
              // border-radius: 50%;
              background-color: red;
              top: 2px;
              left: 10px;
            }
          }
        }
      }

      .status-chart {
        cursor: pointer;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
      }
    }
  }

}
.DemandList_Footer {
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 30px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
        height: 30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background-color: #2647ee;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          // line-height: 40px;
          width: 40px;
          // height: 40px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 20px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height: 30px;


          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>