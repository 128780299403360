<template>
  <ModuleBox title="任务成果走势" width="100%" height="50vh" class="number-of-tasks">
    <div class="out">
      <div style="width: 100%;height:100%">
        <!-- 头部机构类型选择 -->
        <div class="hwb-flex" style="margin:0 0 22px 0;">
          <!-- 选择框 -->
          <div class="select-outbox hwb-flex">
            <!-- 机构 -->
            <div class="selectBox">
              <!-- <div class="dec iconfont icon-jigoumingcheng"></div>
              <div class="dec2"></div> -->
              <el-cascader popper-class="input-orgname" placeholder="请选择所属机构" v-model="organId"
                :options="allOrganizationselectList" :show-all-levels="false" @change="updataflyValue()" :props="{
                  children: 'child',
                  label: 'name',
                  value: 'id',
                  checkStrictly: true,
                  emitPath: false,
                  empty: false
                }" style="margin-right:5px;" clearable>
              </el-cascader>
            </div>
            <!-- 类型 -->
            <div class="selectBox">
              <!-- <div class="dec1 iconfont icon-renwuchangjing"></div>
              <div class="dec3"></div> -->
              <el-select v-model="taskTypeValue" placeholder="请选择任务类型" clearable @change="updatataskstate()">
                <el-option v-for="item in taskTypeList" :key="item.id" :label="item.title" :value="item.id" />
              </el-select>
            </div>
          </div>
          <!-- 右侧时间筛选 -->
          <div class="right ">
            <TimeSelect @on-selectTime="selectTime" />
          </div>
        </div>
        <!-- 图标信息 -->
        <div class="legend hwb-flex">
          <div class="dec1"></div>
          <div class="dec2"></div>
          <div class="cgk public hwb-flex"><img src="./img/baogao.png" />
            <div>
              <div class="dec" style="color:#027fd0">总报告数</div>
              <div class="num" style="color:#027fd0">{{zb}}</div>
            </div>
          </div>
          <div class="spk public hwb-flex"><img src="./img/shiping.png" />
            <div>
              <div class="dec" style="color:#1decde">总视频数</div>
              <div class="num" style="color:#1decde">{{zs}}</div>
            </div>
          </div>
          <div class="tpk public hwb-flex"><img src="./img/tupian.png" />
            <div>
              <div class="dec" style="color:#f6bd1d">总图片数</div>
              <div class="num" style="color:#f6bd1d">{{zt}}</div>
            </div>
          </div>
        </div>
        <!-- 图表 -->
        <div id="achievements-chart" class="achievements-chart mt50"></div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import { Chart, registerTheme } from "@antv/g2";
import API from "@/api";
import { mapGetters } from 'vuex'
import getgetAllOrganization from "@/api/mechanismPullDown.js"; // 获取机构下拉js
import highcharts from 'highcharts'
let chart;
export default {
  components: { ModuleBox, TimeSelect },
  data() {
    return {
      organId: null,
      allOrganizationselectList: null,
      typeValue: null,
      taskTypeValue: null,
      taskTypeList: [
        { id: 2, title: '常态任务' },
        { id: 3, title: '临时紧急任务' }
      ],
      zb: null,
      zs: null,
      zt: null,
      zbData: [],
      zsData: [],
      ztData: [],
      datali: [],
      organName: [],
      timeType: null
    };
  },
  computed: {
    ...mapGetters(["dbox_data"]),
  },
  methods: {
    ...getgetAllOrganization,
    // getAllOrganization() {
    //     // console.log(this.dbox_data);
    //   // let data  = this.dbox_data
    //   let data=[
    //     {
    //     child:[
    //       {id: 8,
    //     name: "公关办",},
    //       {id: 9,
    //     name: "情指中心",},
    //       {id: 10,
    //     name: "科通科",},
    //       {id: 11,
    //     name: "政治安全保卫大队",},
    //       {id: 12,
    //     name: "刑事侦查大队",},
    //       {id: 13,
    //     name: "巡特警大队",},
    //       {id: 14,
    //     name: "食药环侦大队",},
    //       {id: 15,
    //     name: "交通警察大队",},
    //       {id: 16,
    //     name: "禁毒大队",},
    //     ],
    //     id: 2,
    //     name: "公安局",
    //     },{
    //     child:[
    //     ],
    //     id: 3,
    //     name: "综合执法局",},
    //     {
    //     child:[

    //     ],
    //     id: 4,
    //     name: "应急管理局",},
    //     {
    //     child:[

    //     ],
    //     id: 5,
    //     name: "自然资源和规划局",},
    //     {
    //     child:[
    //       {id: 17,
    //     name: "河湖中心",},
    //       {id: 18,
    //     name: "水行政执法",},
    //     ],
    //     id: 6,
    //     name: "水利局",},
    //     {
    //     child:[

    //     ],
    //     id: 7,
    //     name: "生态办",},
    //   ]
    //   this.selectList = this.getTreeData(data);
    //   console.log(this.selectList);
    //   // this.organId = this.selectList[this.selectList.length-1].id
    // },
    // getTreeData(data) {
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].child && data[i].child.length < 1) {
    //       // children若为空数组，则将children设为undefined
    //       data[i].child = undefined;
    //     } else {
    //       // children若不为空数组，则继续 递归调用 本方法
    //       if (data[i].child && data[i].child.length < 1) {
    //         this.getTreeData(data[i].child);
    //       }
    //     }
    //   }
    //   return data;
    // },
    async updatataskstate() {
      console.log(this.taskTypeValue);
      if (!this.taskTypeValue) this.taskTypeValue = null
      // if (this.taskTypeValue==1) {
      //   this.zb=1756
      //   this.zs=813
      //   this.zt=4012
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 1756 },
      //   { name: '总视频数', status: '总视频数', num: 813 },
      //   { name: '总图片数', status: '总图片数', num: 4012 },
      // ];
      // } else {
      //   this.zb=742
      //   this.zs=301
      //   this.zt=1711
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 742 },
      //   { name: '总视频数', status: '总视频数', num: 301 },
      //   { name: '总图片数', status: '总图片数', num: 1711 },
      // ];
      // }
      await this.async_get_list()
      chart.destroy()
      this.init_chart_list()
    },
    async updataflyValue() {
      console.log(this.organId);

      // if (this.organId==16) {//缉毒
      //   this.zb=49
      //   this.zs=22
      //   this.zt=112
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 49 },
      //   { name: '总视频数', status: '总视频数', num: 22 },
      //   { name: '总图片数', status: '总图片数', num: 112 },
      // ];
      // } else if(this.organId==15){ //交警
      //   this.zb=598
      //   this.zs=285
      //   this.zt=1521
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 598 },
      //   { name: '总视频数', status: '总视频数', num: 285 },
      //   { name: '总图片数', status: '总图片数', num: 1521 },
      // ];
      // }else if(this.organId==14){ //食药
      //   this.zb=125
      //   this.zs=50
      //   this.zt=249
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 125 },
      //   { name: '总视频数', status: '总视频数', num: 50 },
      //   { name: '总图片数', status: '总图片数', num: 249 },
      // ];
      // }else if(this.organId==12){ //刑侦
      //   this.zb=285
      //   this.zs=133
      //   this.zt=665
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 285 },
      //   { name: '总视频数', status: '总视频数', num: 133 },
      //   { name: '总图片数', status: '总图片数', num: 665 },
      // ];
      // }else if(this.organId==13){ //巡特警
      //   this.zb=367
      //   this.zs=178
      //   this.zt=921
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 367 },
      //   { name: '总视频数', status: '总视频数', num: 178 },
      //   { name: '总图片数', status: '总图片数', num: 921 },
      // ];
      // }else if(this.organId==7){ //生态办
      //   this.zb=139
      //   this.zs=55
      //   this.zt=287
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 139 },
      //   { name: '总视频数', status: '总视频数', num: 55 },
      //   { name: '总图片数', status: '总图片数', num: 287 },
      // ];
      // }else if(this.organId==6){ //水利局
      //   this.zb=31
      //   this.zs=13
      //   this.zt=68
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 31 },
      //   { name: '总视频数', status: '总视频数', num: 13 },
      //   { name: '总图片数', status: '总图片数', num: 68 },
      // ];
      // }else if(this.organId==4){ //应急管理
      //   this.zb=119
      //   this.zs=49
      //   this.zt=254
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 119 },
      //   { name: '总视频数', status: '总视频数', num: 49 },
      //   { name: '总图片数', status: '总图片数', num: 254 },
      // ];
      // }else if(this.organId==5){ //自然资源
      //   this.zb=458
      //   this.zs=193
      //   this.zt=965
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 458 },
      //   { name: '总视频数', status: '总视频数', num: 193 },
      //   { name: '总图片数', status: '总图片数', num: 965 },
      // ];
      // }else if(this.organId==3){ //综合执法
      //   this.zb=264
      //   this.zs=111
      //   this.zt=556
      //   this.datali = [
      //   { name: '总报告数', status: '总报告数', num: 264 },
      //   { name: '总视频数', status: '总视频数', num: 111 },
      //   { name: '总图片数', status: '总图片数', num: 556 },
      // ];
      // }
      await this.async_get_list()
      chart.destroy()
      this.init_chart_list()
    },
    async selectTime(time) {
      if (time == 1) {
        this.timeType = 'day'
      } else if (time == 2) {
        this.timeType = 'week'
      } else if (time == 3) {
        this.timeType = 'month'
      } else if (time == 4) {
        this.timeType = 'year'
      }
      await this.async_get_list(time)
      chart.destroy()
      this.init_chart_list()
    },
    init_chart_list() {
      chart = highcharts.chart('achievements-chart', {
        chart: {
          type: 'column',
          options3d: {
            enabled: true,
            alpha: 5,
            beta: 15,
            viewDistance: 25,
            depth: 40
          },
          marginTop: 0,
          marginRight: 0,
          marginLeft: 0,
          marginBottom: 60,
          backgroundColor: 'transparent',
          color: '#92C6F5'
        },
        credits: {
          enabled: false //不显示LOGO 
        },
        legend: {
          enabled: false
        },
        // title: {
        // 	text: '以性别划分的水果消费总量'
        // },
        xAxis: {
          categories: this.organName,
          gridLineColor: null,
          labels: {
            formatter: function () {
              //获取到刻度值
              var labelVal = this.value;
              //实际返回的刻度值
              var reallyVal = labelVal;
              //判断刻度值的长度
              if (labelVal.length > 3) {
                //截取刻度值
                reallyVal = labelVal.substr(0, 2) + labelVal.substring(2, labelVal.length);
              }
              return reallyVal;
            },
            style: {
              color: '#92C6F5'
            }
          }
        },
        title: null,
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: null,
          gridLineColor: '#92C6F5',
          labels: {
            style: {
              color: '#92C6F5'
            }
          }

        },
        tooltip: {
          headerFormat: '<b>{point.key}</b><br>',
          pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} '
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            depth: 40
          }
        },
        series: [{
          name: '总报告数',
          data: this.zbData,
          stack: 'toBeApproved',
          color: {
            linearGradient: {
              y1: 0.2,
              y2: 1
            },
            stops: [
              [0, '#1c96ee'],
              [1, '#1c96ee']]
          },
        }, {
          name: '总视频数',
          data: this.zsData,
          stack: 'DefaultPass',
          color: {
            linearGradient: {
              y1: 0.2,
              y2: 1
            },
            stops: [
              [0, '#1decde'],
              [1, '#1decde']]
          }
        }, {
          name: '总图片数',
          data: this.ztData,
          stack: 'Approved',
          color: {
            linearGradient: {
              y1: 0.2,
              y2: 1
            },
            stops: [
              [0, '#f6bd1d'],
              [1, '#f6bd1d']]
          }
        },]
      });
    },
    async async_get_list() {
      // type
      // timeType
      // organizationId
      let ztsj = 0
      let zbsj = 0
      let zssj = 0
      this.zbData = []
      this.zsData = []
      this.ztData = []
      const params = {
        type: this.taskTypeValue,
        timeType: this.timeType,
        organizationId: this.organId
      }
      let res = await API.HOMEDATA.result(params)
      //常态任务2临时3
      if (res.code == 200) {
        let newData = []
        if (res.data.length == 0) {
          newData = [
            { type: '图片', count: 0 },
            { type: '报告', count: 0 },
            { type: '视频', count: 0 },
          ]
          this.zt = 0
          this.zb = 0
          this.zs = 0
        } else {
          this.organName = []
          res.data.forEach(v => {
            v.forEach(value => {
              if (value.type == '文档') {
                value.type = '报告'
                this.zb = value.count
              } else if (value.type == '视频') {
                this.zs = value.count
              } else if (value.type == '图片') {
                this.zt = value.count
              }
              newData.push(value)
            })
          });
          newData.forEach((v) => {
            this.organName.push(v.date)
            switch (v.type) {
              case "报告":
                return zbsj += v.count, this.zbData.push(v.count)
              case "视频":
                return zssj += v.count, this.zsData.push(v.count)
              case "图片":
                return ztsj += v.count, this.ztData.push(v.count)
              default:
                break;
            }
          })
          this.organName = [...new Set(this.organName)]
          this.zt = ztsj
          this.zb = zbsj
          this.zs = zssj


        }
        this.datali = newData.reverse()
      } else {
        this.$message.error(res.message)
      }
    }
  },
  mounted() {
    this.init_chart_list()
    this.getAllOrganization()
    this.selectTime(4)
  },
};
</script>

<style lang="scss" scoped>
.number-of-tasks {
  width: 100%;

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .select-outbox {
      align-items: center;
      width: 60%;

      .selectBox {
        text-align: left;
        position: relative;

        .dec {
          position: absolute;
          top: 8px;
          left: 10px;
          z-index: 100;
          font-size: 16px;
          color: #00BAFF;
        }

        .dec2 {
          position: absolute;
          top: 9px;
          left: 29px;
          width: 1px;
          height: 20px;
          background: linear-gradient(180deg, rgba(54, 124, 255, 0) 0%, #4CB4FF 51%, rgba(54, 124, 255, 0) 100%);
          z-index: 100;
        }

        .dec1 {
          position: absolute;
          top: 8px;
          left: 10px;
          z-index: 100;
          font-size: 16px;
          color: #00BAFF;
        }

        .dec3 {
          position: absolute;
          top: 7px;
          left: 30px;
          width: 1px;
          height: 20px;
          background: linear-gradient(180deg, rgba(54, 124, 255, 0) 0%, #4CB4FF 51%, rgba(54, 124, 255, 0) 100%);
          z-index: 100;
        }


        ::v-deep {
          .el-select {
            .el-input__inner {
              padding-left: 4px;
              width: 125px;
              height: 27px;
              // background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
              // border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
              background: #0B3595;
              backdrop-filter: blur(4px);
              font-size: 12px;
              font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
              font-weight: 400;
              color: #65ABE9;
              line-height: 32px;
              border: 1px solid #4C85E9;
            }

            .el-input .el-select__caret {
              line-height: 0;
            }


          }

          .el-cascader .el-input {
            .el-input__inner {
              // padding-left: 36px;
              padding-left: 6px;
              width: 125px;
              height: 27px;
              // background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
              background: #0B3595;
              // border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
              backdrop-filter: blur(4px);
              font-size: 12px;
              font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
              font-weight: 400;
              color: #65ABE9;
              line-height: 27px;
              border: 1px solid #4C85E9;
            }

            .el-input .el-select__caret {
              line-height: 0;
            }

            .el-input__icon {
              line-height: 27px;
            }

            .el-icon-arrow-down {
              font-size: 12px;
            }

          }
        }
      }
    }

    .legend {
      margin-bottom: 2vh;
      position: relative;

      // .dec1{
      //   position: absolute;
      //   top: 2px;
      //   left: 139px;
      //   width: 1px;
      //   height: 20px;
      //   background: linear-gradient(180deg, rgba(54,124,255,0) 0%, #4CB4FF 51%, rgba(54,124,255,0) 100%);
      //   z-index: 100;
      // }
      // .dec3{
      //   position: absolute;
      //   top: 2px;
      //   left: 289px;
      //   width: 1px;
      //   height: 20px;
      //   background: linear-gradient(180deg, rgba(54,124,255,0) 0%, #4CB4FF 51%, rgba(54,124,255,0) 100%);
      //   z-index: 100;
      // }
      .public {
        width: 30%;

        .num {
          margin: 3px;
          color: #C4F0FF;
          font-size: 21px;
          font-family: LcdD-Regular, LcdD;
        }
      }

      .cgk {
        color: #C4F0FF;
        text-align: center;

        .dec {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          color: #C4F0FF;
        }

        .num {}
      }

      .spk {
        color: #C4F0FF;
        text-align: center;

        .dec {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          color: #C4F0FF;
        }

        .num {}
      }

      .tpk {
        color: #C4F0FF;
        text-align: center;

        .dec {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          color: #C4F0FF;
        }

        .num {}
      }
    }

    .achievements-chart {
      width: 100%;
      height: 30vh;
    }
  }
}
</style>
<style lang="scss">
::v-deep .el-popper {
  background: linear-gradient(180deg, #0a378c 0%, #12206a 35%, #0f265a 66%, #0a378c 100%);
  color: red;
}
</style>