<template>
  <div style="height:100%">
    <AiModuleBox />
    <DeviceBox />
    <PilotModuleBox />
    <Fly />
  </div>
</template>

<script>
import AiModuleBox from "./ai-module-box/index.vue"; // 预警触发概览
import PilotModuleBox from "./demand-trends/index.vue"; // 需求趋势
import DeviceBox from "./device-module-box/index.vue"; //设备运行概览
import Fly from "./fly-statistics/index.vue"; // 飞行统计
export default {
  components: {
    AiModuleBox,
    PilotModuleBox,
    DeviceBox,
    Fly
  },
}
</script>

<style lang="less" scoped>

</style>