<template >

    <div id="closeIDsPopupdiv">
        <Dialog @on-close="closeIDsPopup" title="方案" :left="140" :top="105" :width="438">
            <div class="layerBox " id="layerBox">
                <el-row>
                    <div class="w100 mb20 tc cf btn" @click="add('point')"> 创建方案</div>
                </el-row>
                <div class="itemBox pr10">
                    <div class="item p10 pr" v-for="item in records">
                        <div class="jcfs wih100 ">
                            <img class="w103 h62 dib " :src="item.image || img" alt="">
                            <div class="ml10 cf f12">
                                <el-row class="h20 lh20 w240 hidden"><span style="color:#00c7ff"
                                        class="iconfont f12 icon-chengguoku" />
                                    {{ item.name }}
                                </el-row>
                                <el-row class="h20 lh20"><span style="color:#d2414b" class="iconfont f12 icon-chengguoku" /> 类型：{{ item.typeName }}</el-row>
                                <el-row class="h20 lh20"><span style="color:#ecb035" class="iconfont f12 icon-chengguoku" /> 资源数量：{{ item.resourceCount }}</el-row>
                                <el-row class="h20 lh20"><span style="color:#00ff6c"
                                        class="iconfont f12 icon-chengguoku" />{{ item.addtime }}</el-row>
                            </div>
                            <div class="pa right0 top0 mt10 mr5 cf">
                                <div class="jcsb mb10 ">
                                    <div class="btn2 tc h25 lh20 w50 f10 cp mr5" @click="del(item)">删除</div>
                                    <div class="btn2 tc h25 lh20 w50 f10 cp" @click="findArea(item,true)"
                                        style="background: rgba(34,75,106,0.71);">
                                        查看</div>
                                </div>
                                <div class="btn2 tc w105 mt10 f10 cp" @click="deploy(item)">
                                    资源部署
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
        <Dialog v-if='title' @on-close="closeFn" :title="title" :left="600" :top="235" :width="350">
            <div class="jcfs wih100">
                <img class="wih100 h200 dib " :src="dialogImg" alt="">
            </div>
            <div class="ml10 cf h30  lh30">
                <el-row class="cp">
                    <span @click="handle_screenshot">
                        <span class="iconfont mr4  icon-tuxiangku" style="color:#ecb035"></span> 截取封面
                    </span>
                </el-row>
                <el-row class="cp">
                    <span class="" @click="updateFn"> <span class="iconfont mr4 f18  icon-dingwei"
                            style="color:#ecb035"></span> 更新位置</span>
                </el-row>
            </div>

            <div class="mt20 h190 cf mt40">
                <el-form size="mini" ref="formRef" :model="form" :rules="rules" label-width="80px">
                    <div v-show="itemIndex == 0">
                        <el-form-item label="方案名称" prop="name">
                            <el-input v-model="form.name" placeholder="请输入方案名称"></el-input>
                        </el-form-item>
                        <!-- faType -->
                        <el-form-item label="方案类型" prop="typeId">
                            <el-select v-model="form.typeId" size="mini" placeholder="请选择方案类型">
                                <el-option v-for="(item, index) in  faType" :key="item.id" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                            <!-- <el-input v-model="form.resourceCount" placeholder="请输入资源数量"></el-input> -->
                        </el-form-item>

                        <el-form-item label="规划时间" prop="planTime">
                            <el-date-picker v-model="form.planTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                                placeholder="请选择日期时间">
                            </el-date-picker>
                        </el-form-item>

                    </div>

                    <!-- table为基本信息时显示 -->
                    <el-form-item v-show="itemIndex == 0">
                        <div class="wih100">
                            <div class="cf ml100 btn" @click="onSubmit">确定</div>
                        </div>
                    </el-form-item>
                </el-form>

            </div>
        </Dialog>
        <Dialog v-if='hcqFLlag && title' title="缓冲区设置" :left="969" :top="497" :closeFlag="false" :width="350">
            <div class="mt20 h85 cf">
                <el-form size="mini" ref="formRef2" :model="form" label-width="80px">
                    <div v-show="itemIndex == 0">
                        <el-form-item label="半径(m)">
                            <el-input v-model="radius" placeholder="请输入方案半径"></el-input>
                        </el-form-item>
                    </div>
                    <!-- table为基本信息时显示 -->
                    <el-form-item v-show="itemIndex == 0">
                        <div class="wih100">
                            <div class="cf ml100 btn" @click="showAadius">
                                <div @click="showAadius">确定</div>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>

            </div>
        </Dialog>

        <Dialog v-if='zyEntitiFlag' title="资源设置" @on-close="zyDiaClose" :left="600" :top="235" :width="350">
            <div class="mt20 h205 cf">
                <el-form size="mini" ref="zyformRef" :model="zyform" :rules="zyformRules" label-width="80px">
                    <div v-show="itemIndex == 0">
                        <el-form-item label="选择资源">
                            <el-select v-model="zyform.typeId" placeholder="请选择图标">
                                <el-option v-for="item in iconArr" :key="item.id" :label="item.icoName"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="标注名称" prop="name">
                            <el-input v-model="zyform.name" placeholder="请输入标注名称"></el-input>
                        </el-form-item>
                        <el-form-item label="执行状态">
                            <el-select v-model="zyform.status" placeholder="执行状态">
                                <el-option v-for="item in zxTatus" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>


                    </div>
                    <!-- table为基本信息时显示 -->
                    <el-form-item v-show="itemIndex == 0">
                        <div class="wih100">
                            <div class="cf ml100 btn" @click="handclickZY">
                                确定
                            </div>
                        </div>
                    </el-form-item>
                </el-form>

            </div>
        </Dialog>
    </div>
</template>
<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Dialog from "../dialog/index.vue"
import screenshot from '@/utils/Cesium/Screenshot'
import dataURItoBlob from '@/utils/dataURItoBlob'
import drawGraphic from '@/utils/Cesium/DrawGraphic'
import Bubble from '@/components/cesium-layer/bubbleModel/index.js'

import {
    createPoint,
    createAreaPoint,
    createLine,
    createPolygon,
} from '@/utils/Cesium/CreateLabel'
let dataEntities = null;
let dataEntitiesArr = []
let markerArr = []
let zyEntitieArr = []
let entitiesList = []
let zyEntitie = null
import API from "@/api";
import { number } from "echarts";
import { Log } from "@antv/scale";
let bubblesList = []
export default {
    components: { ModuleBox, Dialog },
    data() {
        return {
            faType: [],//方案类型
            zyform: {
                activityId: null,
                name: null,
                lng: null,
                lat: null,
                status: 0,
                typeId: null
            },
            zyformItme: null,
            // 正在编辑的 方案点位 start
            PointEntitis: null,
            hcqFLlag: false,
            num: 0,
            radius: null,
            // 正在编辑的 方案点位 end
            // 方案列表
            // 资源start
            zyEntitiFlag: false,
            records: [],
            iconArr: [],
            // end
            title: null,
            itemIndex: 0,//table 0 代表基本信息 1 代表样式设计  2 代表备注说明
            img: require('@/assets/img/acquiescent.png'),
            dialogImg: require('@/assets/img/acquiescent.png'),
            zxTatus: [
                {
                    id: 0,
                    name: '待命'
                },
                {
                    id: 1,
                    name: '执行中'
                }
            ],
            form: {
                // '資源數量'
                name: null,
                typeId: null,
                image: "",
                lng: null,
                lat: null,
                radius: null,
                planTime: null,
            },
            rules: {
                name: [
                    { required: true, message: '请输入方案名称', trigger: 'blur' }
                ],
                typeId: [
                    { required: true, message: '请选择方案类型', trigger: 'blur' }
                ],
                planTime: [
                    { required: true, message: '规划时间不能为空', trigger: 'blur' }
                ],
            },
            zyformRules: {
                name: [
                    { required: true, message: '请输入资源名称', trigger: 'blur' }
                ],
            },

            VUE_APP_IMGIP: "",
            cartographic: null,
        }
    },
    created() {
        this.getIcon()
        const { VUE_APP_IMGIP } = process.env;
        this.VUE_APP_IMGIP = VUE_APP_IMGIP
        this.activityList()
        this.init_events()
        this.activityTypePageFn()

    },
    watch: {
        'form.name'(title) {
            if (this.PointEntitis) {
                this.PointEntitis.label.text = title
            }
        },
        'zyform.typeId'(id) {
            let { VUE_APP_IMGIP, iconArr } = this
            let icon = iconArr.filter(item => item.id == id)
            if (icon.length > 0) {
                zyEntitie.billboard.image = VUE_APP_IMGIP + icon[0].icoUrl
            }
        },
        'zyform.name'(title) {
            zyEntitie.label.text = title
        },


    },
    beforeDestroy() {
        for (let index = 0; index < dataEntitiesArr.length; index++) {
            let element = dataEntitiesArr[index];
            viewer.entities.remove(element)
        }
        for (let index = 0; index < zyEntitieArr.length; index++) {
            let element = zyEntitieArr[index];
            viewer.entities.remove(element)
        }
        entitiesList.forEach(element => {
            viewer.entities.remove(element)
        })
        for (let i = 0; i < markerArr.length; i++) {
            const element = markerArr[i];
            viewer.entities.remove(element)
        }
        bubblesList.forEach(element => {
            element.windowClose()
        })
        bubblesList = []
    },
    methods: {
        async activityTypePageFn() {
            let res = await API.MODEL.activityTypePage({
                pageNo: 1,
                pageSize: 100
            })
            if (res.code == 200) {
                this.faType = res.data.records
            }
        },
        updateFn() {
            this.add('point', true)
        },
        bubble(id) {
            if (bubblesList.length > 0) {
                bubblesList.forEach((v, i) => {
                    if (v.vmInstance.id == id) {
                        v.windowClose()
                        bubblesList.splice(i, 1)
                    }
                })
            }
            let aa = markerArr.filter(item => item.id === id);
            this.bubbles = new Bubble(Object.assign(aa[0], {
                viewer
            }))
            let b = this.bubbles
            bubblesList.push(b)
        },
        async activityResourceList(item) {
        },
        // 保存资源
        handclickZY() {
            this.$refs['zyformRef'].validate(async (valid) => {
                if (valid) {
                    let resFilter = await API.MODEL.activityResourceAdd(this.zyform)
                    if (resFilter.code == 200) {
                        this.$message.success('添加成功')
                        this.zyEntitiFlag = false
                        this.zyform = {
                            activityId: null,
                            name: null,
                            lng: null,
                            lat: null,
                            status: 0,
                            typeId: null
                        }
                        viewer.entities.remove(zyEntitie)
                        for (let i = 0; i < markerArr.length; i++) {
                            const element = markerArr[i];
                            viewer.entities.remove(element)
                        }
                        this.findArea(this.zyformItme)
                    this.activityList()

                    }

                }
            })
        },
        // 资源弹出框关闭
        zyDiaClose() {
            this.zyform = {
                activityId: null,
                name: null,
                lng: null,
                lat: null,
                status: 0,
                typeId: null
            }
            this.zyEntitiFlag = false
            viewer.entities.remove(zyEntitie)
        },
        // 打开资源弹出框
        async deploy(item) {
            this.zyformItme = item
            this.zyform.activityId = item.id
            this.$notify({
                title: '请点击地图上需要部署资源的位置',
                showClose: false,
            });
            // this.findArea(item)
            let state = 'point'
            drawGraphic(viewer, state, (earthPosition, floatingPoint = []) => {
                let cartesian3 = earthPosition[0] || null
                if (cartesian3) {
                    let ellipsoid = viewer.scene.globe.ellipsoid
                    let cartographic = ellipsoid.cartesianToCartographic(cartesian3)
                    this.cartographic = cartographic
                    let lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(8)
                    let lng = Cesium.Math.toDegrees(cartographic.longitude).toFixed(8)
                    this.zyform.lat = lat
                    this.zyform.lng = lng
                }
                let icon = null
                if (this.iconArr.length > 0 && state == 'point') {
                    icon = this.VUE_APP_IMGIP + this.iconArr[0].icoUrl
                    try {
                        this.zyform.typeId = this.iconArr[0].id
                    } catch (error) {
                        console.log(error);
                    }
                    let entities = createPoint(viewer, {
                        id: 'zy' + Math.random().toString(36).slice(-6),
                        position: cartesian3,
                        image: icon,
                        title: '默认资源',
                    })
                    zyEntitie = entities
                    this.zyEntitiFlag = true
                }


            })
        },
        // 获取列表
        async activityList() {
            let res = await API.MODEL.activityList()
            if (res.code == 200) {
                this.records = res.data.records.reverse()
            }
        },
        async findArea(item,falg) {
             if (window.plate_data_source.show) {
            window.plate_data_source.show = false
            viewer.scene.globe.show = true
            }
            let positions = Cesium.Cartesian3.fromDegrees(item.lng, item.lat)
            let img = require('./img/1.png')
            let enti = viewer.entities.getById('showArea' + item.id);
            if (enti) {
                viewer.flyTo(enti)
            } else {
                let ellipse = null
                if (!item.radius) {
                    item.radius = 0
                } else {
                    ellipse = {
                        semiMinorAxis: item.radius,
                        material: Cesium.Color.fromCssColorString('#188fff86'),
                        semiMajorAxis: item.radius,
                        rotation: Cesium.Math.toRadians(-40.0),
                        outline: true,
                        outlineWidth: 4,
                        stRotation: Cesium.Math.toRadians(90),
                    }
                }
                let entities = createAreaPoint(viewer, {
                    id: 'showArea' + item.id + 'zy',
                    position: positions,
                    image: img,
                    title: item.name,
                    ellipse,
                    longitude: {
                        type: 'model',
                        lon: item.lng,
                        lat: item.lat,
                        height: 0,
                        data: {
                            ...item,
                        }
                    },
                    properties: {
                        metadata: item,
                    },
                })
                if(falg){
                viewer.flyTo(entities)

                }
                markerArr.push(entities)
                let res = await API.MODEL.activityResourceList({ activityId: item.id, pageNo: 1, pageSize: 230 })
                if (res.code == 200) {
                    for (let i = 0; i < res.data.records.length; i++) {
                        const item = res.data.records[i];
                        let positions = Cesium.Cartesian3.fromDegrees(item.lng, item.lat)
                        let p = createPoint(viewer, {
                            id: 'showArea' + item.id,
                            position: positions,
                            image: this.VUE_APP_IMGIP + item.image,
                            title: item.name,
                            longitude: {
                                type: 'resources',
                                lon: item.lng,
                                lat: item.lat,
                                height: 0,
                                data: {
                                    ...item,
                                }
                            },
                            properties: {
                                metadata: item,
                            },
                        })
                        markerArr.push(p)
                    }
                }
            }



        },
        // 删除
        del(item) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await API.MODEL.activityDel(item.id)
                if (res.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.activityList()
                }

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        showAadius() {
            this.num++
            if (this.PointEntitis) {
                this.PointEntitis._ellipse.semiMinorAxis = this.form.radius;
                this.PointEntitis._ellipse.semiMajorAxis = this.form.radius;
            }
            this.$forceUpdate()
            console.log(this.num, ' this.num');
            this.form.radius = this.radius
            if (this.num == 2) {
                this.num = 0
                this.hcqFLlag = false
            }
        },
        handle_flyTo() {
            if (dataEntities) {
                viewer.flyTo(dataEntities)
            }
        },
        //保存标注
        onSubmit() {
            if(!this.form.radius)return  this.$notify({
                title: '请点击地图上方案图标设置缓冲区',
                showClose: false,
            });
            this.$refs['formRef'].validate(async (valid) => {
                if (valid) {
                    let resFilter = await API.MODEL.activityAdd(this.form)
                    if (resFilter.code == 200) {
                        this.$message.success('添加成功')
                    }
                    viewer.entities.remove(dataEntities)
                    this.closeFn()
                    this.activityList()
                }
            })

        },
        async getIcon() {
            let res = await API.MODEL.getIco({
                pageNum: 1,
                pageSize: 1000
            })
            if (res.code == 200) {
                this.iconArr = res.data.records
            }
        },
        // 获取封面
        handle_screenshot() {
            screenshot(this.viewer, async (img) => {
                if (img) {
                    this.dialogImg = img.src
                    img = await this.getfileUpload(img.src)
                    document.body.style.cursor = 'default'
                }
            })
        },
        async getfileUpload(src) {
            const params = new FormData()
            const blobFile = dataURItoBlob(src)
            params.append('uploadFile', blobFile)
            let res = await API.TASK.Upvideo(params)
            console.log(res.storage, ' res.storage');
            this.form.image = res.storage
        },
        closeFn() {
            this.form = {
                // '資源數量'
                name: null,
                image: "",
                lng: null,
                lat: null,
                radius: null,
                planTime: null,
            }
            this.radius =null
            viewer.entities.remove(dataEntities)
            this.hcqFLlag=false
            this.img = require('@/assets/img/acquiescent.png')
            this.dialogImg = require('@/assets/img/acquiescent.png')
            this.title = null
        },
        // cesium生成标注
        add(state, flag) {
            this.$notify({
                title: '请点击地图上需要新建活动的位置',
                showClose: false,
            });
            viewer.entities.remove(dataEntities)
            this.itemIndex = 0;
            drawGraphic(viewer, state, (earthPosition, floatingPoint = []) => {

                let cartesian3 = earthPosition[0] || null
                if (cartesian3) {
                    let ellipsoid = viewer.scene.globe.ellipsoid
                    let cartographic = ellipsoid.cartesianToCartographic(cartesian3)
                    this.cartographic = cartographic

                    let lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(8)
                    let lng = Cesium.Math.toDegrees(cartographic.longitude).toFixed(8)
                    this.form.lat = lat
                    this.form.lng = lng
                }
                let icon = null
                if (this.iconArr.length > 0 && state == 'point') {
                    let img = require('./img/1.png')
                    icon = img
                    let data = {
                        id: 'point' + Math.random().toString(36).slice(-6),
                        positions: earthPosition,
                        image: icon,
                        title: '默认方案',
                        state,
                        methodState: 'add',
                        width: 5,
                        outlineColor: '#238ff3',
                    }

                    data = this.createLabelEntities(viewer, data, state)
                    dataEntities = data.entities
                    dataEntitiesArr.push(dataEntities)
                    if (flag) {
                        this.PointEntitis = dataEntities
                    }
                    this.title = '新增方案'

                    this.$forceUpdate()
                }


            })
        },
        init_events() {
            let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)
            handler.setInputAction((movement) => {
                //获取当前相机高度
                let height = Math.ceil(viewer.camera.positionCartographic.height)
                this.height = height
            }, Cesium.ScreenSpaceEventType.WHEEL);
            handler.setInputAction(async (movement) => {
                let pickedLabel = viewer.scene.pick(movement.position);
                var pick = new Cesium.Cartesian2(
                    movement.position.x,
                    movement.position.y
                );
                if (Cesium.defined(pickedLabel)) {
                    // 获取点击位置坐标
                    let cartesian = viewer.scene.globe.pick(
                        viewer.camera.getPickRay(pick),
                        viewer.scene
                    );
                    if (dataEntities == pickedLabel.id) {
                        let str = pickedLabel.id.id
                        if (str.indexOf('point') > -1) {
                            this.PointEntitis = dataEntities
                            // console.log,'pickedLabel.id');
                            this.hcqFLlag = true

                        }
                    }

                    if (markerArr.indexOf(pickedLabel.id) > -1) {
                        if (!document.getElementById(pickedLabel.id.id)) {
                            let id = pickedLabel.id.id;
                            this.bubble(id)
                        } else {
                            if (this.bubbles) {
                                this.bubbles.windowClose()
                            }
                        }
                    }



                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        },
        //  创建标注实例
        createLabelEntities(viewer, data, state) {
            let entities
            if (state == 'point') {
                entities = createAreaPoint(viewer, {
                    id: data.id,
                    position: data.positions[0],
                    image: data.image || null,
                    title: data.title,
                    ellipse: {
                        // semiMinorAxis:  0,
                        // semiMajorAxis:  0,
                        rotation: Cesium.Math.toRadians(-40.0),
                        material: Cesium.Color.fromCssColorString('#188fff86')
,

                    },
                })
            } else if (state == 'line') {
                entities = createLine(viewer, {
                    polyCenter: data.polyCenter,
                    positions: data.positions,
                    width: data.width,
                    color: data.color,
                    title: data.title,
                })
            } else {
                entities = createPolygon(viewer, {
                    polyCenter: data.polyCenter,
                    positions: data.positions,
                    color: data.color,
                    title: data.title,
                    outlineColor: data.outlineColor,
                })
            }
            data.entities = entities
            // viewer.flyTo(entities)
            return data
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
        createFn() {

        }
    }
}
</script>
<style lang="scss" scoped>
.text:hover {
    color: #1F4DCB;
}

.f569 {
    color: #00f569
}

.layerBox {
    height: 77vh;
    // overflow-y: auto;
    // overflow-x: hidden;

    .titleBox {
        height: 34px;
        line-height: 34px;
        background: rgba(138, 152, 177, 0.18);
    }

    ::v-deep {
        .el-input--mini {
            box-shadow: inset 0px 1px 3px 0px #00142F;
            border: 1px solid #73A6D1;
            backdrop-filter: blur(2px);
        }

        .el-input--mini .el-input__inner {
            border: 1px solid #73A6D1;
            box-shadow: inset 0px 1px 3px 0px #00142F;
            background: rgba(21, 95, 192, 0.3);
            color: #fff;
        }
    }

    .itemBox {
        height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;

        .item {
            width: 385px;
            height: 94px;
            margin-bottom: 10px;

            background: rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            border: 1px solid #73A6D1;
            backdrop-filter: blur(4px);
            box-sizing: border-box;

            .hidden {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }


        }

    }
}

.list {
    background: rgba(0, 0, 0, 0.7);
    display: flex;

    .item {
        // width: calc(100% / 3);
        flex: 1;
        text-align: center;
        color: #fff;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
    }

    .active {
        background: #1F4DCB;
    }
}



::v-deep {
    .el-input__inner {
        background: rgba(21, 95, 192, 0.1);
        border: 1px solid #73A6D1;
        min-width: 230px;
        color: #fff;
    }

    .el-textarea__inner {
        background: rgba(21, 95, 192, 0.1);
        border: 1px solid #73A6D1;
        color: #fff;

    }

    .el-color-picker__trigger {
        width: 308px;
    }

    .el-select--mini {
        margin-left: 0px !important;
    }

    .el-form-item__label {
        color: #fff;
    }
}

.btn {
    width: 86px;
    height: 30px;
    cursor: pointer;
    line-height: 27px;
    text-align: center;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;

}

.btn2 {
    width: 44px;
    height: 26px;
    line-height: 26px;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;

}
</style>
