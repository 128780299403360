<template>
  <ModuleBox title="预警提示" width="100%" height="45vh" class="approval">
    <div class="container">
      <div class="box">
        <!-- 头部 -->
        <div class="top hwb-flex">
          <!-- 左侧任务次数统计 -->
          <div class="left hwb-flex">
            <div class="text f14 pl14">总计预警数</div>
            <div class="num hwb-flex">
              <div class="one-num" v-for="item in totalList">{{ item }}</div>
              <span class="cf ml20 mr30">次</span>
            </div>
            <!-- <div class="text">次</div> -->
          </div>
          <!-- 眼睛 -->
          <!-- <div class="eye iconfont" @click="warningStatusChange" :class="isShowWarning?'icon-xianshi':'icon-yincang'">
          </div> -->
          <!-- 右侧时间筛选 -->
          <!-- <div class="right">
            <TimeSelect @on-selectTime="selectTime"/>
          </div> -->
        </div>
        <!-- 预警内容 -->
        <div class="bottom">
          <div
            class="warning hwb-flex cp"
            @click="showEl(item, true)"
            v-for="(item, index) in data"
            :key="index"
          >
            <!-- 图片 -->
            <div class="pictrue" @dblclick="showimg(item.url)">
              <img class="img" :src="item.url" alt="" />
            </div>
            <!-- 文字 -->
            <div class="text">
              <!-- 上 -->
              <div class="text-up">
                {{ item.name }}
              </div>
              <!-- 下 -->
              <div class="text-down">
                <!-- <div class="dec iconfont icon-fanzhi"></div>{{ item.time }} -->
                <div class="dec">
                  <img style="vertical-align: bottom" src="./img/time.png" />{{
                    item.time
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      class="dialog-box"
      v-if="dialogIsShow"
      @on-close="coloseNeedPopup"
      title="图片详情"
      :width="800"
    >
      <div class="bigimg">
        <img :src="bigImg" alt="" />
      </div>
    </Dialog>
  </ModuleBox>
</template>

<script>
let tagging = [];

import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import API from "@/api";
import cesiumVue from "../../../../pages/platform/visualization/components/cesium.vue";
import Dialog from "@/components/common/new-dialog"; // 弹框组件

import Bubble from "@/components/cesium-layer/bubble/index.js";
let bubblesList = [];
let entitiesList = [];
export default {
  components: { ModuleBox, TimeSelect, Dialog },
  data() {
    return {
      isShowWarning: true,
      tagging: null,
      totalList: [0, 0, 0, 0, 0],

      ico: require("@/assets/flylog/warn.png"),
      data: [
        {
          url: require("@/assets/img/1.png"),
          name: "浙江省海宁市八堡观潮点",
          time: "2022/9/7 14:33:15",
          device: "device0001",
          type: "应急救援",
          latitude: "30.392965",
          longitude: "120.689784",
          remark: "该处发现疑似溺水人员",
        },
        {
          url: require("@/assets/img/2.png"),
          name: "浙江省海宁市文苑路311号",
          time: "2022/9/6 23:39:26",
          device: "device0002",
          latitude: "30.564071",
          longitude: "120.683066",
          type: "ptu空地巡查",
          remark: "该处发现疑似打架事件，请立即前往核查",
        },
        {
          url: require("@/assets/img/3.png"),

          name: "浙江省海宁市海昌南路75号",
          time: "2022/9/6 22:15:26",
          device: "device0003",
          latitude: "30.51544",
          longitude: "120.691783",
          type: "现场勘察",
          remark: "该处发现打架斗殴事件",
        },
        {
          url: require("@/assets/img/4.png"),

          name: "浙江省海宁市九虎路与育才路交叉口东100米",
          time: "2022-08-18 12:00:00",
          device: "device0004",
          latitude: "30.508226",
          longitude: "120.696362",
          type: "交通巡查",
          remark: "该处发现疑似违停现象，请立即前往调查",
        },
        {
          url: require("@/assets/img/5.png"),
          name: "浙江省海宁市九虎路与育才路交叉口东100米",
          time: "2022-08-18 12:00:00",
          device: "device0006",
          latitude: "30.526928",
          longitude: "120.692162",
          type: "ptu空地巡查",
          remark: "该处发现疑似醉酒人员",
        },
        {
          url: require("@/assets/img/1.png"),
          name: "浙江省海宁市八堡观潮点",
          time: "2022/9/7 14:33:15",
          device: "device0001",
          type: "应急救援",
          latitude: "30.392965",
          longitude: "120.689784",
          remark: "该处发现疑似溺水人员",
        },
      ],
      dialogIsShow: false,
      bigImg: null,
    };
  },
  created() {},
  mounted() {
    viewer.scene.camera.changed.addEventListener(this.handleZoom);
    tagging = new Cesium.CustomDataSource("warn-list");
    viewer.dataSources.add(tagging);
    setTimeout(async () => {
      await this.getWarningListFn();
      await this.init_events();
      this.data.forEach((item) => {
        if (item && Number(item.longitude) > 0) {
          this.showEl(item);
        }
      });
    }, 3000);
    const pinBuilder = new Cesium.PinBuilder();
    const pin100 = pinBuilder
      .fromText("100+", Cesium.Color.RED, 80)
      .toDataURL();
    const pin50 = pinBuilder
      .fromText("50+", Cesium.Color.PURPLE, 70)
      .toDataURL();

    const pin40 = pinBuilder
      .fromText("40+", Cesium.Color.ORANGE, 60)
      .toDataURL();
    const pin30 = pinBuilder
      .fromText("30+", Cesium.Color.YELLOW, 48)
      .toDataURL();
    const pin20 = pinBuilder
      .fromText("20+", Cesium.Color.GREEN, 48)
      .toDataURL();
    const pin10 = pinBuilder.fromText("10+", Cesium.Color.BLUE, 40).toDataURL();

    const singleDigitPins = new Array(8);
    for (let i = 0; i < singleDigitPins.length; ++i) {
      singleDigitPins[i] = pinBuilder
        .fromText(`${i + 2}`, Cesium.Color.VIOLET, 48)
        .toDataURL();
    }
    tagging.clustering.enabled = true;
    tagging.clustering.pixelRange = 20;
    tagging.clustering.minimumClusterSize = 2;
    tagging.clustering.clusterEvent.addEventListener(
      (clusteredEntities, cluster) => {
        cluster.label.show = false;
        cluster.billboard.show = true;
        cluster.billboard.id = cluster.label.id;
        cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;
        if (clusteredEntities.length >= 100) {
          cluster.billboard.image = pin100;
        } else if (clusteredEntities.length >= 50) {
          cluster.billboard.image = pin50;
        } else if (clusteredEntities.length >= 40) {
          cluster.billboard.image = pin40;
        } else if (clusteredEntities.length >= 30) {
          cluster.billboard.image = pin30;
        } else if (clusteredEntities.length >= 20) {
          cluster.billboard.image = pin20;
        } else if (clusteredEntities.length >= 10) {
          cluster.billboard.image = pin10;
        } else {
          cluster.billboard.image =
            singleDigitPins[clusteredEntities.length - 2];
        }
      }
    );

  },

  beforeDestroy() {
    entitiesList.forEach((element) => {
      viewer.entities.remove(element);
    });
    bubblesList.forEach((element) => {
      element.windowClose();
    });
    viewer.dataSources.remove(tagging);
  },
  methods: {
    handleZoom() {
      var zoomLevel = viewer.scene.camera.positionCartographic.height;
      if (zoomLevel > 200) {
        if(tagging.clustering){
          tagging.clustering.enabled = true;
        }
      } else {
        if(tagging.clustering){
          tagging.clustering.enabled = false;
        }
      }
    },
    async Read(id) {
      let model = tagging.entities.getById(`warn-${id}`);
      if (model) {
        tagging.entities.remove(model);
      }
      bubblesList.forEach((element) => {
        element.windowClose();
      });
      //点击已读刷新地图上的图标
      // entitiesList.forEach((element) => {
      //   viewer.entities.remove(element);
      // });
      // await this.getWarningListFn();
      // this.data.forEach((item) => {
      //   this.showEl(item);
      // });
    },
    async getWarningListFn() {
      let res = await API.TASK.getWarningList({
        isRead: 0,
      });
      // let res2 = await API.TASK.aiWarningList()
      // console.log(res, 'res');
      // console.log(res2, 'res2');
      if (res.code == 200) {
        let totalList = res.total.toString().split("");
        for (let i = 0; i < 6; i++) {
          if (totalList.length < 5) {
            totalList.unshift(0);
          }
        }
        this.totalList = totalList;
        if (res.data) {
          let newData = [];
          res.data.forEach((element) => {
            if (element.isRead == 0) {
              //未读的
              let warningPlace = element.warningPlace.split(",");
              newData.push({
                url: element.warningPhotoUrl,
                name: element.taskTitle,
                time: element.addTime,
                device: "device" + element.taskId,
                type: element.warningTypeName,
                latitude: warningPlace[1],
                longitude: warningPlace[0],
                remark: element.remark,
                id: element.id,
                isRead: element.isRead,
              });
            }
          });
          this.data = newData;
        }
      }
    },
    showimg(url) {
      this.dialogIsShow = true;
      this.bigImg = url;
    },
    coloseNeedPopup() {
      this.dialogIsShow = false;
    },
    showEl(item, flag) {
      // console.log(item.id);
      // console.log(item.device);
      // let model = viewer.entities.getById(`warn-${item.device}`)
      let model = viewer.entities.getById(`warn-${item.id}`);
      if (model && flag) {
        window.plate_data_source.show = false;
        viewer.scene.globe.show = true;
        viewer.camera.flyTo({
          //定位过去
          destination: Cesium.Cartesian3.fromDegrees(
            Number(item.longitude),
            Number(item.latitude),
            4000
          ),
        });
        console.log();
        this.bubble(model.id);
      }
      if (!model) {
        const position = Cesium.Cartesian3.fromDegrees(
          Number(item.longitude),
          Number(item.latitude),
          0
        );
        let data = tagging.entities.add({
          // id: `warn-${item.device}`,
          id: `warn-${item.id}`,
          position: position.clone(),
          show: true,
          longitude: {
            type: 119,
            lon: item.longitude * 1,
            lat: item.latitude * 1,
            height: 3,
            Read: this.Read, //传入方法
            data: {
              ...item,
              typeName: "预警提示",
            },
          },
          billboard: {
            width: 30,
            height: 30,
            image: this.ico,
            show: true,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -10), // default: (0, 0)
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
          },
          properties: {
            metadata: item,
          },
        });
        entitiesList.push(data);
        if (flag) {
          viewer.camera.flyTo({
            //定位过去
            destination: Cesium.Cartesian3.fromDegrees(
              Number(item.longitude),
              Number(item.latitude),
              4000
            ),
          });
        }
      }
      // `warn-${item.device}`
    },
    bubble(id) {
      if (bubblesList.length > 0) {
        bubblesList.forEach((v, i) => {
          if (v.vmInstance.id == id) {
            v.windowClose();
            bubblesList.splice(i, 1);
          }
        });
      }
      let aa = entitiesList.filter((item) => item.id === id);
      this.bubbles = new Bubble(
        Object.assign(aa[0], {
          viewer,
        })
      );
      let b = this.bubbles;
      bubblesList.push(b);
    },
    init_events() {
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        //获取当前相机高度
        let height = Math.ceil(viewer.camera.positionCartographic.height);
        this.height = height;
      }, Cesium.ScreenSpaceEventType.WHEEL);

      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(
          movement.position.x,
          movement.position.y
        );

        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          // 判断点击位置是否等于渲染全要素并且没有创建弹框
          if (
            entitiesList.indexOf(pickedLabel.id) > -1 &&
            !document.getElementById(pickedLabel.id.id)
          ) {
            let id = pickedLabel.id.id;
            this.bubble(id);
          } else {
            if (this.bubbles) {
              this.bubbles.windowClose();
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    selectTime(time) {
      console.log(time);
    },
    warningStatusChange() {
      this.isShowWarning = !this.isShowWarning;
      if (this.isShowWarning) {
        this.data.forEach((item) => {
          this.showEl(item);
        });
        window.plate_data_source.show = false;
        viewer.scene.globe.show = true;
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            120.625748,
            30.369629,
            34879.96979198625
          ),
          orientation: {
            heading: 0.031173336961421327,
            pitch: -1.1699130213649074,
            roll: 6.283184330860693,
          },
        });
      } else {
        entitiesList.forEach((element) => {
          viewer.entities.remove(element);
        });
        bubblesList.forEach((element) => {
          element.windowClose();
        });
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            120.625748,
            30.369629,
            34879.96979198625
          ),
          orientation: {
            heading: 0.031173336961421327,
            pitch: -1.1699130213649074,
            roll: 6.283184330860693,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.approval {
  height: 100%;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: top;
    justify-content: center;

    .box {
      width: 100%;

      .top {
        margin-bottom: 10px;

        .left {
          width: 290px;
          padding-left: 4px;
          background-image: url("~@/assets/img/titleBox.png");
          background-size: 100% 100%;
          height: 40px;
          line-height: 40px;

          .text {
            font-size: 16px;
            font-family: AppleSystemUIFont;
            color: #defdfe;
          }

          .num {
            width: 166px;

            .one-num {
              width: 16px;
              height: 27px;
              background: linear-gradient(
                180deg,
                #01a0ff 0%,
                #01a0ff 50%,
                #1784fa 50%,
                #1784fa 100%
              );
              font-family: Rajdhani-SemiBold, Rajdhani;
              border-radius: 1px;
              font-size: 22px;
              color: #ffffff;
              line-height: 27px;
              text-align: center;
            }
          }
        }

        .right {
          text-align: center;
        }

        .eye {
          margin-right: 20px;
        }
      }
    }

    .bottom {
      height: calc(100% - 80px);
      overflow: auto;

      .warning {
        height: 72px;
        width: 100%;
        margin-bottom: 14px;

        .pictrue {
          width: 130px;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;

          .img {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          width: 252px;
          height: 100%;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          display: flex;
          justify-content: space-around;
          align-items: left;
          flex-direction: column;
          padding-left: 36px;

          .text-up {
            font-size: 14px;
            color: #ffffff;
          }

          .text-down {
            padding-left: 20px;
            font-size: 14px;
            color: #aab7c3;
            position: relative;

            .dec {
              position: absolute;
              color: #c8dfff;
              font-size: 12px;
              left: 0px;
              top: 3px;

              img {
                margin-right: 13px;
              }
            }
          }
        }
      }
    }
  }
}

.dialog-box {
  .bigimg {
    width: 100%;
    height: 600px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

// .contant :hover{
//         border: 2px solid red;
//       }
</style>
