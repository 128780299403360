<template>
  <ModuleBox
    title="入侵事件分析"
    width="100%"
    height="31%"
    class="intrusion"
  >
    <div class="body">
      <div class="top-box">
        <div class="selectBox">
         <div class="item_box">
           <!-- <div class="dec iconfont icon-jigoumingcheng"></div>
          <div class="dec2"></div> -->
          <!-- <el-cascader
          popper-class="input-orgname"
          placeholder="请选择所属机构"
          v-model="organId"
          :options="selectList"
          :show-all-levels="false"
          @change="updataflyValue()"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          style="margin-right:10px;"
        >
        </el-cascader> -->
         </div>

          <div class="item_box">
            <!-- <div class="dec iconfont icon-renwuchangjing"></div>
          <div class="dec2"></div> -->
          <!-- <el-select
          class="input-orgname"
          placeholder="请选择任务场景"
          v-model="organId1"
          @change="updataflyValue()"
        >
        <el-option
        v-for="item in selectList1"
        :key="item.id"
        :label="item.state"
        :value="item.id">
         </el-option>
        </el-select> -->
          </div>
        </div>
        
      </div>
      <div class="top">
          <div class="text f12">
          <div v-for="item in list" :key="item.id" class="written" :style="`color: ${item.color};`">
           <span class="qiu" :style="{background:item.bgc}"></span> {{ item.title }} {{ item.num }}
          </div>
          
        </div>     <!-- 右侧时间筛选 -->
          <div class="right">
            <TimeSelect @on-selectTime="selectTime" />
          </div>      <!-- <TimeSelect @on-selectTime="selectTime"/> -->
      </div>
      
      <div id="container" class="container">
        
      </div>
    </div>
  </ModuleBox>
</template>

<script>
let warChart;
import {mapGetters,mapActions} from "vuex"
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import { Chart } from "@antv/g2";
export default {
    components: { ModuleBox, TimeSelect },
    data() {
      return {
        list: [
          {
            id: 1,
            title: '累计发现',
            num: 52,
            color: 'rgba(0, 210, 255, 1)',
            bgc:'#00D2FF'

          },
          {
            id: 2,
            title: '累计入侵',
            num: 12,
            color: 'rgba(255, 189, 54, 1)',
            bgc:'#FFBD36'
          },
        ],
        yeatData: [
          { country: '发现', year: '1750', value: 800 },
          { country: '发现', year: '1800', value: 900 },
          { country: '发现', year: '1850', value: 600 },
          { country: '发现', year: '1900', value: 700 },
          { country: '发现', year: '1950', value: 400 },
          { country: '发现', year: '1999', value: 1000 },
          { country: '发现', year: '2050', value: 634 },
          { country: '入侵', year: '1750', value: 198 },
          { country: '入侵', year: '1800', value: 216 },
          { country: '入侵', year: '1850', value: 150 },
          { country: '入侵', year: '1900', value: 178 },
          { country: '入侵', year: '1950', value: 70 },
          { country: '入侵', year: '1999', value: 245 },
          { country: '入侵', year: '2050', value: 160 },
        ],
        monthData: [
          { country: '累计发现', year: '一月', value: 3 },
          { country: '累计发现', year: '二月', value: 2 },
          { country: '累计发现', year: '三月', value: 8 },
          { country: '累计发现', year: '四月', value: 4 },
          { country: '累计发现', year: '五月', value: 7 },
          { country: '累计发现', year: '六月', value: 9 },
          { country: '累计发现', year: '七月', value: 6 },
          { country: '累计发现', year: '八月', value: 6 },
          { country: '累计发现', year: '九月', value: 7 },
          { country: '累计入侵', year: '一月', value: 0 },
          { country: '累计入侵', year: '二月', value: 0 },
          { country: '累计入侵', year: '三月', value: 2 },
          { country: '累计入侵', year: '四月', value: 0 },
          { country: '累计入侵', year: '五月', value: 3 },
          { country: '累计入侵', year: '六月', value: 4 },
          { country: '累计入侵', year: '七月', value: 0 },
          { country: '累计入侵', year: '八月', value: 3 },
          { country: '累计入侵', year: '九月', value: 0 },
          { country: '累计入侵', year: '十月', value:0 },
          { country: '累计发现', year: '十月', value: 0 },
          { country: '累计入侵', year: '十一月', value:0 },
          { country: '累计发现', year: '十一月', value: 0 },
          { country: '累计入侵', year: '十二月', value:0 },
          { country: '累计发现', year: '十二月', value: 0 },
        ],
        dayData: [
          { country: '发现', year: '1', value: 3 },
          { country: '发现', year: '2', value: 2 },
          { country: '发现', year: '3', value: 4 },
          { country: '发现', year: '4', value: 0 },
          { country: '发现', year: '5', value: 0 },
          { country: '发现', year: '6', value: 4 },
          { country: '发现', year: '7', value: 9 },
          { country: '发现', year: '8', value: 7 },
          { country: '发现', year: '9', value: 6 },
          { country: '发现', year: '10', value: 9 },
          { country: '发现', year: '11', value: 0 },
          { country: '发现', year: '12', value: 0 },
          { country: '发现', year: '13', value: 4 },
          { country: '发现', year: '14', value: 8 },
          { country: '发现', year: '15', value: 7 },
          { country: '发现', year: '16', value: 4 },
          { country: '发现', year: '17', value: 6 },
          { country: '发现', year: '18', value: 0 },
          { country: '发现', year: '19', value: 0 },
          { country: '发现', year: '20', value: 4 },
          { country: '发现', year: '21', value: 6 },
          { country: '发现', year: '22', value: 7 },
          { country: '发现', year: '23', value: 8 },
          { country: '发现', year: '24', value: 9 },
          { country: '入侵', year: '1', value: 2 },
          { country: '入侵', year: '2', value: 2 },
          { country: '入侵', year: '3', value: 4 },
          { country: '入侵', year: '4', value: 0 },
          { country: '入侵', year: '5', value: 0 },
          { country: '入侵', year: '6', value: 4 },
          { country: '入侵', year: '7', value: 7 },
          { country: '入侵', year: '8', value: 5 },
          { country: '入侵', year: '9', value: 6 },
          { country: '入侵', year: '10', value: 7 },
          { country: '入侵', year: '11', value: 0 },
          { country: '入侵', year: '12', value: 0 },
          { country: '入侵', year: '13', value: 4 },
          { country: '入侵', year: '14', value: 8 },
          { country: '入侵', year: '15', value:  4},
          { country: '入侵', year: '16', value: 4 },
          { country: '入侵', year: '17', value: 3 },
          { country: '入侵', year: '18', value: 0 },
          { country: '入侵', year: '19', value: 0 },
          { country: '入侵', year: '20', value: 4 },
          { country: '入侵', year: '21', value: 3 },
          { country: '入侵', year: '22', value: 2 },
          { country: '入侵', year: '23', value: 4 },
          { country: '入侵', year: '24', value: 5 },
          { country: '入侵', year: '23', value: 10 },
          { country: '入侵', year: '24', value: 10 },
        ],
        weekData: [
          { country: '发现', year: '1', value: 120 },
          { country: '发现', year: '2', value: 90 },
          { country: '发现', year: '3', value: 111 },
          { country: '发现', year: '4', value: 78 },
          { country: '入侵', year: '1', value: 32 },
          { country: '入侵', year: '2', value: 35 },
          { country: '入侵', year: '3', value: 17 },
          { country: '入侵', year: '4', value: 48 },
        ],
        id: 1,
        value: null,
        selectList:null,
        organId:null,
        selectList1:[
          {
            id:1,
            state:'重大敏感警情处置'
          },
          {
            id:2,
            state:'大型活动安保'
          },
          {
            id:3,
            state:'重大疑难交通事故现场勘查'
          },
          {
            id:4,
            state:'偷种罂粟巡查'
          },
        ],
        organId1:null
      }
    },
    computed:{
      ...mapGetters(["dbox_data"])
    },
    methods: {
      updataflyValue(){

      },
      getAllOrganization() {
        // console.log(this.dbox_data);
      // let data  = this.dbox_data
      let data=[
        {
        child:[
          {id: 8,
        name: "公关办",},
          {id: 9,
        name: "情指中心",},
          {id: 10,
        name: "科通科",},
          {id: 11,
        name: "政治安全保卫大队",},
          {id: 12,
        name: "刑事侦查大队",},
          {id: 13,
        name: "巡特警大队",},
          {id: 14,
        name: "食药环侦大队",},
          {id: 15,
        name: "交通警察大队",},
          {id: 16,
        name: "禁毒大队",},
        ],
        id: 2,
        name: "公安局",
        },{
        child:[
        ],
        id: 3,
        name: "综合执法局",},
        {
        child:[

        ],
        id: 4,
        name: "应急管理局",},
        {
        child:[

        ],
        id: 5,
        name: "自然资源和规划局",},
        {
        child:[
          {id: 17,
        name: "河湖中心",},
          {id: 18,
        name: "水行政执法",},
        ],
        id: 6,
        name: "水利局",},
        {
        child:[

        ],
        id: 7,
        name: "生态办",},
      ]
      this.selectList = this.getTreeData(data);
      console.log(this.selectList);
      // this.organId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
      init() {
        const data = this.monthData;
        warChart = new Chart({
          container: 'container',
          autoFit: true,
          height: 200,
        });

        warChart.data(data);
        warChart.scale('year', {
          // type: 'linear',
          // tickInterval: 1,
        });
        warChart.scale('value', {
          nice: true,
        });
        warChart.axis('year', {
          title:null,
          label: {
            style:{
              fill:'#fff',
            }
          }
        });
        warChart.axis('value', {
          title:null,
          label: {
            style:{
              fill:'#fff',
            }
          }
        });
        warChart.tooltip({
          // showCrosshairs: true,
          // shared: false,
          show:false,  // 隐藏
        });
        warChart.legend(false); 


        warChart
          .area()
          .adjust('stack')
          .position('year*value')
          .color('country',['#00d2ff', '#ffbd36']);
        warChart
          .line()
          .adjust('stack')
          .position('year*value')
          .color('country',['#00d2ff', '#ffbd36']);

        warChart.interaction('element-highlight');
        warChart.render();
      },
      getList() {
        if(this.id >= 4) {
          this.id = 1
        } else {
          this.id = this.id +1
        }
        this.getType(this.id)
        warChart.changeData(this.value)
      },
      getType(value) {
        switch (value) {
          case 4:
            return this.value = this.yeatData, this.list.map((item,index) => {if (index==0) {
              item.num = 52
            }else{item.num = 12}})
          case 3:
            return this.value = this.monthData, this.list.map((item,index) => {if (index==0) {
              item.num = 21
            }else{item.num = 5}})
          case 1:
            return this.value = this.dayData, this.list.map((item,index) => {if (index==0) {
              item.num = 4
            }else{item.num =1 }})
          case 2:
            return this.value = this.weekData, this.list.map((item,index) => {if (index==0) {
              item.num =  1 
            }else{item.num =0 }})
          default:
            break;
        }
      },
      selectTime(time) {
      console.log(time);
      this.getType(time)
      warChart.changeData(this.value)
    },
    },
    mounted() {
      this.init()
      this.getAllOrganization()
    }
}
</script>

<style lang="scss" scoped>
::V-deep .input-orgname {
  border: 1px solid red;
}
::v-deep {
  .el-select {
    .el-input__inner{
    width: 167px;
    height: 32px;
    background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
    backdrop-filter: blur(4px);
    font-size: 16px;
    font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
    font-weight: 400;
    color: #65ABE9;
    // line-height: 24px;
  }
  .el-input .el-select__caret{
    line-height: 0;
  }
  }
    
  }
  .intrusion {
    .body {
      .top-box{
        display: flex;
        justify-content: space-between;
        
        .selectBox{
          display: flex;
          align-items: center;
          .item_box{
            position: relative;
          }
        ::v-deep {
          .el-cascader .el-input {
            .el-input__inner{
            padding-left: 36px;
            width: 190px;
            height: 32px;
            background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
            backdrop-filter: blur(4px);
            font-size: 16px;
            font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
            font-weight: 400;
            color: #65ABE9;
            line-height: 24px;
          }
          .el-input .el-select__caret{
            line-height: 0;
          }
          } 
          .el-select .el-input {
            .el-input__inner{
            padding-left: 36px;
            width: 190px;
            height: 32px;
            background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
            backdrop-filter: blur(4px);
            font-size: 16px;
            font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
            font-weight: 400;
            color: #65ABE9;
            line-height: 24px;
          }
          .el-input .el-select__caret{
            line-height: 0;
          }
          } 
         }
         .dec{
        position: absolute;
        top: 9px;
        left: 10px;
        z-index: 100;
        font-size: 16px;
        color: #00BAFF;
      }
      .dec2{
        position: absolute;
        top: 9px;
        left: 29px;
        width: 1px;
        height: 20px;
        background: linear-gradient(180deg, rgba(54,124,255,0) 0%, #4CB4FF 51%, rgba(54,124,255,0) 100%);
        z-index: 100;
      }
      }
      }
      .top {
        height: 20px;
        display: flex;
        justify-content: space-between;
        /* width: 100%; */
        .text {
          height: 20px;
          line-height: 20px;
          display: flex;
          justify-content: space-between;
          width: 176px;
          .qiu{
            display: inline-block;
            width: 10px;
            height: 10px;
            // border-radius: 50%;
          }
        // .written {
        //   width: 116px;
        //   height: 20px;
        //   &:last-child {
        //     border-right: none !important
        //   }
        // }
        
        }
        .select {
          width: 50px;
          margin-top: -4px;
        }
      }
      .container {
        padding-top: 10px;
      }
      
    }
  }
</style>