<template>
  <ModuleBox title="今日新增数据" width="100%" height="20vh" class="civilFly">
    <div class="top-box">
      <div class="left-box">
      </div>

    </div>
    <div class="bottom-box">
      <div class="item-box-value item-box-value1">
        <div class="Box_Val" style="cursor: pointer;" @click="details('用户', '15')">
          <div class="value">
            <div class="label1 mb25">用户</div>
            <div class="label2 f22 fw700">{{ listArr?.userInfo?.count || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="item-box-value item-box-value2">
        <div class="Box_Val" style="cursor: pointer;" @click="details('飞行器', '872')">
          <div class="value ">
            <div class="label1 mb25">飞行器</div>
            <div class="label3 f22 fw700">{{ listArr?.aircraftRegisters?.count || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="item-box-value item-box-value3">
        <div class="Box_Val cp" @click="details('报飞审批', '872')">
          <div class="value">
            <div class="label1 mb25">报飞审批</div>
            <div class="label4 f22 fw700">{{ listArr?.flightRegisters?.count || 0 }}</div>
          </div>
        </div>

      </div>

    </div>
    <Dialog v-if="showDetails" :title="title" :width="900" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValue" />
    </Dialog>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import API from "@/api";

export default {
  components: {
    ModuleBox,
    Table,
    Dialog
  },
  created() {
    this.getTodayFn()
  },
  data() {
    return {
      tableData: [{
        date: '2022-08-18',
        name: '浙江科比特创新科技有限公司',
        address: '刘庆'
      }, {
        date: '2022-08-18',
        name: '浙江科比特创新科技有限公司',
        address: '李寻欢'
      }, {
        date: '2022-08-18',
        name: '深圳科比特有限公司',
        address: '何花'
      }, {
        date: '2022-08-18',
        name: '深圳科比特有限公司',
        address: '何百万'
      }],
      tableDataer: [{
        date: '2022-08-18',
        name: 'MMC01024',
        address: '何百万'
      }, {
        date: '2022-08-18',
        name: 'MMC01024',
        address: '刘庆'
      }, {
        date: '2022-08-18',
        name: 'MMC01024',
        address: '李寻欢'
      }, {
        date: '2022-08-18',
        name: 'MMC01024',
        address: '何花'
      }],
      showList: true,
      dataList: [],
      dataValue: [],
      showDetails: false,
      title: '',
      value: '',
      listArr: []
    };
  },
  methods: {
    details(title, value) {
      if (title == '用户') {
        this.dataList = [
          { title: '个人/单位', name: 'userName', },
          { title: '注册时间', name: 'createdAt' },
          { title: '电话号码', name: 'userPhone' },
          { title: '证件号', name: 'userNumber' },
          // { title: '审核时间', name: 'createdAt' },
          { title: '状态', name: 'status' },
        ]
        let data = this.listArr?.userInfo?.user || []
        let newData = []
        data.forEach(element => {
          let status = null
          if (element.userState == 1) {
            status = '正常'
          }
          newData.push({
            ...element,
            status
          })
        });
        this.dataValue = newData
      } else if (title == '飞行器') {
        this.dataList = [
          { title: '序号', name: 'id'},
          { title: '生产厂家', name: 'manufacturerName' },
          { title: '型号', name: 'aircraftName' },
          { title: '产品序列号', name: 'productNumber' },
          { title: '飞控序列号', name: 'flightControlNumber' },
        ]
        let data = this.listArr?.aircraftRegisters?.aircraftList || []
        let newData = data
        this.dataValue = newData

      } if (title == '报飞审批') {
        this.dataList = [
          { title: '姓名', name: 'applyUserName', },
          { title: '用户类型', name: 'applyUserType' },
          { title: '飞行区域', name: 'flightArea' },
          { title: '飞行活动说明', name: 'flightActivityRemark' },
          { title: '手机号', name: 'applyUserPhone' },
        ]
        let data = this.listArr?.flightRegisters?.flightList || []
        let newData = []
        data.forEach(element => {
          let status = null
          if (element.applyUserType == 1) {
            element.applyUserType = '个人'
          } else {
            element.applyUserType = '单位'
          }
          newData.push({
            ...element,
            status
          })
        });
        this.dataValue = newData
      } else if (title == '飞行日志') {
        this.dataList = [
          { title: '姓名', name: 'name', },
          { title: '用户类型', name: 'status' },
          { title: '民航局登记标识', name: 'b' },
          { title: '无人机类型', name: 'type' },
          { title: '手机号', name: 'a' },
        ]
      }
      this.title = title
      this.value = value
      this.showDetails = true
      console.log(this.showDetails);
    },
    async getTodayFn() {
      let res = await API.AIRCRAFT.getToday()
      console.log(res, 'res');
      if (res.code == 200) {
        this.listArr = res.data
        console.log(this.dataList, '  this.dataList');
      }
    },
    closeDetails() {
      this.showDetails = false
    },
  }
}
</script>

<style lang="scss" scoped>
.civilFly {
  position: relative;

  .bottom-box {
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;


    .item-box-value {
      text-align: center;
      background: url('~@/assets/home/shuju1.png') no-repeat;
      background-size: 100% 100%;
      width: 194px;
      height: 130px;
      background-position-y: -19px;


      &.item-box-value2 {
        background: url('~@/assets/home/shuju1.png') no-repeat;

        background-size: 100% 100%;
        width: 194px;
        height: 130px;
        background-position-y: -19px;
      }

      &.item-box-value3 {
        background: url('~@/assets/home/shuju1.png') no-repeat;
        background-size: 100% 100%;
        width: 194px;
        height: 130px;
        background-position-y: -19px;
      }

      &.item-box-value4 {
        background: url('~@/assets/home/shuju1.png') no-repeat;
        background-size: 100% 100%;
        width: 194px;
        height: 130px;
        background-position-y: -19px;
      }

      display: flex;
      justify-content: center;

      .Box_Val {
        width: 100%;

        .value {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;

          .label1 {
            font-size: 12px;
            color: #a7c0e2;
            font-family: UniDreamLED-Regular, UniDreamLED;
          }

          .label2 {
            color: #fed668;
            font-family: UniDreamLED-Regular, UniDreamLED;
          }

          .label3 {
            font-family: UniDreamLED-Regular, UniDreamLED;
            color: #fed668;
          }

          .label4 {
            color: #fed668;
          }

          .label5 {
            color: #fed668;
          }
        }
      }

    }

  }

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;

    .left-box {
      flex: 1;

      .left {
        padding-left: 10px;
        // width: 78%;
        width: 90%;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        .text {
          font-size: 16px;
          font-family: AppleSystemUIFont;
          color: #DAE9FF;
          // width: 100px;
        }

        .num {
          width: 180px;

          .one-num {
            width: 24px;
            height: 40px;
            background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(113, 153, 242, 0.44), rgba(60, 91, 166, 0)) 1 1;
            font-family: 'UnidreamLED';
            font-size: 30px;
            color: #00FFFF;
            line-height: 40px;
            text-align: center;
          }
        }
      }
    }

    .right-top-switch {
      z-index: 2;
      font-size: 20px;
      color: #DAE9FF;
      cursor: pointer;
    }

  }

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .mc {
      width: 21%;
    }

    .lx {
      width: 60%;
    }

    .zt {
      width: 19%;
    }

    .header {
      font-size: 16px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #AAB7C3;
      text-align: center;
      background: rgba(3, 24, 66, 0.4);
      padding: 0 0 10px 0;

    }

    .content {
      height: 164px;
      overflow: auto;

      .list {
        padding: 10px 0;
        background: #2A459F;
        border-radius: 0px 0px 0px 0px;

      }

      .list2 {
        background: rgba(3, 24, 66, 0.4);
      }
    }

  }

}
</style>