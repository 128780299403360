<template>
  <ModuleBox title="飞行数据统计" width="100%" height="35%" class="distribute distribute_fx">
    <div class="pa top140 w200 " style="z-index:30">
      <el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="change" size="mini"
        class="w346" v-model="value1" type="datetimerange" range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
    <div class="out">
      <div class="content  hwb-flex ofh" style="width: 100%">
        <!-- 累计任务数量 -->
        <div v-for="(item, index) in FlightInfoData" :key="index" @click="details('飞行数据统计')" class="jc hwb-flex ljjc "
          style="color:#00FF99FF">
          <img class="w45 h45 ml10" :src="item.icon" alt="" srcset="">
          <div class="jc-text">
            <div class="num f25">{{ item.data }}</div>
            <div class="text f14">{{ item.typeName }}</div>
          </div>
        </div>
      </div>
    </div>

    <Dialog class="Details" v-if="showDetails" :title="title" :width="1050" @on-close="closeDetails">
      <el-cascader popper-class="input-orgname" @change="taskTotalListSearch" placeholder="请选择所属机构" v-model="organId"
        class="ml50" :options="allOrganizationselectList" :show-all-levels="false" :props="{
          children: 'child',
          label: 'name',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
          empty: false
        }" style="margin-right:5px;" clearable>
      </el-cascader>
      <Table :cellName="dataList" :dataSource="data" />
      <div class="DemandList_Footer">
        <el-pagination class="cpt-table-pagination" v-if="pageShow" @current-change="onChangePage"
          :current-page="currentPage" layout="total , prev, pager, next, jumper" :total="total || 0"
          :page-size="size || 10" />
        <el-pagination class="cpt-table-pagination" v-else current-page="1" layout="total , prev, pager, next, jumper"
          :total="total || 0" :page-size="size || 10" />
      </div>
    </Dialog>

    <!-- 累计任务数量
    <Dialog class="Details" v-if="showDetails2" :title="title" :width="1200" @on-close="closeDetails2">
      <Table :cellName="dataList2" :dataSource="dataValue2" :tableWidth="'2400px'" />
      <div class="DemandList_Footer" v-if="dataValue2.length">
        <el-pagination class="cpt-table-pagination" @current-change="onChangePage2" :current-page="currentPage2 || 1"
          layout="total , prev, pager, next, jumper" :total="total2 || 0" :page-size="size || 10" />
      </div>
    </Dialog> -->
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import feixingdata from './JSONVAL/feixingdata.json'; // 弹框组件
import API from "@/api";
import renwudata from './JSONVAL/renwudata.json'

import { mapGetters } from 'vuex'

export default {
  components: { ModuleBox, Table, Dialog },
  data() {
    return {
      pageShow: true,
      value1: null,
      loading: false,
      dataList: [],
      data: [],
      dataValue2: [],
      title: '',
      value: '',
      showDetails: false,
      showDetails2: false,
      currentPage: 1,
      currentPage2: 1,
      total: 0,
      total2: 0,
      size: 10,
      organId: null,
      FlightInfoData: [

      ]
    };
  },


  created() {
    this.getList()
    this.getSelfOrganizationFn()
  },
  computed: {
    ...mapGetters(['user_info_hn'])
  },
  methods: {
    async getSelfOrganizationFn() {
      let res = await API.TASK.getSelfOrganization()
      this.allOrganizationselectList = this.getTreeData(res.data);
    },
    change(val) {
      if (this.value1) {
        this.getList(this.value1.join(','))
      } else {
        this.getList()
      }
    },
    initData() { },
    async getList(tiem) {
      const { data } = await API.DEVICE.FlightInfo(
        {
          time: tiem || null,
        }
      )
      data.map(element => {
        if (element.type == 1) {//累计飞行距离
          element.icon = require('@/assets/home/s1.png')
          element.style = 'ljjc'
          element.typeName = '累计飞行架次'
        } else if (element.type == 2) {//累计飞行距离
          element.icon = require('@/assets/home/s2.png'),
            element.style = 'ljjl'
          element.data = (element.data / 1000).toFixed(2)
          element.typeName = '累计飞行里程(km)'
        } else if (element.type == 3) {//累计飞行距离
          element.icon = require('@/assets/home/s3.png')
          element.style = 'ljsc'
          element.typeName = '累计飞行时长(h)'
          element.data = (element.data / 3600).toFixed(2)
          console.log(element.data, '累计飞行时长');

        } else {
          element.icon = require('@/assets/home/s4.png'),
            element.style = 'ljsl'
          element.typeName = '累计任务数量'
        }
      });
      this.FlightInfoData = data
    },
    onChangePage(val) {
      this.taskTotalList(val, 10)
    },
    async taskTotalListSearch() {
      this.currentPage = 1
      let time = null
      if (this.value1) {
        time = this.value1.join(',')
      }
      this.pageShow = false
      let res = await API.TASK.taskTotalList({ page: 1, size: 10, time, orgId: this.organId })
      if (res.code == 200) {
        this.data = res.data.data
        this.total = res.data.totalCount
        this.title = '飞行数据统计'
      }
      this.$nextTick(() => {
        this.pageShow = true

      })

    },
    async taskTotalList(page, size) {
      let time = null
      if (this.value1) {
        time = this.value1.join(',')
      }
      console.log(1, '1')
      this.data = []
      let res = await API.TASK.taskTotalList({ page, size, time, orgId: this.organId })
      if (res.code == 200) {
        this.data = res.data.data
        this.total = res.data.totalCount
        this.title = '飞行数据统计'
        if (!this.showDetails) {
          this.showDetails = true
        }
      }


    },
    updataflyValue() {

    },
    async getAllOrganization() {
      // let data  = this.dbox_data
      let data = [
        {
          child: [
            { id: 8, name: "公关办" },
            { id: 9, name: "情指中心" },
            { id: 10, name: "科通科" },
            { id: 11, name: "政治安全保卫大队" },
            { id: 12, name: "刑事侦查大队" },
            { id: 13, name: "巡特警大队" },
            { id: 14, name: "食药环侦大队" },
            { id: 15, name: "交通警察大队" },
            { id: 16, name: "禁毒大队" },
          ],
          id: 2,
          name: "公安局",
        },
        {
          child: [],
          id: 3,
          name: "综合执法局",
        },
        {
          child: [],
          id: 4,
          name: "应急管理局",
        },
        {
          child: [],
          id: 5,
          name: "自然资源和规划局",
        },
        {
          child: [
            { id: 17, name: "河湖中心" },
            { id: 18, name: "水行政执法" },
          ],
          id: 6,
          name: "水利局",
        },
        {
          child: [],
          id: 7,
          name: "生态办",
        },
      ];
      this.selectList = this.getTreeData(data);
    },
    getTreeData(val) {
      val.forEach(item => {
        if (item.child && item.child.length > 0) {
          this.getTreeData(item.child)
        }else{
          item.child =  undefined
        }
      })
      return val
    },
    details(title, value) {
      this.getAllOrganization()

      this.dataList = [
        { title: '任务序号', name: 'id', width: '200px' },
        { title: '任务-需求单名称', name: 'taskTitle', width: '300px' },
        { title: '无人机名称', name: 'deviceName', width: '300px' },
        { title: '飞行时长（分钟）', name: 'flightTime', width: '200px' },
        { title: '飞行距离（公里 km）', name: 'distance', width: '200px' },
      ]
      this.taskTotalList(1, this.size)

    },
    closeDetails() {
      this.showDetails = false
      this.organId = null
    },
    closeDetails2() {
      this.showDetails2 = false
    },
  },

};
</script>

<style lang="scss" scoped>
.DemandList_Footer {
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 50px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
        height: 30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background-color: #2647ee;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          // line-height: 40px;
          width: 40px;
          // height: 40px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 20px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height: 30px;


          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.Details {
  .select {
    display: flex;
    align-items: center;

    .selectBtn {
      cursor: pointer;
      width: 86px;
      height: 30px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
    }
  }

  ::v-deep .el-cascader .el-input {
    .el-input__inner {
      background-color: #000;
      border: 0;
      height: 30px;
      line-height: 30px;
      color: #fff;
      margin-bottom: 10px;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }
}

.distribute {
  width: 100%;
  height: 1500px;

  .out {
    width: 100%;
    height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    .content {
      width: 100%;
      box-sizing: border-box;
      height: 240px;
      flex-wrap: wrap;

      .ljjc {
        background-image: url(./img/bg.png);
      }

      .ljsc {
        background-image: url(./img/2.png);
      }

      .ljjl {
        background-image: url(./img/3.png);
      }

      .ljsl {
        background-image: url(./img/4.png);
      }

      .jc {
        width: 166px;
        height: 90px;
        cursor: pointer;

        .jc-tp {
          width: 46px;
          height: 46px;
          font-size: 46px;
          margin-left: 20px;

          &.iconfont.icon-leijifeihangjiaci {
            background: linear-gradient(180deg, #FFFFFF 0%, #D0FDFC 59%, #50F9F4 100%);
            //将字体图标改为只显示背景在字体上，就完成了字体的渐变
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijifeihangshichang {
            background: linear-gradient(180deg, #CBF3FF 0%, #9BE8FF 59%, #00C4FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijifeihangjuli {
            background: linear-gradient(180deg, #EFFFF5 0%, #82FFD0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-leijirenwushuliang {
            background: linear-gradient(180deg, #FFF0C8 0%, #FFCE4F 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .jc-text {
          margin-right: 20px;
          text-align: center;

          .num {
            font-size: 32px;
            font-family: DINAlternate-Bold, DINAlternate;
          }

          .text {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #DAE4FF;
          }
        }
      }
    }
  }
}

.out {
  width: 100%;
  height: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // text-align: center;

  .mc {
    width: 25%;
  }

  .lx {
    width: 15%;
  }

  .zt {
    width: 22%;
  }

  .bs {
    width: 29%;
  }

  .cz {
    width: 13%;
  }

  .header {
    font-size: 16px;
    font-family: SourceHanSansSC-Bold, SourceHanSansSC;
    font-weight: bold;
    color: #AAB7C3;
    text-align: center;
    background: rgba(3, 24, 66, 0.4);
    padding: 0 0 10px 0;

  }

  .content {
    height: 164px;
    overflow: auto;

    .list {
      padding: 10px 0;
      background: linear-gradient(270deg, rgba(132, 179, 242, 0) 0%, rgba(132, 179, 242, 0.3) 52%, rgba(132, 179, 242, 0) 100%);

    }

    .list2 {
      background: rgba(3, 24, 66, 0.4);
    }
  }
}
</style>
<style lang="scss">
.distribute_fx {
  .el-date-editor {
    background: #082c64;
    border: 1px solid #2a80b4;

    .el-range-input {
      color: #fff;
    }

    .el-range-separator {
      color: #fff;

    }
  }


}
</style>