<template>
  <ModuleBox
    title="需求趋势"
    width="100%"
    height="20%"
    class="cpt-device-demand-box"
  >
    <div class="out">
      <div class="container">
        <div class="topText">
            <div class="button" v-for="item in list" :key="item.type">
                <div class="text"><div class="icon" :style="`background: ${item.color}`"></div>{{ item.type }}</div>
                <div class="num">{{ item.num }}</div>
            </div>
        </div>
        <div id="demand-chart" class="demand-chart"></div>
      </div>
    </div>
     
  </ModuleBox>
</template>

<script>
let chart;
import { Chart } from "@antv/g2";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import API from "@/api";
import {mapGetters,mapActions} from "vuex"
export default {
  computed:{
    ...mapGetters(["dbox_data"])
  },
  components: { ModuleBox },
  data() {
    return {
      filter: {
        organId: null,
      },
      type: 3,
      flylist: [],
      flyValue: "",
      selectList:null,
      dataList: null,

      list:[
        {
            type: '年业务量',
            num: 111,
            color: 'red'
        },
        {
            type: '月业务量',
            num: 74,
            color: 'blue'
        },
        {
            type: '周业务量',
            num: 20,
            color: 'yellow'
        },
        {
            type: '日业务量',
            num: 3,
            color: 'green'
        },
      ]

    };
  },
  methods: {
    ...mapActions("user",["Dbox"]),
    getAllOrganization() {
      let data  = this.dbox_data
      this.selectList = this.getTreeData(data);
      this.filter.organId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    // 初始化视图
    async init_viewer() {
      await this.getdataList()
      this.newChart()
    },



    // 初始化图表
    newChart (){
      chart = new Chart({
        container: "task-chart",
        autoFit: true,
        height: 70,
        padding: [10, 28, 60, 38], // 上，右，下，左
      });
      chart.data(this.dataList);
      chart.scale({
        date: {
          range: [0, 1],
        },
        temperature: {
          nice: true,
        },
      });

      chart.axis("temperature", {
        label: {
          formatter: (val) => {
            return val;
          },
        },
      });
      // 隐藏y轴水平线
      chart.axis('values', {
        grid: null
      });
      chart.legend({
        itemName: {
          style: {
            fill: '#FFFFFF',
          }
        },
      })
      chart.tooltip({
        showCrosshairs: true,
        shared: true,
        enterable: true,
  // 自定义 item 模板：每项记录的默认模板，自定义模板时必须包含各个 dom 节点的 class。
  itemTpl: `
    <li class="g2-tooltip-list-item">
      <span class="g2-tooltip-marker" style="background-color: {color};"></span>
      <span class="g2-tooltip-name">{showType}</span>:<span class="g2-tooltip-value">{showValue}</span>
    </li>
  `
      });

      chart.line().position("date*values").color("types").shape("smooth").tooltip('types*values*typeTime', (types, values,typeTime) => {
        return {
          showType: types,
          showValue: types === '飞行总时间' && values > 0  ? typeTime :values
        }
      });

      chart.point().position("date*values").color("types").shape("circle");
      chart.render();
    },

    // 获取图表数据
    async getdataList() {
      const datalist = await API.HOME.getFlightCount({
        organId: this.filter.organId,
        deviceId: this.flyValue,
        type: this.type,
      });
      let list = datalist.data
      let data = [];
      list.map((item) => item.map((value) => data.push(value)));
      data.map((item) =>
        item.value === "0" || item.value === "0.0000"
          ? (item.values = 0)
          : (item.values = Number(item.value))
      );
      data.map((item) => (item.date = item.date + " "));
      data.map((item) => {
        switch (item.type) {
          case 1:
            return (item.types = "飞行总架次(pcs)");
            break;
          case 2:
            return (item.types = "飞行总距离(KM)");
            break;
          case 3:
            return (item.types = "飞行总时间");
            break;
          default:
            null;
        }
      });
      data.map(item => {
        if(item.type === 2 && item.values !== 0) {
          item.values = (item.values / 1000).toFixed(2)
        }
        if(item.type === 3) {
          item.typeTime = this.getTime(item.values) + " "
        }
      })
      this.dataList = data
    },

    init() {
        const data = [
  { year: '7-23', value: 5, type: '需求量' },
  { year: '7-24', value: 4.9, type: '需求量' },
  { year: '7-25', value: 6, type: '需求量' },
  { year: '7-26', value: 7, type: '需求量' },
  { year: '7-27', value: 7, type: '需求量' },
  { year: '7-28', value: 9, type: '需求量' },
  { year: '7-29', value: 13, type: '需求量' },
];
const chart = new Chart({
  container: 'demand-chart',
  autoFit: true,
  height: 70,
        
});

chart.data(data);
chart.scale({
  year: {
    range: [0, 1],
  },
  value: {
    min: 0,
    nice: true,
  },
});

// 隐藏y轴水平线
      chart.axis('value', {
        grid: null
      });
      chart.legend({
        itemName: {
          style: {
            fill: '#fff',
          }
        },
      })

chart.tooltip({
  showCrosshairs: false,
  shared: true,
  enterable: true,
  // 自定义 item 模板：每项记录的默认模板，自定义模板时必须包含各个 dom 节点的 class。
  itemTpl: `
    <li class="g2-tooltip-list-item">
      <span class="g2-tooltip-marker" style="background-color: #5e92f6;"></span>
      <span class="g2-tooltip-name">{showType}</span>:<span class="g2-tooltip-value">{showValue}</span>
    </li>
  `
});

chart.line().position('year*value')
.tooltip('year*value*type', (year, value,type) => {
        return {
          showType: type,
          showValue: value
        }
      });
chart.point().position('year*value').tooltip('year*value*type', (year, value,type) => {
        return {
          showType: type,
          showValue: value
        }
      });

chart.render();
    }
  },    
  mounted() {
    // this.getAllOrganization()
    // this.Dbox()
    // this.getflylist();
    // this.init_viewer();
    this.init()
  },
};
</script>

<style lang="scss" scoped>
.cpt-device-demand-box {
  width: 100%;
  height: 100%;
  .out{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .container{
      height: 90%;
      .demand-chart{
        height: calc(100% - 50px);
      }
    }
  }
  .topText {
      display: flex;
      margin-top: 10px;
  }
  .button {
    width: 86px;
    height: 44px;
    background: linear-gradient(360deg, rgba(25,53,104,0.1000) 0%, rgba(47,79,131,0.8000) 100%);
    border-radius: 4px;
    margin-right: 5px;
    .text {

    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #AFCDFF;
    line-height: 17px;
    margin-left: 9px;

    .icon{
      content:'';
      display: inline-block;
      width:5px;
      height:5px;
      background:#999999;
      border-radius: 50%;
      margin-right:6px;
    }
    }
    .num {
        width: 21px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #D8E7FF;
        line-height: 25px;
        margin-left: 20px;
    }
  }
}
</style>
<style>
  .el-scrollbar.el-cascader-menu {
  max-height: 400px;
  overflow-y: auto;
}
</style>