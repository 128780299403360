<template>
  <ModuleBox
    title="应用场景占比"
    width="100%"
    height="30%"
    class="cpt-device-module-box"
  >
    <div class="container">
      <div class="box">
        <div class="in-desc">
          <div class="words">应用场景占比</div>
        </div>
        <div id="status-chart" class="status-chart"></div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import { Chart, registerShape } from "@antv/g2";
import API from "@/api";


// 可以通过调整这个数值控制分割空白处的间距，0-1 之间的数值
const sliceNumber = 0.01;

// 自定义 other 的图形，增加两条线
registerShape("interval", "slice-shape", {
  draw(cfg, container) {
    const points = cfg.points;
    let path = [];
    path.push(["M", points[0].x, points[0].y]);
    path.push(["L", points[1].x, points[1].y - sliceNumber]);
    path.push(["L", points[2].x, points[2].y - sliceNumber]);
    path.push(["L", points[3].x, points[3].y]);
    path.push("Z");
    path = this.parsePath(path);
    return container.addShape("path", {
      attrs: {
        fill: cfg.color,
        path,
      },
    });
  },
});

export default {
  components: { ModuleBox },
  data() {
    return {
      list: [],
      status_list: [],
    };
  },
  methods: {
   async init_chart() {
      
      const list = {
        count: 500,
        executionComplete: 75, //打架斗殴
        inExecution: 74, //违章停车
        pending: 241, // 交通事故
        toBeDistributed: 110 //河道污染
      }
      const pending = list.pending === 0 ? 0 : (list.pending / list.count).toFixed(2);
      const inExecution = list.inExecution === 0 ? 0 : (list.inExecution / list.count).toFixed(2);
      const executionComplete =list.executionComplete === 0 ? 0 : (list.executionComplete / list.count).toFixed(2);
      const toBeDistributed =list.toBeDistributed === 0 ? 0 : (list.toBeDistributed / list.count).toFixed(2);
      const data = [
        { item: "交通事故", count: list.pending, percent: Number(pending) },
        {
          item: "违章停车",
          count: list.inExecution,
          percent: Number(inExecution),
        },
        {
          item: "打架斗殴",
          count: list.executionComplete,
          percent: Number(executionComplete),
        },
        {
          item: "河道污染",
          count: list.toBeDistributed,
          percent: Number(toBeDistributed),
        },
      ];

      const chart = new Chart({
        container: "status-chart",
        width: 430,
        height: 200
      });
      chart.data(data);
      chart.legend(false);
      chart.scale("percent", {
        formatter: (val) => {
          val = val * 100 + "%";
          return val;
        },
      });
      chart.coordinate("theta", {
        radius: 0.85,
        innerRadius: 0.85,
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl:
          '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
      });
      
      chart
        .interval()
        .adjust("stack")
        .position("percent")
        .shape('slice-shape')
        .color("item", (item) => {
          switch (item) {
            case "交通事故":
              return "l(360) 0:rgba(38, 128, 255,0) 1:rgba(38, 128, 255,1)";
              break;
            case "违章停车":
              return "l(360) 0:rgba(35, 224, 198, 0) 1:rgba(35, 224, 198, 1)";
              break;
            case "打架斗殴":
              return "l(360) 0:rgba(250, 174, 66, 0) 1:rgba(250, 174, 66, 1)";
              break;
            case "河道污染":
              return "l(360) 0:rgba(255, 100, 100, 0) 1:rgba(255, 100, 100, 1)";
              break;
            default:
              break;
          }
        })

        .label("percent", (percent) => {
          return {
            style: {
              fill: "#AFCDFF",
            },
            content: (data) => {
              return `${data.item}: ${(percent * 100).toFixed(0)}%`;
            },
          };
        })
        .tooltip("item*percent*count", (item, percent, count) => {
          // percent = percent * 100 + "%";
          return {
            name: item,
            value: count,
          };
        });

      chart.interaction("element-active");

      chart.render();
    },
  },
  mounted() {
    this.init_chart();
  },
};
</script>

<style lang="scss" scoped>
.cpt-device-module-box {
  .container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .box{
      position: relative;
      .in-desc{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -57px;
        margin-top: -57px;
        width: 114px;
        height: 114px;
        background: 50% url(./img/bg.png) no-repeat;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #E3F3FF;
        display: flex;
        align-items: center;
        justify-content: center;
        .words{
          width: 64px;
          text-align: center;
        }
      }
      .status-chart {
        height: 100%;
      }
    }
  }
  
}
</style>