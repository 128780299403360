<template >
    <div id="closeIDsPopupdiv">
        <Dialog @on-close="closeIDsPopup" title="标注" :left="140" :top="105" :width="438">
            <div class="layerBox " id="layerBox">
                <el-input placeholder="输入标注名称搜索" size="mini " clear v-model="search">
                    <i class="el-input__icon el-icon-search cp" slot="suffix" @click="handleIconClick">
                    </i>
                </el-input>
                <el-row :gutter="20" class="mt15 mb15">
                    <el-col :span="15" class="tl">
                        <el-checkbox v-model="checked" @change="all">全选</el-checkbox>
                        <el-checkbox v-model="checked2" @change="InvertSelection">反选</el-checkbox>
                    </el-col>
                    <el-col :span="8" class="jcsb pl20 cf">
                        <el-tooltip placement="bottom">
                            <div slot="content">
                                <div class=" info w70 tc ">
                                    <div class="cp mt10 text" @click="getmarkerLists(null)">全部</div>
                                    <div class="cp mt10 text" @click="getmarkerLists(1)">筛选点标注</div>
                                    <div class="cp mt10 text" @click="getmarkerLists(2)">筛选线标注</div>
                                    <div class="cp mt10 text" @click="getmarkerLists(3)">筛选面标注</div>
                                </div>
                            </div>
                            <span class="icon-shaixuan iconfont cp"></span>
                        </el-tooltip>
                        <span class="icon-shanchu1 iconfont cp" @click="del"></span>
                        <!--  -->



                        <!-- <el-tooltip placement="bottom">
                            <div slot="content">
                                <div class="  info ">
                                    <JsonExcel :fields="json_fields" :fetch="fetch" :data="jsonExcelData" name="标注统计.xls">
                                        <span class="cp text">.xls文件导出</span>
                                    </JsonExcel>
                                    <div class="cp mt10 text" @click="exportFn('kml')">.kml文件导出</div>
                                    <div class="cp mt10 text" @click="add('polygon')">.kmz文件导出</div>
                                </div>
                            </div>
                            <span class="icon-xiazaimoban iconfont  cp"></span>
                        </el-tooltip> -->
                        <el-tooltip class="item" effect="dark" content=".xsl导出" placement="top">
                            <JsonExcel :fields="json_fields" :fetch="fetch" :data="jsonExcelData" name="标注统计.xls">
                                <span class="cp text icon-xiazaimoban iconfont"></span>
                            </JsonExcel>
                        </el-tooltip>

                        <el-tooltip placement="bottom">
                            <div slot="content">
                                <div class="  info ">
                                    <div class="cp mt5 text" @click="add('point')">添加点标注</div>
                                    <div class="cp mt10 text" @click="add('line')">添加线标注</div>
                                    <div class="cp mt10 text" @click="add('polygon')">添加面标注</div>
                                </div>
                            </div>
                            <span class="icon-tianjia iconfont cp"></span>
                        </el-tooltip>

                    </el-col>
                </el-row>
                <div class="itemBox pr10">
                    <div class="item p10 pr" v-for="item in records">
                        <div class="jcfs wih100 ">
                            <img class="w103 h62 dib " :src="item.img || img" alt="">
                            <div class="ml10 cf f12">
                                <el-row class="h20 lh20 w240 hidden"><span style="color:#00c7ff"
                                        class="iconfont f12 icon-chengguoku" />
                                    {{ item.title }}
                                    <el-checkbox style="position:absolute" class=" top0 right0 cp" v-model="item.status">
                                    </el-checkbox>
                                </el-row>
                                <el-row class="h20 lh20"><span style="color:#ecb035" class="iconfont f12 icon-chengguoku" />
                                    等级：{{ item.level }}级</el-row>
                                <el-row class="h20 lh20"><span style="color:#00ff6c" class="iconfont f12 icon-chengguoku" />
                                    {{ item.addtime }}</el-row>
                            </div>
                        </div>
                        <div class="f14 cf mt10 ">
                            <el-row class="h24 lh24 w300 hidden"><span style="color:#0058ff"
                                    class="iconfont    f14 mr10 icon-chengguoku"></span><span v-for="i in item.tagList">{{
                                        i.name }}、</span></el-row>
                            <el-row class="h24 lh24 hidden w350 cp">
                                <span :class="item.addressStatus ? 'f569' : 'cf'" class="iconfont f14    mr10 icon-dingwei3"
                                    @click="showArea(item)"></span>{{
                                        item.address || '暂无'
                                    }}

                                <span class="fr">
                                    <el-tooltip placement="bottom">
                                        <div slot="content">
                                            <div class="  info ">
                                                <div class="cp mt10 text" @click="exportFn('kml', item)">.kml文件导出</div>
                                                <div class="cp mt10 text" @click="exportFn('kmz', item)">.kmz文件导出</div>
                                            </div>
                                        </div>
                                        <span class="icon-xiazaimoban iconfont  cp"></span>
                                    </el-tooltip>


                                </span>
                            </el-row>
                        </div>

                    </div>
                </div>
            </div>
        </Dialog>
        <Dialog v-if='form.type' @on-close="closeFn" :title="title" :left="600" :top="235" :width="438">
            <div class="jcfs">
                <img class="w133 h82 dib " :src="img" alt="">
                <div class="ml10 cf h30  lh30">
                    <el-row class="cp">
                        <span class="" @click="updateFn"> <span class="iconfont mr4 f18  icon-dingwei"
                                style="color:#ecb035"></span> 更新位置</span>
                    </el-row>
                    <el-row class="cp">
                        <span @click="handle_screenshot">
                            <span class="iconfont mr4  icon-tuxiangku" style="color:#ecb035"></span> 截取封面
                        </span>
                    </el-row>
                    <el-row class="cp ">
                        <span @click="handle_flyTo"> <span class="iconfont icon-dingwei3" style="color:#00c7ff"></span>
                            飞入标注</span>
                    </el-row>
                </div>
            </div>
            <div class="jcsb list mt32">
                <div v-for="(item, index) in table[form.type]" :key="index" :class="index == itemIndex ? 'active' : ''"
                    class=" item" @click="changeTble(index)">{{ item }}</div>
            </div>
            <div class="mt20 h425 cf">
                <el-form size="mini" ref="formRef" :model="form" :rules="rules" label-width="80px">
                    <div v-show="itemIndex == 0">
                        <el-form-item label="编号" prop="code">
                            <el-input placeholder="请输入编号" v-model="form.code"></el-input>
                        </el-form-item>
                        <el-form-item label="标注名称" prop="title">
                            <el-input v-model="form.title" placeholder="请输入标注名称"></el-input>
                        </el-form-item>
                        <el-form-item label="等级" width="315">
                            <el-select v-model="form.level" placeholder="请选择等级">
                                <el-option label="1" value="1"></el-option>
                                <el-option label="2" value="2"></el-option>
                                <el-option label="3" value="3"></el-option>
                                <el-option label="4" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="经度">
                            {{ form.lng }}
                        </el-form-item>
                        <el-form-item label="纬度">
                            {{ form.lat }}
                        </el-form-item>
                        <el-form-item label="标签">
                            <el-select v-model="form.tags" multiple multiple-limit="4" style="margin-left: 20px;"
                                placeholder="请选择">
                                <el-option v-for="item in tagList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="地址">
                            <el-input type="textarea" v-model="form.address"></el-input>
                        </el-form-item>
                    </div>
                    <!-- 点标注 -->
                    <div v-show='form.type == 1'>
                        <el-form-item v-show="itemIndex == 1" label="图标">
                            <el-select v-model="form.icon" placeholder="请选择图标">
                                <el-option v-for="item in iconArr" :key="item.id" :label="item.icoName"
                                    :value="item.icoUrl">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-show="itemIndex == 2" label="备注">
                            <!-- desc 改为 remarks -->
                            <el-input type="textarea" v-model="form.remarks"></el-input>
                        </el-form-item>

                    </div>
                    <!-- 线标注 -->
                    <div v-show='form.type == 2'>
                        <el-form-item v-show="itemIndex == 1" label="线宽">
                            <el-input placeholder="请输入线宽" v-model="form.length"></el-input>
                        </el-form-item>
                        <el-form-item v-show="itemIndex == 1" label="颜色">
                            <el-color-picker class="w153" v-model="form.style"></el-color-picker>
                        </el-form-item>
                        <el-form-item label="高程" v-show="itemIndex == 1">
                            <el-select v-model="form.height" placeholder="请选择等级">
                                <el-option label="海拔高程" value="1"></el-option>
                                <el-option label="贴地表对象" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-show="itemIndex == 2" label="备注">
                            <el-input type="textarea" v-model="form.remarks"></el-input>
                        </el-form-item>
                    </div>
                    <!-- 面标注 -->
                    <div v-show='form.type == 3'>
                        <el-form-item v-show="itemIndex == 1" label="颜色">
                            <el-color-picker class="w153" v-model="form.style"></el-color-picker>
                        </el-form-item>
                        <el-form-item label="高程" v-show="itemIndex == 1">
                            <el-select v-model="form.height" placeholder="请选择高程">
                                <el-option label="海拔高程" value="海拔高程"></el-option>
                                <el-option label="贴地表对象" value="贴地表对象"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <!-- table为基本信息时显示 -->
                    <el-form-item v-show="itemIndex == 0">
                        <div class="wih100">
                            <div class="cf ml100 btn" @click="onSubmit">确定</div>
                        </div>
                    </el-form-item>
                </el-form>

            </div>
        </Dialog>
    </div>
</template>
<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Dialog from "../dialog/index.vue"
import screenshot from '@/utils/Cesium/Screenshot'
import dataURItoBlob from '@/utils/dataURItoBlob'
import drawGraphic from '@/utils/Cesium/DrawGraphic'
import JsonExcel from 'vue-json-excel'

import FileSaver from 'file-saver'; //注意大小写
import {
    createPoint,
    createLine,
    createPolygon,
} from '@/utils/Cesium/CreateLabel'
let dataEntities = null;
let markerArr = [];
let viewer;
import API from "@/api";
import { number } from "echarts";
import { link } from "fs";
export default {
    components: { ModuleBox, Dialog, JsonExcel },
    data() {
        return {
            checked: false,
            checked2: false,
            iconArr: [],
            search: "",
            radio: '0',//全选反选
            title: "",
            itemIndex: 0,//table 0 代表基本信息 1 代表样式设计  2 代表备注说明
            tagList: [],
            table: {
                1: ['基本信息', '样式设置', '备注说明'],
                2: ['基本信息', '样式设置', '备注说明'],
                3: ['基本信息', '样式设置']
            },
            entitieType: {
                1: "point",
                2: 'polyline',
                3: 'polygon'
            },
            form: {
                code: null,
                address: null,
                lng: null,
                lat: null,
                level: '1',
                title: "默认标注",
                remarks: '',
                type: null,//标注类型 1点标注 2 线标注 3面标注
                img: null,
                icon: null,
                length: 5,//线宽
                height: "贴地表对象",//高程
                style: '',//颜色
                geom: null,
                tags: null
            },
            rules: {
                code: [
                    { required: true, message: '请输入编号', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: '请输入标注名称', trigger: 'blur' }
                ],
            },
            records: [],
            jsonExcelData: [],
            img: require('@/assets/img/acquiescent.png'),
            VUE_APP_IMGIP: "",
            json_fields: {
                "编号": "code",    //常规字段
                "标注名称": "title",
                "标注类型": "type",
                "等级": "level",
                "颜色": "style",
                "经度": "lng",
                "维度": "lat",
                "地址": "address",
                "标签": "tagList",
                "封面地址": "img",
                "三维坐标": "geom",
                "备注": "remarks",
            },
        }
    },
    created() {
        this.getIcon()
        const { VUE_APP_IMGIP } = process.env;
        this.VUE_APP_IMGIP = VUE_APP_IMGIP
        this.tagListFn()
        this.getmarkerLists()
    },
    watch: {
        'form.title'(title) {
            if (dataEntities) {
                dataEntities.label.text = title
            }
        }, 'form.icon'(url) {
            if (dataEntities) {
                let { VUE_APP_IMGIP } = this
                dataEntities.billboard.image = VUE_APP_IMGIP + url
            }
        },
        'form.length'(num) {
            if (typeof parseInt(num) == 'number') {
                if (dataEntities) {
                    dataEntities.polyline.width = parseInt(num)
                }
            } else {
                this.$message.warning('请输入数字！')
            }

        },
        'form.style'(color) {
            if (dataEntities) {
                let { form } = this
                if (form.type == 2) {
                    dataEntities.polyline.material = Cesium.Color.fromCssColorString(color)
                } else if (form.type == 3) {
                    dataEntities.polygon.material = Cesium.Color.fromCssColorString(color)
                }
            }
        }
    },
    beforeDestroy() {
        if (dataEntities) {
            viewer.entities.remove(dataEntities)
        }
        markerArr.forEach(element => {
            viewer.entities.remove(element.entities)

        });
        markerArr = []
        dataEntities = null
    },
    mounted() {
        viewer = window.viewer
    },
    methods: {
        async exportFn(type, item) {
            if (type == 'kml') {
                let res = await API.MODEL.deriveMarker({ id: item.markerId, type: 1 })
                let content = res
                let blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, `${item.title}.${type}`)
            } else if(type == 'kmz'){
                let res = await API.MODEL.deriveMarkerKmz({ id: item.markerId, type: 2 })
                let content = res
                const blob = new Blob([content], { type: 'application/vnd.google-earth.kmz' });
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download = `${item.title}.${type}`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
            }
        },
        typeFiter(type) {
            if (type == 1) {
                return '点标注'
            } else if (type == 2) {
                return '线标注'
            } else {
                return '面标注'
            }
        },
        fetch() {
            let recordsAll = this.records.filter((i) => { return i.status == true }) || []
            recordsAll = JSON.parse(JSON.stringify(recordsAll))
            console.log(recordsAll, 'recordsAll');
            recordsAll.forEach(v => {
                v.code = '`' + v.code
                if (v.type) {
                    v.type = this.typeFiter(v.type)
                }
                if (v.tagList) {
                    v.tagList = v.tagList.map(v => v.name).join(',')
                }
            })
            return recordsAll
        },
        showArea(item) {
            item.addressStatus = !item.addressStatus
            let marker = markerArr.filter((e) => e.markerId == item.markerId)
            if (marker.length > 0) {
                viewer.entities.remove(marker[0].entities)
                markerArr = markerArr.filter(e => e.markerId != item.markerId)
                return
            }
            let state;
            let positions = JSON.parse(item.geom)
            if (item.type == 1) {
                state = 'point'
            } else if (item.type == 2) {
                state = 'line'
            } else if (item.type == 3) {
                state = 'polygon'
            }
            let data = {
                positions,
                image: this.VUE_APP_IMGIP + item.icon,
                title: item.title,
                state,
                color: item.style,
                methodState: 'add',
                width: item.length,
                polyCenter: Cesium.BoundingSphere.fromPoints(positions).center,
                outlineColor: '#238ff3',
                markerId: item.markerId
            }
            data = this.createLabelEntities(viewer, data, state)
            markerArr.push(data)
            viewer.flyTo(data.entities) //飞入实例
        },
        // 全选
        all() {
            this.checked2 = false
            if (this.checked) {
                this.records.map((i) => { return i.status = true })
            } else {
                this.records.map((i) => { return i.status = false })
            }
        },
        InvertSelection() {
            this.checked = false
            this.records.map((i) => { return i.status = !i.status })
        },
        del() {
            let ids = []
            this.records.forEach(element => {
                if (element.status) {
                    ids.push(element.markerId)
                }
            });
            if (ids.length == 0) return this.$message.warning('请选择需要删除的标注！')
            this.removeBatchFn(ids.join(','))
        },
        async removeBatchFn(ids) {
            let res = await API.MODEL.removeBatch({ ids })
            if (res.code == 200) {
                this.$message.success('删除成功！')
                this.getmarkerLists()
            }
        },
        handleIconClick() {
            this.getmarkerLists()
        },
        // 获取标注列表
        async getmarkerLists(type) {
            let res = await API.MODEL.getmarkerList({
                pageNo: 1,
                pageSize: 1000,
                title: this.search || null,
                type
            })
            if (res.code == 200) {
                let data = res.data.records.map((item) => ({
                    ...item,
                    status: false,
                    addressStatus: false
                }))
                this.records = data.reverse()
                let ExcelData = []
                try {
                    res.data.records.forEach((item) => {
                        if (item.level) {
                            item.level = item.level + "级"
                        }
                        if (item.tagList?.length > 0) {
                            item.tagList = item.tagList.map((i) => i.name).join('、')
                        }
                        item.type = item.type == 1 ? '点标注' : item.type == 2 ? '线标注' : '面标注'
                        ExcelData.unshift(item)
                    })
                } catch (error) {
                    console.log(error);
                }
                this.jsonExcelData = JSON.parse(JSON.stringify(ExcelData))
            }
        },
        async tagListFn() {
            let res = await API.TASK.tagList()
            if (res.code == 200) {
                this.tagList = res.data
            }
        },
        //保存标注
        onSubmit() {
            this.$refs['formRef'].validate(async (valid) => {
                if (valid) {
                    this.form.tagList = JSON.stringify(this.form.tagList)
                    let resFilter = await API.MODEL.MarkerFilter({ code: this.form.code })
                    if (resFilter.code == 200 && resFilter.data == false) {
                        let res = await API.MODEL.addMarker(this.form)
                        if (res.code == 200) {
                            this.$message.success('添加成功')
                            this.getmarkerLists()
                            this.closeFn()
                        } else {
                            this.$message.error(resFilter.message)
                        }
                    } else {
                        this.$message.error('编号已存在')
                    }
                }
            })

        },
        // 更新位置
        updateFn() {
            let type = this.form.type
            this.add(this.entitieType[type])
        },
        //关闭新增标出弹框  
        closeFn() {
            this.form = {
                code: null,
                address: null,
                lng: null,
                lat: null,
                level: '1',
                title: "默认标注",
                remarks: '',
                type: null,//标注类型 1点标注 2 线标注 3面标注
                img: null,
                icon: null,
                length: 5,//线宽
                height: "贴地表对象",//高程
                style: '#188fff86',//颜色
                geom: null,
                tags: null
            }
            viewer.entities.remove(dataEntities)
            this.img = require('@/assets/img/acquiescent.png')
        },
        // 飞入标注
        handle_flyTo() {
            if (dataEntities) {
                viewer.flyTo(dataEntities)
            }
        },
        // 获取icon列表
        async getIcon() {
            let res = await API.MODEL.getIco({
                pageNum: 1,
                pageSize: 1000
            })
            if (res.code == 200) {
                this.iconArr = res.data.records
            }
        },
        // 切换标注中的table
        changeTble(num) {
            this.itemIndex = num
        },
        // 关闭弹窗
        closeIDsPopup() {
            this.$emit('closeDemand');
        },

        // 获取封面
        handle_screenshot() {
            screenshot(this.viewer, async (img) => {
                if (img) {
                    this.img = img.src
                    img = await this.getfileUpload(img.src)
                    document.body.style.cursor = 'default'
                }
            })
        },
        async getfileUpload(src) {
            const params = new FormData()
            const blobFile = dataURItoBlob(src)
            params.append('uploadFile', blobFile)
            let res = await API.TASK.Upvideo(params)
            this.form.img = res.storage
        },
        // cesium生成标注
        add(state) {
            viewer.entities.remove(dataEntities)
            this.itemIndex = 0;
            drawGraphic(viewer, state, (earthPosition, floatingPoint = []) => {

                let cartesian3 = earthPosition[0] || null
                if (cartesian3) {
                    let ellipsoid = viewer.scene.globe.ellipsoid
                    let cartographic = ellipsoid.cartesianToCartographic(cartesian3)
                    let lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(6)
                    let lng = Cesium.Math.toDegrees(cartographic.longitude).toFixed(6)
                    this.form.lat = lat
                    this.form.lng = lng
                }
                let icon = null
                if (this.iconArr.length > 0 && state == 'point') {
                    icon = this.iconArr[0].icoUrl
                    this.form.icon = this.iconArr[0].icoUrl
                }
                let data = {
                    positions: earthPosition,
                    image: this.VUE_APP_IMGIP + icon,
                    title: '默认标注',
                    state,
                    color: this.form.style,
                    methodState: 'add',
                    width: 5,
                    polyCenter: Cesium.BoundingSphere.fromPoints(earthPosition).center,
                    outlineColor: '#238ff3',
                }
                floatingPoint.map((item) => {
                    viewer.entities.remove(item)
                })
                if (state == 'line' && earthPosition.length < 2) {
                    return this.$message.warning('线标注为两个点或两个点以上')
                } else if (state == 'polygon' && earthPosition.length < 3) {
                    return this.$message.warning('面标注为三个点或三个点以上')
                }
                data = this.createLabelEntities(viewer, data, state)
                dataEntities = data.entities
                this.form.geom = JSON.stringify(data.positions)
                this.form.code = Date.now().toString()
                if (state == 'point') {
                    this.form.type = 1
                    this.title = '新增点标注'
                } else if (state == 'line') {
                    this.form.type = 2
                    this.title = '新增线标注'
                } else if (state == 'polygon') {
                    this.form.type = 3
                    this.title = '新增面标注'
                }
            })
        },
        //  创建标注实例
        createLabelEntities(viewer, data, state) {
            let entities
            if (state == 'point') {
                entities = createPoint(viewer, {
                    position: data.positions[0],
                    image: data.image,
                    title: data.title,
                })
            } else if (state == 'line') {
                entities = createLine(viewer, {
                    polyCenter: data.polyCenter,
                    positions: data.positions,
                    width: data.width,
                    color: data.color,
                    title: data.title,
                })
            } else {
                entities = createPolygon(viewer, {
                    polyCenter: data.polyCenter,
                    positions: data.positions,
                    color: data.color,
                    title: data.title,
                    outlineColor: data.outlineColor,
                })
            }
            data.entities = entities
            // viewer.flyTo(entities)
            return data
        }
    },
}
</script>
<style lang="scss" scoped>
.text:hover {
    color: #1F4DCB;
}

.f569 {
    color: #00f569
}

.layerBox {
    height: 77vh;
    // overflow-y: auto;
    // overflow-x: hidden;

    .titleBox {
        height: 34px;
        line-height: 34px;
        background: rgba(138, 152, 177, 0.18);
    }

    ::v-deep {
        .el-input--mini {
            box-shadow: inset 0px 1px 3px 0px #00142F;
            border: 1px solid #73A6D1;
            backdrop-filter: blur(2px);
        }

        .el-input--mini .el-input__inner {
            border: 1px solid #73A6D1;
            box-shadow: inset 0px 1px 3px 0px #00142F;
            background: rgba(21, 95, 192, 0.3);
            color: #fff;
        }
    }

    .itemBox {
        height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;

        .item {
            width: 385px;
            height: 152px;
            margin-bottom: 10px;

            background: rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            border: 1px solid #73A6D1;
            backdrop-filter: blur(4px);
            box-sizing: border-box;

            .hidden {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }


        }

    }
}

.list {
    background: rgba(0, 0, 0, 0.7);
    display: flex;

    .item {
        // width: calc(100% / 3);
        flex: 1;
        text-align: center;
        color: #fff;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
    }

    .active {
        background: #1F4DCB;
    }
}

.btn {
    width: 86px;
    height: 30px;
    cursor: pointer;
    line-height: 30px;
    text-align: center;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;

}

::v-deep {
    .el-input__inner {
        background: rgba(21, 95, 192, 0.1);
        border: 1px solid #73A6D1;
        min-width: 308px;
        color: #fff;
    }

    .el-textarea__inner {
        background: rgba(21, 95, 192, 0.1);
        border: 1px solid #73A6D1;
        color: #fff;

    }

    .el-color-picker__trigger {
        width: 308px;
    }

    .el-select--mini {
        margin-left: 0px !important;
    }

    .el-form-item__label {
        color: #fff;
    }
}
</style>
