<template>
  <div class="page-home">

    <!-- 发起需求弹框 -->
    <InitiateRequirements :createShow="createShow" :clear="closeInitiateRequirements"></InitiateRequirements>
    <Header @sadeflyshow='sadeflyshow' :selectData="selectData" @sadeflyshow2='sadefly2show'
      @safeflyModel="safeflyModel" @openNotice="openNotice" v-if="selectData != 9" />

    <!-- 中间文字 -->
    <div class="left hwb-flex" v-if="selectData == 1">
      <!-- 四个按钮 -->
      <div class="left-center">
        <!-- @click="showOnline(online,!onlineShow,1)" -->
        <div class="left-item">
          <div class="num hwb-flex">
            <img src="./img/zaizhi.png" alt="">
            <div style="color:#C8DFFF">在执</div>
            <div class="one-num" v-for="(item, index) in onlineVal" :key="index">{{ item }}</div>
            <div class="text">台</div>
          </div>
          <!-- <div class="text bottom">在执</div> -->
        </div>
        <!-- @click="showOnline(offline,!offlineShow,2)" -->
        <div class="left-item">
          <div class="num hwb-flex">
            <img src="./img/daiming.png" alt="">
            <div style="color:#C8DFFF">待命</div>
            <div class="one-num" v-for="(item, index) in offlineVal" :key="index">{{ item }}</div>
            <div class="text">台</div>
          </div>
          <!-- <div class="text bottom">待命</div> -->
        </div>
      </div>
    </div>

    <div class="Router_Btn">
    </div>
    <CesiumLayer />
    <!-- 首页左边统计图 -->
    <LeftBox :selectData="selectData" v-if="selectData != 9" />
    <RightBox v-if="RightisShow && selectData != 9" :selectData="selectData" />
    <transition name="animate__animated dh" key="1" enter-active-class="animate__fadeInUp"
      leave-active-class="animate__fadeOutDown">
    </transition>

    <transition name="animate__animated dh" key="1" enter-active-class="animate__fadeInUp"
      leave-active-class="animate__fadeOutDown">
    </transition>
    <!-- 全要素 -->
    <FiexdRight :styleContent="this.$route.query.id == 9 ? {right:0} : null" :gbwy="isShow" :issShow="isShow" :qysFlag="isShow">
    </FiexdRight>
    <transition name="animate__animated dh" key="1" enter-active-class="animate__fadeInUp"
      leave-active-class="animate__fadeOutDown">
    </transition>
    <!-- 下边的按钮 -->
    <Bottombase :openInitiateRequirements="openInitiateRequirements" :selectData="selectData" @updataSelect="updateSel"
      v-if="selectData != 9" />
    <!-- 地图切换 -->
    <transition name="animate__animated dh" key="1" enter-active-class="animate__fadeInUp"
      leave-active-class="animate__fadeOutDown">
      <Bottomright v-if="!isShow" style="zIndex:100" />
    </transition>
    <transition name="animate__animated dh" key="1" enter-active-class="animate__fadeInUp"
      leave-active-class="animate__fadeOutDown">
      <Bottomright v-if="!isShow" />

    </transition>

    <SafeFly :securityFlyVisible="FlyVisible" :clear="() => FlyVisible = false" />

    <!-- <CheckSafe :checkVisibles="checkVisible" :clear="() => checkVisible = false"></CheckSafe> 飞行清单检查去除 11.18 -->
    <!-- 三维模型申请弹框 -->
    <SafeflyModel v-if="ModelVisible" :ModelVisible="ModelVisible" @CloseModel="CloseModel"
      @openApplication="openApplication"></SafeflyModel>
    <!-- 三维模型申请记录弹框 -->
    <ApplicationRecord v-if="ApplicationRecord" :ApplicationRecord="ApplicationRecord" @CloseRecord="closeRecord">
    </ApplicationRecord>
    <!-- 公告弹框 -->
    <Notice v-if="NoticeShow" :NoticeShow="NoticeShow" @closeNotice="closeNotice" />
    <!-- 三维标注 -->
  <div class="wih100 h60" style='background-color: red;'>
    <HeaderSW v-if="selectData == 9" @sadeflyshow='sadeflyshow' @sadeflyshow2='sadefly2show' @openNotice="openNotice"
      @safeflyModel="safeflyModel" />
  </div>
    <application_3D v-if="selectData == 9"></application_3D>
  </div>
</template>

<script>
import HeaderSW from "@/components/newmenu/header";
import { mapActions } from "vuex"
import CesiumLayer from "@/components/cesium-layer";
import Header from "@/components/home/header";
import LeftBox from "@/components/home/left-box";
import RightBox from "@/components/home/right-box";
import OrganizeBox from "@/components/home/organize-box";
import TaskBox from "@/components/home/task-box";
import FiexdRight from '@/components/fiexdright'
import SafeFly from "@/components/header-right/safeFly.vue";
// import CheckSafe from '@/components/header-right/check_safe.vue';
import SafeflyModel from '@/components/header-right/safefly-model.vue';
import Notice from '@/components/header-right/notice.vue';
import ApplicationRecord from '@/components/header-right/application-record.vue';
import Bottombase from '@/components/Bottombase';
import Bottomright from '@/components/Bottomright';
import InitiateRequirements from './initiate-requirements.vue'; // 发起需求弹框
import Bubble from '@/components/cesium-layer/bubble/index.js'
import application_3D from '@/pages/application/application_3D/index.vue'
import API from '@/api'
import { number } from "echarts";
export default {
  components: {
    CesiumLayer,
    Header,
    LeftBox,
    RightBox,
    OrganizeBox,
    TaskBox,
    FiexdRight,
    SafeFly,
    Bottombase,
    Bottomright,
    InitiateRequirements,
    SafeflyModel,
    ApplicationRecord,
    Notice,
    HeaderSW,
    application_3D,
  },
  data() {
    return {
      list:
        [
          {
            id: 1,
            name: '图层',
          },
          {
            id: 2,
            name: '标签',
          },
          {
            id: 3,
            name: '标注',
          }
        ],
      createShow: false, // 发起需求弹框的显示与隐藏
      FlyVisible: false,
      checkVisible: false,
      isShow: true,
      gbwy: 0,
      selectData: 1,// 下方模块选中情况
      LeftisShow: true,
      RightisShow: true,
      ModelVisible: false,//三维模型申请弹框
      ApplicationRecord: false,//三维模型申请记录弹框
      NoticeShow: false,//公告弹框
      //baseUrl: 'http://39.99.144.107:9000',
      onlineShow: false,
      offlineShow: false,
      tagging: null,
      bubbles: null,
      ico: require('@/assets/flylog/fly_ico.png'),
      online: [
        {
          id: 1,
          deviceHardId: 'MMC156',
          deviceName: "入云龙01",
          latitude: 30.445419,
          longitude: 120.760767,
          deviceSerial: 'MMC156',
          online: 1,
          taskTitle: 'PTU空地巡查',
          videoInfo: [
            {
              "videoType": "flv",
              "vUrl": "webrtc://39.99.144.107:10800/rtc/stream_5"
            }
          ]
        },
        {
          id: 2,
          deviceHardId: 'MMC146',
          deviceName: "入云龙02",
          latitude: 30.435413,
          longitude: 120.790769,
          deviceSerial: 'MMC146',
          online: 1,
          taskTitle: '市容市貌巡查',
          videoInfo: [
            {
              "videoType": "flv",
              "vUrl": "webrtc://39.99.144.107:10800/rtc/stream_5"
            }
          ]
        }
      ],
      offline: [
        {
          id: 3,
          deviceHardId: 'MMC149',
          deviceName: "入云龙03",
          latitude: 30.475419,
          longitude: 120.760767,
          deviceSerial: 'MMC149',
          online: 0,
          taskTitle: null
        },
        {
          id: 4,
          deviceHardId: 'MMC147',
          deviceName: "入云龙04",
          latitude: 30.465413,
          longitude: 120.790769,
          deviceSerial: 'MMC147',
          online: 0,
          taskTitle: null
        }
      ],
      offlineVal: [],
      onlineVal: [],
      havers: []
    }
  },
  watch: {
    '$route.query.id': {
      handler(value) {
        this.selectData = Number(value)
      },
      deep: true,
    }
  },
  methods: {
    ...mapActions("user", ["Dbox"]),
    showList(item) {
      console.log(item, 'item');
      // this.showListType = item.id
      // this.$forceUpdate()
    },
    updateSel(data) {
      if(data==2){
        this.$router.push({ path: '/new/flightManagement'  })
      }else if(data==3){
        this.$router.push({ path: '/new/control?scene=1'  })
        
      }else{
        this.$router.push({ path: '/home', query: { id: data } })
        this.selectData = data
      }
    },
    async getMajorEquipment() {
      const res = await API.DEVICE.organTree();
      this.$store.commit('device/SET_DEVICE', res.data[0].devices)
      let offlineVal = 0;
      let onlineVal = 0;
      for (let index = 0; index < res.data.length; index++) {
        offlineVal += res.data[index].offline
        onlineVal += res.data[index].online
      }
      this.offlineVal = String(offlineVal).padStart(4, 0)
      this.onlineVal = String(onlineVal).padStart(4, 0)
    },
    sadeflyshow(data) {
      this.FlyVisible = data
    },
    sadefly2show(data) {
      this.checkVisible = data
    },
    openNotice(data) {//公告弹框
      this.NoticeShow = data
    },
    closeNotice(data) {//关闭公告弹框
      this.NoticeShow = data
    },
    safeflyModel(data) {//三维模型申请弹框
      this.ModelVisible = data
    },
    CloseModel(data) {//关闭三维模型申请弹框
      this.ModelVisible = data
    },
    openApplication(data) {//打开三维模型申请记录弹框
      this.ModelVisible = false
      this.ApplicationRecord = true
    },
    closeRecord(data) {//关闭三维模型申请记录弹框，并打开三维模型申请弹框
      this.ApplicationRecord = data
      this.ModelVisible = true
    },
    dhbuttons() {
      this.isShow = !this.isShow
      // this.gbwy = this.isShow 
    },
    LeftDhbuttons() {
      this.LeftisShow = !this.LeftisShow
      // this.gbwy = this.isShow 
    },
    RightDhbuttons() {
      this.RightisShow = !this.RightisShow
      // this.gbwy = this.isShow 
    },
    closeInitiateRequirements() { // 关闭发起需求弹框
      this.createShow = false
    },
    openInitiateRequirements() { // 打开发起需求弹框
      this.createShow = true
    },
    showOnline(data, show, type) {
      type == 1 ? this.onlineShow = show : this.offlineShow = show
      this.onlineShow = show
      if (!this.tagging) {
        this.tagging = new Cesium.CustomDataSource("devices");
        viewer.dataSources.add(this.tagging);
      }
      data.map(item => {
        // item.reportStatus=1
        const position = Cesium.Cartesian3.fromDegrees(
          Number(item.longitude),
          Number(item.latitude),
          2
        );
        const entity = this.tagging.entities.getById(
          `devices-${item.deviceHardId}`
        );
        if (entity) {
          entity.position = position;
          entity.show = show ? true : false
        } else {
          this.tagging.entities.add({
            id: `devices-${item.deviceHardId}`,
            position: position.clone(),
            show: true,
            longitude: {
              type: 28,
              lon: item.longitude,
              lat: item.latitude,
              height: 0,
              data: item
            },

            billboard: {
              width: 61,
              height: 80,
              image: this.ico,
              show: true,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(0, 0), // default: (0, 0)
              eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
            },
            properties: {
              metadata: item,
            },
          })

        }

      })

    },
    init_events() {

      let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)
      handler.setInputAction((movement) => {
        //获取当前相机高度
        let height = Math.ceil(viewer.camera.positionCartographic.height)
        this.height = height
      }, Cesium.ScreenSpaceEventType.WHEEL);


      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(
          movement.position.x,
          movement.position.y
        );


        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );

          // 判断点击位置是否等于渲染全要素并且没有创建弹框
          if (this.tagging?.entities.values.indexOf(pickedLabel.id) > -1 && !document.getElementById(pickedLabel.id.id)) {
            let id = pickedLabel.id.id;
            this.bubble(id)
          } else {
            if (this.bubbles) {
              this.bubbles.windowClose()
            }
          }

        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    },
    bubble(id) {
      let aa = this.tagging?.entities.values.filter(item => item.id === id);
      this.bubbles = new Bubble(Object.assign(aa[0], {
        viewer
      }))
    },
  },
  async mounted() {
    this.getMajorEquipment()
    this.init_events()
    this.Dbox()

  },
  created() {
    console.log(this.$route.query.id == 9);
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:sky") != -1) {
      this.havers.push(1)
    }
    if (permissionList.indexOf("hn:tasks") != -1) {
      this.havers.push(2)
    }
    if (permissionList.indexOf("home:personalUav") != -1) {
      this.havers.push(3)
    }
    if (permissionList.indexOf("home:detect") != -1) {
      this.havers.push(4)
    }
    if (permissionList.indexOf("hn:sky:model") != -1) {
      this.havers.push(5)
    }
    if (permissionList.indexOf("home:analyse") != -1) {
      this.havers.push(6)
    }
    if (this.$route.params.id == 1) {
      this.selectData = 2
    } else if (this.$route.query.id) {
      this.selectData = Number(this.$route.query.id)
    } else {
      this.selectData = this.havers[0]
    }
  }
};
</script>

<style lang="scss" scoped>
.page-home {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .item {
    font-size: 18px;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 42px;
    text-align: center;
  }

  .cpt_header_button_o {
    cursor: pointer;
    width: 160px;
    height: 40px;
    background: url('./img/jx1.png') no-repeat;
    position: absolute;
    top: -32%;
    left: -340px;
    z-index: 100;

    &:hover {
      background: url('./img/jx3.png') no-repeat;
    }
  }

  .cpt_header_button_tw {
    cursor: pointer;
    width: 160px;
    height: 40px;
    background: url('./img/jx1.png') no-repeat;
    position: absolute;
    top: -32%;
    left: -190px;

    &:hover {
      background: url('./img/jx3.png') no-repeat;
    }
  }

  .cpt_header_button_th {
    cursor: pointer;
    width: 57px;
    height: 64px;
    background: url('./img/jx4.png') no-repeat;
    position: absolute;
    top: -32%;
    right: -400px;
    width: 160px;
    height: 40px;

    &:hover {
      background: url('./img/jx2.png') no-repeat;
    }
  }

  .cpt_header_button_f {
    cursor: pointer;
    width: 160px;
    height: 40px;
    background: url('./img/jx4.png') no-repeat;
    position: absolute;
    top: -32%;
    right: -550px;

    &:hover {
      background: url('./img/jx2.png') no-repeat;
    }
  }

  .Router_Btn {
    width: 100%;
    background-color: red;
    position: fixed;
    left: 0;
    top: 10%;
  }

  .left {
    position: fixed;
    left: 50%;
    top: 10%;
    margin-left: -330px;
    width: 400px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    transform: translateX(32%);

    .left-center {
      display: flex;

      .left-item {
        margin-right: 20px;
        width: 203px;
        height: 63px;
        background: url('./img/fiy-box.png') no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text {
        font-size: 16px;
        font-family: AppleSystemUIFont;
        color: #DAE9FF;

        &.bottom {
          text-align: center;
          font-size: 18px;
          margin-top: 10px;
        }
      }

      .num {
        // width: 116px;
        width: 95%;

        .one-num {
          width: 16px;
          height: 30px;
          background: linear-gradient(180deg, #01a0ff 0%, #01a0ff 50%, #1784FA 50%, #1784FA 100%);
          font-family: Rajdhani-SemiBold, Rajdhani;
          border-radius: 1px;
          font-size: 22px;
          color: #FFFFFF;
          line-height: 30px;
          text-align: center;
        }
      }
    }
  }

  .border-bg {
    .border-01 {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      height: 60px;
      background: left top url(~@/assets/home/border-01.png) no-repeat;
      background-size: 100% 100%;
      z-index: 1;
    }

    .border-02 {
      display: block;
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      width: 62px;
      background: left top url(~@/assets/home/border-02.png) no-repeat;
      background-size: 100% 100%;
      z-index: 1;
    }

    .border-03 {
      display: block;
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      height: 60px;
      background: left top url(~@/assets/home/border-03.png) no-repeat;
      background-size: 100% 100%;
      z-index: 1;
    }

    .border-04 {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      width: 62px;
      background: left top url(~@/assets/home/border-04.png) no-repeat;
      background-size: 100% 100%;
      z-index: 1;
    }

    .border-corner-01 {
      width: 111px;
      height: 108px;
      position: absolute;
      left: 0;
      top: 0;
      background: left top url(~@/assets/home/border-corner-01.png) no-repeat;
      background-size: 111px 108px;
      z-index: 2;
    }

    .border-corner-02 {
      width: 111px;
      height: 108px;
      position: absolute;
      right: 0;
      top: 0;
      background: right top url(~@/assets/home/border-corner-02.png) no-repeat;
      background-size: 111px 108px;
      z-index: 2;
    }

    .border-corner-03 {
      width: 111px;
      height: 108px;
      position: absolute;
      right: 0;
      bottom: 0;
      background: right bottom url(~@/assets/home/border-corner-03.png) no-repeat;
      background-size: 111px 108px;
      z-index: 1;
    }

    .border-corner-04 {
      width: 111px;
      height: 108px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: left bottom url(~@/assets/home/border-corner-04.png) no-repeat;
      background-size: 111px 108px;
      z-index: 2;
    }
  }
}


.iconfont {
  cursor: pointer;
  font-size: 30px;
  color: rgb(72, 161, 250);
  margin-right: 4px;
  position: fixed;
  top: 482px;
  left: 461px;
  z-index: 999;

  &:last-child {
    margin-right: 0px;
  }
}
</style>