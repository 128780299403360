<template >
    <div>
        <Dialog :create="true" @on-close="closeIDsPopup" title="标签" :left="140" :top="105" :width="438">
            <div class="tagBox">
                <div class="jcsb list mt12">
                    <div v-for="(item, index) in table" :key="index" :class="index == itemIndex ? 'active' : ''" class=" item"
                        @click="changeTble(index)">
                        <span class="iconfont cp" :class="item.icon"></span>
                        {{ item.name }}
                    </div>
                </div>
                <div v-if="itemIndex == 0">
                    <div class="mt10 jcsb">
                        <div class="seach">
                            <el-input class="mb10 w380" el-input placeholder="输入标签名称搜索" size="mini " clear
                                v-model="searchTag">
                                <i class="el-input__icon el-icon-search cp" slot="suffix" @click="getList">
                                </i>
                            </el-input>
                            <el-input placeholder="添加标签" size="mini " class="w380" clear v-model="search">
                                <i class="el-input__icon el-icon-plus cp" slot="suffix" @click="addTag">
                                </i>
                            </el-input>
                        </div>
                        <!-- <el-input class="w300" placeholder="添加标签" size="mini " clear v-model="search">
                        </el-input>
                        <div class="addTag h30 lh30 tc w100 cp cf" @click="addTag">确定</div> -->
                    </div>
                    <div class="mt15">
                        <div v-for="tag in tagList" :key="tag.id" class="item mb10 jcsb p5 pl10 pr10 ">
                            <div>
                                <div style="color:#ecb035" class="iconfont dib vat f20 icon-chengguoku" />
                                <span class=" tag dib cf ml10"> {{ tag.name }}</span>
                            </div>
                            <div>
                                <span class="iconfont icon-bianji1 f16 cp" style="color:aqua" @click="edit(tag)"></span>
                                <span class="iconfont icon-shanchu1 f16 cp ml10" style="color:aqua" s
                                    @click="del(tag)"></span>


                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="itemIndex == 1">
                    <div class=" icon-tianjia iconfont mt12 fr   f12 btn w89 cp" @click="showTagTypeFn(1)">
                        标签类型
                    </div>
                    <div class="fix"></div>
                    <div class="mt15 mt30">
                        <div v-for="(tag, index) in tagTypeList" :key="index" class="item mb10 jcsb p5 pl10 pr10 ">
                            <div class=" text_ellipsis w300">
                                <span class="  dib cf ml10" v-for="i in tag.tagList" :key="i.id">
                                    {{ i.name || '暂无' }}</span>
                            </div>
                            <div>
                                <span class="iconfont icon-bianji1 f16 cp" style="color:aqua"
                                    @click="showTagTypeFn(2, tag)"></span>
                                <span class="iconfont icon-shanchu1 f16 cp ml10" style="color:aqua" s
                                    @click="delTagType(tag)"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
        <el-dialog class="mt200" :append-to-body="true" title="修改标签" :visible.sync="dialogVisible" width="30%"
            :before-close="beforeClose">
            <el-input v-model="editFrom.name">

            </el-input>
            <div class="wih100 ">
                <el-button type="primary" style="display:block;margin: 0 auto" size="mini" class="mt20  ml100"
                    @click="editBtn">确定</el-button>
            </div>
        </el-dialog>
        <Dialog v-if="addTagType" @on-close="closeFn" :title="addTagTypeTitle + '标签类型'" :left="600" :top="235" :width="438">

            <div class="mt10  tagListType">
                <div>
                    输入名称 <el-input class="w300" placeholder="请输入标签类型" size="mini " clear v-model="tagType">
                    </el-input>
                </div>
                <div class="itemBox">
                    <div v-for="tag in tagList" :key="tag.id" class="item mb10  p5  ">
                        <div>
                            <el-checkbox v-model="tag.status"></el-checkbox>
                            <span class=" tag dib cf ml10"> {{ tag.name }}</span>
                        </div>
                    </div>
                </div>
                <div class=" mt12 fr mt50   btn w100 cp" @click="addTagTypeFn">
                    确定
                </div>
            </div>

        </Dialog>
    </div>
</template>
<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Dialog from "../dialog/index.vue"
import API from "@/api";

export default {
    components: { ModuleBox, Dialog },
    data() {
        return {
            tagType: "",
            searchTag: null,
            table: [{
                name: '标签',
                icon: "icon-biaoqian"
            }, {
                name: '标签管理',
                icon: "icon-biaoqian"

            }],
            formEdit: {},
            itemIndex: 0,
            search: "",
            checked: "",
            tagList: [],
            dialogVisible: false,
            editFrom: {},
            tagTypeList: [],
            addTagType: false,
            addTagTypeTitle: '添加'
        }
    },
    created() {
        this.getList()
        this.getTagGroupListFn()
    },
    methods: {
        async delTagType(tag) {
            let res = await API.TASK.tagGroupDelete({ id: tag.id })
            if (res.code == 200) {
                this.$message.success('删除成功！')
                this.getTagGroupListFn()
            }
        },
        showTagTypeFn(num, tag) {
            if (num == 1) {
                this.addTagTypeTitle = '添加'
            } else {
                tag.tagList.forEach(element => {
                    this.tagList.map(e => {
                        if (e.id == element.id) {
                            e.status = true
                        }
                    })
                });
                this.tagType = tag.name
                this.addTagTypeTitle = '修改'
                this.formEdit = tag
            }
            this.addTagType = true

        },
        // 添加标签类型
        async addTagTypeFn() {
            let ids = []
            if (this.tagType == '') return this.$message.warning('请输入标签类型！')
            this.tagList.forEach(element => {
                if (element.status) {
                    ids.push(element.id)
                }
            });
            if (this.addTagTypeTitle == '添加') {
                let res = await API.TASK.addTagGroup({
                    name: this.tagType,
                    tagIds: ids
                })
                if (res.code == 200) {
                    this.$message.success('添加成功')
                    this.getTagGroupListFn()
                    this.tagList = this.tagList.map(e => ({
                        ...e,
                        status: false
                    }))
                    this.addTagType = false
                    this.tagType = ''
                }
            } else {
            if(!ids||ids.length==0){
                return this.$message.warning('请选择标签！')
            }   
                let res = await API.TASK.updateGroup({
                    name: this.tagType,
                    tagIds: ids,
                    id: this.formEdit.id
                })
                if (res.code == 200) {
                    this.$message.success('修改成功')
                    this.getTagGroupListFn()
                    this.tagList = this.tagList.map(e => ({
                        ...e,
                        status: false
                    }))
                    this.addTagType = false
                    this.tagType = ''
                }
            }
        },
        async getTagGroupListFn() {
            let res = await API.TASK.getTagGroupList()
            if (res.code === 200) {
                this.tagTypeList = res.data.records
            }
        },
        closeFn() {
            this.tagList = this.tagList.map(e => ({
                ...e,
                status: false
            }))
            this.tagType = ''
            this.addTagType = false
        },
        beforeClose() {
            this.dialogVisible = false
        },
        async getList() {
            let res = await API.TASK.tagList(
                {
                    name: this.searchTag
                }
            )
            if (res.code == 200) {
                this.tagList = res.data.map(e => ({
                    ...e,
                    status: false
                }))
            }
        },
        async addTag() {
            if (this.search != '') {
                let res = await API.TASK.tagAdd({ name: this.search })
                if (res.code === 200) {
                    this.$message.success('添加成功')
                    this.search = ''
                    this.getList()
                } else {
                    this.$message.error(res.message)
                }
            } else {
                this.$message.warning('请输入标签名称')

            }
        },
        async del(item) {
            let res = await API.TASK.dropAdd(item.id)
            this.$message.success('删除成功')
            this.getList()
        },
        edit(item) {
            this.editFrom = JSON.parse(JSON.stringify(item))
            this.dialogVisible = true
        },
        async editBtn() {
            let { editFrom } = this
            let res = await API.TASK.updateTag({
                id: editFrom.id,
                name: editFrom.name
            })
            if (res.code == 200) {
                this.$message.success("修改成功！")
                this.dialogVisible = false
                this.getList()
            }
        },
        handleIconClick() {

        },
        changeTble(num) {
            this.itemIndex = num
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
    },
}
</script>
<style lang="scss" scoped>
.tagBox {
    height: 77vh;
    overflow-y: auto;

    .list {
        background: rgba(0, 0, 0, 0.7);
        display: flex;

        .item {
            // width: calc(100% / 3);
            flex: 1;
            text-align: center;
            color: #fff;
            height: 34px;
            line-height: 34px;
            cursor: pointer;
        }

        .active {
            background: #1F4DCB;
        }
    }

    .item {
        height: 34px;
        line-height: 34px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        border: 1px solid #73A6D1;
        backdrop-filter: blur(4px);

        .tag {
            width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.btn {
    height: 34px;
    line-height: 34px;
    background: #1F4DCB;
    border-radius: 2px;
    border: 1px solid #73A6D1;
    backdrop-filter: blur(4px);
    text-align: center;
    color: white
}

.tagListType {
    height: 500px;

    .itemBox {
        margin-top: 20px;
        height: 375px;
        overflow-y: auto;

    }
}

::v-deep {
    .el-input__inner {
        background: rgba(21, 95, 192, 0.1);
        border: 1px solid #73A6D1;
        min-width: 301px;
        color: #fff;
    }

    .el-textarea__inner {
        background: rgba(21, 95, 192, 0.1);
        border: 1px solid #73A6D1;
        color: #fff;

    }

    .el-color-picker__trigger {
        width: 308px;
    }

    .el-select--mini {
        margin-left: 0px !important;
    }

    .el-form-item__label {
        color: #fff;
    }
}

::v-deep {
    .el-input--mini {
        box-shadow: inset 0px 1px 3px 0px #00142F;
        border: 1px solid #73A6D1;
        backdrop-filter: blur(2px);
    }

    .el-input--mini .el-input__inner {
        border: 1px solid #73A6D1;
        box-shadow: inset 0px 1px 3px 0px #00142F;
        background: rgba(21, 95, 192, 0.3);
        color: #fff;
    }

    .el-input-group__append {
        background: #143469;
        border: 1px solid #143469;
    }
}
</style>