<template>
  <div style="height:100%">
    <warningInformation v-if="haves.includes(1)"/>
    <!-- <dataPush /> -->
  </div>
</template>

<script>
import dataPush from "./data-push.vue"; // 数据推送
import warningInformation from "./warning-information.vue"; // 预警信息
export default {
  components: {warningInformation },
        data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:dataAnalysis:earlyWarningInformation") > -1) {
      this.haves.push(1)
    }
    }
}
</script>

<style lang="less" scoped>

</style>