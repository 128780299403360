<template>
  <ModuleBox title="设备统计" width="100%" height="30vh" class="equipment">
    <div class="selectBox">
      <div v-for="item in typeList" :key="item.id" :class="`${type === item.id ? 'btnSelect' : 'btn'}`"
        @mouseover="handle_search(item.id)">
        {{ item.title }}
      </div>
    </div>
    <div class="mt40 mb32" style="display: flex;margin-top: 0.75rem;">
      <div class="TaskScenario-list h157 cp ">
        <div class=" w200 h157">
          <div class="w200 h157" id="content_huxbox" @click="details()"></div>
        </div>
      </div>
      <div class="right-box-value ">
        <div class="item-box">
          <i class="item-icon" style=" background-color: #6eed56;"></i>
          <span class="item-label-value">正常</span>
          <span class="item-label-value2">{{ dataList[0].y }}</span>
          <span class="item-label-value3">{{ labelValue }}</span>
        </div>
        <div class="item-box">
          <i style="background:#4A9FF3" class="item-icon"></i>
          <span style="color:#4A9FF3" class="item-label-value">保养</span>
          <span class="item-label-value2 f20">{{ dataList[3].y }}</span>
          <span class="item-label-value3">{{ labelValue }}</span>
        </div>
        <div class="item-box">
          <i class="item-icon" style=" background-color: #ffb21c;"></i>
          <span style="color:#ffb21c" class="item-label-value">维修</span>
          <span class="item-label-value2">{{ dataList[1].y }}</span>
          <span class="item-label-value3" style="color:#ffb21c">{{ labelValue }}</span>
        </div>
        <div class="item-box">
          <i style="background:#FF563B" class="item-icon"></i>
          <span style="color:#FF563B" class="item-label-value">损坏</span>
          <span class="item-label-value2 f20">{{ dataList[2].y }}</span>
          <span class="item-label-value3">{{ labelValue }}</span>
        </div>

      </div>
    </div>
    <Dialog class="Details" v-if="showDetails" :title="title" :width="1050" @on-close="closeDetails">
      <Table :cellName="dataList1" :dataSource="dataValue" />
      <div class="DemandList_Footer" v-if="dataValue.length">
        <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper" :total="total || 0" :page-size="size || 10" />
      </div>
    </Dialog>
  </ModuleBox>
</template>

<script>
let chart;
import { Chart } from "@antv/g2";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import wurenjidata from './JSONVAL/wurenjidata.json';
import guazaidata from './JSONVAL/guazaidata.json';
import dianchidata from './JSONVAL/dianchidata.json';
import cheliangdata from './JSONVAL/cheliangdata.json';
import highcharts from "highcharts";
import { fontChart } from "@/utils/echartPxToRem"
import API from '@/api'
export default {
  components: { ModuleBox, Table, Dialog },
  data() {
    return {
      statusType: {
        1: '正常',
        2: '损坏',
        3: '维修',
        4: '保养'
      },
      stateType: {
        1: '正常',
        2: '损坏',
        3: '维修',
        5: '保养'
      },
      carStatusType: {
        0: '损坏',
        1: '正常',
        2: '保养',
        3: '维修',
      },
      typeList: [
        {
          id: 1,
          title: '无人机',
          value: ''
        },
        {
          id: 2,
          title: '挂载',
          value: ''
        },
        {
          id: 3,
          title: '电池',
          value: ''
        },
        // {
        //   id: 4,
        //   title: '车辆',
        //   value: ''
        // },
        {
          id: 5,
          title: '机库',
          value: ''
        }
      ],
      valueTitle: '',
      contentVal: '',
      labelValue: '',
      typeValue: 1,
      type: 1,
      dataList: [
        { name: '正常', y: 40, percent: 0.13 },
        { name: '维修', y: 21, percent: 0.3 },
        { name: '损坏', y: 17, percent: 0.17 },
        { name: '保养', y: 13, percent: 0.4 },
      ],
      dataList1: [
      ],
      dataValue: [
      ],
      showDetails: false,
      title: '无人机统计',
      value: '',
      organId: null,
      selectList: [],
      currentPage: 1,
      total: 0,
      size: 10,
      flag: true
      // dataList: [
      //   {
      //     name: "红草莓",
      //     y: 10254,
      //     h: 0,
      //     bfb: 0
      //   },
      //   {
      //     name: "白草莓",
      //     y: 6894,
      //     h: 0,
      //     bfb: 0
      //   },
      //   {
      //     name: "红颜草莓",
      //     y: 7667,
      //     h: 0,
      //     bfb: 0
      //   },
      //   {
      //     name: "甜宝草莓",
      //     y: 4287,
      //     h: 0,
      //     bfb: 0
      //   },
      //   {
      //     name: "红颜草莓",
      //     y: 8687,
      //     h: 0,
      //     bfb: 0
      //   },
      //   {
      //     name: "甜宝草莓",
      //     y: 16112,
      //     h: 0,
      //     bfb: 0
      //   }
      // ]
    }
  },
  methods: {
    initOption() {
      let quantity = 0; // 总数

      this.dataList.forEach((item) => {
        quantity += item.y;
      });
      this.dataList.forEach((item) => {
        item.bfb = parseInt((item.y / quantity) * 100);
        item.h = item.bfb * 0.5 >= 40 ? 40 : item.bfb * 0.5
        // item.h = parseInt(0.86 * item.bfb); // 最高高度60，根据比例渲染高度
      });
      // 修改3d饼图绘制过程
      var each = highcharts.each,
        round = Math.round,
        cos = Math.cos,
        sin = Math.sin,
        deg2rad = Math.deg2rad;
      highcharts.wrap(
        highcharts.seriesTypes.pie.prototype,
        "translate",
        function (proceed) {
          proceed.apply(this, [].slice.call(arguments, 1));
          // Do not do this if the chart is not 3D
          if (!this.chart.is3d()) {
            return;
          }
          var series = this,
            chart = series.chart,
            options = chart.options,
            seriesOptions = series.options,
            depth = seriesOptions.depth || 0,
            options3d = options.chart.options3d,
            alpha = options3d.alpha,
            beta = options3d.beta,
            z = seriesOptions.stacking
              ? (seriesOptions.stack || 0) * depth
              : series._i * depth;
          z += depth / 2;
          if (seriesOptions.grouping !== false) {
            z = 0;
          }
          each(series.data, function (point) {
            var shapeArgs = point.shapeArgs,
              angle;
            point.shapeType = "arc3d";
            var ran = point.options.h;
            shapeArgs.z = z;
            shapeArgs.depth = depth * 0.75 + ran;
            shapeArgs.alpha = alpha;
            shapeArgs.beta = beta;
            shapeArgs.center = series.center;
            shapeArgs.ran = ran;
            angle = (shapeArgs.end + shapeArgs.start) / 2;
            point.slicedTranslation = {
              translateX: round(
                cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
              translateY: round(
                sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
            };
          });
        }
      );
      (function (H) {
        H.wrap(
          highcharts.SVGRenderer.prototype,
          "arc3dPath",
          function (proceed) {
            // Run original proceed method
            var ret = proceed.apply(this, [].slice.call(arguments, 1));
            ret.zTop = (ret.zOut + 0.5) / 100;
            return ret;
          }
        );
      })(highcharts);
      highcharts.chart('content_huxbox', {
        chart: {
          animation: true,
          backgroundColor: "none",
          type: "pie", //饼图
          margin: [0, 0, 0, 0],
          options3d: {
            enabled: true, //使用3d功能
            // alpha: 20, //延y轴向内的倾斜角度
            alpha: 25, //延y轴向内的倾斜角度

            beta: 0,
          },
          events: {
            load: function () {
              var each = highcharts.each,
                points = this.series[0].points;
              each(points, function (p, i) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran,
                });
              });
            },
          },
        },
        legend: {
          enabled: false, // 关闭图例
          align: "right", //水平方向位置
          verticalAlign: "top", //垂直方向位置
          layout: "vertical",
          x: 20,
          y: 30,
          symbolWidth: 1,
          symbolHeight: 1,
          symbolRadius: "50%", // 修改成圆
          itemMarginBottom: 8,
          useHTML: true,
          itemStyle: {
            color: "#f4f4f6",
            fontSize: 12,
          },
        },
        title: {
          // enabled: false,
          text: "",
        },
        subtitle: {
          floating: true,
          text: "统计",
          align: 'center',
          y: -5,
          verticalAlign: "middle",
          style: {
            color: '#c0d8fa'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: false, // 禁用点击
            cursor: "pointer",
            depth: 0,//12
            showInLegend: false,
            size: "79%", // 外圈直径大小
            innerSize: 85, // 内圈直径大小
            center: ["50%", "60%"],
            colors: [
              "#6eed56",
              "#ffb21c",
              "#ff563b",
              "#4a9ff3",
              "rgb(13,145,239)",
              "rgb(167,67,234)",
            ],
            dataLabels: {
              useHTML: true,
              enabled: false, //是否显示饼图的线形tip
              distance: 5,
              borderColor: '#007acc',
              align: "center",
              position: "right",
              format: '<div class="dataLabels"> {point.bfb:.1f} %</div>',
              // format: "{point.bfb}%",
              color: "#ffffff",
              style: {
                textOutline: "none",
                fontSize: 14,
              },
            },
          },
        },
        credits: {
          enabled: false, // 禁用版权信息
        },
        series: [
          {
            type: "pie",
            name: "数量",
            data: this.dataList,
          },
        ],
      });
    },
    onChangePage(val) {
      this.currentPage = val
      this.details(val)
    },
    updataflyValue() {

    },
    getAllOrganization() {

      // let data  = this.dbox_data
      let data = [
        {
          child: [
            {
              id: 8,
              name: "公关办",
            },
            {
              id: 9,
              name: "情指中心",
            },
            {
              id: 10,
              name: "科通科",
            },
            {
              id: 11,
              name: "政治安全保卫大队",
            },
            {
              id: 12,
              name: "刑事侦查大队",
            },
            {
              id: 13,
              name: "巡特警大队",
            },
            {
              id: 14,
              name: "食药环侦大队",
            },
            {
              id: 15,
              name: "交通警察大队",
            },
            {
              id: 16,
              name: "禁毒大队",
            },
          ],
          id: 2,
          name: "公安局",
        }, {
          child: [
          ],
          id: 3,
          name: "综合执法局",
        },
        {
          child: [

          ],
          id: 4,
          name: "应急管理局",
        },
        {
          child: [

          ],
          id: 5,
          name: "自然资源和规划局",
        },
        {
          child: [
            {
              id: 17,
              name: "河湖中心",
            },
            {
              id: 18,
              name: "水行政执法",
            },
          ],
          id: 6,
          name: "水利局",
        },
        {
          child: [

          ],
          id: 7,
          name: "生态办",
        },
      ]
      this.selectList = this.getTreeData(data);

      // this.organId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    async details(pageNum) {
      this.dataValue = []
      this.getAllOrganization()
      if (this.type == 1) {
        this.dataList1 = [
          { title: '序号', name: 'index', },
          { title: '名称', name: 'deviceName' },
          { title: 'SN码(无人机ID)', name: 'deviceHardId' },
          // { title: '型号', name: 'model' },
          { title: '型号', name: 'goodsName' },
          { title: '所属机构', name: 'anotherName' },
          { title: '登记日期', name: 'addtime' },
          { title: '状态', name: 'state' },
        ]
        let res = await API.DEVICE.deviceList({
          pageSize: 10,
          pageNo: pageNum || 1
        })
        res.data.data.forEach((element, index) => {
          element.state = this.stateType[element.state]
          element.index = index + 1
        });
        this.dataValue = res.data.data
        this.total = res.data.totalCount
      } else if (this.type == 2) {
        this.dataList1 = [
          { title: '名称', name: 'name' },
          { title: '型号', name: 'model', },
          { title: '状态', name: 'state' },
          { title: 'SN号', name: 'snNumber' },
          { title: '描述', name: 'remark' },
          { title: '添加时间', name: 'addtime' },
          // { title: '更新时间', name: 'edittime' },
        ]
        let res = await API.DEVICE.mountList({
          pageSize: 10,
          pageNo: pageNum || 1
        })
        res.data.data.forEach(element => {
          console.log(element.status, '配发状态');
          element.state = this.statusType[element.state]
          if (element.store == 1) {
            element.store = '出库'
          } else if (element.store == 2) {
            element.store = '入库'
          }
        });
        console.log(res.data.data,);
        this.dataValue = res.data.data
        this.total = res.data.totalCount
      } else if (this.type == 3) {
        this.dataList1 = [
          // { title: '编号', name: 'battery_id', },
          { title: '名称', name: 'batteryName' },
          { title: '型号', name: 'battery_type' },
          { title: '状态', name: 'battery_status' },
          { title: 'SN号', name: 'battery_number' },
          // { title: '归属无人机', name: 'deviceName' },
          // { title: '物料规格', name: 'materiel' },
          // { title: '购入日期', name: 'addtime' },
          // { title: '出厂日期', name: 'factory_time' },
          { title: '添加时间', name: 'endtime' },
          { title: '累计飞行架次', name: 'flightSorties' },
        ]
        let res = await API.DEVICE.selectLikeList({
          pageSize: 10,
          pageNo: pageNum || 1

        })
        let typeList = {
          1: "正常",
          2: '保养',
          3: "维修",
          4: "损坏"
        }
        this.dataValue = res.data.data.map(item => {
          return {
            ...item,
            battery_status: typeList[item.battery_status]
          }
        })
        this.total = res.data.totalCount
      } else if (this.type == 4) {
        this.dataList1 = [
          // { title: '编号', name: 'carTypeId', },
          { title: '车牌号', name: 'carNumber' },
          { title: '车辆类型', name: 'carType' },
          { title: '创建时间', name: 'createdTime' },
          { title: '修改时间', name: 'updatedTime' },
          { title: '状态', name: 'status' },
        ]

        let res = await API.DEVICE.vehicleGetList({
          pageSize: 10,
          pageNum: pageNum || 1

        })
        res.data.forEach(element => {
          element.status = this.carStatusType[element.carState]
        });
        this.dataValue = res.data
        this.total = res.total
      } else if (this.type == 5) {
        this.dataList1 = [
          { title: '机库名称', name: 'name' },
          { title: '起降场位置', name: 'address' },
          { title: '所属单位', name: 'anotherName' },
          { title: '设备状态', name: 'status' },
        ]
        let res = await API.DEVICE.landList({
          pageNo: pageNum || 1,
          pageSize: 10,
        })
        res.data.data.forEach(element => {
          element.status = this.statusType[element.status]
        });
        this.dataValue = res.data.data
        this.total = res.data.totalCount
      }
      if (!pageNum) {
        this.showDetails = true
      }
    },
    closeDetails() {
      this.showDetails = false
      this.currentPage = 1
    },
    async handle_search(data) {
      this.type = data
      if (this.type == 1) {
        this.title = '无人机统计'
        this.labelValue = '架'
      } else if (this.type == 2) {
        this.title = '挂载统计'
        this.labelValue = '个'
      } else if (this.type == 3) {
        this.title = '电池统计'
        this.labelValue = '个'
      } else if (this.type == 4) {
        this.title = '车辆统计'
        this.labelValue = '辆'
      } if (this.type == 5) {
        this.title = '机库统计'
        this.labelValue = '个'
      }
      await this.async_get_data()
      this.initOption()
    },
    init_antv() {

    },
    dataFilte() {

    },
    async async_get_data() {
      let type = this.type == 5
      let res = await API.HOMEDATA.equipment(this.type)
      if (res.code == 200) {
        this.valueTitle = res.data.total
        let data = res.data.data
        let newArr = []
        let newValu = {}
        for (const key in data) {
          newValu = {
            name: key,
            y: data[key],
            percent: Number((data[key] / this.valueTitle).toFixed(5)),
            h: 0,
            bfb: 0
          }
          if (key == '正常') {
            newArr[0] = newValu
          } else if (key == '维修') {
            newArr[1] = newValu
          } else if (key == '损坏') {
            newArr[2] = newValu
          } else if (key == '保养') {
            newArr[3] = newValu
          }

        }
        this.dataList = newArr
        this.$forceUpdate()
      } else {
        this.$message.error(res.message)
      }
    }
  },
  async mounted() {
    // this.init_antv()
    await this.async_get_data()
    this.initOption()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select {
    .el-input__inner {
      width: 167px;
      height: 32px;
      background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
      backdrop-filter: blur(4px);
      font-size: 16px;
      font-family: '' Barlow'';
      font-weight: 400;
      color: #65ABE9;
      line-height: 24px;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }

  .el-cascader .el-input {
    .el-input__inner {
      background-color: #000;
      border: 0;
      height: 30px;
      line-height: 30px;
      color: #fff;
      margin-bottom: 10px;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }
}

.Details {
  .select {
    display: flex;
    align-items: center;

    .selectBtn {
      cursor: pointer;
      width: 86px;
      height: 30px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-family: '' Barlow'';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
    }
  }

}

.equipment {
  .selectBox {
    display: flex;
    justify-content: space-between;
    border-radius: 4px 0px 0px 4px;
    padding-left: 15px;

    .btnSelect {
      width: 86px;
      height: 26px;
      // background: #1F4DCB;
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 26px;
      background: linear-gradient(90deg, #37A5EE 0%, #265AD5 50%, #266EF5 100%);
      box-shadow: 0px 3px 6px 1px rgba(54, 86, 148, 0.35);
      opacity: 0.95;
      border: 1px solid #4DCEFF;

      cursor: pointer;
    }

    .btn {
      width: 86px;
      height: 26px;
      // background: rgba(0,0,0,0.7);
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #fff;
      line-height: 26px;
      background: linear-gradient(90deg, #05348B 0%, #002363 50%, #05348B 100%);
      box-shadow: 0px 3px 6px 1px rgba(54, 86, 148, 0.35);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #47ABF4;
      cursor: pointer;
    }
  }

  display: flex;

  .TaskScenario-list {
    width: 200px;
    background: url('~@/assets/home/list-background.png') no-repeat;
    align-items: center;
    background-size: 100% 100%;
    justify-content: center;
    cursor: pointer;

    #content-box {}
  }

  .right-box-value {
    overflow: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-evenly;
    color: #fff;
    height: 120px;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 12px;

    .item-box {
      display: flex;
      align-items: center;
      justify-content: right;

      .item-icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-radius: 2px;
      }

      .item-label-value {
        text-align: center;
        color: #6eed56;
      }

      .item-label-value2 {
        text-align: center;
        flex: 1;
        width: 40px;
        margin-left: 10px;
        font-weight: 600;
        color: #06bee2;
        text-shadow: 0px 2px 0px #06bee2;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
      }

      .item-label-value3 {
        width: 20px;
      }
    }

  }
}

.DemandList_Footer {
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 30px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 16px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 16px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
        height: 30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background: linear-gradient(90deg, #032251 0%, rgba(10, 58, 129, 0.88) 43%, rgba(0, 0, 0, 0) 100%);
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          // line-height: 40px;
          width: 40px;
          // height: 40px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 16px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height: 30px;


          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

canvas {
  cursor: pointer;
}
</style>