<template>
  <!-- <ModuleBox
    title="设备运行概览"
    width="100%"
    height="35%"
    class="cpt-device-module-box"
  >
    <div class="chart-wrap">
      <div id="device-module-box" class="chart-box"></div>
      <div class="chart-value">
        <div class="status-list">
          <div class="status-item" v-for="item in list" :key="item.id">
            <div class="status-item-name">
              <span
                class="status-item-name-color"
                :style="`background:${item.color};`"
              ></span>
              {{ item.title }}
            </div>
            <div class="status-item-value">{{ item.value }}</div>
            <div class="status-item-value">{{ item.num }}</div>
          </div>
        </div>
      </div>
    </div>
  </ModuleBox> -->
  <ModuleBox
    title="设备运行概览"
    width="100%"
    height="25%"
    class="cpt-device-module-box"
  >
    <div class="chart-wrap">
      <div class="in-desc">
          <div class="words">
            <span class="num">10</span>
            <div class="sub">总数(架)</div>
          </div>
        </div>
      <div id="device-module-box" class="chart-box"></div>
      <div class="chart-value">
        <div class="status-list">
          <div class="status-item" v-for="item in list" :key="item.id">
            <div class="status-item-name">
              <span
                class="status-item-name-color"
                :style="`background:${item.color};`"
              ></span>
              {{ item.title }}
            </div>
            <div class="status-item-value">{{ item.value }}</div>
            <div class="status-item-value">{{ item.num }}</div>
          </div>
        </div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
// import ModuleBox from "@/components/home/common/module-box";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import { Chart, registerShape } from "@antv/g2";
import API from "@/api";

// 可以通过调整这个数值控制分割空白处的间距，0-1 之间的数值
const sliceNumber = 0.01;

// 自定义 other 的图形，增加两条线
registerShape("interval", "slice-shape", {
  draw(cfg, container) {
    const points = cfg.points;
    let path = [];
    path.push(["M", points[0].x, points[0].y]);
    path.push(["L", points[1].x, points[1].y - sliceNumber]);
    path.push(["L", points[2].x, points[2].y - sliceNumber]);
    path.push(["L", points[3].x, points[3].y]);
    path.push("Z");
    path = this.parsePath(path);
    return container.addShape("path", {
      attrs: {
        fill: cfg.color,
        path,
      },
    });
  },
});

export default {
  components: { ModuleBox },
  data() {
    return {
      list: [],
      status_list: [],
    };
  },
  methods: {
    async init_chart() {
      let list = await API.HOME.droneCount();
      const testList = list.data;
      let data = [
        // { type: "出勤", value: testList.attendance },
        // { type: "待命", value: testList.wait },
        // { type: "维护", value: testList.maintain },
        // { type: "库存", value: testList.stock },
        { type: "出勤", value: 4 },
        { type: "待命", value: 5 },
        { type: "维护", value: 1 },
        { type: "库存", value: 0 },
      ];

      this.list = [
        {
          id: 1,
          title: "出勤",
          color: "#246AE2",
          value:
          //   ((testList.attendance / testList.count) * 100).toFixed(0) + "%",
          // num: testList.attendance,
          40+ '%'
        },
        {
          id: 2,
          title: "待命",
          color: "#2ECBE2",
          value: 
          //   ((testList.wait / testList.count) * 100).toFixed(0) + "%",
          // num: testList.wait,
          50+ '%'
        },
        {
          id: 3,
          title: "维护",
          color: "#DC872B",
          value: 
          // ((testList.maintain / testList.count) * 100).toFixed(0) + "%",
          // num: testList.maintain,
          10 + '%'
        },
        {
          id: 4,
          title: "库存",
          color: "#AD2CE2",
          value: 
          // ((testList.stock / testList.count) * 100).toFixed(0) + "%",
          // num: testList.stock,
          0+ '%'
        },
      ];


      // this.status_list = [
      //   { id: 1, status: "出勤", value: testList.attendance },
      //   { id: 2, status: "待命", value: testList.wait },
      //   { id: 3, status: "维护", value: testList.maintain },
      //   { id: 4, status: "库存", value: testList.stock },
      // ]

      const chart = new Chart({
        container: "device-module-box",
        // autoFit: true,
        width: 171,
        height: 171,
      });

      
      chart.data(data);
      chart.coordinate("theta", {
        radius: 0.88,
        innerRadius: 0.85,
      });
      chart.legend(false);

      // 点击事件
      chart.on("interval:click", (ev) => this.cc(ev));

      // chart.annotation().image({
      //   src: require("@/assets/images/observe/fckernel/09_bg.png"),
      //   offsetX: -40,
      //   offsetY: 12,
      // });

      chart
        .interval()
        .adjust("stack")
        .position("value")
        // .color("type", ["#246AE2", "#2ECBE2", "#DC872B", "#AD2CE2"])
        .color("type", ["l(180) 0:#246AE200 1:#246AE2FF", "l(180) 0:#2ECBE200 1:#2ECBE2FF", "l(180) 0:#DC872B00 1:#DC872BFF", "l(180) 0:#AD2CE200 1:#AD2CE2FF"])
        .shape("slice-shape");

      chart.render();
    },
    cc(data) {
      
    },
  },
  mounted() {
    this.init_chart();
  },
};
</script>

<style lang="scss" scoped>
.cpt-device-module-box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .chart-wrap {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    .in-desc{
      position: absolute;
      top: 50%;
      left: 21%;
      margin-left: -57px;
      margin-top: -57px;
      width: 114px;
      height: 114px;
      background: 50% url(../img/bg.png) no-repeat;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #E3F3FF;
      display: flex;
      align-items: center;
      justify-content: center;
      .words{
        width: 64px;
        text-align: center;
        .num{
          font-size: 38px;
          font-family: D-DIN-Bold, D-DIN;
          font-weight: bold;
          color: #D8E7FF;
        }
        .sub{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #C1D9FF;
        }
      }
    }
    .chart-box {
      width: 172px;
      height: 172px;
      box-sizing: border-box;
      margin-right: 16px;
      flex-shrink: 0;
      // background: center center url(~@/assets/home/circle-stat-bg.png) no-repeat;
      background-size: calc(100% - 1px) calc(100% - 1px);
      box-sizing: border-box;
    }
    .chart-value {
      flex: 1;
      box-sizing: border-box;
      .status-list {
        display: flex;
        flex-direction: column;
        .status-item {
          height: 28px;
          background: rgba(25, 64, 125, 0.4);
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 10px;
          margin-bottom: 4px;
          position: relative;
          &::before {
            content: " ";
            display: inline-block;
            width: 40%;
            border-top: 1px dashed #246ae2;
            position: absolute;
            left: 55%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
          &::after {
            content: " ";
            display: block;
            border-width: 4px;
            border-color: #0ab2ff;
            border-style: solid;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            position: absolute;
            top: -4px;
            right: -4px;
            transform: rotate(-136deg);
          }
          &:last-child {
            margin-bottom: 0px;
          }
          &-name {
            font-size: 12px;
            color: #caf2ff;
            font-weight: 400;
            display: flex;
            align-items: center;
            &-color {
              background: #000;
              width: 9px;
              height: 9px;
              border-radius: 9px;
              margin-right: 8px;
            }
          }
          &-value {
            font-size: 12px;
            color: #caf2ff;
            text-align: right;
            font-weight: 400;
          }
        }
      }
    }
  }
  .list-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    .list-hd {
      height: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: left top url(~@/assets/home/list-border.png) no-repeat,
        left bottom url(~@/assets/home/list-border.png) no-repeat;
      background-size: 100% 2px;
      flex-shrink: 0;
      .hd-item {
        font-size: 16px;
        color: #0096ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
        color: #0096ff;
        flex: 1;
      }
    }
    .list-bd {
      flex: 1;
      overflow: auto;
      .bd-item {
        height: 40px;
        font-size: 16px;
        color: #c4d6ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:nth-of-type(even) {
          background: rgba(29, 82, 167, 0.3);
        }
        .item-id {
          flex: 1;
          text-align: center;
        }
        .item-status {
          flex: 1;
          text-align: center;
        }
        .item-value {
          flex: 1;
          text-align: center;
        }
      }
    }
  }
}
</style>