<template>
  <div class="homeLeft">
    <Equipment v-if="haves.includes(1)"/>
    <TaskScene v-if="haves.includes(2)"/>
    <Civil v-if="haves.includes(3)"/>
  </div>
</template>

<script>
import Civil from "./civilFly.vue";
import Equipment from "./equipment.vue"
import TaskScene from "./taskScene.vue"
export default {
    components: { Civil, Equipment, TaskScene },
    data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:skyDefenders:resourceStatistics") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:skyDefenders:taskStatistics") > -1) {
      this.haves.push(2)
    }
    if (permissionList.indexOf("hn:skyDefenders:civilAircraftManagement") > -1) {
      this.haves.push(3)
    }
    }
}
</script>

<style lang="scss" scoped>
    .homeLeft {
        height: 100%;
    }
</style>