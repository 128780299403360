<template>
  <ModuleBox :title="!showList ? '任务统计' : '任务场景占比'" width="100%" height="31vh" class="taskScene">
    <div class="right-top-switch " @click="click_init_chart"></div>
    <!-- iconfont icon-qiehuan -->
    <div v-show="!showList" class="task">
      <div style="width: 100%; color: #fff">
        <div class="header f14 cp" @click="details('任务数量总计')">
          <div class="name lh40  ml12">任务数量总计</div>
          <div class="num hwb-flex">
            <div class="one-num" v-for="item in totalList">{{ item }}</div>
          </div>
          <div class="count lh40">个</div>
        </div>
        <div class="bottom">
          <div class="text f12 mt14 mb18">
            <div class="top-text" style="color:#FFBD36">
              <div style="background-color:#FFBD36" class="dec"></div>临时任务
            </div>
            <div class="top-text" style="color:#1ee5d9">
              <div style="background-color:#1ee5d9" class="dec"></div>常态任务
            </div>
          </div>
          <div class="task-chart " style='height:18vh' id="task-chart"></div>
        </div>
      </div>
    </div>
    <div v-show="showList" class="containers-scene-body">
      <div class="selectBox">
        <div class="dec iconfont mt8 icon-jigoumingcheng"></div>
        <div class="dec2"></div>
        <el-cascader class="input-orgname" placeholder="请选择机构名称" v-model="organId" :options="selectList"
          :show-all-levels="false" @change="ChangeTaskType" :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false,
          }" clearable>
        </el-cascader>
        <div style="height:100%;display: flex; padding-top: 5px">
          <div class=" h160 mb30">
            <div class="w260 h160" id="content-body-list"></div>
          </div>

          <div id="bottom-boxs">
            <div class="item-boxs" v-for="(item, index) in getScenesCountData" :key="item.item"
              :style="{ color: colorList[index] }">
              <div class="item-icons" :style="{ backgroundColor: colorList[index] }"></div>
              <span class="item-texts  ml10 f14">{{ item.item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog class="Details" v-if="showDetails" :title="title" :width="1050" @on-close="closeDetails">

      <Table :cellName="dataList1" :dataSource="dataValue" />
      <div class="DemandList_Footer" v-if="dataValue.length">
        <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper" :total="totalCount || 0" :page-size="size || 10" />
      </div>
    </Dialog>
  </ModuleBox>
</template>

<script>
let chart;
import { Chart, registerTheme, registerShape } from "@antv/g2";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import renwudata from './JSONVAL/renwudata.json'
import API from "@/api";
import getgetAllOrganization from "@/api/mechanismPullDown.js"; // 获取机构下拉js
import { mapGetters, mapActions } from "vuex";
import highcharts from "highcharts";

import { fontChart } from "@/utils/echartPxToRem";
export default {
  components: { ModuleBox, Table, Dialog },
  data() {
    return {
      rwList: [],
      colorList: ['#6b7cff', '#996aff', '#3fce8e', '#e38130', '#dece29', '#4c9ff7', '#FF745A', '#007E99', '#FFA8A8', '#FFC328', '#A0DC2C', '#CD8150', '#36BCCB', '#327039', '#803488', '#83BC99'],
      totalList: [0, 0, 0, 0, 0],
      data: [],
      dataValue1: [],
      dataValue: [],
      totalCount: 0,
      getScenesCountData: [],//任务场景占比
      tableData: [{
        date: '交通事故处置',
        name: '交通警察大队',
        address: '25%'
      }, {
        date: 'PUT空地巡查',
        name: '巡特警大队',
        address: '21%'
      }, {
        date: '森林火情',
        name: '应急管理局',
        address: '42%'
      }, {
        date: '食药环抓捕',
        name: '生态创建办',
        address: '12%'
      }, {
        date: '河道巡查',
        name: '水利局',
        address: '12%'
      }
      ],
      showList: false,
      dataList: [
        { item: '森林防火巡查', population: 41.8 },
        { item: 'PUT空地巡查', population: 38 },
        { item: '文明养犬巡查', population: 33.7 },
        { item: '食药环抓捕', population: 30.7 },
        { item: '河道巡查', population: 25.8 }
      ],
      // typeList:[
      //   {
      //     id:1,
      //     title:'海宁市'
      //   }
      // ],
      // typeValue:1,
      selectList: [],
      organId: null,
      organId2: null,

      showDetails: false,
      title: '',
      value: '',
      currentPage: 1,
      total: 0,
      size: 10,
      // 任务统计
      categories: [],
      rwDataList: [],
      organization_id: null,
      cate_id: null

    }
  },
  computed: {
    ...mapGetters(["dbox_data"])
  },
  async mounted() {
    // 任务统计数据
    await this.taskTotalFn()
    await this.getScenesCountFn()
    this.init_chart();
    this.getAllOrganization();
    this.initTask_chart();
    this.initData()
  },
  methods: {
    async taskCountList(page, size, organization_id, cate_id) {
      if (!page && !size) {
        page = this.currentPage
        size = this.size

      }
      let res = await API.TASK.taskCountList({ page, size, type: 'year', organization_id:organization_id || this.organization_id, cate_id: cate_id || this.cate_id })
      if (res.code == 200) {
        this.dataValue = res.data.data
        this.totalCount = res.data.totalCount
      }
    },
    // 任务统计数据
    async taskTotalFn() {
      let res = await API.TASK.taskTotal()
      if (res.code == 200) {
        let totalList = res.data.total.toString().split('')
        for (let i = 0; i < 6; i++) {
          if (totalList.length < 5) {
            totalList.unshift(0)
          }
        }
        this.totalList = totalList
        this.rwList = res.data
        let data = [{
          name: '临时',
          data: [],
          stack: 'value',
          color: {
            linearGradient: {
              y1: 1,
              y2: 1
            },
            stops: [
              [0, '#FFD21F'],
              [1, '#E28C1B']]
          }
        },
        {
          name: '常态',
          data: [],
          stack: 'male',
          color: {
            linearGradient: {
              y1: 1,
              y2: 1
            },
            stops: [
              [0, '#1FF7FF'],
              [1, '#1BE2C0']]
          }
        }]
        let categories = []
        for (const iterator of res.data.data) {
          data[0].data.push(iterator.urgent)
          data[1].data.push(iterator.normal)
          categories.push(iterator.organizationName)

        }
        this.categories = categories
        this.rwDataList = data

      }
    },
    async getScenesCountFn() {
      let res = await API.TASK.getScenesCount()
      if (res.code == 200) {
        let data = []
        for (const iterator of res.data) {
          data.push({
            item: iterator.name,
            y: iterator.taskTypeCount,
            h: 0,
            population: iterator.taskTypeCount,
            name: iterator.name
          })

        }
        this.getScenesCountData = data.slice(0, 6);
      }
    },
    // 任务统计数据 
    initTask_chart() {
      let _this = this
      chart = highcharts.chart('task-chart', {
        chart: {
          type: 'column',
          options3d: {
            enabled: true,//显示图表是否设置为3D， 我们将其设置为 true
            alpha: 0,  //图表视图旋转角度
            beta: 0,  //图表视图旋转角度
            viewDistance: 20,   //图表的合计深度，默认为100
            depth: 14 //定义图表的浏览长度
          },
          marginTop: 10,
          marginRight: 0,
          marginLeft: 30,
          backgroundColor: '#203c749e',
          color: '#ffffff'
        },

        credits: {
          enabled: false //不显示LOGO 
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: this.categories,
          gridLineWidth: 1,
          // gridLineDashStyle: 'Dash',
          gridLineColor: '',
          labels: {
            style: {
              color: '#ffffff'
            },
            formatter: function () {
              //获取到刻度值
              var labelVal = this.value;
              //实际返回的刻度值
              var reallyVal = labelVal;
              //判断刻度值的长度
              if (labelVal.length > 3) {
                //截取刻度值
                reallyVal = labelVal.substr(0, 3) + "<br/>" + labelVal.substring(3, labelVal.length - 1);
              }
              return reallyVal;
            }
          }

        },
        title: null,
        yAxis: {
          gridLineWidth: 1,
          gridLineDashStyle: 'Dash',
          gridLineColor: '#ddd',
          allowDecimals: false,
          min: 0,
          title: null,
          labels: {
            style: {
              color: '#ffffff'
            }
          }
        },
        style: {
          color: '#ffffff'
        },
        tooltip: {
          headerFormat: '<b>{point.key}</b><br>',
          pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
        },
        plotOptions: {
          series: {
            stacking: '默认normal，百分比percent', /*默认normal，百分比percent*/

            dataLabels: {
              format: '<div class="dataLabels"> {point.percentage:.1f} %</div>',
              enabled: false,
              style: {
                color: '#ffffff',//字体颜色
                textOutline: 'none' //去掉文字阴影
              }
            },
            enableMouseTracking: true,
            events: {
              //控制图标的图例legend不允许切换
              // legendItemClick: function (event) {
              //   return false;//return true则表示允许切换
              // },
              click(event) {
                console.log(event, 'event');
                let index = event.point.category
                let num = event.point.options.y
                _this.handClickDetails(index, num)


              }
            }
          }
        },
        series: this.rwDataList
      });
    },
    onChangePage(val) {
      this.currentPage = val
      this.taskCountList()
    },
    initData() {
      this.dataList1 = [
        { title: '需求单名称', name: 'taskTitle', width: '200px' },
        { title: '任务类别', name: 'taskCateName', width: '200px' },
        { title: '任务场景', name: 'taskTypeName', width: '200px' },
        { title: '开始日期', name: 'startedAt', width: '200px' },
        { title: '结束日期', name: 'endAt', width: '200px' },
        { title: '任务目标位置', name: 'destination', width: '200px' },
        { title: '需求描述', name: 'remark', width: '200px' },
      ]
      this.taskCountList(1, 10)
    },
    updataflyValue() {

    },
    async handClickDetails(key, num) {
      let data = await this.rwList.data.filter(item => {
        return item.organizationName == key
      })
      if (data.length > 0) {

        let cate_id = null
        let title = null
        if (data[0].normal == num) {
          cate_id = 2
          title = "常态任务统计"
        } else if (data[0].urgent == num) {
          cate_id = 3
          title = "临时任务统计"

        }
        this.title = key + '-' + title
        this.organization_id = data[0].id
        this.cate_id = cate_id


        this.taskCountList(1, 10, data[0].id, cate_id)
        this.showDetails = true
      } else {
        this.$message.info('暂无数据')
      }

    },
    details(title, value) {
      this.title = title
      this.dataList1 = [
        { title: '需求单名称', name: 'taskTitle', width: '200px' },
        { title: '任务类别', name: 'taskCateName', width: '200px' },
        { title: '任务场景', name: 'taskTypeName', width: '200px' },
        { title: '开始日期', name: 'startedAt', width: '200px' },
        { title: '结束日期', name: 'endAt', width: '200px' },
        { title: '任务目标位置', name: 'destination', width: '200px' },
        { title: '需求描述', name: 'remark', width: '200px' },
      ]
      this.organization_id = null
      this.cate_id = null
      this.taskCountList(1, 10)
      this.showDetails = true
    },
    closeDetails() {
      this.currentPage = 1
      this.showDetails = false
    },
    async getAllOrganization() {
      let { data } = await API.AIRWAY.getAllOrganizationBack();
      this.selectList = this.getTreeData(data);
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length >= 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    async ChangeTaskType(organId) {
      let res = await API.TASK.getScenesCount({
        organId
      })
      if (res.code == 200) {
        let data = []
        for (const iterator of res.data) {
          data.push({
            item: iterator.name,
            y: iterator.taskTypeCount,
            h: 0,
            population: iterator.taskTypeCount,
            name: iterator.name
          })

        }
        this.getScenesCountData = data.slice(0, 6);
        this.init_chart()
      }

    },

    click_init_chart() {
      this.showList = !this.showList;
      if (this.showList) {
        this.init_chart()
      }
    },
    // 饼图
    init_chart() {
      let quantity = 0; // 总数
      this.getScenesCountData.forEach((item) => {
        quantity += item.y;
      });
      this.getScenesCountData.forEach((item) => {
        item.bfb = parseInt((item.y / quantity) * 100);
        item.h = item.bfb * 0.5 >= 40 ? 40 : item.bfb * 0.5
        // item.h = parseInt(0.86 * item.bfb); // 最高高度60，根据比例渲染高度
      });
      // 修改3d饼图绘制过程
      var each = highcharts.each,
        round = Math.round,
        cos = Math.cos,
        sin = Math.sin,
        deg2rad = Math.deg2rad;
      highcharts.wrap(
        highcharts.seriesTypes.pie.prototype,
        "translate",
        function (proceed) {
          proceed.apply(this, [].slice.call(arguments, 1));
          // Do not do this if the chart is not 3D
          if (!this.chart.is3d()) {
            return;
          }
          var series = this,
            chart = series.chart,
            options = chart.options,
            seriesOptions = series.options,
            depth = seriesOptions.depth || 0,
            options3d = options.chart.options3d,
            alpha = options3d.alpha,
            beta = options3d.beta,
            z = seriesOptions.stacking
              ? (seriesOptions.stack || 0) * depth
              : series._i * depth;
          z += depth / 2;
          if (seriesOptions.grouping !== false) {
            z = 0;
          }
          each(series.data, function (point) {
            var shapeArgs = point.shapeArgs,
              angle;
            point.shapeType = "arc3d";
            var ran = point.options.h;
            shapeArgs.z = z;
            shapeArgs.depth = depth * 0.75 + ran;
            shapeArgs.alpha = alpha;
            shapeArgs.beta = beta;
            shapeArgs.center = series.center;
            shapeArgs.ran = ran;
            angle = (shapeArgs.end + shapeArgs.start) / 2;
            point.slicedTranslation = {
              translateX: round(
                cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
              translateY: round(
                sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
            };
          });
        }
      );
      (function (H) {
        H.wrap(
          highcharts.SVGRenderer.prototype,
          "arc3dPath",
          function (proceed) {
            // Run original proceed method
            var ret = proceed.apply(this, [].slice.call(arguments, 1));
            ret.zTop = (ret.zOut + 0.5) / 100;
            return ret;
          }
        );
      })(highcharts);
      highcharts.chart('content-body-list', {
        chart: {
          animation: true,
          backgroundColor: "none",
          type: "pie", //饼图
          margin: [0, 0, 0, 0],
          options3d: {
            enabled: true, //使用3d功能
            alpha: 10, //延y轴向内的倾斜角度
            beta: 0,
          },
          events: {
            load: function () {
              var each = highcharts.each,
                points = this.series[0].points;
              each(points, function (p, i) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran,
                });
              });
            },
          },
        },
        legend: {
          enabled: false, // 关闭图例
          align: "right", //水平方向位置
          verticalAlign: "top", //垂直方向位置
          layout: "vertical",
          x: 20,
          y: 30,
          symbolWidth: 1,
          symbolHeight: 1,
          symbolRadius: "50%", // 修改成圆
          itemMarginBottom: 8,
          useHTML: true,
          itemStyle: {
            color: "#fff",
            fontSize: 12,
          },
        },
        title: {
          // enabled: false,
          text: "",
        },
        // subtitle: {
        //   floating: true,
        //   text: "在线设备",
        //   align: 'center',
        //   verticalAlign: "bottom",
        //   style: {
        //     color: '#c0d8fa'
        //   },
        // },
        plotOptions: {
          pie: {
            allowPointSelect: false, // 禁用点击
            cursor: "pointer",
            depth: 18,
            showInLegend: false,
            size: "100%", // 外圈直径大小
            innerSize: 0, // 内圈直径大小
            center: ["50%", "62%"],
            colors: [
              "#6b7cff",
              "#996aff",
              "#3fce8e",
              "#e38130",
              "#dece29",
              "#4c9ff7",
            ],
            dataLabels: {
              useHTML: true,
              enabled: false, //是否显示饼图的线形tip
              distance: 5,
              borderColor: '#007acc',
              align: "center",
              position: "right",
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                color: "#ffffff",
                textOutline: "none",
                fontSize: 14,
              },
            },
          },
        },
        credits: {
          enabled: false, // 禁用版权信息
        },
        series: [
          {
            type: "pie",
            name: "数量",
            data: this.getScenesCountData,
          },
        ],
      });
      // highcharts.chart('content-body-list', option);

    }
  },

};
</script>

<style lang="scss" scoped>
.DemandList_Footer {
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 30px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
        height: 30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background-color: #2647ee;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          // line-height: 40px;
          width: 40px;
          // height: 40px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 20px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height: 30px;


          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.Details {
  .select {
    display: flex;
    align-items: center;

    .selectBtn {
      cursor: pointer;
      width: 86px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
    }
  }

  ::v-deep .el-cascader .el-input {
    .el-input__inner {
      background-color: #000;
      border: 0;
      height: 30px;
      line-height: 30px;
      color: #fff;
      margin-bottom: 10px;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }
}

.taskScene {
  position: relative;

  .right-top-switch {
    position: absolute;
    right: 0px;
    top: 75px;
    z-index: 2;
    font-size: 20px;
    color: #dae9ff;
    cursor: pointer;
  }

  .task {
    .header {
      display: flex;
      height: 40px;
      width: 290px;
      background-image: url('~@/assets/img/titleBox.png');
      background-size: 100% 100%;
      padding: 0 9px;

      .name {
        // line-height: 25px;
        margin-right: 40px;
      }

      .num {
        width: 86px;

        .one-num {
          // width: 24px;
          padding-left: 1px;
          padding-right: 1px;
          font-size: 22px;
          height: 26px;
          background: linear-gradient(180deg, #01a0ff 0%, #01a0ff 50%, #1784FA 50%, #1784FA 100%);
          font-family: Rajdhani-SemiBold, Rajdhani;
          border-radius: 1px;
          color: #FFFFFF;
          line-height: 26px;
          text-align: center;
        }
      }

      .count {
        margin-left: 20px;
      }
    }

    .bottom {
      margin-top: 16px;
      width: 100%;
      height: 100%;

      .task-chart {
        cursor: pointer;
      }

      .text {
        display: flex;

        .top-text {
          position: relative;
          padding-left: 24px;

          .dec {
            position: absolute;
            width: 8px;
            height: 8px;
            // border-radius: 50%;
            border-radius: 2px;
            background-color: red;
            top: 3px;
            left: 10px;
          }
        }
      }
    }
  }

  .bd {
    display: flex;
  }

  .containers-scene-body {
    display: flex;
    flex-direction: column;

    .selectBox {
      position: relative;

      ::v-deep {
        .el-cascader .el-input {
          .el-input__inner {
            padding-left: 36px;
            width: 190px;
            height: 32px;
            background: #1784FAFF;
            backdrop-filter: blur(4px);
            font-size: 16px;
            font-family: "SourceHanSansCN-Regular", "SourceHanSansCN";
            font-weight: 400;
            color: #fff;
            line-height: 24px;
          }

          .el-input .el-select__caret {
            line-height: 0;
          }
        }
      }

      .dec {
        position: absolute;
        top: 3px;
        left: 10px;
        z-index: 100;
        font-size: 16px;
        color: #00baff;
      }

      .dec2 {
        position: absolute;
        top: 9px;
        left: 29px;
        width: 1px;
        height: 20px;
        background: linear-gradient(180deg,
            rgba(54, 124, 255, 0) 0%,
            #4cb4ff 51%,
            rgba(54, 124, 255, 0) 100%);
        z-index: 100;
      }
    }

    .content-body-right {
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: space-evenly;
      color: #fff;
      flex: 1;
      margin-left: 6px;
      overflow: auto;

      .item-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 17px;

        .item-icon {
          display: inline-block;
          width: 10px;
          height: 10px;
          // border-radius: 50%;
          background-color: #59EFA9;
          margin-right: 10px;
        }

        .item-label-value {
          color: #59EFA9;
          width: 110px;
        }

        .item-label-value2 {
          color: #59EFA9;
          font-size: 16px;
        }

        .item-label-value3 {
          color: #59EFA9;
        }
      }
    }

    #content-body-list {}
  }
}

.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: rgba(0, 0, 0, 0);
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: rgba(0, 0, 0, 0);
}

.el-table th.el-table__cell {
  background: rgba(3, 25, 58, 1);
}

.out {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .mc {
    width: 35%;
  }

  .lx {
    width: 27%;
  }

  .zt {
    width: 25%;
  }

  .cz {
    width: 13%;
  }

  .header {
    font-size: 16px;
    font-family: SourceHanSansSC-Bold, SourceHanSansSC;
    font-weight: bold;
    color: #aab7c3;
    text-align: center;
    background: rgba(3, 24, 66, 0.4);
    padding: 0 0 10px 0;
  }

  .content {
    height: 164px;
    overflow: auto;

    .list {
      padding: 10px 0;
      background: linear-gradient(270deg,
          rgba(132, 179, 242, 0) 0%,
          rgba(132, 179, 242, 0.3) 52%,
          rgba(132, 179, 242, 0) 100%);
    }

    .list2 {
      background: rgba(3, 24, 66, 0.4);
    }
  }
}

#bottom-boxs {
  color: #fff;
  overflow: auto;
  line-height: 24px;

  .item-boxs {
    display: flex;
    align-items: center;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;

    .item-icons {
      margin-right: 10px;
      width: 10px;
      height: 10px;
      // border-radius: 50%;
    }

    .item-texts {
      text-align: center;
      max-width: 200px;
    }
  }
}
</style>
<style>
.el-cascader-panel {
  background: linear-gradient(180deg, #0a378c 0%, #12206a 35%, #0f265a 66%, #0a378c 100%);
  color: #fff;
}

.el-cascader-node__label {
  color: #fff;

}

.el-cascader-node:not(.is-disabled):focus,
.el-cascader-node:not(.is-disabled):hover {
  background: rgba(3, 24, 66, 0.4);

}
</style>
