<template>
  <ModuleBox title="热点区域" width="100%" height="25vh" class="equipment">
    <div class="top-box">
      <div class="left">
        <div v-for="item in list" :key="item.id" @click="isClickBox(item)" class="item-box"
          :class="item.isClick?'isSelect':''">{{item.name}}</div>
      </div>
      <TimeSelect @on-selectTime="selectTimeFn" />
    </div>
    <div style="display: flex;" v-show="showList">
      <div class="TaskScenario-list">
        <div>
          <div id="content-box" class='w160 h160'></div>
        </div>
      </div>
      <div class="right-box-value">
        <div class="item-box " v-for="(item,index) in data2" :key="index">
          <i class="item-icon" :style="{backgroundColor:colors[index]}"></i>
          <span :style="{color:colors[index]}">{{ item.item}}</span>
          <span :style="{color:colors[index]}">{{item.count}}</span>
          <span class="item-label-value3">次</span>
        </div>
   
      </div>
    </div>
    <div style="height:100%" v-if="!showList">
      <div class="out">
        <div style="width: 100%;height:100%">
          <!-- 表头 -->
          <div class="header hwb-flex">
            <div class="mc">排名</div>
            <div class="lx">区域</div>
            <div class="zt">次数</div>
          </div>
          <!-- 内容 -->
          <div class="content">
            <div class="hwb-flex list" :class="index%2 === 0?'':'list2'" v-for="(item,index) in data2" :key="index">
              <div class="mc item-color" :style="{color:item.col}">{{index+1}}</div>
              <div class="lx item-color" :style="{color:item.col}">{{ item.item}}</div>
              <div class="zt item-color" :style="{color:item.col}">{{item.count}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="showDetails" :title="title" :width="900" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValue" />
    </Dialog>
  </ModuleBox>
</template>

<script>
let chart;
import { Chart } from "@antv/g2";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import API from "@/api";

import highcharts from "highcharts";
export default {
  components: { ModuleBox, TimeSelect, Table, Dialog },
  data() {
    return {
      prefecture: {
                1: '许村镇',
                2: '长安镇',
                3: '周王庙镇',
                4: '盐官镇',
                5: '丁桥镇',
                6: '斜桥镇',
                7: '袁花镇',
                8: '黄湾镇',
                9: '硖石街道',
                10: '海洲街道',
                11: '海昌街道',
                12: '马桥街道',
            },
  colors: [
              "#a2b355",
              "#1c7dd1",
              "#7d30cf",
              "#48cd82",
              "#d79163",
              "#e9483b",
            ],
      selectTime:4,
      sum: 249,
      typeValue: 1,
      isClick: '',
      list: [
        {
          id: 1,
          name: '图表',
          isClick: true
        },
        {
          id: 2,
          name: '列表',
          isClick: false
        },
      ],
      data: [
        {
          type: '盐官度假区',
          status: 62,
          col: '#a2b355'
        }, {
          type: '硖石街道',
          status: 58,
          col: '#1c7dd1'
        }, {
          type: '丁桥镇',
          status: 44,
          col: '#7d30cf'
        }, {
          type: '盐仓镇',
          status: 38,
          col: "#48cd82"
        }, {
          type: '海洲',
          status: 25,
          col: "#d79163"
        }, {
          type: '海昌',
          status: 22,
          col: "#e9483b"
        },
      ],
      data2: [
        { item: '盐官度假区', count: 62 },
        { item: '硖石街道', count: 28 },
        { item: '丁桥镇', count: 44},
        { item: '盐仓镇', count: 38 },
        { item: '海洲', count: 25 },
        { item: '海昌', count: 22},
      ],
      showList: true,
      dataList: [
        { title: '排名', name: 'name', },
        { title: '姓名', name: 'type' },
        { title: '手机号', name: 'status' },
        { title: '飞行架次(架次)', name: 'a' },
      ],
      dataValue: [],
      showDetails: false,
      title: '',
      value: ''
    }
  },
  methods: {
   async getHotFn(){
      let res = await  API.AIRCRAFT.getHot({
        type:this.selectTime
      })
    if(res.code==200){
      this.data2= res.data.map(item =>{
      return({
        item:this.prefecture[item.jurisdiction],
        count:item.count
      })
      })
    }
    },
    details(title, value) {
      this.title = title
      this.value = value
      this.showDetails = true
    },
    closeDetails() {
      this.showDetails = false
    },
    async selectTimeFn(v) {
      this.selectTime = v
    await this.getHotFn()
    this.init_antv()
      },
    isClickBox(item) {
      this.list.forEach(v => {
        v.isClick = false
      })
      item.isClick = true
      this.list.forEach(v => {
        console.log(v.isClick);
        console.log(v.id);
        if (v.isClick && v.id == 1) {
          this.showList = true
        } else if (v.isClick && v.id == 2) {
          this.showList = false
        }
      })
    },
    ChangeTaskType() {
      console.log('typeValue', this.typeValue);
    },
    init_antv() {
      let data = []
      this.data2.forEach(element => {
        data.push({
          name: element.item,
          "y": element.count,
          "percent": 0.09375,
          "h": 0,
          "bfb": 0
        })
      });


      let quantity = 0; // 总数
      this.data.forEach((item) => {
        quantity += item.y;
      });
      this.data.forEach((item) => {
        item.bfb = parseInt((item.y / quantity) * 100);
        item.h = item.bfb * 0.5 >= 40 ? 40 : item.bfb * 0.5
        // item.h = parseInt(0.86 * item.bfb); // 最高高度60，根据比例渲染高度
        // console.log(this.dataList, "dataList----->>>");
      });
      // 修改3d饼图绘制过程
      var each = highcharts.each,
        round = Math.round,
        cos = Math.cos,
        sin = Math.sin,
        deg2rad = Math.deg2rad;
      highcharts.wrap(
        highcharts.seriesTypes.pie.prototype,
        "translate",
        function (proceed) {
          proceed.apply(this, [].slice.call(arguments, 1));
          // Do not do this if the chart is not 3D
          if (!this.chart.is3d()) {
            return;
          }
          var series = this,
            chart = series.chart,
            options = chart.options,
            seriesOptions = series.options,
            depth = seriesOptions.depth || 0,
            options3d = options.chart.options3d,
            alpha = options3d.alpha,
            beta = options3d.beta,
            z = seriesOptions.stacking
              ? (seriesOptions.stack || 0) * depth
              : series._i * depth;
          z += depth / 2;
          if (seriesOptions.grouping !== false) {
            z = 0;
          }
          each(series.data, function (point) {
            var shapeArgs = point.shapeArgs,
              angle;
            point.shapeType = "arc3d";
            var ran = point.options.h;
            shapeArgs.z = z;
            shapeArgs.depth = depth * 0.75 + ran;
            shapeArgs.alpha = alpha;
            shapeArgs.beta = beta;
            shapeArgs.center = series.center;
            shapeArgs.ran = ran;
            angle = (shapeArgs.end + shapeArgs.start) / 2;
            point.slicedTranslation = {
              translateX: round(
                cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
              translateY: round(
                sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
            };
          });
        }
      );
      (function (H) {
        H.wrap(
          highcharts.SVGRenderer.prototype,
          "arc3dPath",
          function (proceed) {
            // Run original proceed method
            var ret = proceed.apply(this, [].slice.call(arguments, 1));
            ret.zTop = (ret.zOut + 0.5) / 100;
            return ret;
          }
        );
      })(highcharts);
      highcharts.chart('content-box', {
        chart: {
          animation: true,
          backgroundColor: "none",
          type: "pie", //饼图
          margin: [0, 0, 0, 0],
          options3d: {
            enabled: true, //使用3d功能
            alpha: 50, //延y轴向内的倾斜角度
            beta: 0,
          },

          events: {
            load: function () {
              var each = highcharts.each,
                points = this.series[0].points;
              each(points, function (p, i) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran,
                });
              });
            },
          },
        },
        legend: {
          enabled: false, // 关闭图例
          align: "right", //水平方向位置
          verticalAlign: "top", //垂直方向位置
          layout: "vertical",
          x: 20,
          y: 30,
          symbolWidth: 1,
          symbolHeight: 1,
          symbolRadius: "50%", // 修改成圆
          itemMarginBottom: 8,
          useHTML: true,
          itemStyle: {
            color: "#f4f4f6",
            fontSize: 12,
          },
        },
        title: {
          // enabled: false,
          text: "",
        },
        subtitle: {
          floating: true,
          text: "",
          align: 'center',
          verticalAlign: "bottom",
          style: {
            color: '#c0d8fa'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: false, // 禁用点击
            cursor: "pointer",
            depth: 20,
            showInLegend: false,
            size: "78%", // 外圈直径大小
            innerSize: 0, // 内圈直径大小
            center: ["50%", "55%"],
            colors: [
              "#a2b355",
              "#1c7dd1",
              "#7d30cf",
              "#48cd82",
              "#d79163",
              "#e9483b",
            ],
            dataLabels: {
              useHTML: true,
              enabled: false, //是否显示饼图的线形tip
              distance: 5,
              borderColor: '#007acc',
              align: "center",
              // verticalAlign: 'top',
              position: "right",
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              color: "#ffffff",
              style: {
                textOutline: "none",
                fontSize: 14,
              },
            },
          },
        },
        credits: {
          enabled: false, // 禁用版权信息
        },
        series: [
          {
            type: "pie",
            name: "数量",
            data: data,
          },
        ],
      });


    }
  },
  async mounted() {
    await this.getHotFn()
    this.ChangeTaskType()
    this.init_antv()
    console.log(chart);
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select {
    .el-input__inner {
      width: 167px;
      height: 32px;
      background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
      backdrop-filter: blur(4px);
      font-size: 16px;
      font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
      font-weight: 400;
      color: #65ABE9;
      line-height: 24px;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }

}

.equipment {
  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .mc {
      width: 20%;
    }

    .lx {
      width: 60%;
    }

    .zt {
      width: 20%;
    }

    .header {
      font-size: 16px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      line-height: 22px;
      color: #AAB7C3;
      text-align: center;
      background: rgba(3, 24, 66, 0.4);
      padding: 10px 0;
    }

    .content {
      // height: 125px;
      height: 55%;
      overflow: auto;

      .list {
        padding: 10px 0;
        background: linear-gradient(270deg, rgba(132, 179, 242, 0) 0%, rgba(132, 179, 242, 0.3) 52%, rgba(132, 179, 242, 0) 100%);

        .item-color {
          color: #fff;
        }
      }

      .list2 {
        background: rgba(3, 24, 66, 0.4);
      }
    }

  }

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;

      .item-box {
        width: 72px;
        height: 26px;
        background: linear-gradient(270deg, #032251 0%, rgba(10, 58, 129, 0.88) 46%, rgba(0, 0, 0, 0) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        border: 1px solid #47ABF4;
        color: rgba(148, 179, 194, 0.7);
        line-height: 26px;
        text-align: center;
        cursor: pointer;
      }

      .isSelect {
        background: linear-gradient(90deg, #37A5EE 0%, #265AD5 50%, #266EF5 100%);
        box-shadow: 0px 3px 6px 1px rgba(54, 86, 148, 0.35);
        border-radius: 4px 0px 0px 4px;
        opacity: 0.95;
        border: 1px solid #4DCEFF;
        color: #FFFFFF;
      }
    }
  }

  .selectBox {
    text-align: right;
    margin-right: 16px;

    .select {}
  }

  .TaskScenario-list {
    width: 165px;
    height: 165px;
    // background: url('~@/assets/home/list-background.gif') no-repeat;
    display: flex;
    align-items: center;
    background-size: 100% 100%;
    justify-content: center;

    #content-box {}
  }

  .right-box-value {
    width:200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-evenly;
    color: #fff;
    flex: 1;
    // margin-left: 10px;
    height: 146px;
    overflow-y: auto;

    .item-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 15px;
      font-size: 14px;
      height: 146px;
    overflow-y: auto;
      .item-icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        // border-radius: 50%;
        margin-right: 5px;
      }

      .item-label-value {
        color: #a2b355;
        width: 140px;
      }

      .item-label-value2 {
        color: #a2b355;
        margin-right: 5px;
        font-size: 22px;
      }
    }

  }
}
</style>