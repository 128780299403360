<template>
  <div style="height:100%">
    <detectionData />
    <!-- <modelsProportion /> -->
    <!-- <eventTrend /> -->
  </div>
</template>

<script>
import detectionData from "./detection-data.vue"; // 侦察反制
// import modelsProportion from "./models-proportion.vue"; // 机型占比
// import eventTrend from "./event-trend.vue"; // 入侵事件趋势
export default {
  components: { detectionData },
}
</script>

<style lang="less" scoped>

</style>