<template>
  <ModuleBox title="民用机管理" width="100%" height="32vh" class="civilFly">
    <div class="body">
      <div class="box" @click="details(item.text, '12340')" :class="`box`+(index+1)" v-for="(item,index) in list"
        :key="item.id">
        <div class="left">
          <img :src="item.icon" alt="">
        </div>
        <div class="right">
          <div class="item-text">
            {{ item.text }}
          </div>
          <div class=" item-label">
            {{ item.num }}
          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="showDetails" :title="title" :width="1050" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValue" />
      <div class="DemandList_Footer" v-if="dataValue.length">
        <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper" :total="total || 0" :page-size="size || 10" />
      </div>
    </Dialog>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import dengjidata from './JSONVAL/dengjidata.json'
import chizhengdata from './JSONVAL/chizhengdata.json'
import zhucedata from './JSONVAL/zhucedata.json'
import yongyoudata from './JSONVAL/yongyoudata.json'
import API from "@/api";

export default {
  components: { ModuleBox, Table, Dialog },
  data() {
    return {
      list: [
        {
          id: 1,
          num: 2164,
          text: '用户注册数',
          icon: require('@/assets/tkws/1.png'),
          color: '#50F9F4'
        },
        {
          id: 2,
          num: 2048,
          text: '低慢小登记数',
          icon: require('@/assets/tkws/2.png'),
          color: '#00C4FF'
        },
        {
          id: 3,
          num: 1780,
          text: '驾驶员登记数',
          icon: require('@/assets/tkws/3.png'),
          color: '#3AFF95'
        },
        {
          id: 4,
          num: 162,
          text: '驾驶证登记数',
          icon: require('@/assets/tkws/4.png'),
          color: '#FFD360'
        }
      ],
      dataList: [
      ],
      dataValue: [],
      showDetails: false,
      title: '',
      value: '',
      currentPage: 1,
      total: 0,
      size: 10
    }
  },
  methods: {
    details(title, value) {
      if (title == '用户注册数') {
        this.dataList = [
          { title: '注册时间', name: 'createdAt' },
          { title: '姓名', name: 'userName' },
          { title: '手机号', name: 'userPhone' },
          { title: '身份证', name: 'userNumber' },
        ]
        this.async_get_list()
      } else if (title == '低慢小登记数') {
        this.dataList = [
          { title: '序号', name: 'index', width: '80px' },
          { title: '创建时间', name: 'createTime' },
          { title: '型号', name: 'aircraftName' },
          { title: '产品序列号', name: 'productNumber' },
          { title: '飞控序列号', name: 'flightControlNumber' },
        ]
        this.PageList()
      } if (title == '驾驶员登记数') {
        this.dataList = [
          { title: '个人/单位', name: 'gr', },
          { title: '驾驶人员', name: 'js' },
          { title: '身份证号', name: 'sf' },
          { title: '联系方式', name: 'lx' },
          { title: '品牌', name: 'pingpai' },
          { title: '型号', name: 'xh' },
          { title: '用途', name: 'yt' },
        ]
        this.dataValue = yongyoudata.Sheet1.slice(0, 10)
        this.total = yongyoudata.Sheet1.length
      } else if (title == '驾驶证登记数') {
        this.dataList = [
          { title: '序号', name: 'index', width: '40px' },
          { title: '姓名', name: 'userName', },
          { title: '证件类型', name: 'certificateType' },
          { title: '分类等级', name: 'classificationName' },
          { title: '驾驶员等级', name: 'driverName' },
          { title: '证书签发日期', name: 'issuingTime' },
          { title: '证书截至日期', name: 'endTime' },
          
        ]
        this.jszList()
      }
      this.title = title
      this.value = value
      this.showDetails = true
    },
    onChangePage(val) {
      let sm = (val - 1) * 10 - 1 < 0 ? 0 : (val - 1) * 10
      let bi = val * 10
      if (this.title == '驾驶证登记数') {
        this.jszList(val)
      } else if (this.title == '低慢小登记数') {
        this.PageList(val)
      } else if (this.title == '用户注册数') {
        this.async_get_list(val)
      } else if (this.title == '驾驶员登记数') {
        this.dataValue = yongyoudata.Sheet1.slice(sm, bi)
      }
    },
    closeDetails() {
      this.showDetails = false
      this.dataValue = []
    },
    // 用户
    async async_get_list(page) {
      let res = await API.AIRCRAFT.UserInfo({
        page: page || this.currentPage,
        size: this.size
      })
      if (res.code == 200) {
        this.dataValue = res.data
        this.total = res.total
        this.list[0] = {
          id: 1,
          num: res.total,
          text: '用户注册数',
          icon: require('@/assets/tkws/1.png'),
          color: '#50F9F4'
        }
        this.$forceUpdate()
      }
    },
    async PageList(page) {
      let res = await API.AIRCRAFT.PageList({
        page: page || this.currentPage,
        size: this.size
      })
      if (res.code == 200) {
        this.dataValue = res.data.map((item, index) => {
          return ({
            ...item,
            index: index + 1
          })
        })
        this.list[1] = {
          id: 2,
          num: res.total,
          text: '低慢小登记数',
          icon: require('@/assets/tkws/2.png'),
          color: '#00C4FF'
        }
        this.$forceUpdate()
      }

    },
    async jszList(page) {
      let res = await API.AIRCRAFT.certificateInfoLists({
        page: page || this.currentPage,
        size: this.size
      })
      if (res.code == 200) {
        let certificateType ={
          1:'无人机执照',
          2:'utc合格证',
          3:'asfc飞行员执照'
        }
        this.dataValue = res.data.map((item, index) => {
          
          return ({
            ...item,
            index: index + 1,
            certificateType:certificateType[item.certificateType]
          })
        })
        this.list[3] = {
          id: 4,
          num: res.total,
          text: '驾驶证登记数',
          icon: require('@/assets/tkws/4.png'),
          color: '#FFD360'
        }
        this.$forceUpdate()

      }

    }
  },
  created() {
    this.async_get_list()
    this.PageList()
    this.jszList()
  }
}
</script>

<style lang="scss" scoped>
.civilFly {
  width: 100%;

  .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .box {
      cursor: pointer;
      width: 166px;
      height: 90px;
      background: rgba(80, 249, 244, 0.01);
      // border: 1px solid;
      background: url('../flying-commission/img/bg.png') no-repeat;
      background-size: 100% 100%;
      margin-bottom: 20px;
      display: flex;

      .left {
        width: 94px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          width: 40px;
          height: 40px;
          font-size: 40px;

          // background: linear-gradient(180deg, #FFFFFF 0%, #D0FDFC 59%, #50F9F4 100%);
          // box-shadow: 0px 0px 10px 0px rgba(162,255,254,0.5);
          &.iconfont.icon-yonghuzhuceshu {
            background: linear-gradient(180deg, #FFFFFF 0%, #D0FDFC 59%, #50F9F4 100%);
            //将字体图标改为只显示背景在字体上，就完成了字体的渐变
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-dimanxiaodengjishu {
            background: linear-gradient(180deg, #CBF3FF 0%, #9BE8FF 59%, #00C4FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-jiashiyuandengjishu {
            background: linear-gradient(180deg, #EFFFF5 0%, #82FFD0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &.iconfont.icon-jiashizhengdengjishu {
            background: linear-gradient(180deg, #FFF0C8 0%, #FFCE4F 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

      }

      .right {
        width: 110px;
        text-align: center;

        .item-label {
          // width: 24px;
          height: 36px;
          font-size: 22px;
          font-family: 'SemiBold';
          font-weight: 600;
          color: #00FF99;
          line-height: 36px;
        }

        .item-text {
          margin-top: 37px;
          font-size: 14px;
          font-family: SemiBold;
          color: #B1D3FFFF;
          font-weight: 400;
          color: #B1D3FF;
          line-height: 0px;
        }
      }
    }

    .box2 {
      background: url('../flying-commission/img/bg.png') no-repeat;
      background-size: 100% 100%;
    }

    .box3 {
      background: url('../flying-commission/img/bg.png') no-repeat;
      background-size: 100% 100%;
    }

    .box4 {
      background: url('../flying-commission/img/bg.png') no-repeat;
      background-size: 100% 100%;
    }
  }


}

.DemandList_Footer {
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 30px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
        height: 30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background-color: #2647ee;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          // line-height: 40px;
          width: 40px;
          // height: 40px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 20px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height: 30px;

          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>