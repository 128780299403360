<template>
  <div style="height:100%">
    <toDo v-if="haves.includes(1)"/>
    <!-- <Video/> -->
    <onlineWarning v-if="haves.includes(2)"/>
  </div>
</template>

<script>
import toDo from "./to-do.vue"; // 待办事项
// import Video from "./video.vue"; // 无人机视频
import onlineWarning from "./online-warning.vue"; // 无人机视频
export default {
  components: { toDo, onlineWarning },
      data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:tasks:toDoList") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:tasks:alertPrompt") > -1) {
      this.haves.push(2)
    }
    }
}
</script>

<style lang="less" scoped>

</style>