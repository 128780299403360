<template>
  <div style="height: 100%">
    <Shot v-if="haves.includes(1)"/>
    <Resources v-if="haves.includes(2)"/>
    <!-- <Application v-if="haves.includes(3)"/> -->
  </div>
</template>

<script>
import Resources from "./resources.vue"
import Shot from "./shot.vue"
import Application from "./application-case.vue"
export default {
    components: {
        Resources,
        Shot,
        Application
    },
        data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:3DApplication:keyAreas") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:3DApplication:3DResourceSharing") > -1) {
      this.haves.push(2)
    }
    if (permissionList.indexOf("hn:3DApplication:applicationCaseStatistics") > -1) {
      this.haves.push(3)
    }
    }
}
</script>

<style>

</style>