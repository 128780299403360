<template>
  <ModuleBox @changeSelect="changeSelect" :changeFlag='true' title="设备飞行时长" width="100%" height="32%"
    class="number-of-tasks">
    <div class="out">
      <div style="width: 100%">
        <!-- 表头 -->
        <div class="header hwb-flex">
          <div class="cz">排名</div>
          <div class="lx">设备名</div>
          <div class="zt">所属机构</div>
          <div class="zt">任务数</div>
          <div class="lx" :title="select == 1 ? '作业时长(min)' : '飞行时长(min)'">{{ select == 1 ? '作业时长' : '飞行时长' }}</div>
        </div>
        <!-- 内容 -->
        <div class="content" id="airspaceCarousel">
          <div class="hwb-flex list" :class="index % 2 === 0 ? '' : 'list2'" v-for="(item, index) in data" :key="index">
            <div class="cz" style="color:#fff">{{ index + 1 }}</div>
            <el-tooltip :content="item.name" placement="top" effect="light">
              <div class="cf lx">{{ item.name }}</div>
            </el-tooltip>
            <el-tooltip :content="item.organizationName" placement="top" effect="light">
              <div class="zt cf">{{ item.organizationName }}</div>
            </el-tooltip>
            <div class="zt cf">{{ item.taskCount }}</div>
            <div class="lx tc cf">{{ (item.duration).toFixed(2) }}{{ item.duration == 0 ? '' : 'h' }}</div>
          </div>
        </div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import API from "@/api";

export default {
  components: { ModuleBox, },
  data() {
    return {
      select: 1,
      data: [],
      timer: null,
      moveStatus: false,
      flyType: null
    };
  },
  methods: {
    async GetDrdType() {
      let res = await API.UAV.GetDrdType();
      this.flyType = res.data
    },
    changeSelect(select) {
      if (select == 1) {
        this.deviceRank(1)
      } else {
        this.deviceRank(2)
      }
      this.select = select
    },
    //设备飞行时长
    async deviceRank(num) {

      let res = await API.TASK.deviceRank({goodsId:num})
      if (res.code == 200) {
        this.data = res.data.records
      }
    }
  },
   mounted() {
    // await this.GetDrdType()
    this.deviceRank(1)
  },

  beforeDestroy() {
  }
};
</script>

<style lang="scss" scoped>
.number-of-tasks {
  width: 100%;

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .select {
      height: 32px;
      width: 100%;
      margin-bottom: 10px;

      .button {
        box-sizing: border-box;
        background: linear-gradient(270deg, #032251 0%, rgba(10, 58, 129, 0.88) 46%, rgba(0, 0, 0, 0) 100%);
        width: 33.33%;
        height: 100%;
        text-align: center;
        border: 1px solid #47ABF4;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: rgba(148, 179, 194, 0.7);
        line-height: 32px;
        cursor: pointer;
      }

      .button-active {
        background: linear-gradient(90deg, #37A5EE 0%, #265AD5 50%, #266EF5 100%);
        box-shadow: 0px 3px 6px 1px rgba(54, 86, 148, 0.35);
        border-radius: 4px 0px 0px 4px;
        opacity: 0.95;
        border: 1px solid #4DCEFF;
        color: #FFFFFF;
      }
    }

    .mc {
      width: 35%;
    }

    .lx {
      width: 27%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis
    }

    .zt {
      width: 25%;
    }

    .cz {
      width: 13%;
    }

    .header {
      font-size: 16px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #AAB7C3;
      text-align: center;
      background: rgba(3, 24, 66, 0.4);
      padding: 10px;
      margin-top: 10px;
    }

    .content {
      height: 160px;
      overflow: auto;

      .hwb-flex {
        .list-item {
          white-space: nowrap; // 强制一行
          overflow: hidden; // 溢出隐藏
          text-overflow: ellipsis; // 文字溢出显示省略号
        }
      }

      .list {
        height: 20px;
        padding: 10px 0;
        background: linear-gradient(270deg, rgba(132, 179, 242, 0) 0%, rgba(132, 179, 242, 0.3) 52%, rgba(132, 179, 242, 0) 100%);
      }

      .list2 {
        background: rgba(3, 24, 66, 0.4);
      }
    }

    .map {
      position: relative;
      height: 240px;
      border-radius: 6px;
      margin-top: 10px;
      overflow: hidden;
    }
  }
}
</style>