let  Cesium = window.Cesium

/**
 *
 * @param {*} viewer cesium viewer
 * @param {*} drawingMode 绘制模式 point | line | polygon
 * @param {*} callback 回调函数，携带两个参数 backShapePoints: 所有点坐标（Cartesian3）, backShapeEntities（所有显示实体，用于删除临时实体自定义样式）
 */
let handler
const drawGraphic = function(viewer, drawingMode, callback) {
  console.log(drawingMode,'drawingModedrawingMode');
  if (handler) {
    handler.destroy()
  }
  if (!viewer.scene.pickPositionSupported) {
    window.alert('This browser does not support pickPosition.')
  }

  viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
    Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
  )

  let activeShapePoints = []
  let activeShape
  let floatingPoint
  let backShapePoints = []
  let backShapeEntities = []

  viewer.scene.globe.depthTestAgainstTerrain = true // 开启地形深度检测
  handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)
  handler.setInputAction(function(event) {
    // We use `viewer.scene.pickPosition` here instead of `viewer.camera.pickEllipsoid` so that
    // we get the correct point when mousing over terrain.
    // let earthPosition = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid)
    // let earthPosition = viewer.scene.pickPosition(event.position)
    var earthPosition = viewer.camera.pickEllipsoid(
      event.position,
      viewer.scene.globe.ellipsoid
    )
    // var cartographic = Cesium.Cartographic.fromCartesian(position, viewer.scene.globe.ellipsoid, new Cesium.Cartographic());
    // var lat=Cesium.Math.toDegrees(cartographic.latitude);
    // var lng=Cesium.Math.toDegrees(cartographic.longitude);
    // var height=cartographic.height;
    // console.log("[Lng=>"+lng+",Lat=>"+lat+",H=>"+height+"]");

    // try {
    //   let ellipsoid = viewer.scene.globe.ellipsoid
    //   cartesian3 = ellipsoid.cartographicToCartesian(earthPosition)
    // } catch (e) {
    //   console.log(e)
    // }
    // if (!earthPosition) {
    //   earthPosition = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid)
    // }

    // `earthPosition` will be undefined if our mouse is not over the globe.
    if (Cesium.defined(earthPosition)) {
      if (activeShapePoints.length === 0) {
        floatingPoint = createPoint(earthPosition)
        if (drawingMode === 'point') {
          handler.destroy() //关闭事件句柄
          handler = null
          viewer.scene.globe.depthTestAgainstTerrain = false
          callback([earthPosition], [floatingPoint])
          return
        }
        activeShapePoints.push(earthPosition)
        let dynamicPositions = new Cesium.CallbackProperty(function() {
          if (drawingMode === 'polygon') {
            return new Cesium.PolygonHierarchy(activeShapePoints)
          }
          return activeShapePoints
        }, false)
        if (drawingMode !== 'point') {
          activeShape = drawShape(dynamicPositions)
        }
      }
      activeShapePoints.push(earthPosition)
      createPoint(earthPosition)
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

  handler.setInputAction(function(event) {
    if (Cesium.defined(floatingPoint)) {
      let newPosition = viewer.scene.pickPosition(event.endPosition)
      if (Cesium.defined(newPosition)) {
        floatingPoint.position.setValue(newPosition)
        activeShapePoints.pop()
        // @ts-ignore
        activeShapePoints.push(newPosition)
      }
    }
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

  handler.setInputAction(function(event) {
    terminateShape()
    viewer.scene.globe.depthTestAgainstTerrain = false // 关闭地形深度检测
    callback(backShapePoints, backShapeEntities)
    handler.destroy() //关闭事件句柄
    handler = null
    // viewer.entities.remove(floatingPoint)
    // viewer.entities.remove(activeShapePoints)
    // viewer.entities.remove(activeShape)
  }, Cesium.ScreenSpaceEventType.RIGHT_CLICK)
  function createPoint(worldPosition) {
    let point = viewer.entities.add({
      position: worldPosition,
      point: {
        color: Cesium.Color.fromCssColorString('#00ff00').withAlpha(0),
        pixelSize: 6,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
      },
    })
    // @ts-ignore
    backShapeEntities.push(point)
    return point
  }

  // Redraw the shape so it's not dynamic and remove the dynamic shape.
  function terminateShape() {
    activeShapePoints.pop()
    drawShape(activeShapePoints)
    viewer.entities.remove(floatingPoint)
    viewer.entities.remove(activeShape)
    floatingPoint = undefined
    activeShape = undefined
    activeShapePoints = []
  }

  function drawShape(positionData) {
    backShapePoints = positionData
    let shape
    if (drawingMode === 'line') {
      shape = viewer.entities.add({
        polyline: {
          positions: positionData,
          clampToGround: true,
          width: 3,
          material: new Cesium.PolylineOutlineMaterialProperty({
            //设置线的样式
            color: Cesium.Color.fromCssColorString('#ef8518'),
          }),
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
        },
      })
    } else if (drawingMode === 'polygon') {
      shape = viewer.entities.add({
        polygon: {
          hierarchy: positionData,
          material: new Cesium.ColorMaterialProperty(
            Cesium.Color.WHITE.withAlpha(0.7)
          ),
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
        },
      })
    }
    backShapeEntities.push(shape)
    return shape
  }
}

export default drawGraphic
