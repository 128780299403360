<template>
  <div>
    <ModuleBox title="三维数据统计" width="100%" height="29.5vh" class="home-statistics">
      <div class="body">
        <div class="header">
          <div class="left">
            <!-- <span v-if="type === 2">二维模型</span> -->
            <span v-if="type === 1" class="mt7">济源市总面积</span>
            <div class="num hwb-flex cp" @click="details">
              <div class="one-num" v-for="(item,index) in Area" :key="index">{{item}}</div>
            </div>
            <span class="mt10">km²</span>
          </div>
          <div class="right">
            <!-- <div
          :class="type === 2 ? 'btnSelect ' : 'btn'"
          @click="handle_search(2)"
        >
          二维模型
        </div> -->
            <div :key="key" :class="type === 1 ? 'btnSelect' : 'btn'" @click="handle_search(1)">
              三维模型
            </div>
          </div>
        </div>


        <div class="bottom pr" >
          <div class="statistics  " style="height:18vh" id="statistics3d">
          </div>
          <div class="pa right0 w80 tootip">
            <div class="jcsb" style="color:#17f93b"><div class="w10 mt4 h10 dec " style="backgroundColor:#17f93b"></div>已完成</div>
            <div class="jcsb mt10" style="color:#ffeb38"><div class="w10 mt4 h10 cd dec " style="backgroundColor:#ffeb38"></div> 未完成</div>
          </div>
        </div>
      </div>
    </ModuleBox>
    <Dialog class="Details" v-if="showDetails" :title="title" :width="1050" @on-close="closeDetails">
      <Table :cellName="dataList1" :dataSource="dataValue" >
        <template #index = {item}>
          {{item.index}}
        </template>
      </Table>
      <div class="DemandList_Footer" v-if="dataValue.length">
        <!-- <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper" :total="total || 0" :page-size="size || 10" /> -->
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import API from "@/api"
import highcharts from "highcharts";

export default {
  components: {
    ModuleBox,
    Table, Dialog
  },
  data() {
    return {
      key: 0,
      type: 1,
      data: null,
      modeledData: [],
      dataValue: [],
      dataList1: [],
      showDetails: false,
      title: "济源市总面积",
      Area: [],
      data:[]
    }
  },
  methods: {
    async details() {
      let res = await API.MODEL.serviceMapDataList()
      if (res.code == 200) {
        let data = res.data.records
        data.forEach((item,index)=>item.index = index+1) //添加序号(下标)
        this.dataValue = data
      }
      this.dataList1 = [
        { title: '序号', name: 'index', },
        { title: '三维建模名称', name: 'name' },
        { title: '面积(km²)', name: 'area' },
        { title: '完成时间', name: 'addtime' },
      ]

      this.showDetails = true
    },
    async serviceMapDataAreaFn() {
      let res = await API.MODEL.serviceMapDataArea()
      if (res.code == 200) {
        let data = []
        res.data.forEach(element => {
          console.log(element.item, element.percent);
          data.push([element.item, element.percent])

        });
        this.modeledData = data
      }

    },
    closeDetails() {
      this.showDetails = false
    },
    handle_search(data) {
      this.type = data
    },
    init() {
      this.data = this.modeledData
      console.log(this.modeledData, 'this.modeledData');
      const chart = new Chart({
        container: 'statistics',
        height: 159,
        width: 400
      });
      chart.coordinate('theta', {
        radius: 0.75,
      });

      chart.data(this.data);

      chart.scale('percent', {
        formatter: (val) => {
          val = val + '%';
          return val;
        },
      });

      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        
      });
      chart.legend({
        position: "right",
        itemName: {
          style: {
            fill: "#9EBFEE",
            fontSize: 14
          },
        },
        itemValue: {
          formatter: (val, value, index) => {
            return this.data[index].percent + '%'
          },
          style: {
            fill: "#9EBFEE",
            fontSize: 12
          }
        }
      });

      chart
        .interval()
        .position('percent')
        .color('item')
        // .label('percent', {
        //   content: (data) => {
        //     return `${data.item}: ${data.percent * 100}%`;
        //   },
        // })
        .adjust('stack');

      chart.interaction('element-active');

      chart.render();
    },
    async initValue() {
      let res = await API.DEVICE.hainingArea()
      if (res.code == 200) {
        this.Area = res.data.padStart(5, 0).split('')
      } else {
        this.$message.error(res.message)
      }
    },
    drawChart() {
      let data = []
      this.modeledData.forEach(element => {
        data.push({
          name: element[0],
          "y": element[1],
          "percent": 0.09375,
          "h": 0,
          "bfb": 0
        })
      });
      this.data=  this.modeledData
      let quantity = 0; // 总数
      this.data.forEach((item) => {
        quantity += item.y;
      });
      this.data.forEach((item) => {
        item.bfb = parseInt((item.y / quantity) * 100);
        item.h = item.bfb * 0.5 >= 40 ? 40 : item.bfb * 0.5
        // item.h = parseInt(0.86 * item.bfb); // 最高高度60，根据比例渲染高度
        // console.log(this.dataList, "dataList----->>>");
      });
      // 修改3d饼图绘制过程
      var each = highcharts.each,
        round = Math.round,
        cos = Math.cos,
        sin = Math.sin,
        deg2rad = Math.deg2rad;
      highcharts.wrap(
        highcharts.seriesTypes.pie.prototype,
        "translate",
        function (proceed) {
          proceed.apply(this, [].slice.call(arguments, 1));
          // Do not do this if the chart is not 3D
          if (!this.chart.is3d()) {
            return;
          }
          var series = this,
            chart = series.chart,
            options = chart.options,
            seriesOptions = series.options,
            depth = seriesOptions.depth || 0,
            options3d = options.chart.options3d,
            alpha = options3d.alpha,
            beta = options3d.beta,
            z = seriesOptions.stacking
              ? (seriesOptions.stack || 0) * depth
              : series._i * depth;
          z += depth / 2;
          if (seriesOptions.grouping !== false) {
            z = 0;
          }
          each(series.data, function (point) {
            var shapeArgs = point.shapeArgs,
              angle;
            point.shapeType = "arc3d";
            var ran = point.options.h;
            shapeArgs.z = z;
            shapeArgs.depth = depth * 0.75 + ran;
            shapeArgs.alpha = alpha;
            shapeArgs.beta = beta;
            shapeArgs.center = series.center;
            shapeArgs.ran = ran;
            angle = (shapeArgs.end + shapeArgs.start) / 2;
            point.slicedTranslation = {
              translateX: round(
                cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
              translateY: round(
                sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
            };
          });
        }
      );
      (function (H) {
        H.wrap(
          highcharts.SVGRenderer.prototype,
          "arc3dPath",
          function (proceed) {
            // Run original proceed method
            var ret = proceed.apply(this, [].slice.call(arguments, 1));
            ret.zTop = (ret.zOut + 0.5) / 100;
            return ret;
          }
        );
      })(highcharts);
      highcharts.chart('statistics3d', {
        chart: {
          animation: true,
          backgroundColor: "none",
          type: "pie", //饼图
          margin: [0, 0, 0, 0],
          options3d: {
            enabled: true, //使用3d功能
            alpha: 30, //延y轴向内的倾斜角度
            beta: 0,
          },

          events: {
            load: function () {
              var each = highcharts.each,
                points = this.series[0].points;
              each(points, function (p, i) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran,
                });
              });
            },
          },
        },
        legend: {
          enabled: false, // 关闭图例
          align: "right", //水平方向位置
          verticalAlign: "top", //垂直方向位置
          layout: "vertical",
          x: 20,
          y: 30,
          symbolWidth: 1,
          symbolHeight: 1,
          symbolRadius: "50%", // 修改成圆
          itemMarginBottom: 8,
          useHTML: true,
          itemStyle: {
            color: "#f4f4f6",
            fontSize: 12,
          },
        },
        tooltip:{
          formatter: function() {
            // .data.y
            console.log(this.key ,'series');
            return `${this.key} ${this.y}%`
          }
        },
        title: {
          // enabled: false,
          text: "",
        },
        subtitle: {
          floating: true,
          text: "三维面积占比",
          align: 'center',
          verticalAlign: "bottom",
          x:-100,
          y:30,
          style: {
            color: '#c0d8fa'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: false, // 禁用点击
            cursor: "pointer",
            depth: 25,
            showInLegend: true,
            size: "80%", // 外圈直径大小
            innerSize: 0, // 内圈直径大小
            center: ["20%", "55%"],
            colors: [
              "#17f93b",
              "#ffeb38",
            ],
            dataLabels: {
              useHTML: true,
              enabled: false, //是否显示饼图的线形tip
              distance: 5,
              borderColor: '#007acc',
              align: "center",
              position: "right",
              format: '<b>{point.name}</b>: {point.y %',
              color: "#ffffff",
              style: {
                textOutline: "none",
                fontSize: 14,
              },
            },
          },
        },
        credits: {
          enabled: false, // 禁用版权信息
        },
        series: [
          {
            type: "pie",
            name: "数量",
            data: data,
            
          },
        ],
      });





    }

  },
  created() {
    this.initValue()
  },
  async mounted() {
    await this.serviceMapDataAreaFn()
    // this.init()
    // this.init3d()
    this.drawChart()
  }
}
</script>

<style lang="scss" scoped>
.home-statistics {
  .body {
    .header {
      display: flex;
      justify-content: space-between;

      .left {
        width: 260px;
        display: flex;
        justify-content: space-around;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #DAE9FF;

        .num {
          width: 120px;

          .one-num {
            width: 16px;
            height: 27px;
            background: linear-gradient(180deg, #01a0ff 0%, #01a0ff 50%, #1784FA 50%, #1784FA 100%);
            font-family: Rajdhani-SemiBold, Rajdhani;
            border-radius: 1px;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 27px;
            text-align: center;
          }
        }
      }

      .right {
        display: flex;

        .btnSelect {
          width: 72px;
          height: 26px;
          background: #1F4DCB;
          text-align: center;
          font-size: 14px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
        }

        .btn {
          width: 72px;
          height: 26px;
          background: rgba(0, 0, 0, 0.7);
          text-align: center;
          font-size: 14px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: rgba(148, 179, 194, 0.7);
          line-height: 26px;
        }
      }


    }

    .bottom {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      .tootip{
        top:50%;
        right:60px;
        .dec{
          border-radius:2px;
        }
      }
      .statistics {
        // width: 250px;
        width: 100%;
        height: 250px;
      }

      .center {
        position: absolute;
        left: 60%;
        margin-top: 50px;
        width: 1px;
        height: 112px;
        background: linear-gradient(180deg, rgba(54, 124, 255, 0) 0%, #C6DAFF 51%, rgba(54, 124, 255, 0) 100%);
      }

      .right {
        height: 100%;
      }
    }
  }

}
</style>