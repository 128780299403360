<template>
  <div class="cpt-box" :style="`width:${width};height:${height};`">
    <!-- <span class="flow"></span>
    <span class="flow"></span>
    <span class="flow"></span>
    <span class="flow"></span> -->
    <div class="box-bd">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "0px",
    },
    height: {
      type: String,
      default: "0px",
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes animate1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

@keyframes animate2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

@keyframes animate3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

@keyframes animate4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

.cpt-box {
  overflow: hidden;
  border-radius: 2px;
  box-sizing: border-box;
  .box-bd {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .flow {
    position: absolute;
    &:nth-child(1) {
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, #298EFF);
      animation: animate1 4s linear infinite;
    }
    &:nth-child(2) {
      top: -100%;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, transparent, #298EFF);
      animation: animate2 4s linear infinite;
      animation-delay: 1s;
    }
    &:nth-child(3) {
      bottom: 0;
      right: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, transparent, #298EFF);
      animation: animate3 4s linear infinite;
      animation-delay: 2s;
    }
    &:nth-child(4) {
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(360deg, transparent, #298EFF);
      animation: animate4 4s linear infinite;
      animation-delay: 3s;
    }
  }
}
</style>