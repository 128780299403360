var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleBox',{staticClass:"distribute distribute_fx",attrs:{"title":"飞行数据统计","width":"100%","height":"35%"}},[_c('div',{staticClass:"pa top140 w200",staticStyle:{"z-index":"30"}},[_c('el-date-picker',{staticClass:"w346",attrs:{"format":"yyyy-MM-dd HH:mm:ss","value-format":"yyyy-MM-dd HH:mm:ss","size":"mini","type":"datetimerange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},on:{"change":_vm.change},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}})],1),_c('div',{staticClass:"out"},[_c('div',{staticClass:"content hwb-flex ofh",staticStyle:{"width":"100%"}},_vm._l((_vm.FlightInfoData),function(item,index){return _c('div',{key:index,staticClass:"jc hwb-flex ljjc",staticStyle:{"color":"#00FF99FF"},on:{"click":function($event){return _vm.details('飞行数据统计')}}},[_c('img',{staticClass:"w45 h45 ml10",attrs:{"src":item.icon,"alt":"","srcset":""}}),_c('div',{staticClass:"jc-text"},[_c('div',{staticClass:"num f25"},[_vm._v(_vm._s(item.data))]),_c('div',{staticClass:"text f14"},[_vm._v(_vm._s(item.typeName))])])])}),0)]),(_vm.showDetails)?_c('Dialog',{staticClass:"Details",attrs:{"title":_vm.title,"width":1050},on:{"on-close":_vm.closeDetails}},[_c('el-cascader',{staticClass:"ml50",staticStyle:{"margin-right":"5px"},attrs:{"popper-class":"input-orgname","placeholder":"请选择所属机构","options":_vm.allOrganizationselectList,"show-all-levels":false,"props":{
        children: 'child',
        label: 'name',
        value: 'id',
        checkStrictly: true,
        emitPath: false,
        empty: false
      },"clearable":""},on:{"change":_vm.taskTotalListSearch},model:{value:(_vm.organId),callback:function ($$v) {_vm.organId=$$v},expression:"organId"}}),_c('Table',{attrs:{"cellName":_vm.dataList,"dataSource":_vm.data}}),_c('div',{staticClass:"DemandList_Footer"},[(_vm.pageShow)?_c('el-pagination',{staticClass:"cpt-table-pagination",attrs:{"current-page":_vm.currentPage,"layout":"total , prev, pager, next, jumper","total":_vm.total || 0,"page-size":_vm.size || 10},on:{"current-change":_vm.onChangePage}}):_c('el-pagination',{staticClass:"cpt-table-pagination",attrs:{"current-page":"1","layout":"total , prev, pager, next, jumper","total":_vm.total || 0,"page-size":_vm.size || 10}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }