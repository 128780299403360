<template>
  <ModuleBox
    title="隐患数据"
    width="100%"
    height="50%"
    class="number-of-tasks"
  >
    <div class="out">
      <div style="width: 100%">
        <!-- 头部选择 -->
        <div class="selectBox">
          <div class="dec iconfont icon-jigoumingcheng"></div>
          <div class="dec2"></div>
          <!-- <el-select
            v-model="typeValue"
            placeholder="请选择机构名称"
            clearable
          >
            <el-option
              v-for="item in mechanismList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select> -->
          <el-cascader
          popper-class="input-orgname"
          placeholder="请选择所属机构"
          v-model="organId"
          :options="selectList"
          :show-all-levels="false"
          @change="updataflyValue()"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          style="margin-right:10px;"
          clearable
        >
        </el-cascader>
        </div>
        <!-- 头部总数展示 -->
        <div class="top hwb-flex">
          <!-- 左侧任务次数统计 -->
          <div class="left hwb-flex">
            <div class="text">总隐患数</div>
            <div class="num hwb-flex">
              <div class="one-num">0</div>
              <div class="one-num">0</div>
              <div class="one-num">0</div>
              <div class="one-num">0</div>
              <div class="one-num">3</div>
            </div>
            <div class="text">条</div>
          </div>
          <!-- 右侧时间筛选 -->
          <div class="right">
            <!-- <TimeSelect @on-selectTime="selectTime"/> -->
          </div>
        </div>
        <!-- 下面 -->
        <div class="bottom">
          <!-- 一条样式 -->
          <div class="slider-list hwb-flex-column" v-for="item in data" :key="item.name">
            <div class="one-scroll-text hwb-flex">
              <div class="one-scroll-text-left">{{item.name}}</div>
              <div class="one-scroll-text-right" :style="{color:item.color}">{{item.num}}</div>
            </div>
            <div class="slider ">
              <div class="tiao">
                <div class="have" :style="{width: (item.num/item.sum)*100 + '%',background:item.bg}"></div>
                <!-- 圆点 -->
                <div class="dec-circular" :style="{left:'calc(' +(item.num/item.sum)*100 + '% - 4px)'}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import API from "@/api";

export default {
  components: { ModuleBox, TimeSelect },
  data() {
    return {
      organId:null,
      selectList:null,
      typeValue:null,
      mechanismList: [
        {
          id:1,
          title:'市公安局'
        },
        {
          id:2,
          title:'市综合执法局'
        },
        {
          id:3,
          title:'市应急管理局'
        },
        {
          id:4,
          title:'市自然资源和规划局'
        },
        {
          id:5,
          title:'市水利局'
        },
        {
          id:6,
          title:'市生态办'
        }
      ],
      data: [
        {name:'今日隐患数', num: 0, sum: 5, bg: 'linear-gradient(270deg, #FFEDA7 0%, #FFC90A 30%, #D3762D 66%, #FF850E 100%)',color:'#FDD029'},
        {name:'本周隐患数', num: 1, sum: 5, bg: 'linear-gradient(270deg, #FFDE92 0%, #E89D5F 31%, #D3502D 65%, #AE1818 100%)',color:'#FF8500'},
        {name:'隐患整改中', num: 1, sum: 5, bg: 'linear-gradient(270deg, #EFFFF2 0%, #5FE8C0 31%, #2DCED3 67%, #058396 100%)',color:'#00FFDA'},
        {name:'整改完成数', num: 2, sum: 5, bg: 'linear-gradient(270deg, #C3F1FF 0%, #75E2FF 34%, #008CFF 70%, #0C52FF 100%)',color:'#4BCAFF'}
      ]
    };
  },
  methods: {
    selectTime(time) {
      console.log(time);
    },
    updataflyValue(){

    },
    getAllOrganization() {
        // console.log(this.dbox_data);
      // let data  = this.dbox_data
      let data=[
        {
        child:[
          {id: 8,
        name: "公关办",},
          {id: 9,
        name: "情指中心",},
          {id: 10,
        name: "科通科",},
          {id: 11,
        name: "政治安全保卫大队",},
          {id: 12,
        name: "刑事侦查大队",},
          {id: 13,
        name: "巡特警大队",},
          {id: 14,
        name: "食药环侦大队",},
          {id: 15,
        name: "交通警察大队",},
          {id: 16,
        name: "禁毒大队",},
        ],
        id: 2,
        name: "公安局",
        },{
        child:[
        ],
        id: 3,
        name: "综合执法局",},
        {
        child:[

        ],
        id: 4,
        name: "应急管理局",},
        {
        child:[

        ],
        id: 5,
        name: "自然资源和规划局",},
        {
        child:[
          {id: 17,
        name: "河湖中心",},
          {id: 18,
        name: "水行政执法",},
        ],
        id: 6,
        name: "水利局",},
        {
        child:[

        ],
        id: 7,
        name: "生态办",},
      ]
      this.selectList = this.getTreeData(data);
      console.log(this.selectList);
      // this.organId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
  },
  mounted() {
    this.getAllOrganization()
  },
};
</script>

<style lang="scss" scoped>
.number-of-tasks{
  width: 100%;
  .out{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .selectBox {
      text-align: left;
      margin:0 0 22px 0;
      position: relative;
      .dec{
        position: absolute;
        top: 7px;
        left: 10px;
        z-index: 100;
        font-size: 16px;
        color: #00BAFF;
      }
      .dec2{
        position: absolute;
        top: 7px;
        left: 29px;
        width: 1px;
        height: 20px;
        background: linear-gradient(180deg, rgba(54,124,255,0) 0%, #4CB4FF 51%, rgba(54,124,255,0) 100%);
        z-index: 100;
      }
      ::v-deep{
        .el-select {
          .el-input__inner{
            padding-left: 36px;
            width: 204px;
            height: 32px;
            background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
            backdrop-filter: blur(4px);
            font-size: 16px;
            font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
            font-weight: 400;
            color: #65ABE9;
            line-height: 24px;
          }
          .el-input .el-select__caret{
            line-height: 0;
          }
        }
          .el-cascader .el-input {
            .el-input__inner{
            padding-left: 36px;
            width: 190px;
            height: 32px;
            background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
            backdrop-filter: blur(4px);
            font-size: 16px;
            font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
            font-weight: 400;
            color: #65ABE9;
            line-height: 24px;
          }
          .el-input .el-select__caret{
            line-height: 0;
          }
          } 
      }
    }
    .top{
      margin-bottom: 26px;
      .left{
        width: 56%;
        .text{
          font-size: 16px;
          font-family: AppleSystemUIFont;
          color: #DAE9FF;
        }
        .num{
          width: 150px;
          .one-num{
            width: 24px;
            height: 40px;
            background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(113, 153, 242, 0.44), rgba(60, 91, 166, 0)) 1 1;
            font-family: 'UnidreamLED';
            font-size: 30px;
            color: #00FFFF;
            line-height: 40px;
            text-align: center;
          }
        }
      }
      .right{
        text-align: center;
      }
    }
    .bottom{
      padding: 0 8px 0 0;
      flex: 1;
      .slider-list{
        width: 100%;
        .slider{
          padding-bottom: 22px;
          width: 100%;
          .tiao{
            background: rgba(76,118,193,0.38);
            height: 5px;
            position: relative;
            .dec-circular{
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #DFFFFE;
              position: absolute;
              top: -1.5px;
              box-shadow: 0px 0px 16px #fff;
            }
            .have{
              height: 100%;
            }
          }
        }
        .one-scroll-text{
          padding: 0 0 12px 0;
          width: 100%;
          .one-scroll-text-left{
            font-size: 14px;
            font-family: AppleSystemUIFont;
            color: #FFFFFF;
          }
          .one-scroll-text-right{
            font-size: 26px;
            font-family: 'DINAlternate-Bold', 'DINAlternate';
            font-weight: bold;
            color: #00FFFF;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>