<template>
  <ModuleBox title="设备管理" width="100%" height="29.5vh" class="number-of-tasks">
    <div class="out">
      <div style="width: 100%">
        <!-- 头部总数展示 -->
        <div class="top hwb-flex">
          <!-- 左侧任务次数统计 -->
          <div class="left hwb-flex">
            <div class="text">今日在线设备</div>
            <div class="num hwb-flex">
              <div class="one-num">0</div>
              <div class="one-num">0</div>
              <div class="one-num">0</div>
              <div class="one-num">0</div>
              <div class="one-num">2</div>
            </div>
            <div class="text">台</div>
          </div>
          <!-- 右侧时间筛选 -->
          <div class="right mr2">
            <TimeSelect :timeHasSelect="3" @on-selectTime="selectTime" />
          </div>
        </div>
        <!-- 图表 -->
        <div class="chart hwb-flex">
          <!-- 中间竖线装饰 -->
          <!-- <div class="l-dec"></div> -->
          <div></div>
          <!-- 饼图 -->
          <div id="pata-push" class="left"></div>
          <!-- 文字描述 -->
          <div class="right">
            <!-- 一条数据 -->
            <div class="one-data hwb-flex" v-for="(item,index) in data" :key="index">
              <div class="dec" :style="{backgroundColor:item.color}"></div>
              <div class="text" :style="{color:item.color}">{{item.name}}</div>
              <div class="sum" :style="{color:item.color}">{{item.num}}</div>
              <div class="text-l">台</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="showDetails" :title="title" :width="900" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValue" />
    </Dialog>
  </ModuleBox>
</template>

<script>
let chart
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import { Chart } from "@antv/g2";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import API from "@/api";
import highcharts from "highcharts";
export default {
  components: { ModuleBox, TimeSelect, Table, Dialog },
  data() {
    return {
      data: [
        { name: '雷达探测', num: 1, color: '#59EFA9' },
        { name: '光电探测', num: 1, color: '#FFB21C' },
        { name: '频谱探测', num: 1, color: '#FF563B' },
        { name: '频谱定向反制器', num: 1, color: '#4FA6F4' },
        { name: '手持反制枪', num: 4, color: '#7B87F9' },
        { name: '手持反制盾', num: 4, color: '#0B87F9' },
      ],
      dataList: [
        { title: '设备名称', name: 'name', },
        { title: '正常', name: 'type' },
        { title: '维修', name: 'a' },
        { title: '损坏', name: 'b' },
        { title: '保养', name: 'c' },
      ],
      dataValue: [],
      showDetails: false,
      title: '',
      value: ''
    };
  },
  methods: {
    details(title, value) {
      this.title = title
      this.value = value
      this.showDetails = true
      console.log(this.showDetails);
    },
    closeDetails() {
      this.showDetails = false
    },
    selectTime(v) {
      if (v == 1) {
        this.data = [
          { name: '雷达探测', num: 1, color: '#59EFA9' },
          { name: '光电探测', num: 1, color: '#FFB21C' },
          { name: '频谱探测', num: 1, color: '#FF563B' },
          { name: '频谱定向反制器', num: 1, color: '#4FA6F4' },
          { name: '手持反制枪', num: 0, color: '#7B87F9' },
          { name: '手持反制盾', num: 0, color: '#0B87F9' },
        ]
        // chart.destroy()
        this.drawChart()
      } else if (v == 2) {
        this.data = [
          { name: '雷达探测', num: 1, color: '#59EFA9' },
          { name: '光电探测', num: 1, color: '#FFB21C' },
          { name: '频谱探测', num: 1, color: '#FF563B' },
          { name: '频谱定向反制器', num: 1, color: '#4FA6F4' },
          { name: '手持反制枪', num: 1, color: '#7B87F9' },
          { name: '手持反制盾', num: 1, color: '#0B87F9' },
        ]
        // chart.destroy()
        this.drawChart()
      } else if (v == 3) {
        this.data = [
          { name: '雷达探测', num: 1, color: '#59EFA9' },
          { name: '光电探测', num: 1, color: '#FFB21C' },
          { name: '频谱探测', num: 1, color: '#FF563B' },
          { name: '频谱定向反制器', num: 1, color: '#4FA6F4' },
          { name: '手持反制枪', num: 3, color: '#7B87F9' },
          { name: '手持反制盾', num: 3, color: '#0B87F9' },
        ]
        // chart.destroy()
        this.drawChart()
      } else if (v == 4) {
        this.data = [
          { name: '雷达探测', num: 1, color: '#59EFA9' },
          { name: '光电探测', num: 1, color: '#FFB21C' },
          { name: '频谱探测', num: 1, color: '#FF563B' },
          { name: '频谱定向反制器', num: 1, color: '#4FA6F4' },
          { name: '手持反制枪', num: 4, color: '#7B87F9' },
          { name: '手持反制盾', num: 4, color: '#0B87F9' },
        ]
        // chart.destroy()
        this.drawChart()
      }
    },
    drawChart() {
      let data = []
      this.data.forEach(element => {
        data.push({
          name: element.name,
          "y": element.num,
          "percent": 0.09375,
          "h": 0,
          "bfb": 0
        })
      });


      let quantity = 0; // 总数
      this.data.forEach((item) => {
        quantity += item.y;
      });
      this.data.forEach((item) => {
        item.bfb = parseInt((item.y / quantity) * 100);
        item.h = item.bfb * 0.5 >= 40 ? 40 : item.bfb * 0.5
        // item.h = parseInt(0.86 * item.bfb); // 最高高度60，根据比例渲染高度
      });
      // 修改3d饼图绘制过程
      var each = highcharts.each,
        round = Math.round,
        cos = Math.cos,
        sin = Math.sin,
        deg2rad = Math.deg2rad;
      highcharts.wrap(
        highcharts.seriesTypes.pie.prototype,
        "translate",
        function (proceed) {
          proceed.apply(this, [].slice.call(arguments, 1));
          // Do not do this if the chart is not 3D
          if (!this.chart.is3d()) {
            return;
          }
          var series = this,
            chart = series.chart,
            options = chart.options,
            seriesOptions = series.options,
            depth = seriesOptions.depth || 0,
            options3d = options.chart.options3d,
            alpha = options3d.alpha,
            beta = options3d.beta,
            z = seriesOptions.stacking
              ? (seriesOptions.stack || 0) * depth
              : series._i * depth;
          z += depth / 2;
          if (seriesOptions.grouping !== false) {
            z = 0;
          }
          each(series.data, function (point) {
            var shapeArgs = point.shapeArgs,
              angle;
            point.shapeType = "arc3d";
            var ran = point.options.h;
            shapeArgs.z = z;
            shapeArgs.depth = depth * 0.75 + ran;
            shapeArgs.alpha = alpha;
            shapeArgs.beta = beta;
            shapeArgs.center = series.center;
            shapeArgs.ran = ran;
            angle = (shapeArgs.end + shapeArgs.start) / 2;
            point.slicedTranslation = {
              translateX: round(
                cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
              translateY: round(
                sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
            };
          });
        }
      );
      (function (H) {
        H.wrap(
          highcharts.SVGRenderer.prototype,
          "arc3dPath",
          function (proceed) {
            // Run original proceed method
            var ret = proceed.apply(this, [].slice.call(arguments, 1));
            ret.zTop = (ret.zOut + 0.5) / 100;
            return ret;
          }
        );
      })(highcharts);
      highcharts.chart('pata-push', {
        chart: {
          animation: true,
          backgroundColor: "none",
          type: "pie", //饼图
          margin: [0, 0, 0, 0],
          options3d: {
            enabled: true, //使用3d功能
            alpha: 50, //延y轴向内的倾斜角度
            beta: 0,
          },

          events: {
            load: function () {
              var each = highcharts.each,
                points = this.series[0].points;
              each(points, function (p, i) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran,
                });
              });
            },
          },
        },
        legend: {
          enabled: false, // 关闭图例
          align: "right", //水平方向位置
          verticalAlign: "top", //垂直方向位置
          layout: "vertical",
          x: 20,
          y: 30,
          symbolWidth: 1,
          symbolHeight: 1,
          symbolRadius: "50%", // 修改成圆
          itemMarginBottom: 8,
          useHTML: true,
          itemStyle: {
            color: "#f4f4f6",
            fontSize: 12,
          },
        },
        title: {
          // enabled: false,
          text: "",
        },
        subtitle: {
          floating: true,
          text: "在线设备",
          align: 'center',
          verticalAlign: "bottom",
          x:0,
          y:30,
          style: {
            color: '#c0d8fa'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: false, // 禁用点击
            cursor: "pointer",
            depth: 20,
            showInLegend: false,
            size: "78%", // 外圈直径大小
            innerSize: 0, // 内圈直径大小
            center: ["50%", "55%"],
            colors: [
              "#a2b355",
              "#1c7dd1",
              "#7d30cf",
              "#48cd82",
              "#d79163",
              "#e9483b",
            ],
            dataLabels: {
              useHTML: true,
              enabled: false, //是否显示饼图的线形tip
              distance: 5,
              borderColor: '#007acc',
              align: "center",
              position: "right",
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              color: "#ffffff",
              style: {
                textOutline: "none",
                fontSize: 14,
              },
            },
          },
        },
        credits: {
          enabled: false, // 禁用版权信息
        },
        series: [
          {
            type: "pie",
            name: "数量",
            data: data,
          },
        ],
      });




      // chart = new Chart({
      //   container: 'pata-push',
      //   autoFit: true,
      //   height: 200,
      // });
      // chart.legend(false); // 关闭图例
      // chart.data(this.data);
      // chart.coordinate('polar');
      // chart.axis(false);
      // chart.tooltip({
      //   showMarkers: false
      // });
      // chart.scale('num', {
      //   formatter: (val) => {
      //     val = ((val/12) * 100 ).toFixed(0)+ '%';
      //     return val;
      //   },
      // });
      // chart.interaction('element-highlight');
      // chart
      //   .interval()
      //   .position('name*num')
      //   .color('name',['#59EFA9','#FFB21C','#FF563B','#4FA6F4','#7B87F9','#0B87F9'])
      //   .style({
      //     lineWidth: 1,
      //     stroke: '#fff',
      //   });
      // chart.render();
    }
  },
  mounted() {
    this.drawChart()
  },
};
</script>

<style lang="scss" scoped>
.number-of-tasks {
  width: 100%;

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .top {
      margin-bottom: 15px;

      .left {
        width: 66%;

        .text {
          font-size: 16px;
          font-family: AppleSystemUIFont;
          color: #DAE9FF;
        }

        .num {
          width: 100px;
          .one-num {
            width: 16px;
            height: 27px;
            background: linear-gradient(180deg, #01a0ff 0%, #01a0ff 50%, #1784FA 50%, #1784FA 100%);
            font-family: Rajdhani-SemiBold, Rajdhani;
            border-radius: 1px;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 27px;
            text-align: center;
          }
        }
      }

      .right {
        text-align: center;
      }
    }

    .chart {
      width: 100%;
      height: 200px;
      position: relative;

      .l-dec {
        position: absolute;
        top: 36px;
        left: 190px;
        width: 1px;
        height: 162px;
        background: linear-gradient(180deg, rgba(54, 124, 255, 0) 0%, #C6DAFF 51%, rgba(54, 124, 255, 0) 100%);
      }

      .right {
        width: 50%;

        .one-data {
          margin-bottom: 6px;

          .dec {
            width: 10px;
            height: 10px;
            // border-radius: 50%;
          }

          .text {
            font-size: 14px;
            width: 126px;
          }

          .sum {
            font-size: 26px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            padding: 0 10px 0 0px;
          }

          .text-l {
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .left {
        width: 40%;
        height: 150px;
      }
    }
  }
}
</style>