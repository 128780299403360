export default {
  getAllOrganization() {
    let data  = this.dbox_data
    this.allOrganizationselectList = this.getTreeData(data);
  },
  getTreeData(data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].child && data[i].child.length < 1) {
        // children若为空数组，则将children设为undefined
        data[i].child = undefined;
      } else {
        // children若不为空数组，则继续 递归调用 本方法
        if (data[i].child && data[i].child.length >= 1) {
          this.getTreeData(data[i].child);
        }
      }
    }
    return data;
  }
}
