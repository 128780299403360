<template>
  <div class="cpt-draw">
    <!-- 搜索 -->
    <div class="select">
      <el-input
        style="width: 350px"
        placeholder="请输入要素名称"
        v-model="selectData"
        clearable
      >
        <el-button
          @click="selectPoint"
          slot="append"
          icon="el-icon-search"
          style="padding: 0"
        ></el-button>
      </el-input>
    </div>
    <!-- 要素图标选择展示哪些类型 -->
    <div class="icons-list_box">
      <el-tooltip
        class="item"
        style="margin-right: 4px"
        content="全要素"
        placement="top-start"
      >
        <div class="icon-gjx" @click="isGjx = !isGjx">
          <div class="iconfont icon-quanyaosu1"></div>
        </div>
      </el-tooltip>
      <!-- 图标 -->
      <div
        v-if="isGjx"
        style="display: flex; flex-wrap: nowrap; justify-content: flex-end"
      >
        <el-tooltip
          :class="item.isShow ? 'show' : 'hide'"
          v-for="item in elementTypeList"
          :key="item.id"
          class="item"
          :content="item.typeName"
          placement="top-start"
        >
          <div class="icon-gjx" @click="stateChange(item)">
            <div class="iconfont" :class="icon[item.id]"></div>
          </div>
        </el-tooltip>
      </div>
    </div>

    <!-- 创建航线 -->
    <div class="create-fiyline" @click="openDrawFlyLinePopup">
      <el-tooltip content="创建航线" placement="right">
        <div class="iconfont icon-yulanhangxian-moren"></div>
      </el-tooltip>
    </div>
    <!-- 创建航线弹框 -->
    <!-- 表单整理框 -->
    <el-dialog
      title="手动规划"
      :visible.sync="isShow"
      width="400px"
      top="2vh"
      :close-on-click-modal="false"
      :modal="false"
      class="pub_dialog"
      :before-close="beforeClose"
    >
      <div class="headBtn">
        <div class="linear-btn" @click="redcurr()">上一航点</div>
        <div class="airwayNum">
          <span class="current">{{
            edit_form.points[0].lon === 0 && edit_form.points[0].lat === 0
              ? 0
              : action.curr_index + 1
          }}</span>
          /
          <span class="total">{{
            edit_form.points[0].lon === 0 && edit_form.points[0].lat === 0
              ? 0
              : edit_form.points.length
          }}</span>
        </div>
        <div class="linear-btn" @click="addcurr()">下一航点</div>
      </div>
      <el-form
        ref="edit_form"
        :rules="rules"
        :model="edit_form"
        class="linear-form vertical"
      >
        <el-form-item label="航线名称" prop="flightName">
          <el-input
            v-model="edit_form.flightName"
            placeholder="请输入航线名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="航线速度" prop="speed">
          <el-input
            v-model="edit_form.speed"
            placeholder="请输入航线速度"
            clearable
          >
            <i slot="suffix" class="speedSlot">m/s</i>
          </el-input>
        </el-form-item>

        <el-form-item label="目标位置" prop="destination">
          <el-input
            v-model="edit_form.destination"
            placeholder="请输入目标位置"
            clearable
            class="destination"
          />
        </el-form-item>

        <!-- <el-form-item label="航线状态" prop="status" v-if="isUpdate">
                <el-radio-group v-model="edit_form.status">
                <el-radio-button :label="1">启用</el-radio-button>
                <el-radio-button :label="0">禁用</el-radio-button>
                </el-radio-group>
            </el-form-item> -->

        <el-form-item prop="points">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div style="width: 48%">
              <div style="color: #9fc4ff; margin-bottom: 4px">
                <span style="color: #f56c6c">*</span> 经度
              </div>
              <el-input-number
                v-model="edit_form.points[action.curr_index].lon"
                placeholder="经度"
                :min="-180"
                :max="180"
                clearable
              ></el-input-number>
            </div>
            <div style="width: 48%">
              <div style="color: #9fc4ff; margin-bottom: 4px">
                <span style="color: #f56c6c">*</span> 纬度
              </div>
              <el-input-number
                v-model="edit_form.points[action.curr_index].lat"
                placeholder="纬度"
                :min="-90"
                :max="90"
                clearable
              ></el-input-number>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="高度" prop="alt">
          <el-input-number
            v-model="edit_form.points[action.curr_index].alt"
            placeholder="高度"
            :min="0"
            clearable
          />
        </el-form-item>

        <el-form-item label="偏航角" prop="pitch">
          <el-input-number
            v-model="edit_form.points[action.curr_index].pitch"
            :min="-90"
            :max="90"
            class="aainput"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="俯仰角" prop="gimbalPitch">
          <el-input-number
            v-model="edit_form.points[action.curr_index].gimbalPitch"
            :min="-90"
            :max="90"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="航点动作">
          <div
            class="linear-btn"
            style="width: 100%"
            @click="() => (action.visible = true)"
          >
            {{
              this.edit_form.points[this.action.curr_index].actions.length
            }}个动作
          </div>
        </el-form-item>

        <div class="form-submit-item">
          <div @click="handleSave" class="linear-btn">保存</div>
          <div
            v-if="this.edit_form.points.length > 2"
            @click="delcurr"
            class="linear-btn"
          >
            删除
          </div>
        </div>
      </el-form>
    </el-dialog>
    <!-- 动作弹框 -->
    <el-dialog
      title="新增航点动作"
      :visible.sync="action.visible"
      width="316px"
      :close-on-click-modal="false"
      :modal="false"
      class="pub_dialog_hddz"
    >
      <div class="form-submit-item">
        <el-dropdown style="width: 100%" trigger="click">
          <div class="form-submit-item">
            <div class="linear-btn" style="width: 100%">
              增加<i class="el-icon-plus el-icon--right"></i>
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in action.types"
              :key="index"
            >
              <div @click="handle_add_action(item)">{{ item.label }}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="list-box">
        <div
          class="action-item"
          v-for="(item, index) in edit_form.points[action.curr_index].actions"
          :key="index"
        >
          <div class="flex">
            <div class="item-label">{{ item.label }}</div>
            <div
              v-if="!item.input"
              @click="handle_delete_action(index, item)"
              class="iconfont icon-shanchu"
              title="删除"
            ></div>
          </div>

          <div class="flex" v-if="item.input">
            <div class="item-input">
              <el-input-number
                v-model="item.actionParam"
                :is="item.input"
                :min="item.min"
                :max="item.max"
              ></el-input-number>
            </div>
            <div
              @click="handle_delete_action(index, item)"
              class="iconfont icon-shanchu"
              title="删除"
            ></div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 地球容器 -->
    <div id="draw-container" ref="drawRef"></div>
  </div>
</template>

<script>
let entity;
import { mapGetters } from "vuex";
import API from "@/api";
import { transformCartesian2WGS84 } from "@/utils/cesium";
import { nanoid } from "nanoid";
import { initMap } from "@/utils/cesium.js";
export default {
  computed: {
    ...mapGetters(["my_layers_data"]),
  },
  props: {
    updateElement: {
      // 用来判断是否更新全要素
      type: Number,
      default: 0,
    },
    closeFlyLine: {
      // 用来关闭航线规划
      type: Number,
      default: 0,
    },
    editFlyLineData: {
      // 编辑航线传进来的对象
      type: Object,
      default: {},
    },
    routeSimulationData: {
      // 飞行模拟参数传进来的对象
      type: Object,
      default: {},
    },
  },
  data() {
    let points = (rule, value, callback) => {
      // 经纬度验证
      for (let index = 0; index < value.length; index++) {
        if (value[index].lon === undefined || value[index].lat === undefined) {
          this.errorIndex.latAndlon = index;
          break;
        } else {
          this.errorIndex.latAndlon = -1;
        }
      }
      if (
        value[this.action.curr_index].lon === undefined ||
        value[this.action.curr_index].lat === undefined
      ) {
        callback(new Error("经纬度信息不能为空，请补充完整该航点经纬度信息"));
      }
      callback();
    };
    let altValidator = (rule, value, callback) => {
      // 高度验证
      for (let index = 0; index < this.edit_form.points.length; index++) {
        if (this.edit_form.points[index].alt === undefined) {
          this.errorIndex.alt = index;
          break;
        } else {
          this.errorIndex.alt = -1;
        }
      }
      if (this.edit_form.points[this.action.curr_index].alt === undefined) {
        callback(new Error("请输入高度"));
      }
      callback();
    };
    let pitchValidator = (rule, value, callback) => {
      // 偏航角验证
      for (let index = 0; index < this.edit_form.points.length; index++) {
        if (this.edit_form.points[index].pitch === undefined) {
          this.errorIndex.pitch = index;
          break;
        } else {
          this.errorIndex.pitch = -1;
        }
      }
      if (this.edit_form.points[this.action.curr_index].pitch === undefined) {
        callback(new Error("请输入偏航角"));
      }
      callback();
    };
    let gimbalPitchValidator = (rule, value, callback) => {
      // 俯仰角验证
      for (let index = 0; index < this.edit_form.points.length; index++) {
        if (this.edit_form.points[index].gimbalPitch === undefined) {
          this.errorIndex.gimbalPitch = index;
          break;
        } else {
          this.errorIndex.gimbalPitch = -1;
        }
      }
      if (
        this.edit_form.points[this.action.curr_index].gimbalPitch === undefined
      ) {
        callback(new Error("请输入俯仰角"));
      }
      callback();
    };
    return {
      viewer: null, // 地球实例
      // ---------------------------------------------------------------要素图标相关
      tagging: null, // 存储渲染在地球上的要素点
      baseUrl: null, // 基本请求路径
      selectData: "", // 用户搜索参数
      elementList: [], // 全要素渲染数据
      elementTypeList: [], // 全要素类型列表
      isGjx: false, // 控制全要素类型选择图标是否展示
      icon: {
        // 要素类型图标
        10: "icon-gaosulukou",
        11: "icon-jiudianmenkou",
        12: "icon-jingwuzhan",
        13: "icon-jiedaojiankong",
        14: "icon-qiaoliang",
      },
      // ---------------------------------------------------------------绘制航线相关
      handler: null, // 地球点击事件
      isShow: false, // 控制绘制航线弹框是否显示
      isUpdate: false, // 是否是在修改航线
      viewList: null, // 存储空域数据
      regionalData: null, //存储区域渲染实例，便于控制隐藏显示
      errorIndex: {
        // 保存是否有航点信息不完整
        latAndlon: -1, //经纬度
        alt: -1, // 高度
        pitch: -1, // 偏航角
        gimbalPitch: -1, // 俯仰角
      },
      rules: {
        // 校验规则
        flightName: [
          { required: true, message: "请输入航线名称", trigger: "blur" },
        ],
        speed: [
          { required: true, message: "请输入航线速度", trigger: "blur" },
          {
            pattern:
              /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确的航线速度格式,可保留两位小数,但不可为0",
          },
          // { type: "number", message: "速度只能为数字", trigger: "blur" },
        ],
        destination: [
          { required: true, message: "请输入目标位置", trigger: "blur" },
        ],
        // status: [
        //   { required: true, message: "请输入航线状态", trigger: "blur" },
        // ],
        points: [{ required: true, validator: points, trigger: "change" }],
        alt: [{ required: true, validator: altValidator, trigger: "change" }],
        // 用来验证偏航角俯仰角的必填，然后说去掉了，线注释吧
        // pitch: [
        // { required: true, validator: pitchValidator, trigger: 'change' }
        // ],
        // gimbalPitch: [
        // { required: true, validator: gimbalPitchValidator, trigger: 'change' }
        // ]
      },
      linelist: null,
      visible: {
        edit: false,
        add: true,
        notice: false,
        addhd: false,
        show: false,
        readonly: false,
      },
      edit_form: {
        // 存储选择的航点信息
        flightName: null,
        destination: "",
        speed: 5,
        status: 1,
        points: [
          {
            lon: 0,
            lat: 0,
            alt: 120,
            pitch: 0,
            gimbalPitch: 0,
            actions: [],
            uuid: null,
          },
        ],
      },
      action: {
        // 存储每个航点的动作
        visible: false,
        types: [
          {
            label: "悬停(s)",
            input: "el-input-number",
            min: 1,
            max: 32000,
            actionType: "STAY",
            actionParam: 10,
          },
          {
            label: "拍照",
            actionType: "START_TAKE_PHOTO",
            actionParam: 0,
          },
          {
            label: "开始录像",
            actionType: "START_RECORD",
            actionParam: 0,
          },
          {
            label: "停止录像",
            actionType: "STOP_RECORD",
            actionParam: 0,
          },
        ],
        curr_index: 0,
      },
      // ---------------------------------------------------------------飞行模拟相关
      saveFlyLine: null, // 存储飞行模拟航线，便于销毁
      uav: null, // 存储飞机模型，便于销毁
      index: 0, // 记住下标
      lerp_arr: [], // 飞行轨迹存储
      timer: null,
    };
  },
  methods: {
    init_viewer() {
      this.viewer = window.viewer = initMap(
        this.my_layers_data,
        "draw-container"
      );
    },
    //#region
    // ------------------------------------------------------------------------------------全要素图标函数
    async selectPoint() {
      // 搜索按钮  定位到用户搜索的点
      let { data } = await API.POI.List({ name: this.selectData });
      if (data.length === 0) {
        this.$message.warning("该点不存在");
      } else {
        this.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            data[0].longitude,
            data[0].latitude,
            3000
          ),
        });
      }
    },
    stateChange(data) {
      // 用户点击了要素类型的某个类型
      this.elementTypeList.forEach((item) => {
        if (item.id === data.id) {
          item.isShow = !data.isShow;
          if (item.isShow) {
            // 展示该类型图标
            this.tagging?.entities.values.forEach((item) => {
              if (item.id.indexOf(`tagging2-list-${data.id}`) > -1) {
                item.show = true;
              }
            });
          } else {
            // 隐藏该类型图标
            this.tagging?.entities.values.forEach((item) => {
              if (item.id.indexOf(`tagging2-list-${data.id}`) > -1) {
                item.show = false;
              }
            });
          }
        }
      });
    },
    async getTypeList() {
      // 获取要素类型
      let res = await API.POI.getType({
        pageNum: 1,
        pageSize: 100,
      });
      if (res.data.records) {
        res.data.records.forEach((item) => {
          item.isShow = true;
        });
      }
      s.elementTypeList = res.data.records;
    },
    async async_get_list() {
      // 获取全要素信息并渲染到地球上
      let { data } = await API.POI.List();
      this.elementList = data;
      this.tagging = new Cesium.CustomDataSource("tagging2-list");
      this.viewer.dataSources.add(this.tagging);

      data.map((item) => {
        if (!item.longitude && !item.latitude) {
          return;
        }
        const entity = this.tagging.entities.getById(
          `tagging2-list-${item.typeId}-${item.id}`
        );
        const position = Cesium.Cartesian3.fromDegrees(
          Number(item.longitude),
          Number(item.latitude)
        );

        if (entity) {
          entity.position = position;
        } else {
          this.tagging.entities.add({
            id: `tagging2-list-${item.typeId}-${item.id}`,
            position: position.clone(),
            label: {
              text: item.elementName,
              font: "normal 16px MicroSoft YaHei",
              style: Cesium.LabelStyle.FILL_AND_OUTLINE,
              outlineColor: Cesium.Color.RED,
              outlineWidth: 2,
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(6, -46),
            },
            billboard: {
              width: 44,
              height: 44,
              image: this.baseUrl + item.ico,
              show: true,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(0, 0), // default: (0, 0)
              eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
            },
            properties: {
              metadata: item,
            },
          });
        }
      });

      const pinBuilder = new Cesium.PinBuilder();
      const pin50 = pinBuilder
        .fromText("50+", Cesium.Color.RED, 100)
        .toDataURL();
      const pin40 = pinBuilder
        .fromText("40+", Cesium.Color.ORANGE, 100)
        .toDataURL();
      const pin30 = pinBuilder
        .fromText("30+", Cesium.Color.YELLOW, 48)
        .toDataURL();
      const pin20 = pinBuilder
        .fromText("20+", Cesium.Color.GREEN, 48)
        .toDataURL();
      const pin10 = pinBuilder
        .fromText("10+", Cesium.Color.BLUE, 60)
        .toDataURL();

      const singleDigitPins = new Array(8);
      for (let i = 0; i < singleDigitPins.length; ++i) {
        singleDigitPins[i] = pinBuilder
          .fromText(`${i + 2}`, Cesium.Color.VIOLET, 48)
          .toDataURL();
      }

      this.tagging.clustering.enabled = true;
      this.tagging.clustering.pixelRange = 15;
      this.tagging.clustering.minimumClusterSize = 3;

      this.tagging.clustering.clusterEvent.addEventListener(
        (clusteredEntities, cluster) => {
          cluster.label.show = false;
          cluster.billboard.show = true;
          cluster.billboard.id = cluster.label.id;
          cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;

          if (clusteredEntities.length >= 50) {
            cluster.billboard.image = pin50;
          } else if (clusteredEntities.length >= 40) {
            cluster.billboard.image = pin40;
          } else if (clusteredEntities.length >= 30) {
            cluster.billboard.image = pin30;
          } else if (clusteredEntities.length >= 20) {
            cluster.billboard.image = pin20;
          } else if (clusteredEntities.length >= 10) {
            cluster.billboard.image = pin10;
          } else {
            cluster.billboard.image =
              singleDigitPins[clusteredEntities.length - 2];
          }
        }
      );
    },
    //#endregion
    // #region
    // ------------------------------------------------------------------------------------绘制航线函数
    // 获取所有空域数据
    async async_get_region() {
      if (this.regionalData) {
        this.regionalData.entities.show = true;
      } else {
        let res = await API.AIRSPACE.selectLikeList({
          ...this.search,
          status: 1,
          pageNo: 1,
          pageSize: 10000,
        });
        if (res.status === 1) {
          this.viewList = res.data.data;
          this.add();
        } else {
          this.$message.error(res.message);
        }
      }
    },
    add() {
      this.regionalData = new Cesium.CustomDataSource("regional-data");
      this.viewer.dataSources.add(this.regionalData);
      this.viewList.map((item, index) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (positions.length > 3) {
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (positions.length % 2 !== 0) return;
          this.regionalData.entities.add({
            id: `regional-data-${index}`,
            name: "Red polygon on surface",
            position,
            label: {
              text: item.name,
              font: "20px monospace",
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(5, 5),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              fillColor: Cesium.Color.WHITE,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                200000
              ),
            },
            polygon: {
              hierarchy,
              material:
                item.typeId === 1
                  ? Cesium.Color.fromCssColorString("#00FF11").withAlpha(0.2)
                  : item.typeId === 2
                  ? Cesium.Color.RED.withAlpha(0.2)
                  : Cesium.Color.YELLOW.withAlpha(0.2),
              outline: true,
              outlineColor: Cesium.Color.BLACK,
            },
          });
        }
      });

      this.regionalData.entities.show = false;
    },
    edit(value) {
      // 如果是编辑航线，把传过来的值赋进来
      this.action.curr_index = 0;
      this.init_form(value);
      let { points } = JSON.parse(value.flightCourseJson);

      this.edit_form.points = points;
    },
    delcurr() {
      // 删除某个航点
      let index = this.action.curr_index;
      if (index > 0) {
        this.action.curr_index--;
        this.edit_form.points.splice(index, 1);
        this.handler.removeEditPoint(index);
      } else {
        this.$el_message("无法删除首点");
      }
    },
    init_form(data) {
      Object.keys(this.edit_form).forEach((key) => {
        this.edit_form[key] = data[key];
      });
    },
    leftOnclick(value) {
      const cartesian3 = value.positions[value.positions.length - 1];
      // 判断是否点击修改某点
      if (!isNaN(value.index)) {
        this.action.curr_index = value.index;
        // this.move();
      } else {
        const aa = transformCartesian2WGS84(cartesian3);
        const first = this.edit_form.points[0];
        const last = this.edit_form.points[this.edit_form.points.length - 1];
        // 判断双击事件重复录入航点
        if (aa.lng === last.lon && aa.lat === last.lat) {
        } else {
          // 判断第一个点，第一次点击航点覆盖默认点位信息
          if (first.lon === 0 && first.lat === 0) {
            // console.log("覆盖");
            this.edit_form.points[0].lon = aa.lng;
            this.edit_form.points[0].lat = aa.lat;
            this.edit_form.points[0].uuid = nanoid();
          } else {
            // console.log("新增");
            this.action.curr_index++;
            this.edit_form.points.push({
              lon: aa.lng,
              lat: aa.lat,
              alt: this.edit_form.points[this.action.curr_index - 1].alt,
              pitch: 0,
              gimbalPitch: 0,
              actions: [],
              uuid: nanoid(),
            });
          }
        }
      }
    },
    rigthOnclick(data) {
      if (data.positions.uuid) {
        this.action.curr_index = 0;
        this.edit_form.points = [
          {
            lon: 0,
            lat: 0,
            alt: 120,
            pitch: 0,
            gimbalPitch: 0,
            actions: [],
          },
        ];
      } else {
        if (this.edit_form.points.length <= 1) {
          this.$el_message("无法删除首点");
        } else {
          this.action.curr_index--;
          this.edit_form.points.pop();
        }
      }
    },
    move(data) {
      // 移动航点函数
      if (!isNaN(data.index)) {
        const aa = transformCartesian2WGS84(data.move_position);
        this.action.curr_index = data.index;
        this.edit_form.points[data.index].lon = aa.lng;
        this.edit_form.points[data.index].lat = aa.lat;
      }
    },
    openDrawFlyLinePopup() {
      // 打开绘制航线弹框时做的一些事

      if (this.isUpdate) {
        // 判断是否是修改航线，如果是，渲染航线
        let points = [...this.edit_form.points];
        let poi = [];
        points.map((item) => {
          poi.push(Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 0));
        });

        this.handler = new MMCM.DrawAirwayHandler({
          viewer: this.viewer,
          positions: poi,
        });
        this.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            points[0].lon,
            points[0].lat,
            5000
          ),
          complete: () => {
            this.regionalData.entities.show = true;
          },
        });
      } else {
        this.handler = new MMCM.DrawAirwayHandler({
          viewer: this.viewer,
        });
        this.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            120.67593514717086,
            30.513843958092217,
            5000
          ),
          complete: () => {
            this.regionalData.entities.show = true;
          },
        });
      }
      this.isShow = true;

      this.handler.setInputAction((data) => {
        this.leftOnclick(data);
      }, MMCM.DrawEventType.LEFT_CLICK);

      this.handler.setInputAction((data) => {},
      MMCM.DrawEventType.LEFT_DOUBLE_CLICK);

      this.handler.setInputAction((data) => {
        this.rigthOnclick(data);
      }, MMCM.DrawEventType.RIGHT_CLICK);

      this.handler.setInputAction((data) => {
        this.move(data);
      }, MMCM.DrawEventType.MOUSE_MOVE);
    },
    beforeClose() {
      this.regionalData.entities.show = false;
      // 创建航线弹框关闭时做的一些事
      this.showLine();
      if (this.handler) {
        this.handler.destroy(); // 吧点击的功能也去掉选点的功能也去掉
      }
      this.isUpdate = false;
      this.isShow = false;
      this.edit_form = {
        // 把默认的初始值赋回去
        flightName: null,
        destination: "",
        speed: 5,
        status: 1,
        points: [
          {
            lon: 0,
            lat: 0,
            alt: 120,
            pitch: 0,
            gimbalPitch: 0,
            actions: [],
            uuid: null,
          },
        ],
      };
      this.action = {
        // // 把默认的初始值赋回去
        visible: false,
        types: [
          {
            label: "悬停(s)",
            input: "el-input-number",
            min: 1,
            max: 32000,
            actionType: "STAY",
            actionParam: 10,
          },
          {
            label: "拍照",
            actionType: "START_TAKE_PHOTO",
            actionParam: 0,
          },
          {
            label: "开始录像",
            actionType: "START_RECORD",
            actionParam: 0,
          },
          {
            label: "停止录像",
            actionType: "STOP_RECORD",
            actionParam: 0,
          },
        ],
        curr_index: 0,
      };
    },
    redcurr() {
      // 上个航点
      if (this.action.curr_index > 0) {
        this.action.curr_index--;
      }
    },
    addcurr() {
      // 下个航点
      if (this.action.curr_index < this.edit_form.points.length - 1) {
        this.action.curr_index++;
      }
    },
    handle_add_action(item) {
      this.edit_form.points[this.action.curr_index].actions.push({ ...item });
    },
    handle_delete_action(index, item) {
      this.edit_form.points[this.action.curr_index].actions.splice(index, 1);
    },
    // 计算距离
    transformWGS842Cartesian(position, alt) {
      return position
        ? Cesium.Cartesian3.fromDegrees(
            position.lng,
            position.lat,
            (position.alt = alt || position.alt),
            Cesium.Ellipsoid.WGS84
          )
        : Cesium.Cartesian3.ZERO;
    },
    showLine() {
      // 清除提示框
      const ces = document.getElementsByClassName("cesium-tooltip")[0];
      const body = document.getElementsByTagName("body")[0];
      if (ces) {
        body.removeChild(ces);
      }
    },
    // 保存
    async handleSave() {
      this.$refs["edit_form"].validate(async (valid) => {
        if (this.errorIndex.latAndlon !== -1) {
          this.action.curr_index = this.errorIndex.latAndlon;
        } else if (this.errorIndex.alt !== -1) {
          this.action.curr_index = this.errorIndex.alt;
        } else if (this.errorIndex.pitch !== -1) {
          this.action.curr_index = this.errorIndex.pitch;
        } else if (this.errorIndex.gimbalPitch !== -1) {
          this.action.curr_index = this.errorIndex.gimbalPitch;
        } else if (this.edit_form.points.length <= 1) {
          this.$message.warning("请选择航点");
        } else if (valid) {
          const { date, points, ...rest } = this.edit_form;
          let speed = Number(rest.speed);
          const flightCourse = {
            filename: rest.flightName,
            line: {
              baseSpeed: speed,
            },
            points,
            baseSpeed: speed,
          };
          let distance = points.reduce((total, item, index) => {
            let dist = 0;
            if (points[index + 1]) {
              let cd = Cesium.Cartesian3.distance(
                this.transformWGS842Cartesian({ lng: item.lon, lat: item.lat }),
                this.transformWGS842Cartesian({
                  lng: points[index + 1].lon,
                  lat: points[index + 1].lat,
                })
              );
              dist = cd;
            }
            return total + parseInt(dist);
          }, 0);

          const params = {
            ...rest,
            pointCount: points.length,
            distance,
            time: parseInt(distance / rest.speed),
            flightCourseJson: JSON.stringify(flightCourse),
            speed,
            // issafe: 1,
          };

          let res;
          if (this.isUpdate) {
            res = await API.AIRWAY.updFlightLine({
              ...params,
              id: this.isUpdate,
            });
          } else {
            res = await API.AIRWAY.addFlightLineAdc({
              ...params,
              status: 1,
            });
          }
          if (res.status === 1) {
            this.$el_message(this.isUpdate ? "修改成功" : "创建成功");
            this.$refs["edit_form"].resetFields();
            this.beforeClose();
            this.isUpdate = false;
            this.$refs.listRef.async_get_list();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    //#endregion
    //#region
    // ------------------------------------------------------------------------------------飞行模拟
    // 渲染航线
    renderRoute(val) {
      this.index = 0;
      //------------------------------------------------------航线
      let { points } = JSON.parse(val.flightCourseJson);
      let poi = [];
      points.map((item) => {
        poi.push(Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt));
      });
      this.saveFlyLine = new MMCM.DrawAirwayHandler({
        viewer: this.viewer,
        positions: poi,
        config: {
          isReadonly: true, // 是否可以操作
        },
      });
      // ------------------------------------------------------飞机
      let positions = points.map((item) => {
        return Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt);
      });
      let pos = positions.map(
        (item) => new Cesium.Cartesian3(item.x, item.y, item.z)
      );
      pos.reduce(
        (total, item, index) =>
          index > 0
            ? (total += Cesium.Cartesian3.distance(pos[index - 1], item))
            : total,
        0
      );

      const lerp = (start, end, c) => {
        let arr = [];
        const count = parseInt(`${c}`);
        for (let i = 0; i < count; i++) {
          arr.push(
            Cesium.Cartesian3.lerp(
              start,
              end,
              i / count,
              new Cesium.Cartesian3()
            )
          );
        }
        return arr;
      };

      pos.forEach((item, index) => {
        if (index > 0) {
          const dist = Cesium.Cartesian3.distance(pos[index - 1], pos[index]);
          this.lerp_arr.push(...lerp(pos[index - 1], pos[index], dist));
        }
      });

      this.uav = this.viewer.entities.add({
        position: this.lerp_arr[this.index],
        model: {
          // 模型路径
          uri: "uav/uav_v2.glb",
          minimumPixelSize: 200,
        },
      });
      this.viewer.flyTo(this.uav);
      this.viewer.trackedEntity = this.uav;
      this.changeSpeed(5);
    },
    // 改变速度
    changeSpeed(speed) {
      if (speed === "stop") {
        clearInterval(this.timer);
      } else if (speed === "restart") {
        this.index = 0;
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          this.index++;
          if (this.lerp_arr[this.index]) {
            this.uav.position = this.lerp_arr[this.index];
            let heading = this.calcuAngle(
              this.lerp_arr[this.index - 1],
              this.lerp_arr[this.index]
            );
            heading = Cesium.Math.toRadians(
              Number((heading * 180) / Math.PI - 90)
            );
            const hpr = new Cesium.HeadingPitchRoll(heading, 0, 0);
            let ort = Cesium.Transforms.headingPitchRollQuaternion(
              this.lerp_arr[this.index],
              hpr
            );
            this.uav.orientation = ort;
          }
        }, 1000 / 5);
      } else {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          this.index++;
          if (this.lerp_arr[this.index]) {
            this.uav.position = this.lerp_arr[this.index];
            let heading = this.calcuAngle(
              this.lerp_arr[this.index - 1],
              this.lerp_arr[this.index]
            );
            heading = Cesium.Math.toRadians(
              Number((heading * 180) / Math.PI - 90)
            );
            const hpr = new Cesium.HeadingPitchRoll(heading, 0, 0);
            let ort = Cesium.Transforms.headingPitchRollQuaternion(
              this.lerp_arr[this.index],
              hpr
            );
            this.uav.orientation = ort;
          }
        }, 1000 / speed);
      }
    },
    //改变飞行视角
    changeView(view) {
      const aa = transformCartesian2WGS84(this.lerp_arr[this.index]);
      if (view === "1") {
        const center = Cesium.Cartesian3.fromDegrees(aa.lng, aa.lat, aa.alt);
        const heading = Cesium.Math.toRadians(20.0);
        const pitch = Cesium.Math.toRadians(-20.0);
        const range = 40;
        viewer.camera.lookAt(
          center,
          new Cesium.HeadingPitchRange(heading, pitch, range)
        );
      } else {
        viewer.camera.setView({
          destination: Cesium.Cartesian3.fromDegrees(
            aa.lng,
            aa.lat,
            aa.alt + 3000
          ),
        });
      }
    },
    // 移除飞行模拟
    clearFlySimulation() {
      this.viewer.entities.remove(this.uav);
      this.uav = null;
      this.lerp_arr = [];
      if (this.saveFlyLine) {
        this.saveFlyLine.destroy();
      }
    },
    //#endregion
    // ---------------------------------------------------------三维模型预览
    flyTo3D(data) {
      try {
        let tileset = this.viewer.scene.primitives.add(
          new Cesium.Cesium3DTileset({
            url: data.url, //数据路径
            maximumScreenSpaceError: 2, //最大的屏幕空间误差
            maximumNumberOfLoadedTiles: 1000, //最大加载瓦片个数
          })
        );

        this.viewer.flyTo(tileset);
      } catch (e) {
        console.error(e);
      }
    },
    calcuAngle(pointA, pointB) {
      const transform = Cesium.Transforms.eastNorthUpToFixedFrame(pointA);
      const positionvector = Cesium.Cartesian3.subtract(
        pointB,
        pointA,
        new Cesium.Cartesian3()
      );
      const vector = Cesium.Matrix4.multiplyByPointAsVector(
        Cesium.Matrix4.inverse(transform, new Cesium.Matrix4()),
        positionvector,
        new Cesium.Cartesian3()
      );
      const direction = Cesium.Cartesian3.normalize(
        vector,
        new Cesium.Cartesian3()
      );
      const heading =
        Math.atan2(direction.y, direction.x) - Cesium.Math.PI_OVER_TWO;
      return Cesium.Math.TWO_PI - Cesium.Math.zeroToTwoPi(heading);
    },
  },
  watch: {
    updateElement() {
      // 监视有要素导入了，重新请求新的数据并渲染
      this.async_get_list();
    },
    editFlyLineData(value) {
      // 要修改航线了
      this.edit(value);
      this.isUpdate = value.id;
      this.openDrawFlyLinePopup();
    },
    routeSimulationData(val) {
      // 要查看飞行模拟了
      if (val.flightCourseJson) {
        this.renderRoute(val);
      }
    },
    closeFlyLine() {
      // 监视飞行模拟弹框是否打开，打开的话关掉航线规划弹框
      this.beforeClose();
    },
    my_layers_data: function () {
      viewer.imageryLayers.removeAll();
      viewer.imageryLayers.addImageryProvider(
        new Cesium.UrlTemplateImageryProvider({
          url: `http://172.16.6.15:9000/gis/mapservice1/${this.my_layers_data}/v101/Layers/_alllayers/{selfZ}/{selfY}/{selfX}.png`,
          customTags: {
            selfZ: function (imageryProvider, x, y, level) {
              return "L" + `${level}`.padStart(2, "0");
            },
            selfX: function (imageryProvider, x, y, level) {
              var index = x.toString(16);
              return (
                "C" +
                "00000000".substring(0, 8 - index.length) +
                index.toUpperCase()
              );
            },
            selfY: function (imageryProvider, x, y, level) {
              var index = y.toString(16);
              return (
                "R" +
                "00000000".substring(0, 8 - index.length) +
                index.toUpperCase()
              );
            },
          },
        })
      );
    },
  },
  mounted() {
    this.init_viewer();
    this.getTypeList();
    this.async_get_list();
    this.baseUrl = process.env.VUE_APP_WS_IP;
    this.$bus.$on("flySpeed", (speed) => {
      this.changeSpeed(speed);
    });
    this.$bus.$on("view", (view) => {
      this.changeView(view);
    });
    this.$bus.$on("close", () => {
      this.clearFlySimulation();
    });
    this.async_get_region();
  },
  beforeDestroy() {
    this.$bus.$off("flySpeed");
    this.$bus.$off("view");
    this.$bus.$off("close");
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.showLine();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .select {
    .el-input__inner {
      border: 1px solid #007aff;
      border-right: 0px;
      height: 45px;
    }
    .el-input-group__append {
      border: 1px solid #007aff;
      border-left: 0px;
    }
  }
}

.cpt-draw {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  #draw-container {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .select {
    z-index: 1000;
    position: absolute;
    top: 40px;
    left: 40px;
    width: 390px;
  }
  .icons-list_box {
    position: absolute;
    z-index: 100;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    top: 102px;
    left: 37px;
    .iconfont {
      font-size: 24px !important;
      color: #1ca3ff !important;
      margin-top: -2px;
      cursor: pointer;
    }
    .hide {
      .iconfont {
        color: #ccc !important;
        cursor: pointer;
      }
    }
    .item {
      width: 44px;
      height: 44px;
      background: rgba(22, 29, 45, 0.58);
      border: 1px solid #007aff;
      margin-left: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-gjx {
        margin-top: -4px;
        padding-left: 4px;
        width: 30px;
        height: 30px;
      }
    }
  }
  .create-fiyline {
    z-index: 1000;
    position: absolute;
    top: 328px;
    left: 40px;
    background: rgba(22, 29, 45, 0.58);
    border: 1px solid #007aff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    .iconfont {
      font-size: 24px !important;
      color: #1ca3ff !important;
      margin-top: -2px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
// 航线规划弹框的样式
.pub_dialog {
  width: 400px;
  position: absolute;
  right: 10px;
  top: -10px;
  left: auto;
  bottom: auto;
  z-index: 1000;
  .headBtn {
    display: flex;
    justify-content: center;
    .airwayNum {
      height: 36px;
      line-height: 36px;
      font-size: 17px;
      color: #9fc4ff;
      padding: 0 20px;
    }
  }
}
.pub_dialog_hddz {
  z-index: 1000;
  width: 400px;
  position: absolute;
  right: 420px;
  top: 0;
  left: auto;
  bottom: auto;
  .action-item {
    :hover {
      background: rgba(12, 34, 67, 0.55) !important;
    }
    .flex {
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;
      .iconfont {
        font-size: 30px;
        color: #9fc4ff;
        cursor: pointer;
      }
      .item-label {
        font-size: 14px;
        color: #9fc4ff;
        letter-spacing: 0;
        display: block;
        flex-shrink: 0;
        float: none;
        text-align: left;
      }
    }
  }
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.item-input .el-input-number .el-input .el-input__inner {
  height: 32px;
  box-sizing: border-box;
  background: rgba(12, 34, 67, 0.5);
  border: 1px solid #018fc1 !important;
  border-radius: 0;
  font-size: 16px;
  color: #9fc4ff;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 32px;
}

.item-input .el-input-number .el-input-number__decrease {
  box-sizing: border-box;
  background: transparent;
  border-right: 1px solid #018fc1 !important;
  color: #fff;
  height: 32px;
}
.item-input .el-input-number .el-input-number__increase {
  background: transparent;
  border-left: 1px solid #018fc1 !important;
  color: #fff;
  box-sizing: border-box;
  height: 32px;
}
.showline .el-dialog .el-dialog__body {
  padding: 20px;
}
.speedSlot {
  line-height: 36px;
  font-size: 14px;
  color: #9fc4ff;
}
</style>
