<template>
  <ModuleBox title="任务数统计" width="100%" height="36%" class="number-of-tasks">
    <div class="out " style="height:20vh">
      <div style="width: 100%;height: 100%;">
        <!-- 头部 -->
        <div class="top hwb-flex">
          <!-- 左侧任务次数统计 -->
          <div class="left hwb-flex" @click="details('任务数统计','07752')">
            <div class="text">任务数量总计 </div>
            <div class="num hwb-flex ml20">
              <div class="one-num" v-for="(item,index) in totalList" :key="index">{{item}}</div>
            </div>
            <div class="text ml20">个</div>
          </div>
          <!-- 右侧时间筛选 -->
          <div class="right">
            <TimeSelect @on-selectTime="selectTime" />
          </div>
        </div>
        <!-- 下面 -->
        <div class="bottom">
          <!-- 一条样式 -->
          <div class="slider-list hwb-flex-column" v-for="item in data" :key="item.name">
            <div class="one-scroll-text hwb-flex">
            </div>
              
              
            <div class="slider ">
                <div class="one-scroll-text-left">{{item.anotherName}}</div>
              <div class="tiao">
                <div class="have" :style="{width: (item.distCode/1000)*100 + '%'}"></div>
                <!-- 圆点 -->
                <!-- <div class="dec-circular" :style="{left:'calc(' +(item.distCode/1000)*100 + '% - 4px)'}"></div> -->
              </div>
                <div class="one-scroll-text-right"><span class="right-box1">{{item.distCode}}</span> <span class="right-box">个</span> </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog class="Details" v-if="showDetails" :title="title" :width="1050" @on-close="closeDetails">
      <Table :cellName="dataList1" :dataSource="dataValue1" :tableWidth="'2200px'" />
  
      <div class="DemandList_Footer" v-if="dataValue1.length">
        <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper" :total="tableTotal" :page-size="size || 10" />
      </div>
    </Dialog>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import API from "@/api";
import renwudata from './JSONVAL/renwudata.json'
import DigitalScroll from '@/components/DigitalScroll/index.vue'; // 弹框组件

export default {
  components: { ModuleBox, TimeSelect, Table, Dialog },
  data() {
    return {
      timeHasSelect: 4,
      data: [],
      type: {//	day日, week周, month月，year年
        1: "day",
        2: "week",
        3: "month",
        4: "year",
      },
      apply: {
        0: "待审批",
        1: "审批通过",
        2: "审批不通过",
        3: "默认通过",
      },
      taskStatusType:{
      0:'待执行',
      1:'执行完成',
      3:'执行中',
      '-1':'已取消',
      '-2':'拒绝任务',

      },
      showDetails: false,
      totalList: [0, 0, 0, 0, 0],
      title: '',
      value: '',
      currentPage: 1,
      total: 0,
      tableTotal:0,
      size: 10,
      // dataList:[
      //   {title:'机构名称',name:'name',},
      //   {title:'常态任务数',name:'num'},
      //   {title:'临时任务数',name:'sum'},
      // ],
      dataList1: [
      { title: '需求单名称', name: 'taskTitle', width: '200px' },
        { title: '任务类别', name: 'taskCateName', width: '200px' },
        { title: '任务场景', name: 'taskTypeName', width: '200px' },
        { title: '开始日期', name: 'startedAt', width: '200px' },
        { title: '结束日期', name: 'endAt', width: '200px' },
        { title: '任务目标位置', name: 'locationArea', width: '200px' },
        { title: '需求描述', name: 'remark', width: '200px' },
        { title: '发起单位', name: 'userName', width: '200px' },
        { title: '审批状态', name: 'papplyStatus', width: '200px' },
        { title: '执行状态', name: 'taskStatus', width: '200px' },
        { title: '审批日期', name: 'sprq', width: '200px' },
        // { title: '实际作业日期', name: 'sjzy', width: '200px' },
      ],
      dataValue: [
        { name: '市公安局', num: 611, sum: 73 },
        { name: '市综合执法局', num: 121, sum: 5 },
        { name: '市应急管理局', num: 46, sum: 8 },
        { name: '市自然资源和规划局', num: 0, sum: 204 },
        { name: '市水利局', num: 13, sum: 0 },
        { name: '市生态办', num: 56, sum: 2 },
        { name: '市交通局', num: 0, sum: 15 },
        { name: '市环保局', num: 0, sum: 10 },
      ],
      dataValue1: []
    };
  },
  methods: {
    onChangePage(val) {
      this.taskCountList(val, this.size, this.timeHasSelect)

    },
    initData() {
      this.dataList1 = [
      { title: '需求单名称', name: 'taskTitle', width: '200px' },
        { title: '任务类别', name: 'taskCateName', width: '200px' },
        { title: '任务场景', name: 'taskTypeName', width: '200px' },
        { title: '开始日期', name: 'startedAt', width: '200px' },
        { title: '结束日期', name: 'endAt', width: '200px' },
        { title: '任务目标位置', name: 'destination', width: '200px' },
        { title: '需求描述', name: 'remark', width: '200px' },
        { title: '发起单位', name: 'organizationName', width: '200px' },
        { title: '审批状态', name: 'papplyStatus', width: '200px' },
        { title: '执行状态', name: 'taskStatus', width: '200px' },
        { title: '审批日期', name: 'handleTime', width: '200px' },
        // { title: '实际作业日期', name: 'sjzy', width: '200px' },
      ]
      this.getTaskCount(this.timeHasSelect)
      

    },
    async getTaskCount(timeHasSelect) {
      let res = await API.TASK.taskCount({ type: this.type[timeHasSelect] })
      if (res.code == 200) {
        this.data = res.data.organization
        this.tableTotal = res.data.total
        let totalList = this.tableTotal.toString().split('')
        for (let i = 0; i < 6; i++) {
          if (totalList.length < 5) {
            totalList.unshift(0)
          }
        }
        // item.status = this.apply[item.papplyStatus]
  
        this.totalList = totalList
      }

    },
    async taskCountList(page, size, type) {
      let res = await API.TASK.taskCountList({ page, size, type: this.type[type] })
      if (res.code == 200) {
        console.log(res.data)
        this.dataValue1 =res.data.data
        this.dataValue1.map(item => {
          item.papplyStatus = this.apply[item.papplyStatus]
          item.taskStatus = this.taskStatusType[item.taskStatus]
        })
        this.tableTotal = res.data.totalCount

      }
    },
    updataflyValue() {

    },
    getAllOrganization() {
      // let data  = this.dbox_data
      let data = [
        {
          child: [
            { id: 8, name: "公关办" },
            { id: 9, name: "情指中心" },
            { id: 10, name: "科通科" },
            { id: 11, name: "政治安全保卫大队" },
            { id: 12, name: "刑事侦查大队" },
            { id: 13, name: "巡特警大队" },
            { id: 14, name: "食药环侦大队" },
            { id: 15, name: "交通警察大队" },
            { id: 16, name: "禁毒大队" },
          ],
          id: 2,
          name: "公安局",
        },
        {
          child: [],
          id: 3,
          name: "综合执法局",
        },
        {
          child: [],
          id: 4,
          name: "应急管理局",
        },
        {
          child: [],
          id: 5,
          name: "自然资源和规划局",
        },
        {
          child: [
            { id: 17, name: "河湖中心" },
            { id: 18, name: "水行政执法" },
          ],
          id: 6,
          name: "水利局",
        },
        {
          child: [],
          id: 7,
          name: "生态办",
        },
      ];
      this.selectList = this.getTreeData(data);
      // this.organId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    details(title, value) {
      this.taskCountList(1, this.size, this.timeHasSelect)
      this.title = title
      // this.value = value
      this.showDetails = true
    },
    closeDetails() {
      this.showDetails = false
    },
    selectTime(time) {
      this.timeHasSelect = time
      this.getTaskCount(this.timeHasSelect)
    }
  },
  mounted() {
    this.initData()
  },
};
</script>

<style lang="scss" scoped>
.Details {
  .select {
    display: flex;
    align-items: center;

    .selectBtn {
      cursor: pointer;
      width: 86px;
      height: 30px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
    }
  }

  ::v-deep .el-cascader .el-input {
    .el-input__inner {
      background-color: #000;
      border: 0;
      height: 30px;
      line-height: 30px;
      color: #fff;
      margin-bottom: 10px;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }
}

.number-of-tasks {
  width: 100%;

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .top {
      .left {
        padding-left: 4px;
        // width: 55%;
        cursor: pointer;

        .text {
          font-size: 12px;
          font-family: AppleSystemUIFont;
          color: #DAE9FF;
        }

        .num {
          width: 100px;

          .one-num {
            width: 16px;
            height: 27px;
            background: linear-gradient(180deg, #01a0ff 0%, #01a0ff 50%, #1784FA 50%, #1784FA 100%);
            font-family: Rajdhani-SemiBold, Rajdhani;
            border-radius: 1px;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 27px;
            text-align: center;
          }
        }
      }

      .right {
        text-align: center;
      }
    }

    .bottom {
      margin: 4px 4px 0 6px;
      padding: 0 14px 0 0;
      height: 100%;
      overflow: auto;

      .slider-list {
        width: 100%;

        .slider {
          padding-bottom: 12px;
          width: 100%;
          display: flex;
          align-items: center;
          .one-scroll-text-left{
            width: 60px;
            text-align: right;
            color: #CED7FF;
            font-size: 12px;
          }
          .one-scroll-text-right{
            width: 50px;
            .right-box{
              color: #CED7FF;
              display: inline-block;
              text-align: center;
              font-size: 12px;
              width: 12px;
            }
            .right-box1{
              display: inline-block;
              text-align: right;
              color: #00E5FF;
              font-size: 20px;
            }
          }
          .tiao {
            background: rgba(76, 118, 193, 0.38);
            height: 10px;
            background: rgba(1,18,67,0.9);
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            width: 80%;
            flex: 1;
            margin: 0 9px;
            .dec-circular {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #DFFFFE;
              position: absolute;
              top: -1.5px;
              box-shadow: 0px 0px 16px #fff;
            }

            .have {
              height: 100%;
              // background: linear-gradient(270deg, #92FFFD 0%, #5FB2E8 31%, #2D72D3 66%, #1F18AE 100%);
              background: linear-gradient(270deg, #2DE9FF 0%, #0080C2 100%);
              border-radius: 10px;
            }
          }
        }

        .one-scroll-text {
          padding: 0 0 10px 0;
          width: 100%;

          .one-scroll-text-left {
            font-size: 14px;
            font-family: AppleSystemUIFont;
            color: #FFFFFF;
          }

          .one-scroll-text-right {
            font-size: 26px;
            font-family: 'DINAlternate-Bold', 'DINAlternate';
            font-weight: bold;
            color: #00FFFF;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.Details {
  .select {
    display: flex;
    align-items: center;

    .selectBtn {
      cursor: pointer;
      width: 86px;
      height: 30px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
    }
  }

  ::v-deep .el-cascader .el-input {
    .el-input__inner {
      background-color: #000;
      border: 0;
      height: 30px;
      line-height: 30px;
      color: #fff;
      margin-bottom: 10px;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }
}

.cpt-table-pagination {
  //右下分页组件样式
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: right;

  ::v-deep {
    button {
      background: transparent;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      // border: 1px solid #488cff;
      font-size: 18px;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      border-radius: 4px;
      line-height: 40px;
      margin-right: 8px;
      box-sizing: border-box;
      color: #b6d4ff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      &:disabled {
        background: transparent;
        color: #b6d4ff;
        opacity: 0.3;
      }
    }

    .el-pagination__total {
      font-size: 18px;
      color: #b6d4ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;

      text-align: center;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      margin: 0px;
    }

    .el-pager {
      height:30px;

      .number {
        background: transparent;
        font-size: 12px;
        color: #b6d4ff;
        text-align: center;
        font-weight: lighter;
        width: 25px;
        height: 25px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        border-radius: 4px;
        margin-right: 8px;
        box-sizing: border-box;

        &.active {
          opacity: 0.8;
          // background-image: linear-gradient(
          //   180deg,
          //   rgba(44, 123, 255, 0.5) 0%,
          //   rgba(72, 140, 255, 0) 100%
          // );
          background-color: #2647ee;
          color: #ffffff;
        }
      }

      .more {
        background: transparent;
        // line-height: 40px;
        width: 40px;
        // height: 40px;

        box-sizing: border-box;
        // border: 1px solid #629fff;
        border-radius: 4px;
        margin-right: 8px;
        font-size: 20px;
        color: #b6d4ff;
      }
    }

    .el-pagination__jump {
      font-size: 18px;
      color: #b6d4ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      width: 150px;
      height: 40px;
      margin-left: 6px;
      line-height: 40px;
      display: flex;
      align-items: center;

      .el-input {
        box-sizing: border-box;
        height: 100%;
        width: 84px;

        .el-input__inner {
          width: 60px;
          height: 30px;
          border-radius: 2px;
          border: 1px solid #b6d4ff;
          margin-top: 8px;
          // border: 1px solid #629fff;
          border-radius: 4px;
          background: transparent;
          // height: 100%;
          box-sizing: border-box;
          padding: 0;
          font-size: 18px;
          color: #90b2ec;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }

  &.mini {
    height: 100%;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        height: 20px;
        width: 20px;
        line-height: 20px;
        background: transparent;
        border: none;
        color: #4d7592;
        min-width: auto;

        &.btn-next {
          margin-left: 8px;
        }
      }

      .el-pagination__total {
        font-size: 14px;
        color: #4d7592;
      }

      .el-pagination__sizes {
        display: none;
      }

      .el-pager {
        height:30px;


        .number {
          height: 100%;
          width: auto;
          line-height: 20px;
          background: transparent;
          border: none;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 500;
          min-width: 20px;
          margin-right: 0;

          &.active {
            opacity: 1;
            color: #0099ff;
          }
        }

        .more {
          height: 100%;
          width: 20px;
          line-height: 20px;
          font-size: 12px;
          border: none;
          min-width: auto;
          margin-right: 0px;

          &::before {
            line-height: 20px;
          }
        }
      }

      .el-pagination__jump {
        height: 100%;
        font-size: 14px;
        color: #4d7592;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        .el-input {
          .el-input__inner {
            border: 1px solid #4d7592;
            border-radius: 2px;
            width: auto;
            min-width: 20px;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>