<template>
  <!-- 应付首页侧边栏的小地图 -->
  <div class="cpt-draw">
    <div
      id="draw-container"
      @mouseenter="CesiumLayerMouseEnter"
      @mouseleave="CesiumLayerMouseLeave"
    ></div>
  </div>
</template>

<script>
let handler, viewer, entity;
let noFlyEntities = [];
import { mapGetters } from "vuex"
import Bottomright from '@/components/Bottomright';
import API from "@/api";
import { initMap } from "@/utils/cesium.js";
export default {
  computed: {
    ...mapGetters(["my_layers_data"]),
  },
  components: {
    Bottomright
  },
  data() {
    return {
      viewList: null,
      isShow: false,
      search: {
        name: null,
      },
      VUE_APP_IP: null,
      allairArray: [], // 存储所有禁飞区域
    };
  },
  methods: {
    drawWarningPoints() { // 绘制预警点
      if (this.earlyWarningPoint) {
        viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(this.earlyWarningPoint.split(',')[0], this.earlyWarningPoint.split(',')[1], 200),
          point: {
            color: Cesium.Color.RED,    //点位颜色
            pixelSize: 12                //像素点大小
          },
          label: {
            text: '预警位置',
            font: '8pt Source Han Sans CN',    //字体样式
            fillColor: Cesium.Color.RED,        //字体颜色
            backgroundColor: Cesium.Color.AQUA,    //背景颜色
            showBackground: true,                //是否显示背景颜色
            style: Cesium.LabelStyle.FILL,        //label样式
            outlineWidth: 8,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,//垂直位置
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,//水平位置
            pixelOffset: new Cesium.Cartesian2(-28, -24)            //偏移
          }
        });
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(this.earlyWarningPoint.split(',')[0], this.earlyWarningPoint.split(',')[1], 2000)
        })

      }
    },
    mouseScoll(e) {
      //鼠标滚轮滚动效果
      /*这里获取元素节点*/
      let oPanel = document.getElementById("app");
      let Ripples = document.getElementsByClassName("hwb-mouse-scrolling-effect");
      if (Ripples.length > 0) {
        Ripples[0].remove()
      }
      let oSpan = document.createElement(`div`);
      /*浏览器兼容*/
      e = e || window.Event;
      /*获取相关参数*/
      oSpan.style.left = e.clientX + `px`;
      oSpan.style.top = e.clientY + `px`;
      /*给节点赋值参数数值*/
      oSpan.className = "hwb-mouse-scrolling-effect";
      /*在body中添加span标签*/
      oPanel.appendChild(oSpan);
      /*设置定时器 间隔时间为2000毫秒*/
      setTimeout(() => {
        /*清除ospan节点*/
        oSpan.remove();
      }, 600);
    },
    CesiumLayerMouseEnter() {
      // 鼠标移入地球添加鼠标滚动效果
      document.addEventListener(`mousewheel`, this.mouseScoll);
    },
    CesiumLayerMouseLeave() {
      // 鼠标移出地球添加鼠标滚动效果
      document.removeEventListener(`mousewheel`, this.mouseScoll);
    },
    init_viewer() {
      viewer = window.viewer2 = initMap(this.my_layers_data, "draw-container")
      this.async_get_list()
    },
    showLine() {
      const ces = document.getElementsByClassName("cesium-tooltip")[0];
      const body = document.getElementsByTagName("body")[0];
      body.removeChild(ces);
    },
    add() {
      this.viewList.map((item, index) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (positions && item.typeId === 2) {
          this.allairArray.push(positions);
        }
        if (positions.length > 3) {
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (positions.length % 2 !== 0) return;
          entity = viewer.entities.add({
            name: "Red polygon on surface",
            position,
            label: {
              text: item.name,
              font: "20px monospace",
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(5, 5),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              fillColor: Cesium.Color.WHITE,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                200000
              ),
            },
            polygon: {
              hierarchy,
              material:
                item.typeId === 1
                  ? Cesium.Color.fromCssColorString("#00FF11").withAlpha(0.2)
                  : item.typeId === 2
                  ? Cesium.Color.RED.withAlpha(0.2)
                  : Cesium.Color.YELLOW.withAlpha(0.2),
              outline: true,
              outlineColor: Cesium.Color.BLACK,
            },
          });
          noFlyEntities.push(entity);
        }
      });
    },
    // 获取所有空域数据
    async async_get_list() {
      // 空域可视化
      if (this.search.name === null || this.search.name === "") {
        this.$message.warning("请先输入要搜索的空域");
      } else {
        let res = await API.AIRSPACE.selectLikeList({
          ...this.search,
          status: 1,
          pageNo: 1,
          pageSize: 10000,
        });
        if (res.status === 1 && res.data.data.length == 0) {
          // this.$message.warning('未搜索到相关空域')
        } else {
          viewer.entities.removeAll();
          this.viewList = res.data.data;
          this.add();
          viewer.flyTo(viewer.entities, {
            // 让它展示完全
            offset: {
              heading: viewer.camera.heading,
              pitch: Cesium.Math.toRadians(-90),
              roll: 0.0,
            },
          });
        }
      }
    },
  },
  mounted() {
    const { VUE_APP_IP } = process.env;
    this.VUE_APP_IP = VUE_APP_IP;
    this.init_viewer();
    setTimeout(() => {
      this.drawWarningPoints();
    }, 0);
    if (this.drakyStyle) {
      let aa = document.getElementsByClassName("cesium-tooltip");
      aa[0].classList.add("hewanbai-kytip");
    }
  },
  beforeDestroy() {
    this.CesiumLayerMouseLeave();
  },
  destroyed() {
    if (!this.readonly) {
      this.showLine();
    }
    if (entity) {
      viewer.entities.removeAll();
    }
  },
  watch:  {
    my_layers_data: function (newVal, oldVal) {
      if (newVal != oldVal) {
        viewer.imageryLayers.removeAll();
        if (newVal === "night") {
          viewer.imageryLayers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider({
              url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
            })
          );
        } else if (newVal === "tian") {
          console.log(newVal, "tiantiantian");
          // //高德影像地图数据图层，自带注记
          viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              url: "https://t0.tianditu.gov.cn/img_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
              layer: "img",
              style: "default",
              tileMatrixSetID: "w",
              format: "tiles",
              maximumLevel: 18,
            })
          );

          viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              url: "https://t0.tianditu.gov.cn/cia_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
              layer: "cia",
              style: "default",
              tileMatrixSetID: "w",
              format: "tiles",
              maximumLevel: 18,
            })
          );
        }
      }
    },
  },
};
</script>
<style>
.hewanbai-kytip {
  top: 90px !important;
  left: 354px;
  z-index: 9999;
}
</style>
<style lang="scss" scoped>
::v-deep {
  .select {
    .el-input__inner {
      border: 1px solid #018fc1;
      background: rgba(12, 34, 67, 0.5);
      border-right: 0px;
      font-size: 16px;
      color: #9fc4ff;
      font-weight: 400;
    }
    .el-input-group__append {
      border: 1px solid #018fc1;
      background: rgba(12, 34, 67, 0.5);
      border-left: 0px;
      line-height: 38px;
    }
    .el-input__suffix {
      line-height: 42px;
    }
  }
}

.cpt-draw {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  
  #draw-container {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .seach {
    position: absolute;
    z-index: 1000;
    top: 40px;
    left: 40px;
    .input-with-name {
      width: 250px;
    }
  }
  .back-button {
    z-index: 1000;
    position: absolute;
    top: 40px;
    right: 40px;
    color: #fff;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-image: url(~@/assets/platform/message/task/details/closure.png);
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
