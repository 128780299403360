<template>
  <div class="bigbox">
    <div class="console">
      <div class="console-hz">
        <div
          class="hz-box"
          v-for="(item, index) in list"
          :key="item.id"
          :class="item.id === 1 ? 'wnjr' : ''"
          @click="select(item)"
          :style="filterPo(item.id, index)"
        >
          <div class="hz-top">
            <div class="tb-hz">
              <div class="tb-box" :class="item.cla"></div>
            </div>
          </div>
          <div class="hz-bottom">
            <!-- <span>{{ item.name }}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let radar = [];
let dataSources = [];
let heatMapData = null;
let bubblesList = [];
let entitiesList = [];
import { mapGetters } from "vuex";
import "./radarScanMaterialProperty";
import methods from "./methods";
import data from "./data";
import Bubble from "@/components/cesium-layer/bubble/index.js";
export default {
  props: {
    openInitiateRequirements: {
      type: Function,
      default: () => () => {},
    },
    selectData: {
      type: [Number, String],
      default: 1,
    },
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  data() {
    return {
      ...data,
      ws: null,
      history_line_arr: [],
      userId: false,
      uavMap: {},
      cesium_layer: null,
      selected: null,
      num: 0,
      tagging: null,
      models: [],
      bubbles: null,
      list1: [
        {
          id: 1,
          name: "任务模块",
          path: "",
          children: [
            {
              id: 11,
              name: "航线管理",
              path: "/platform/airway",
            },
            {
              id: 12,
              name: "飞行直播",
              path: "",
            },
            {
              id: 13,
              name: "需求列表",
              path: "/platform/order/task",
            },
            {
              id: 14,
              name: "日历排班",
              path: "",
            },
            {
              id: 15,
              name: "飞行成果",
              path: "/platform/order/achievement",
            },
            {
              id: 16,
              name: "报告库",
              path: "/platform/order/reportList",
            },
            {
              id: 17,
              name: "我的审批",
              path: "/platform/message/task",
            },
          ],
        },
        // {
        //   id: 2,
        //   name: "民用无人机",
        //   path: "",
        //   children: [
        //     {
        //       id: 21,
        //       name: '实名认证',
        //       path: ""
        //     },
        //     {
        //       id: 22,
        //       name: '航空器登记',
        //       path: ""
        //     },
        //     {
        //       id: 23,
        //       name: '飞行报备',
        //       path: ""
        //     },
        //   ]
        // },
        {
          id: 3,
          name: "低空防御",
          path: "",
          children: [
            {
              id: 31,
              name: "拦截记录",
              path: "",
            },
            {
              id: 32,
              name: "黑飞黑名单",
              path: "",
            },
            {
              id: 33,
              name: "空域管理",
              path: "",
            },
          ],
        },
        {
          id: 4,
          name: "三维模型",
          path: "/",
          children: [
            {
              id: 41,
              name: "三维模型",
              path: "/platform/model",
            },
          ],
        },
        {
          id: 5,
          name: "数据中心",
          path: "/",
          children: [
            {
              id: 51,
              name: "人员看板",
              path: "/platform/staff",
            },
            {
              id: 52,
              name: "设备看板",
              path: "/platform/device",
            },
          ],
        },
      ],
      // list: [
      //    {
      //     id: 1,
      //     name: "任务模块",
      //     path: "",
      //   },
      //   {
      //     id: 2,
      //     name: "民用无人机",
      //     path: "",
      //   },
      //   { id: 3, name: "低空防御", path: "" },
      //   { id: 4, name: "三维模型", path: "/" },
      //   { id: 5, name: "数据中心", path: "/" },
      // ],
      list: [
        {
          id: 1,
          name: "天空卫士",
          path: "",
          cla: "tb-box2",
          isSele: false,
        },
        {
          id: 2,
          name: "飞行任务",
          path: "",
          cla: "tb-box3",
          isSele: false,
        },
        {
          id: 3,
          name: "驾驶舱",
          path: "",
          cla: "tb-box4",
          isSele: false,
        },
        { id: 5, name: "三维应用", path: "/", cla: "tb-box7", isSele: false },
        // { id: 3, name: "民用机管理", path: "", cla: 'tb-box4', isSele: false },
        // { id: 4, name: "侦测反制", path: "/", cla: 'tb-box5', isSele: false },

        // { id: 6, name: "数据分析", path: "/", cla: 'tb-box6', isSele: false },
      ],
      politicsList: [
        {
          id: 1,
          name: "发起任务",
          path: "/platform/order/visual/view/device",
          popup: "发起任务",
        },
        {
          id: 2,
          name: "审核中心",
          path: "/platform/message/task",
        },
        // { id: 3, name: "我的任务", path: "/dashboard" },
        { id: 3, name: "我的任务", path: "/platform/order/task" },
        { id: 4, name: "飞行成果", path: "/platform/order/achievement" },
        { id: 5, name: "报告库", path: "/platform/order/reportList" },
        { id: 6, name: "预警中心", path: "/platform/message/warning" },
        { id: 7, name: "智慧飞行", path: "/platform/order/visual/view/device" },
      ],
      typeID: null,
    };
  },
  methods: {
    ...methods,

    handle_click_item(value) {},
    handle_change_num() {},
    go(data) {
      if (data.popup == "发起任务") {
        this.openInitiateRequirements();
        return;
      }
      this.$router.push(data.path);
    },
    init_events() {
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        //获取当前相机高度
        let height = Math.ceil(viewer.camera.positionCartographic.height);
        this.height = height;
      }, Cesium.ScreenSpaceEventType.WHEEL);
      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(
          movement.position.x,
          movement.position.y
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          // 判断点击位置是否等于渲染全要素并且没有创建弹框
          if (
            this.models.indexOf(pickedLabel.id) > -1 &&
            !document.getElementById(pickedLabel.id.id)
          ) {
            let id = pickedLabel.id.id;
            this.bubble(id);
          } else {
            if (this.bubbles) {
              this.bubbles.windowClose();
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    bubble(id) {
      let aa = this.models.filter((item) => item.id === id);
      this.bubbles = new Bubble(
        Object.assign(aa[0], {
          viewer,
        })
      );
      let b = this.bubbles;
      bubblesList.push(b);
    },
    select(data) {
      //选中
      this.list.forEach((v) => (v.isSele = false));
      data.isSele = true;
      this.list.forEach((v) => {
        if (!v.isSele) {
          v.cla = this.imager(v.id);
        }
      });
      data.cla = this.imagerVal(data.id);
      this.$emit("updataSelect", data.id);
      this.handeEl(data);
    },

    imagerVal(id) {
      if (id == 1) {
        return "tb-box10";
      } else if (id == 2) {
        return "tb-box11";
      } else if (id == 3) {
        return "tb-box12";
      } else if (id == 4) {
        return "tb-box13";
      } else if (id == 5) {
        return "tb-box15";
      } else if (id == 6) {
        return "tb-box14";
      }
    },
    imager(id) {
      if (id == 1) {
        return "tb-box2";
      } else if (id == 2) {
        return "tb-box3";
      } else if (id == 3) {
        return "tb-box4";
      } else if (id == 4) {
        return "tb-box5";
      } else if (id == 5) {
        return "tb-box7";
      } else if (id == 6) {
        return "tb-box6";
      }
    },

    heatmap() {
      let tmpXYList = [
        new Cesium.Cartographic(120.3058, 30.5386, 1000),
        new Cesium.Cartographic(120.7809, 30.5563, 1000),
        new Cesium.Cartographic(120.8551, 30.2697, 1000),
        new Cesium.Cartographic(120.3346, 30.2686, 1000),
      ];

      //生成矩形
      var bounds = new Cesium.Rectangle.fromCartographicArray(tmpXYList);
      // 初始化CesiumHeatmap
      heatMapData = CesiumHeatmap.create(
        viewer, // 视图层
        bounds, // 矩形坐标
        {
          // heatmap相应参数
          backgroundColor: "rgba(0,0,0,0)",
          radius: 100,
          maxOpacity: 0.5,
          minOpacity: 0,
          blur: 0.75,
        }
      );

      // 添加数据 最小值，最大值，数据集
      // heatMap.setWGS84Data(0, 100, getData(300));
      heatMapData.setWGS84Data(0, 620, [
        {
          x: 120.554155,
          y: 30.411899,
          value: 620,
        },
        {
          x: 120.715759,
          y: 30.520555,
          value: 580,
        },
        {
          x: 120.650003,
          y: 30.408113,
          value: 440,
        },
        {
          x: 120.434442,
          y: 30.398806,
          value: 380,
        },
        {
          x: 120.690883,
          y: 30.518927,
          value: 250,
        },
        {
          x: 120.707771,
          y: 30.54767,
          value: 220,
        },
      ]);

      // viewer.zoomTo(viewer.entities);

      // 动态数据 [{x: -97.6433525165054, y: 45.61443064377248, value: 11.409122369106317}]
      function getData(length) {
        var data = [];
        for (var i = 0; i < length; i++) {
          var x = Math.random() * (-109 + 80) - 80;
          var y = Math.random() * (50 - 30) + 30;
          var value = Math.random() * 100;
          data.push({ x: x, y: y, value: value });
        }
        return data;
      }
    },
    handeEl(data) {
      // 飞行任务f
      if (data.id == 2) {
      } else {
        this.ws && this.ws.close();
        this.ws = null;

        bubblesList.forEach((element) => {
          element.windowClose();
        });
        bubblesList = [];
        this.modelList = {};
        this.models = [];
      }
      // 热力图
      if (data.id == 3) {
        if (!heatMapData) {
          this.heatmap();
        }
      } else {
        if (heatMapData) {
          viewer.entities.remove(heatMapData._layer);
          heatMapData = null;
        }
      }

      if (data.id == 4) {
        // this.setRadarFn()
      } else {
        if (radar.length > 0) {
          radar.forEach((element) => {
            viewer.entities.remove(element);
          });
        }
        setView(window.viewer);

      }
      if (data.id != 5 && window.modelList && window.modelList.length > 0) {
        window.modelList.forEach((element) => {
          viewer.scene.primitives.remove(element);
        });
      }
      if (viewer.scene.globe.show) {
        viewer.scene.globe.show = false;
        window.plate_data_source.show = true;
      }
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          "112.249138",
          "34.556111",
          93186.753984789
        ),
        orientation: {
          heading: 0.21766716858823276,
          pitch: -0.9699607640658252,
          roll: 6.28318504960658,
        },
      });
    },
    setRadarFn() {
      let id = this.$route.query.id;
      if (id == 4) {
        this.radarFn(
          Cesium.Cartesian3.fromDegrees(120.678009, 30.396414, 2000),
          20
        );
        this.radarFn(
          Cesium.Cartesian3.fromDegrees(120.74498, 30.35937, 2000),
          18
        );
        this.radarFn(
          Cesium.Cartesian3.fromDegrees(120.545623, 30.40426, 2000),
          26
        );
        this.radarFn(
          Cesium.Cartesian3.fromDegrees(120.429953, 30.388904, 2000),
          22
        );
      } else {
        // 移除实体
        if (radar.length > 0) {
          radar.forEach((element) => {
            viewer.entities.remove(element);
          });
        }
      }
    },
    radarFn(position, speed) {
      let img = require("@/assets/img/ld.svg");
      let el = viewer.entities.add({
        position,
        name: "雷达扫描",
        pixelOffset: new Cesium.Cartesian2(0, 0),
        billboard: {
          image: img,
          show: true, // default
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
          scale: 1.0, // default: 1.0
          width: 30, // default: undefined
          height: 30, // default: undefined
          pixelOffset: new Cesium.Cartesian2(0, 0),
        },
        ellipse: {
          semiMajorAxis: 2000.0,
          semiMinorAxis: 2000.0,
          material: new Cesium.RadarScanMaterialProperty({
            color: Cesium.Color.fromCssColorString("#009a60").withAlpha(0.5),
            speed,
          }),
          height: 2000.0,
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
          outline: true,
          outlineColor: Cesium.Color.fromCssColorString("#009a60"),
        },
      });
      radar.push(el);
    },
    filterPo(id, index) {
      // console.log((872 - 86 * this.list.length) /  this.list.length+1);
      // console.log((index+1)%2);
      // if (index==0 || index==this.list.length-1) {
      //   // return `margin-top: -${(this.list.length+1)*this.list.length}px;`
      // }else{
      // }
      // if (id == 1) {
      //   return `margin-top: -${5*this.list.length}px;`
      // } else if (id == 2) {
      //   return `margin-top: -${10*this.list.length}px;`
      // } else if (id == 3) {
      //   return `margin-top: -${5*this.list.length}px;`
      // } else if (id == 4) {
      //   return `margin-top: -${5*this.list.length}px`
      // } else if (id == 5) {
      //   return `margin-top: -${5*this.list.length}px;`
      // } else if (id == 6) {
      //   return `margin-top: -${5*this.list.length}px;`
      // }
    },
  },
  created() {
    if (this.user_info_hn.departmentId == 60) this.userId = true;
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:sky") === -1) {
      this.list.forEach((v, i) => {
        if (v.name == "天空卫士") {
          this.list.splice(i, 1);
        }
      });
    }
    if (permissionList.indexOf("hn:tasks") === -1) {
      this.list.forEach((v, i) => {
        if (v.name == "飞行任务") {
          this.list.splice(i, 1);
        }
      });
    }
    if (permissionList.indexOf("home:personalUav") === -1) {
      this.list.forEach((v, i) => {
        if (v.name == "民用机管理") {
          this.list.splice(i, 1);
        }
      });
    }
    if (permissionList.indexOf("home:detect") === -1) {
      this.list.forEach((v, i) => {
        if (v.name == "侦测反制") {
          this.list.splice(i, 1);
        }
      });
    }
    if (permissionList.indexOf("hn:sky:model") === -1) {
      this.list.forEach((v, i) => {
        if (v.name == "三维应用") {
          this.list.splice(i, 1);
        }
      });
    }
    if (permissionList.indexOf("home:analyse") === -1) {
      this.list.forEach((v, i) => {
        if (v.name == "数据分析") {
          this.list.splice(i, 1);
        }
      });
    }
  },
  mounted() {
    this.mqttClient = null;
    this.jcDeviceList = {};
    // 断开连接
    this.jc_removeEntities();
    this.uav_removeEntities();
    // 初始化mqtt
    this.jc_connectMqtt();
    // 初始化ws
    this.uav_init_ws();
    this.init_events();
    setTimeout(() => {
      this.jc_initJCmodel();
      this.uav_initUavmodel();
    }, 3000);
    //默认选中
    if (this.$route.params.id) {
      this.select(this.list.filter((v) => v.id == this.$route.params.id)[0]);
    } else if (this.$route.query.id) {
      this.select(this.list.filter((v) => v.id == this.$route.query.id)[0]);
    } else {
      this.select(this.list[0]);
    }
  },
  watch: {
    "$store.state.device.JCmodel"(newData) {
      this.jcDeviceList = {};
      this.uavMap = {};
      this.jc_removeEntities();
      this.uav_removeEntities();
      if (newData == true) {
        setTimeout(() => {
          this.jc_initJCmodel();
          this.uav_initUavmodel();
        }, 300);
      }
    },
  },
  beforeDestroy() {
    this.ws && this.ws.close();
    this.ws = null;
    this.uav_removeEntities();
    this.history_line_arr.forEach((item) => {
      viewer.entities.remove(item);
    });
    bubblesList.forEach((element) => {
      element.windowClose();
    });
    this.jc_removeEntities();
    // 断开连接
    this.jc_disconnectMqtt();
    this.mqttClient = null;
    this.jcDeviceList = {};
    this.uavMap = {};
    clearTimeout(this.time);
    bubblesList = [];
    this.modelList = {};
    this.models = [];
  },
};
</script>

<style lang="scss" scoped>
.bigbox {
  // width: 100%;
  left: 50%;
  margin-left: -436px;
  position: absolute;
  z-index: 0;
  // bottom: 30px;
  // bottom: 15px;
  bottom: -31px;
  // padding-left: 10px;
  // border: 1px solid red;
  display: flex;
  justify-content: center;

  .console {
    width: 872px;
    height: 168px;
    background: url("~@/assets/home/background-images.png") no-repeat;
    // background-size: 100% 100%;
    background-position-y: 45px;

    .fn-children-box {
      background-color: rgba(4, 34, 78, 0.6);
      height: 40px;
      width: 90%;
      position: relative;
      transform: translateY(calc(-100% + -90px));

      .fn-children-3d-box-inner {
        display: flex;
        justify-content: center;

        .box {
          width: 120px;
          height: 24px;
          font-size: 16px;
          font-family: PangMenZhengDao;
          color: #e0e6f2;
          line-height: 24px;
          letter-spacing: 3px;

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }
      }
    }
  }

  .consoled {
    // width: 794px;
    width: 794px;
    height: 114px;
    // background: url("~@/assets/basic-tools/bottom_dz1.png") no-repeat;
    background: url("~@/assets/basic-tools/bottom_dz1.png") no-repeat;
    // background-position: -50px -15px;
    background-size: cover;
    position: relative;
  }

  .console-hz {
    display: flex;
    width: 872px;
    height: 192px;
    justify-content: space-evenly;
    align-items: center;

    // margin-top: 22px;
    .btn-box {
      position: absolute;
      width: 117px;
      height: 26px;
      border: 1px solid;
      border-image: linear-gradient(
          270deg,
          rgba(188, 215, 255, 0.1),
          rgba(188, 215, 255, 0.8),
          rgba(188, 215, 255, 0.1)
        )
        1 1;
      background: linear-gradient(
        270deg,
        rgba(17, 135, 255, 0.1) 0%,
        rgba(17, 135, 255, 0.7) 49%,
        rgba(17, 135, 255, 0.1) 100%
      );
      font-size: 16px;
      font-family: PangMenZhengDao;
      color: #cbdfff;
      line-height: 26px;
      text-align: center;
      cursor: pointer;
    }

    .hz-box {
      width: 101px;
      height: 120px;
      // width: 86px;
      // width: 171px;
      // margin-right: 40px;
      // margin-right: 31px;
      // margin-right: 4px;
      // position: relative;

      // .imager {
      //   position: absolute;
      //   width: 100%;
      //   bottom: 14px;
      //   right: -6px;
      // }

      .hz-top {
        width: 100%;
        height: 96px;
        // height: 74px;
        cursor: pointer;
        // background: url("~@/assets/basic-tools/dz.png") no-repeat;
        // background: url("~@/assets/basic-tools/dz1.png") no-repeat;
        // background-position: 0px -4px;
        // background-position: 6px 20px;
        // background-size: contain;

        .tb-hz {
          width: 101px;
          // width: 117px;
          height: 96px;
          // margin: 0 auto;

          .tb-box {
            width: 101px;
            height: 96px;
            // width: 46px;
            // height: 46px;
            margin: 0 auto;
            background: url("~@/assets/basic-tools/zhfx.png") no-repeat;
            background-size: 101px 98px;
          }

          .tb-box2 {
            // background: url("~@/assets/basic-tools/shzx.png") no-repeat;
            background: url("~@/assets/home/newBorder-01.png") no-repeat;
            background-size: 101px 98px;
          }

          .tb-box3 {
            background: url("~@/assets/home/newBorder-02.png") no-repeat;
            background-size: 101px 98px;
            // background-position: 9.5px 23px;
          }

          .tb-box4 {
            background: url("~@/assets/home/newBorder-03.png") no-repeat;
            background-size: 101px 98px;
            // background-position: 9.5px 23px;
          }

          .tb-box5 {
            background: url("~@/assets/home/newBorder-04.png") no-repeat;
            background-size: 101px 98px;
            // background-position: 9.5px 23px;
            background-position-y: -5px;
          }

          .tb-box6 {
            background: url("~@/assets/home/newBorder-05.png") no-repeat;
            background-size: 101px 98px;
            // background-position: 9.5px 23px;
          }

          .tb-box7 {
            background: url("~@/assets/home/newBorder-06.png") no-repeat;
            background-size: 101px 98px;
            // background-position: 9.5px 23px;
          }

          .tb-box10 {
            width: 119px;
            height: 126px;
            // height: 98px;
            // margin: 0 auto;
            // background: url("~@/assets/basic-tools/zhfx.png") no-repeat;
            background: url("~@/assets/home/newBorder-11.png") no-repeat;
            background-size: 100% 91%;
            background-position-y: 7px;
            position: absolute;
            top: 7px;
          }

          .tb-box11 {
            width: 119px;
            height: 126px;
            background: url("~@/assets/home/newBorder-22.png") no-repeat;
            background-size: 100% 91%;
            background-position-y: 7px;
            position: absolute;
            top: 7px;
          }

          .tb-box12 {
            width: 119px;
            height: 126px;
            // background: url("~@/assets/basic-tools/wdrw.png") no-repeat;
            background: url("~@/assets/home/newBorder-33.png") no-repeat;
            background-size: 100% 91%;
            background-position-y: 7px;
            position: absolute;
            top: 7px;
          }

          .tb-box13 {
            width: 119px;
            height: 126px;
            // background: url("~@/assets/basic-tools/wrjsh.png") no-repeat;
            background: url("~@/assets/home/newBorder-44.png") no-repeat;
            background-size: 100% 91%;
            background-position-y: 7px;
            position: absolute;
            top: 7px;
          }

          .tb-box14 {
            width: 119px;
            height: 126px;
            // background: url("~@/assets/basic-tools/bgk.png") no-repeat;
            background: url("~@/assets/home/newBorder-55.png") no-repeat;
            background-size: 100% 91%;
            background-position-y: 7px;
            position: absolute;
            top: 7px;
          }

          .tb-box15 {
            width: 119px;
            height: 126px;
            // background: url("~@/assets/basic-tools/sjfz.png") no-repeat;
            background: url("~@/assets/home/newBorder-66.png") no-repeat;
            background-size: 100% 91%;
            background-position-y: 7px;
            position: absolute;
            top: 7px;
          }

          .tb-box111 {
            width: 119px;
            height: 126px;
            // background: url("~@/assets/basic-tools/fqrw.png") no-repeat;
            background: url("~@/assets/home/newBorder-11.png") no-repeat;
            background-size: 100% 91%;
            background-position-y: 7px;
            position: absolute;
            top: 7px;
          }
        }
      }

      .hz-bottom {
        // width: 104%;
        text-align: center;
        // margin-top: 40px;
        // margin-left: 17px;

        span {
          // position: absolute;
          // right: -37px;
          // bottom: -65px;
          // width: 78px;
          width: 120px;
          height: 24px;
          // font-size: 24px;
          // font-size: 20px;
          font-size: 16px;
          font-family: PangMenZhengDao;
          color: #e0e6f2;
          // line-height: 27px;
          line-height: 24px;
          letter-spacing: 3px;
          text-shadow: 0px 2px 5px rgba(8, 27, 63, 0.5);
          background: linear-gradient(180deg, #ddf0ff 0%, #31a1ff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

.wnj {
  // margin-left: 130px;
  // margin-left: 115px;
  margin-left: 100px;
}

.wnjr {
  // margin-left: 130px;
  // margin-left: 115px;
  // margin-left: 30px;
}

@keyframes moveblock {
  0% {
    transform: translateY(5px);
  }

  25% {
    transform: translateY(8px);
  }

  50% {
    transform: translateY(10px);
  }

  75% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(5px);
  }
}
</style>
