<template>
  <Box width="411px" height="100%" class="cpt-left-box ofh" :class="RightisShow?'rightShow':'rightHiddle'">
    <div class="bigBox" v-if="selectData === 1">
      <!-- <Model /> -->
      <HomeRight />
      <FlyingCommission/>
    </div>

    <div class="bigBox" v-else-if="selectData === 3">
      <Uav />
    </div>
    <div class="bigBox" v-else-if="selectData === 4">
      <detectionReaction/>
    </div>
    <div class="bigBox" v-else-if="selectData === 5">
      <Dimensional />
    </div>
    <div class="bigBox" v-else-if="selectData === 6">
      <dataAnalysis/>
    </div>
    <!-- <div class="bigBox" >
      <FlyingCommission/>
    </div> -->
      <div class="icon" @click="ShowBox" :class="this.RightisShow?'show':'hiddle'"></div>
  </Box>
</template>

<script>
import Box from "../common/box";
import HomeRight from "./home"
import Model from "./model-box"
import FlyingCommission from "./flying-commission/index.vue" // 飞行任务
import dataAnalysis from "./data-analysis/index.vue" // 数据分析
import detectionReaction from "./detection-reaction/index.vue" // 侦测反制
import Uav from "./uav-management"
import Dimensional from "./three-dimensional"
export default {
  props: {
    selectData: {
      type: Number,
      default: 1
    }
  },
  components: {
    Box,
    Model,
    FlyingCommission,
    HomeRight,
    Uav,
    Dimensional,
    dataAnalysis,
    detectionReaction
  },
  data() {
    return {
      RightisShow: true,
    };
  },
  methods:{
    ShowBox(){
      console.log(1);
      this.RightisShow=!this.RightisShow
      this.$store.commit('device/SET_BOX_FLAG', this.RightisShow)
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-box{
  overflow: inherit;
}
.cpt-left-box {
  position: fixed;
  right: 0px;
  top: 0px ;
  padding-top:100px;
  z-index: 1;
  padding-right:66px;
  // background: rgba(3,24,66,0.7);
  // background: linear-gradient(to left, rgba(3, 24, 66,0.7), rgba(3, 24, 66, 0.5), rgba(3, 24, 66, 0.4), rgba(3, 24, 66, 0.3), rgba(3, 24, 66, 0));
  background: linear-gradient(270deg, #032251 0%, rgba(10,58,129,0.88) 46%, rgba(0,0,0,0) 100%);
  transition: all 400ms linear;
  .bigBox {
    height: 100%;
  }
  .icon{
    position: absolute;
    left: -22px;
    top: 50%;
    width: 22px;
    height: 72px;
    background: url('~@/assets/home/right-btn.png') no-repeat ;
    transform: rotate(180deg) translateY(50%);
    cursor: pointer;
    &.show{

    }
    &.hiddle{
    background: url('~@/assets/home/left-btn.png') no-repeat ;
    }
  }
  &.rightShow{
    right: 0px;
  }
  &.rightHiddle{
    right: -411px;
  }
}
</style>