<template>
  <ModuleBox
    title="入侵机型占比"
    width="100%"
    height="30vh"
    class="equipment"
  >
      <div style="display: flex;">
        <div class="TaskScenario-list mt15">
        <div  class="">
         <div class="w160 h160"  id="content-box"></div>
        </div>
         </div>
        <div class="right-box-value  mt37">
          <div class="item-box">
            <i class="item-icon"></i>
            <span class="item-label-value">DJI御mini</span>
            <span class="item-label-value2">5</span>
            <span class="item-label-value3">架</span>
          </div>
          <div class="item-box">
            <i style="background:#d26b00" class="item-icon"></i>
            <span style="color:#d26b00" class="item-label-value">DJI御2</span>
            <span style="color:#80b3e4" class="item-label-value2">2</span>
            <span class="item-label-value3">架</span>
          </div>
          <div class="item-box">
            <i style="background:#41e0ba" class="item-icon"></i>
            <span style="color:#41e0ba" class="item-label-value">DJI御2S</span>
            <span style="color:#80b3e4" class="item-label-value2">3</span>
            <span class="item-label-value3">架</span>
          </div>
          <div class="item-box">
            <i style="background:#e9483b" class="item-icon"></i>
            <span style="color:#e9483b" class="item-label-value">DJI御3</span>
            <span style="color:#80b3e4" class="item-label-value2">2</span>
            <span class="item-label-value3">架</span>
          </div>
        </div>
      </div>
        <Dialog v-if="showDetails" :title="title" :width="900" @on-close="closeDetails">
    <Table :cellName="dataList" :dataSource="dataValue"/>
    </Dialog>
  </ModuleBox>
</template>

<script>
let chart;
import { Chart } from "@antv/g2";
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import highcharts from "highcharts";

export default {
    components: { ModuleBox ,Table,Dialog},
    data() {
      return {
        typeList: [
          {
            id: 1,
            title: '无人机',
            value: ''
          },
          {
            id: 2,
            title: '挂载',
            value: ''
          },
          {
            id: 3,
            title: '电池',
            value: ''
          },
          {
            id: 4,
            title: '车辆',
            value: ''
          },
          {
            id: 5,
            title: '机库',
            value: ''
          }
        ],
        typeValue: 1,
                    dataList:[
        {title:'预警设备',name:'name',},
        {title:'预警地点',name:'type'},
        {title:'预警时间',name:'status'},
      ],
      dataValue:[],
      showDetails:false,
      title:'',
      value:''
      }
    },
    methods: {
        details(title,value){
      this.title=title
      this.value=value
      this.showDetails=true
      console.log(this.showDetails);
    },
        closeDetails(){
      this.showDetails=false
    },
      ChangeTaskType() {
        console.log('typeValue', this.typeValue);
      },
      initOption() {

        this.dataList= [
    {
        "name": "DJI御mini",
        "y": 5,
        "percent": 0.8125,
        "h": 0,
        "bfb": 0
    },
    {
        "name": "DJI御2",
        "y": 2,
        "percent": 0.0625,
        "h": 0,
        "bfb": 0
    },
    {
        "name": "DJI御2S",
        "y": 3,
        "percent": 0.03125,
        "h":0,
        "bfb": 0
    },
    {
        "name": "DJI御3",
        "y": 2,
        "percent": 0.09375,
        "h":0,
        "bfb": 0
    }
]
      let quantity = 0; // 总数
      this.dataList.forEach((item) => {
        quantity += item.y;
      });
      this.dataList.forEach((item) => {
        item.bfb = parseInt((item.y / quantity) * 100);
        item.h = item.bfb * 0.5 >= 40 ? 40 : item.bfb * 0.5
        // item.h = parseInt(0.86 * item.bfb); // 最高高度60，根据比例渲染高度
        // console.log(this.dataList, "dataList----->>>");
      });
      // 修改3d饼图绘制过程
      var each = highcharts.each,
        round = Math.round,
        cos = Math.cos,
        sin = Math.sin,
        deg2rad = Math.deg2rad;
      highcharts.wrap(
        highcharts.seriesTypes.pie.prototype,
        "translate",
        function (proceed) {
          proceed.apply(this, [].slice.call(arguments, 1));
          // Do not do this if the chart is not 3D
          if (!this.chart.is3d()) {
            return;
          }
          var series = this,
            chart = series.chart,
            options = chart.options,
            seriesOptions = series.options,
            depth = seriesOptions.depth || 0,
            options3d = options.chart.options3d,
            alpha = options3d.alpha,
            beta = options3d.beta,
            z = seriesOptions.stacking
              ? (seriesOptions.stack || 0) * depth
              : series._i * depth;
          z += depth / 2;
          if (seriesOptions.grouping !== false) {
            z = 0;
          }
          each(series.data, function (point) {
            var shapeArgs = point.shapeArgs,
              angle;
            point.shapeType = "arc3d";
            var ran = point.options.h;
            shapeArgs.z = z;
            shapeArgs.depth = depth * 0.75 + ran;
            shapeArgs.alpha = alpha;
            shapeArgs.beta = beta;
            shapeArgs.center = series.center;
            shapeArgs.ran = ran;
            angle = (shapeArgs.end + shapeArgs.start) / 2;
            point.slicedTranslation = {
              translateX: round(
                cos(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
              translateY: round(
                sin(angle) * seriesOptions.slicedOffset * cos(alpha * deg2rad)
              ),
            };
          });
        }
      );
      (function (H) {
        H.wrap(
          highcharts.SVGRenderer.prototype,
          "arc3dPath",
          function (proceed) {
            // Run original proceed method
            var ret = proceed.apply(this, [].slice.call(arguments, 1));
            ret.zTop = (ret.zOut + 0.5) / 100;
            return ret;
          }
        );
      })(highcharts);
      highcharts.chart('content-box', {
        chart: {
          animation: true,
          backgroundColor: "none",
          type: "pie", //饼图
          margin: [0, 0, 0, 0],
          options3d: {
            enabled: true, //使用3d功能
            alpha: 20, //延y轴向内的倾斜角度
            beta: 0,
          },
          title: {
		floating:true,
		text: '圆心显示的标题'
	},
          events: {
            load: function () {
              var each = highcharts.each,
                points = this.series[0].points;
              each(points, function (p, i) {
                p.graphic.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side1.attr({
                  translateY: -p.shapeArgs.ran,
                });
                p.graphic.side2.attr({
                  translateY: -p.shapeArgs.ran,
                });
              });
            },
          },
        },
        legend: {
          enabled: false, // 关闭图例
          align: "right", //水平方向位置
          verticalAlign: "top", //垂直方向位置
          layout: "vertical",
          x: 20,
          y: 30,
          symbolWidth: 1,
          symbolHeight: 1,
          symbolRadius: "50%", // 修改成圆
          itemMarginBottom: 8,
          useHTML: true,
          itemStyle: {
            color: "#f4f4f6",
            fontSize: 12,
          },
        },
        title: {
          // enabled: false,
          text: "",
        },
             subtitle: {
            floating: true,
          text: "入侵机型",
          align:'center',
          verticalAlign:"middle",
          style:{
            color:'#c0d8fa'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: false, // 禁用点击
            cursor: "pointer",
            depth: 15,
            showInLegend: false,
            size: "78%", // 外圈直径大小
            innerSize:55, // 内圈直径大小
            center: ["50%", "55%"],
            colors: [
              "#0ecbff",
              "#d26b00",
              "#41e0ba",
              "#e9483b",
              "rgb(13,145,239)",
              "rgb(167,67,234)",
            ],
            dataLabels: {
              useHTML: true,
              enabled: false, //是否显示饼图的线形tip
              distance: 5,
              borderColor: '#007acc',
              align: "center",
              // verticalAlign: 'top',
              position: "right",
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              color: "#ffffff",
              style: {
                textOutline: "none",
                fontSize: 14,
              },
            },
          },
        },
        credits: {
          enabled: false, // 禁用版权信息
        },
        series: [
          {
            type: "pie",
            name: "数量",
            data: this.dataList,
          },
        ],
      });
    },
      init_antv(){
      const data = [
        { item: 'DJI御mini', count: 40, percent: 0.41 },
        { item: 'DJI御2', count: 21, percent: 0.15 },
        { item: 'DJI御2S', count: 17, percent: 0.25 },
        { item: 'DJI御3', count: 13, percent: 0.15 },
      ];





//       chart = new Chart({
//         container: 'content-box',
//         autoFit: false,
//         width: 140,
//         height: 140,
//       });
//       chart.data(data);
//       chart.scale('percent', {
//         formatter: (val) => {
//           val = val * 100 + '%';
//           return val;
//         },
//       });
//       chart.coordinate('theta', {
//         radius: 1,
//         innerRadius: 0.88,
//       });
//       chart.tooltip({
//         showTitle: false,
//         showMarkers: false
//       });
//       chart.legend('item', false);
//       // 辅助文本
//       chart
//         .annotation()
//         .text({
//           position: ['50%', '50%'],
//           content: `入侵机型
// 占比`,
//           style: {
//             fontSize: 26,
//             fill: '#fff',
//             textAlign: 'center',
//           },
//           offsetY: 0,
//         })
//       chart
//         .interval()
//         .adjust('stack')
//         .position('percent')
//         .color('item',["#61DDAA", "#2498D1", "#F6BD16", "#F6903D"])

//       chart.interaction('element-active');

//       chart.render();
      }
    },
    mounted() {
      this.ChangeTaskType()
      // this.init_antv()
      this.initOption()
    }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-select {
    .el-input__inner{
    width: 167px;
    height: 32px;
    background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
    border: 1px solid;
    border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
    backdrop-filter: blur(4px);
    font-size: 16px;
    font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
    font-weight: 400;
    color: #65ABE9;
    line-height: 24px;
  }
  .el-input .el-select__caret{
    line-height: 0;
  }
  }
    
  }
  .equipment {

    .selectBox {
      text-align: right;
      margin-right: 16px;
      .select {

      }
    }
    .TaskScenario-list{
      width: 250px;
      height: 200px;
      background: url('~@/assets/home/list-background.png') no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: 100% 100%;

      #content-box{
      }
    }
    .right-box-value{
      overflow: auto;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: space-evenly;
      color: #fff;
      // margin-left: 30px;
      height: 139;
      .item-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 17px;
        .item-icon{
                display: inline-block;
                width: 7px;
                height: 7px;
                // border-radius: 50%;
                background-color: #0ecbff;
                margin-right: 10px;
              }
              .item-label-value{
                color: #0ecbff;
                width: 120px;
              }
              .item-label-value2{
                color: #80b3e4;
                font-size: 24px;
                margin-right: 5px;
              }
      }
      
    }
  }
</style>