<template>
  <div style="height:100%">
    <dataPush v-if="haves.includes(1)"/>
    <achievements v-if="haves.includes(2)"/>
  </div>
</template>

<script>
import hiddenDanger from "./hidden-danger.vue"; // 隐患数据
import dataPush from "../../right-box/data-analysis/data-push.vue"; // 数据推送
import achievements from "./achievements.vue"; // 成果走势图
export default {
  components: { dataPush, achievements },
      data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:dataAnalysis:dataPush") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:dataAnalysis:trendOfMissionAchievements") > -1) {
      this.haves.push(2)
    }
    }
}
</script>

<style lang="less" scoped>

</style>