<template>
  <ModuleBox :title="!showList ? '飞行器机型统计' : '飞行器统计'" width="100%" height="36vh" class="approval"
    style="padding-top:30px">
    <div class="container">
      <div class="box">
        <!-- 头部 -->
        <div class="top hwb-flex">
          <div class="right">
            <div class="header" @click="details('飞行器统计')">
              <div class="name ml10">统计</div>
              <div class="num hwb-flex">
                <div class="one-num" v-for="(item, index) in count" :key="index">{{ item }}</div>

              </div>
              <div class="count">架</div>
            </div>
            <div class="left-box ml3 mr6">
              <div v-for="item in list1" :key="item.id" @click="isClickBox(item)" class="item-box cp"
                :class="item.isClick ? 'isSelect' : ''">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <!-- 图表 -->
        <div v-show="!showList" style="margin-top:20px;">
          <div id="manufacturer-chart" class="manufacturer-chart w400 h340"></div>
        </div>
        <!-- <div v-show="!showList">
           <div id="typeState-chart" class="typeState-chart" ></div>
        </div> -->
        <div v-show="showList" style="margin-top:20px;">
          <div class="bottom">
            <!-- 一条样式 -->
            <div class="slider-list" v-for="(item, index) in data" :key="index">
              <div style="width:100%">
                <div class="one-scroll-text hwb-flex">
                  <div class="one-scroll-text-left">{{ item.manufacturerName }}</div>
                  <div class="one-scroll-text-right" style="color:#24d5f3">{{ item.count }}<span
                      style="font-size:14px;color:#97AEEE"> 架</span> </div>
                </div>
                <div class="slider ">
                  <div class="tiao">
                    <div class="have" :style="{ width: (item.count / count) * 100 + '%', background: item.bgc }"></div>
                    <!-- 圆点 -->
                    <div class="dec-circular" :style="{ left: 'calc(' + (item.count / count) * 100 + '% - 4px)' }">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <Dialog v-if="showDetails" :title="title" :width="900" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValue" >
        <template #id="{ row }">
                        <div class="action">
                            {{ row.id }}
                        </div>
                    </template>
                    <template #ownerType="{ row }">
                        <div class="action">
                            {{ row.ownerType == 1 ? '个人 ' : '单位' }}
                        </div>
                    </template>
                    <!-- <template #action="{ row }">
                        <div class="action">
                            <div v-if="edit_form.type == 1">
                                <el-tooltip content="审批" placement="top" effect="light">
                                    <div class="cp iconfont f4f7 f20 icon-baogaoshenhe" @click="approval(row)"></div>
                                </el-tooltip>
                            </div>
                            <div v-else>
                                <el-tooltip content="详情" placement="top" effect="light">
                                    <div class="cp iconfont f4f7 f20 icon-chakanxiangqing" @click="approval(row)"></div>
                                </el-tooltip>
                            </div>
                        </div>
                    </template> -->
      </Table>
      <div class="DemandList_Footer" v-if="dataValue.length">
        <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper" :total="total || 0" :page-size="size || 10" />
      </div>
    </Dialog>

  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件

import { Chart, registerTheme } from "@antv/g2";
import API from "@/api";
import toDoVue from '../../right-box/flying-commission/to-do.vue';
import * as echarts from "echarts";
import "echarts-gl";
let chart;
let chart2;
export default {
  components: { ModuleBox, TimeSelect , Table , Dialog },
  data() {
    return {
      count: "",
      list: [],
      xData: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], //横坐标
      yData: [23, 24, 18, 25, 27, 28, 25], //数据
      myChartStyle: { float: "left", width: "100%", height: "400px" }, //图表样式
      list1: [
        {
          id: 1,
          name: '厂商',
          isClick: true
        },
        {
          id: 2,
          name: '机型',
          isClick: false
        },
      ],
      status_list: [],
      showList: true,
      data: [
        { name: '大疆', num: 2004, sum: 3684, col: '#F5CF41', bgc: 'linear-gradient(270deg, #FFE992 0%, #E8C75F 31%, #D3AE2D 66%, #AE6518 100%)' },
        { name: '亿航', num: 232, sum: 3684, col: '#FBFBFB', bgc: 'linear-gradient(270deg, #FFFFFF 0%, #BCF7FF 31%, #E2EDFF 66%, #C7C5FF 100%)' },
        { name: '臻迪', num: 1221, sum: 3684, col: '#EB9C35', bgc: 'linear-gradient(270deg, #FFDB92 0%, #E8A95F 31%, #D3812D 66%, #EB6335 100%)' },
        { name: '道通', num: 1220, sum: 3684 },
      ],
      data2: [
      ],
      type: 1,
            dataList:[
                { name: "id", title: "序号", slot: true, width: "100px" },
                { name: "createTime", title: "登记日期", width: "230px" },
                { name: "manufacturerName", title: "生产厂商", width: "170px" },
                { name: "productTypeName", title: "产品类型", width: "170px" },
                { name: "productModel", title: "无人机型号", width: "170px" },
                { name: "areaBelongs", title: "所属辖区", width: "170px" },
                { name: "ownerPhone", title: "联系手机", width: "170px" },
                { name: "ownerType", slot: true, title: "用户类型", width: "170px" },
                { name: "usagesName", title: "用途", width: "170px" },
                // { name: "action", slot: true, title: "操作", width: "170px" },
          ],
      dataValue:[],
      edit_form: {  // 发起需求表单字段
                type: 1, // 审批状态赛选
                ownerType: null,
                aircraftName: null,
                manufacturerName: null,
                page: "1",
                size: "10",
                purpose: null
      },
      currentPage:1,
      total:0,
      size:10,
      title:null,
      showDetails:false,

    };
  },
  methods: {
    onChangePage(val){
      this.currentPage = val
      this.edit_form.page = String(val)
      this.getPageList()
    },
    closeDetails() {
      this.showDetails = false
    },
   async getPageList(){
    let res = await API.AIRCRAFT.PageList(this.edit_form)  
      // console.log(res);
      if (res.code==200) {
        this.dataValue = res.data
        this.total = res.total
      } else {
        this.$message.error(res.message)
      }
    },
   async details(title) {
      await this.getPageList()
      this.title = title
      this.showDetails = true
    },

    async getFlightCountFn() {
      let res = await API.AIRCRAFT.getFlightCount({
        type: this.type
      })
      if (res.code == 200) {
        let count = res.data.count || 0
        let num = String(count).padStart(5, '0')
        this.count = num

        this.data = res.data.list.map(item => {
          let a = this.getColor()
          let b = this.getColor()
          let c = this.getColor()
          return ({
            ...item,
            col: a,
            bgc: `linear-gradient(270deg, ${a} 0%, ${a} 31%, ${c} 66%, ${c} 100%) `
          })

        })
      }
    },
    async getFlightCount2Fn() {
      let res = await API.AIRCRAFT.getFlightCount({
        type: 2
      })
      if (res.code == 200) {
        // this.data2 = res.data.list.map(item => {
        //   return ({
        //     name: item.aircraftName,
        //     num: item.count,
        //     status: "总量"
        //   })

        // })
        let naemList = []
        let datalsit = []
        res.data.list.forEach(element => {
          naemList.push(element.aircraftName)
          datalsit.push(element.count)
        });
        this.xData = naemList
        this.yData = datalsit


      }
    },
    getColor() {
      return '#' + Math.floor(Math.random() * 16777215).toString(16);
    },
    isClickBox(item) {
      this.type = item.id

      this.list1.forEach(v => {
        v.isClick = false
      })
      item.isClick = true
      this.list1.forEach(v => {
        if (v.isClick && v.id == 1) {
          this.showList = true
        } else if (v.isClick && v.id == 2) {
          this.showList = false
          this.init()
        }

      })
    },

    init() {
      const option = {
        grid: {
          top: "5%",
          height: '50%'
        },
        xAxis: {
          data: this.xData,
          axisLabel: {
            rotate: 40,
            textStyle: {
              color: '#fff', //坐标值得具体的颜色                   
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return params[0].axisValueLabel + "<br>" + "数量"+ ":" + params[0].value + "架" 
          },
        },
          yAxis: {},
          dataZoom: [
            //滑动条
            {
              xAxisIndex: 0, //这里是从X轴的0刻度开始
              show: false, //是否显示滑动条，不影响使用
              type: "inside", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
              startValue: 0, // 从头开始。
              endValue: 6, // 一次性展示几个。
            },
          ],
          series: [
            {
              type: "bar", //形状为柱状图
              data: this.yData,
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(111, 162, 135, 0.2)'
              }, itemStyle: {
                normal: {
                  barBorderRadius: 5,
                  borderWidth: 1,
                  borderType: "solid",
                  borderColor: "#73c0de",
                  shadowColor: "#5470c6",
                  shadowBlur: 3,
                  //这里是颜色
                  color: function (params) {
                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    var colorList = ['#00A3E0', '#FFA100', '#ffc0cb', '#CCCCCC', '#BBFFAA', '#749f83', '#ca8622'];
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        };
        const myChart = echarts.init(document.getElementById("manufacturer-chart"));
        myChart.setOption(option);
        //随着屏幕大小调节图表
        window.addEventListener("resize", () => {
          myChart.resize();
        });

      },
    },
    created() {

      this.getFlightCountFn()
      
    },
    async mounted() {
      await this.getFlightCount2Fn()
      this.init()

    },
  };
</script>

<style lang="scss" scoped>
.approval {
  height: 100%;

  .container {
    width: 100%;
    height: calc(100% - 44px);
    display: flex;
    align-items: center;
    justify-content: center;

    .box {
      position: relative;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      .top {
        padding: 0 0 10px 0px;
        display: flex;
        align-items: center;

        .right {
          width: 100%;
          display: flex;
          padding-left: 4px;
          height: 30px;
          align-items: center;
          justify-content: center;

          .header {
            display: flex;
            width: 218px;
            font-size: 11px;
            height: 30px;
            // background-image: url('~@/assets/img/titleBox.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            cursor: pointer;
            .name {
              line-height: 30px;
              margin-right: 10px;
              color: #dae9ff;
            }

            .num {
              width: 120px;

              .one-num {
                width: 16px;
                height: 27px;
                background: linear-gradient(180deg, #01a0ff 0%, #01a0ff 50%, #1784FA 50%, #1784FA 100%);
                font-family: Rajdhani-SemiBold, Rajdhani;
                border-radius: 1px;
                font-size: 22px;
                color: #FFFFFF;
                line-height: 27px;
                text-align: center;
              }
            }

            .count {
              line-height: 30px;
              margin-left: 10px;
              color: #dae9ff;
            }
          }

          .left-box {
            display: flex;

            .item-box {
              width: 72px;
              height: 26px;
              line-height: 26px;
              background: linear-gradient(90deg, #05348B 0%, #002363 50%, #05348B 100%);
              box-shadow: 0px 3px 6px 1px rgba(54, 86, 148, 0.35);
              border-radius: 0px 4px 4px 0px;
              opacity: 1;
              border: 1px solid #47ABF4;
              text-align: center;
              cursor: pointer;
              color: #fff;
            }

            .isSelect {
              background: linear-gradient(90deg, #37A5EE 0%, #265AD5 50%, #266EF5 100%);
              color: #FFFFFF;
            }
          }
        }
      }

      .status-chart {
        box-sizing: border-box;
        height: 100%;
      }

      .bottom {
        margin: 4px 4px 0 6px;
        padding: 0 14px 0 0;
        height: 246px;

        flex: 1;
        overflow-y: auto;

        .slider-list {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .item-list-left {
            color: #FFFFFF;
          }

          .slider {
            padding-bottom: 12px;
            width: 100%;

            .tiao {
              background: rgba(76, 118, 193, 0.38);
              height: 5px;
              position: relative;

              .dec-circular {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #DFFFFE;
                position: absolute;
                top: -1.5px;
                box-shadow: 0px 0px 16px #fff;
              }

              .have {
                height: 100%;
                background: linear-gradient(270deg, #92FFFD 0%, #5FB2E8 31%, #2D72D3 66%, #1F18AE 100%);
              }
            }
          }

          .one-scroll-text {
            padding: 0 0 10px 0;
            width: 100%;

            .one-scroll-text-left {
              font-size: 14px;
              font-family: AppleSystemUIFont;
              color: #FFFFFF;
            }

            .one-scroll-text-right {
              font-size: 26px;
              font-family: 'DINAlternate-Bold', 'DINAlternate';
              font-weight: bold;
              color: #00FFFF;
              line-height: 30px;
            }
          }
        }
      }
    }
  }

}
.DemandList_Footer {
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 50px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
        height: 30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background-color: #2647ee;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          // line-height: 40px;
          width: 40px;
          // height: 40px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 20px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height: 30px;


          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

</style>
