<template>
      <div class="out">
      <div class="bigbox" id="tableCyBox" :style="`${tableWidth ? `width:${tableWidth}` : `width: 100%`}`">
        <!-- 表头 -->
        <div class="header hwb-flex" :style="`${tableWidth ? `width:${tableWidth}` : ``}`">
          <div v-for="cell in cellName" :key="cell.title" :style="`${cell.width ? `width:${cell.width}` : `flex: 1`}`">
            {{cell.title}}
          </div>
          </div>
       <div class="content table-bd" @scroll="onScroll($event)" :style="`${tableWidth ? `width:${tableWidth}` : `overflow-y: auto;`}`">
      <div class="table-tr" :class="index%2 === 0?'list':'list2'" v-for="(item,index) in dataSource" :key="item.id">
          <div 
           v-for="cell in cellName" 
          :key="cell.name"
          :style="`${cell.width ? `width:${cell.width}` : `flex: 1`}`"
          >
          <template v-if="cell.slot" :style=" {maxWidth:maxw} ">
            <slot :name="cell.name" :row="item"></slot>
          </template>
          <div v-else class="tbody" :style=" {maxWidth:maxw} ">
            <template v-if="item[cell.name] + ''">
              <el-tooltip
                effect="dark"
                :open-delay="300"
                :content="item[cell.name]+''"
                placement="top"
                popper-class="my-tooltip"
              >
                <div class="content-inner">
                  {{ item[cell.name] }}
                </div>
              </el-tooltip>
            </template>
            <template v-else>
              <div class="content-inner">
                    <!-- 暂无 -->
            </div>
            </template>
          </div>
          </div>
      </div>
          <div style="font-size:20px;margin-top:20px" v-if="!dataSource.length">
              暂无数据
          </div>
       </div>
    </div>
      </div>
</template>

<script>

export default {
  props:{
    //数据对应的表头
    cellName:{
        type:Array,
        default:()=>[]
    },
    //数据
    dataSource:{
        type:Array,
        default:()=>[]
    },
    tableWidth:{
        type:String,
        default:()=>''
    },
  },
  data() {
    return {
      maxw:null,//单元格内的最大宽
    };
  },
  methods: {
    onScroll(e) {
      let list = document.getElementsByClassName("el-tooltip__popper");
      if (list.length > 0) {
        list[list.length - 1].style.display = "none";
      }
    },
  },
  mounted() {
    //单个表格内的最大宽度要按照优先级为传入表格内的可移动的总宽除以传入的字段数量，其次在没有传入表格内可移动的总宽情况下，就按照父元素的总宽来计算出当前的每一个单元格内的数据
    console.log(this.maxw = (this.tableWidth || document.getElementById('tableCyBox').offsetWidth) / this.cellName.length + 'px');
  },
};
</script>

<style lang="scss" scoped>
  .out{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .bigbox{
      height: 100%;
      overflow-x: auto;
      .header{
      font-size: 16px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #AAB7C3;
      text-align: center;
      background: rgba(3,24,66,0.4);
      padding:0 0 10px 0;
      
    }
    .content{
      width: 100%;
      height: 460px;
      color: #FFFFFF;
      .list{
        padding: 20px 0;
        background: linear-gradient(270deg, rgba(132,179,242,0) 0%, rgba(132,179,242,0.3) 52%, rgba(132,179,242,0) 100%);
      }
      .list2{
        padding: 20px 0;
        background: rgba(3,24,66,0.4);
      }
    }
    .table-bd{
      .table-tr{
        display: flex;
        .tbody{
          // max-width: 160px;
          .content-inner{
             white-space:nowrap;//禁止换行
            overflow:hidden ;//超出隐藏
            text-overflow: ellipsis//显示省略号
          }
        }
      }
    }
    }
    
  }
</style>