import uav from "./uav"
import jc from "./jc"


function prefix(meta, prefix) {
    return Object.keys(meta).reduce((obj, key) => {
        obj[`${prefix}_${key}`] = meta[key]
        return obj
    }, {})
}

export default {
    ...prefix(uav, "uav"),
    ...prefix(jc, "jc"),
}