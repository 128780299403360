<template>
  <ModuleBox @changeSelect="changeSelect" :changeSelectFlag='true' :title="select == 1 ? '排行榜' : '排行榜'" width="100%"
    height="33%" class="number-of-tasks">
    <div class="out">
      <div style="width: 100%">
        <!-- 区域选择 -->
        <!-- <div class="select jcc">
          <div class="button" @click="changeSelect(1)" :class="select == 1?'button-active':''">任务排行榜</div>
          <div class="button" @click="changeSelect(2)" :class="select == 2 ? 'button-active' : ''">飞手排行榜</div>
        </div> -->
        <!-- 表头 -->
        <div class="header ">
          <div class="cz">排名</div>
          <div class="zt">姓名</div>
          <div class="zt">所属机构</div>
          <div class="zt">{{ select == 1 ? '任务数' : '里程(km)' }}</div>
          <div class="zt" v-if="select != 1" title="作业时长(min)">作业时长</div>

        </div>
        <!-- 内容 -->
        <div class="content" id="airspaceCarousel">
          <div class="hwb-flex list" :class="index % 2 === 0 ? '' : 'list2'" v-for="(item, index) in data" :key="index">
            <div class="cz" style="color:#fff">{{ index + 1 }}</div>
            <div class="zt cf">{{ item.name }}</div>
            <div class="zt cf">{{ item.organizationName }}</div>
            <div class="zt tc cf">{{ select == 1 ? item.taskCount : item.distance }} </div>
            <div class="zt tc cf" v-if="select != 1">{{ (item.duration / 60).toFixed() }} {{ item.duration == 0 ? '' : 'h' }}
            </div>

          </div>
        </div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import API from "@/api";

export default {
  components: { ModuleBox, },
  data() {
    return {
      select: 2,
      data: [],
      timer: null,
      moveStatus: false
    };
  },
  methods: {
    changeSelect(select) {
      if (select == 1) {
        this.taskRank()
      } else {
        this.flyRank()
      }
      this.select = select

    },
    // 任务排行榜
    async taskRank() {
      let res = await API.TASK.taskRank()
      console.log(res, 'res');
      if (res.code == 200) {
        this.data = res.data.records
      }
    },
    // 飞手排行榜
    async flyRank() {
      let res = await API.TASK.flyRank()
      if (res.code == 200) {
        this.data = res.data.records
      }
    }
  },
  mounted() {
    // this.taskRank()
    this.changeSelect(1)
  },

  beforeDestroy() {
  }
};
</script>

<style lang="scss" scoped>
.number-of-tasks {
  width: 100%;

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .select {
      height: 32px;
      width: 100%;
      margin-bottom: 10px;

      .button {
        box-sizing: border-box;
        background: linear-gradient(270deg, #032251 0%, rgba(10, 58, 129, 0.88) 46%, rgba(0, 0, 0, 0) 100%);
        width: 33.33%;
        height: 100%;
        text-align: center;
        border: 1px solid #47ABF4;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: rgba(148, 179, 194, 0.7);
        line-height: 32px;
        cursor: pointer;
      }

      .button-active {
        background: linear-gradient(90deg, #37A5EE 0%, #265AD5 50%, #266EF5 100%);
        box-shadow: 0px 3px 6px 1px rgba(54, 86, 148, 0.35);
        border-radius: 4px 0px 0px 4px;
        opacity: 0.95;
        border: 1px solid #4DCEFF;
        color: #FFFFFF;
      }
    }

    .mc {
      width: 35%;
    }

    .lx {
      width: 27%;
    }

    .zt {
      // width: 25%;
      flex: 1;
    }

    .cz {
      width: 13%;
    }

    .header {
      display: flex;
      font-size: 16px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #AAB7C3;
      text-align: center;
      background: rgba(3, 24, 66, 0.4);
      padding: 10px;
      margin-top: 10px;
    }

    .content {
      height: 200px;
      overflow: auto;

      .list {
        height: 20px;
        padding: 10px 0;
        background: linear-gradient(270deg, rgba(132, 179, 242, 0) 0%, rgba(132, 179, 242, 0.3) 52%, rgba(132, 179, 242, 0) 100%);

      }

      .list2 {
        background: rgba(3, 24, 66, 0.4);
      }
    }

    .map {
      position: relative;
      height: 240px;
      border-radius: 6px;
      margin-top: 10px;
      overflow: hidden;
    }
  }
}
</style>