<template>
  <ModuleBox title="报飞备案" width="100%" height="33%" class="civilFly">
    <!-- <div class="fly-top-box">
      <div class="fly-item-box">
        <span class="item-label-icon iconfont icon-yibeian"></span>
        <span class="item-label-one">已备案</span>
        <span class="item-label-two">{{ tjData.through || 0 }}</span>
      </div>
      <div class="fly-item-box" style="color:#FFBD36;margin:0 20px">
        <i class="item-box-left"></i>
        <span style="color:#FFBD36" class="item-label-icon iconfont icon-daibeian"></span>
        <span style="color:#FFBD36" class="item-label-one">待审批</span>
        <span style="color:#FFBD36" class="item-label-two">{{ tjData.pending || 0 }}</span>
        <i class="item-box-right"></i>
      </div>
      <div class="fly-item-box" style="color:#FF563B">
        <span style="color:#FF563B" class="item-label-icon iconfont icon-beianbutongguo"></span>
        <span style="color:#FF563B" class="item-label-one">未通过</span>
        <span style="color:#FF563B" class="item-label-two">{{ tjData.notThrough || 0 }}</span>
      </div>
    </div> -->
    <div class="out">
      <div style="width: 100%">
        <!-- 表头 -->
        <div class="header hwb-flex">
          <div class="mc">申报日期</div>
          <div class="lx">申请人</div>
          <div class="bs">用户类型</div>
          <div class="zt">飞控序列号</div>
          <div class="cz">操作</div>
        </div>
        <!-- 内容 -->
        <div class="content " id="Carousel">
          <div class="hwb-flex list" :class="index % 2 === 0 ? '' : 'list2'" v-for="(item, index) in data" :key="index">
            <div class="mc" style="color:#fff">{{ item.createTime }}</div>
            <div class="lx" style="color:#fff">{{ item.applyUserName }}</div>
            <div class="bs" style="color:#fff">{{ item.applyUserType == 1 ? '个人' : '单位' }}</div>
            <div class="zt" style="color:#fff">{{ item.flightControlNumber }}</div>
            <div class="iconfont icon-renwubaogaoshenpi  cz" @click="details(item)" style="color:#70F6F9"></div>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="flag" :title="'报飞备案审批'" :width="1048" @on-close="closeDetails">
      <!-- 头部信息 -->
      <div class="dispatch">
        <!-- 需求详情单 -->
        <div class="need-top-title hwb-flex">
          <div class="left">
            <div class="dec"></div>报飞备案信息
          </div>
        </div>
        <div class="need-bottom">
          <div class="one-info"><span>申请人:</span><span style="color:#fff">{{ showDetails.applyUserName }}</span></div>
          <div class="one-info"><span>证件号码：</span><span style="color:#fff">{{ showDetails.applyUserIdNo }}</span></div>
          <div class="one-info"><span>手机号：</span><span style="color:#fff">{{ showDetails.applyUserPhone }}</span></div>
          <div class="one-info"><span>用户类型：</span><span style="color:#fff">{{ showDetails.applyUserType == '1' ? '个人' :
              '单位'
          }}</span></div>

          <!-- <div class="one-info"><span>业主单位：</span><span style="color:#fff">{{showDetails.yzdw}}</span></div>
          <div class="one-info"><span>业主单位联系人：</span><span style="color:#fff">{{showDetails.yzdwlxr}}</span></div>
          <div class="one-info"><span>业主单位联系人电话：</span><span style="color:#fff">{{showDetails.yzdwlxrdh}}</span></div> -->

          <div class="one-info"><span>无人机名称：</span><span style="color:#fff">{{ showDetails.productName }}</span></div>
          <div class="one-info"><span>飞行序列号：</span><span style="color:#fff">{{ showDetails.flightControlNumber }}</span>
          </div>
          <div class="one-info"><span>飞控账号：</span><span style="color:#fff">{{ showDetails.flightControlAccount }}</span>
          </div>
          <div class="one-info"><span>空机重量：</span><span style="color:#fff">{{ showDetails.deviceWeight }}KG</span></div>

          <div class="one-info"><span>装载物品：</span><span style="color:#fff">{{ showDetails.loadTheGoods }}</span></div>

          <div class="one-info"><span>飞行半径：</span><span style="color:#fff">{{ showDetails.flightRadius }}m</span></div>
          <div class="one-info"><span>飞行高度：</span><span style="color:#fff">{{ showDetails.flightAltitude }}m</span>
          </div>
          <div class="one-info"><span>飞行区域：</span><span style="color:#fff">{{ showDetails.flightArea }}</span></div>

          <div class="one-info"><span>飞行目的：</span><span style="color:#fff">{{ showDetails.flightGoal }}</span></div>
          <div class="one-info"><span>所属区域(派出所)：</span><span style="color:#fff">{{ showDetails.jurisdiction }}</span>
          </div>
          <div class="one-info"><span>飞行日期：</span><span style="color:#fff">{{ showDetails.flightTime }}</span></div>
          <div class="one-info"><span>开始时间：</span><span style="color:#fff">{{ showDetails.flightStartTime }}
              {{ showDetails.kssj }}</span></div>
          <div class="one-info"><span>结束时间：</span><span style="color:#fff">{{ showDetails.flightEndTime }}
              {{ showDetails.jssj }}</span></div>
          <div class="one-info"></div>

        </div>
        <div style="color:#AAB7C3">飞行活动说明：</div>
        <div class="detail">
          {{ showDetails.flightActivityRemark }}
        </div>
        <el-divider></el-divider>

        <div style="color:#AAB7C3" class="mt30 mb10">审批备注</div>
        <el-input type="textarea" :rows="2" v-model="textarea">
        </el-input>
      </div>
      <div>
        <div class="button hwb-flex fr w300">
          <div @click="approvalHandClick(2)" class="yes">通过</div>
          <div @click="approvalHandClick(3)" class="no">不通过</div>
        </div>
        <div class="h60"> </div>
      </div>
    </Dialog>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import API from "@/api";
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import dataList from "./js/keeprecord.json";

export default {
  components: { ModuleBox, Dialog },
  data() {
    return {
      data: [],
      tjData: {},
      showDetails: false,
      timer: null,
      moveStatus: false,
      flag: false,
      textarea: ""
    };
  },
  methods: {
    CarouselBind() {
      document.getElementById('Carousel').addEventListener('mouseout', () => {
        this.moveStatus = false
        this.Carousel()
      })
      document.getElementById('Carousel').addEventListener('mouseover', () => {
        this.moveStatus = true
        this.CarouselStop()
      })
    },
    Carousel() {
      if (this.timer) {
        this.CarouselStop()
      }
      this.timer = setInterval(() => {
        document.getElementById('Carousel').scrollTop++
        if (document.getElementById('Carousel').scrollTop == 180 && !this.moveStatus) {
          document.getElementById('Carousel').scrollTop = 0
        }
      }, 60);
    },
    CarouselStop() {
      clearInterval(this.timer)
    },
    details(value) {
      this.showDetails = value
      this.flag = true

    },
    closeDetails() {
      this.flag = false
    },
    // 统计
    async getReportedFn() {
      let res = await API.AIRCRAFT.getReported()
      if (res.code == 200) {
        this.tjData = res.data
      }
    },
    // 待审批
    async async_get_list() {
      let res = await API.AIRCRAFT.flightPage({ type: 1 })
      if (res.code == 200) {
        this.data = res.data
      }
    },
    async approvalHandClick(num) {
      if (this.textarea == '') return this.$message.warning('请输入备注信息！')
      let res = await API.AIRCRAFT.flightApproval({
        id: this.showDetails?.id,
        auditStatus: num,
        auditRemark: this.textarea
      })
      if (res.code == 200) {
        this.$message.success('操作成功')
        this.flag = false
        this.textarea = '';
        this.getReportedFn()
        this.async_get_list()
      } if (res.code == 201) {
        this.flag = false
        this.textarea = '';
        return this.$message.warning('已审批，无需审批')
      }
    }
  },
  created() {
    this.getReportedFn()
    this.async_get_list()
  },
  mounted() {
    this.data = dataList.Sheet2
    this.CarouselBind()
    this.Carousel()
  },
  beforeDestroy() {
    this.CarouselStop()
  }
};
</script>

<style lang="scss" scoped>
/* 调度详情单样式 */
.dispatch {
  /* height: 456px; */
  width: 100%;
  overflow: auto;

  .need-task-type {
    margin-top: 16px;
    width: 300px;
    color: rgb(170, 183, 195);

    ::v-deep {
      .el-radio__label {
        color: #fff;
      }
    }
  }

  .need-top-title {
    height: 34px;

    .left {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      padding-left: 10px;
      position: relative;

      .dec {
        position: absolute;
        top: 2px;
        left: 0;
        width: 4px;
        height: 16px;
        background: #FFBD36;
        border-radius: 2px;
      }
    }

    .right {
      font-size: 14px;
      color: #AAB7C3;
    }
  }

  .need-bottom {
    margin-top: 28px;
    font-size: 14px;
    color: #AAB7C3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .info-add {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .one-info {
      width: 25%;
      margin-bottom: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .one-info-long {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .detail {
    min-height: 20px;
    margin-top: 12px;
    color: #eee;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 24px;
  }

  .button {
    margin-top: 40px;
    padding: 0 140px;
    color: #fff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;

    .no {
      width: 126px;
      height: 40px;
      background: rgba(219, 27, 27, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border: 1px solid;
      cursor: pointer;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }

    .yes {
      width: 126px;
      height: 40px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 6px;
      border: 1px solid;
      cursor: pointer;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }
  }
}

//调度详情单
::v-deep {

  .Resources_Item_Body,
  .Route_Item_Body {
    .linear-form.vertical .el-form-item {
      .fields-icon {
        width: 34px;
        height: 28px;
        background: rgba(0, 0, 0, 0.5);
        border: 1px solid #768094;
        text-align: center;
        line-height: 30px;
        margin-left: 10px;
        color: #fff;
        cursor: pointer;
      }

      .Symbol {
        margin: 0 5px;
        display: inline-block;
        width: 10px;
        height: 1px;
        background: #D8D8D8;
        vertical-align: middle;
      }

      .el-form-item__label {
        line-height: 32px;
        float: left;
        text-align: right;
        color: #AAB7C3;
      }

      .el-form-item__content {
        .el-select {
          width: 210px;
          height: 30px;
          background: rgba(0, 0, 0, 0.5);
        }

        .el-select.Battery {
          width: 443px;
          height: 30px;
          background: rgba(0, 0, 0, 0.5);
        }

        .el-input-number {
          //添加数据按钮右侧
          width: 212px;
          line-height: 38px;

          .el-input-number__increase {
            border-left: 1px solid #768094;
            height: 30px;
            line-height: 32px;
            margin-top: 2px;
          }

          //添加数据按钮左侧
          .el-input-number__decrease {
            border-right: 1px solid #768094;
            height: 30px;
            line-height: 32px;
            margin-top: 2px;
          }
        }
      }

      .el-input {
        .el-input__inner {
          border: 1px solid #768094;
          height: 30px;
          background: rgba(0, 0, 0, 0.5);
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #AAB7C3;
          line-height: 20px;
        }

        .el-input__suffix .el-input__suffix-inner .el-input__icon {
          line-height: 30px;
        }
      }
    }
  }

}

.button {
  margin-top: 40px;
  padding: 0 140px;
  color: #fff;
  font-size: 18px;
  line-height: 40px;
  text-align: center;

  .no {
    width: 126px;
    height: 40px;
    background: rgba(219, 27, 27, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    border: 1px solid;
    cursor: pointer;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  }

  .yes {
    width: 126px;
    height: 40px;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 6px;
    border: 1px solid;
    cursor: pointer;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  }
}

.civilFly {
  width: 100%;

  .fly-top-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .fly-item-box {
      position: relative;
      width: 28%;
      color: #00D2FF;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .item-label-icon {
        color: #00D2FF;
      }

      .item-label-one {
        width: 42px;
        height: 20px;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #00D2FF;
        line-height: 20px;
      }

      .item-label-two {
        width: 29px;
        height: 24px;
        font-size: 20px;
        font-family: DINAlternate-Bold, DINAlternate;
        // font-weight: bold;
        color: #00D2FF;
        // line-height: 24px;
      }

      .item-box-left {
        position: absolute;
        display: inline-block;
        left: -10px;
        width: 1px;
        height: 16px;
        background: linear-gradient(180deg, rgba(0, 223, 255, 0) 0%, #00DFFF 54%, rgba(0, 223, 255, 0) 100%);
      }

      .item-box-right {
        position: absolute;
        display: inline-block;
        right: -16px;
        width: 1px;
        height: 16px;
        background: linear-gradient(180deg, rgba(0, 223, 255, 0) 0%, #00DFFF 54%, rgba(0, 223, 255, 0) 100%);
      }
    }
  }

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    text-align: center;

    .mc {
      width: 25%;
    }

    .lx {
      width: 15%;
    }

    .zt {
      width: 22%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .bs {
      width: 29%;
    }

    .cz {
      width: 13%;
    }

    .header {
      font-size: 14px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #C4E5FFFF;
      text-align: center;
      background: rgba(3, 24, 66, 0.4);
      padding: 0 0 10px 0;

    }

    .content {
      height: 220px;
      overflow: auto;

      .list {
        font-size: 14px;
        height: 20px;
        padding: 10px 0;
        background: #2A459F;
        border-radius: 0px 0px 0px 0px;
        opacity: 0.95;


      }

      .list2 {
        background: rgba(3, 24, 66, 0.4);
      }
    }
  }
}

::v-deep .el-textarea__inner {
  display: inline-block;
  box-sizing: border-box;
  background: #020b1d !important;
  background: transparent;
  border: 1px solid rgba(12, 34, 67, 1) !important;
  border-radius: 5px;
  font-size: 16px;
  color: #9fc4ff;
  letter-spacing: 0;
}

::v-deep .el-divider {
  background-color: #6d6b9654;
}
</style>
