<template>
  <ModuleBox title="侦测数据" width="100%" height="33%" class="approval">
    <div class="top-box">
      <div class="left-box">
        <div class="left hwb-flex">
          <div class="text f14">发现总架次</div>
          <div class="num hwb-flex" style="margin-bottom:10px">
            <div class="one-num">0</div>
            <div class="one-num">0</div>
            <div class="one-num">0</div>
            <div class="one-num">5</div>
            <div class="one-num">2</div>
          </div>
        </div>

        <div class="left hwb-flex">
          <div class="text f14">平均每日架次</div>
          <div class="num hwb-flex">
            <div class="one-num">0</div>
            <div class="one-num">0</div>
            <div class="one-num">0</div>
            <div class="one-num">0</div>
            <div class="one-num">2</div>
          </div>
        </div>
      </div>
      <div>
        <div id="right-box-top"></div>
      </div>
    </div>
    <div class="out">
      <div style="width: 100%;">
        <!-- 内容 -->
        <div class="content" id="Carousel">
          <div class="hwb-flex list" :class="index%2 === 0?'':'list2'" v-for="(item,index) in data" :key="index">
            <!-- <div class="iconfont icon-yujing cz"  style="color:#F93913"></div> -->
            <div class="cz">
              <img class="w30 h30" src="@/assets/images/warning.gif" alt="">
            </div>
            <div class="lx" style="color:#fff">{{item.type.length>8 ? item.type.slice(0,8)+'...':item.type}}</div>
            <div class="zt" style="color:#fff">{{item.status}}</div>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-if="showDetails" :title="title" :width="900" @on-close="closeDetails">
      <Table :cellName="dataList" :dataSource="dataValue" />
    </Dialog>
  </ModuleBox>
</template>

<script>
let chart;
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import { Chart } from "@antv/g2";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
export default {
  components: { ModuleBox, Table, Dialog },
  data() {
    return {
      data: [
        {
          type: '1号侦测设备发现存在未知设备',
          status: '2022/09/10 ',
        },
        {
          type: '12号侦测设备在浙大国际发现存在…',
          status: '2022/09/10 ',
        },
        {
          type: '3号侦测设备发现存在未知设备',
          status: '2022/09/10 ',
        },
        {
          type: '5号侦测设备在万达发现存在未知设备',
          status: '2022/09/10 ',
        },
      ],
      dataList: [
        { title: '预警设备', name: 'name', },
        { title: '预警地点', name: 'type' },
        { title: '预警时间', name: 'status' },
      ],
      dataValue: [],
      showDetails: false,
      title: '',
      value: '',
      timer: null,
      moveStatus: false
    };
  },
  methods: {
    CarouselBind() {
      document.getElementById('Carousel').addEventListener('mouseout', () => {
        this.moveStatus = false
        this.Carousel()
      })
      document.getElementById('Carousel').addEventListener('mouseover', () => {
        this.moveStatus = true
        this.CarouselStop()
      })
    },
    Carousel() {
      if (this.timer) {
        this.CarouselStop()
      }
      this.timer = setInterval(() => {
        document.getElementById('Carousel').scrollTop++
        if (document.getElementById('Carousel').scrollTop == (this.data.length - 3) * 40 && !this.moveStatus) {
          document.getElementById('Carousel').scrollTop = 0
        }
      }, 60);
    },
    CarouselStop() {
      clearInterval(this.timer)
    },
    details(title, value) {
      this.title = title
      this.value = value
      this.showDetails = true
      console.log(this.showDetails);
    },
    closeDetails() {
      this.showDetails = false
    },
    init_antv() {
      const data = [
        { item: '入侵架次', count: 12, percent: 0.24 },
        { item: '入侵总时长', count: 32, percent: 0.5 },
      ];
      chart = new Chart({
        container: 'right-box-top',
        autoFit: false,
        width: 96,
        height: 96,
      });
      chart.data(data);

      chart.scale('item', {
        formatter: (val) => {
          if (val == '入侵架次') {
            return `${data[0].item}: ${data[0].count}次
                 ${data[1].item}: ${data[1].count}h`
          } else {
            return `${data[0].item}: ${data[0].count}次
                 ${data[1].item}: ${data[1].count}h`
          }
        }
      });

      chart.coordinate('theta', {
        radius: 1,
        innerRadius: 0.88,
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl: `
    <li class="g2-tooltip-list-item">
        <span class="g2-tooltip-name">{name}</span>:<span class="g2-tooltip-value"></span>
    </li>
  `
      });
      chart.legend('item', false);
      // 辅助文本
      chart
        .annotation()
        .text({
          position: ['50%', '50%'],
          content: '23.8 %',
          style: {
            fontSize: 24,
            fill: '#FF4A00',
            textAlign: 'center',
          },
          offsetY: -10,
        })
        .text({
          position: ['50%', '50%'],
          content: `入侵架次`,
          style: {
            fontSize: 14,
            fill: '#DAE9FF',
            textAlign: 'center',
          },
          offsetX: 0,
          offsetY: 10,
        })
      chart
        .interval()
        .adjust('stack')
        .position('percent')
        .color('item', ["#FF4A00", "#5D0909"])

      chart.interaction('element-active');

      chart.render();
    }
  },
  mounted() {
    this.init_antv()
    this.CarouselBind()
    this.Carousel()
  },
  beforeDestroy() {
    this.CarouselStop()
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-box {
    flex: 1;
    margin-bottom: 10px;

    .left {
      padding-left: 4px;
      width: 200px;

      .text {
        font-family: AppleSystemUIFont;
        color: #DAE9FF;
        width: 134px;
      }

      .num {
        width: 130px;

        .one-num {
          width: 16px;
          height: 27px;
          background: linear-gradient(180deg, #01a0ff 0%, #01a0ff 50%, #1784FA 50%, #1784FA 100%);
          font-family: Rajdhani-SemiBold, Rajdhani;
          border-radius: 1px;
          font-size: 22px;
          color: #FFFFFF;
          line-height: 27px;
          text-align: center;

        }
      }
    }
  }

  .right-top-switch {
    z-index: 2;
    font-size: 20px;
    color: #DAE9FF;
    cursor: pointer;
  }

  #right-box-top {
    width: 96px;
    height: 96px;
  }
}

.out {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  // overflow: auto;
  .lx {
    width: 53%;
  }

  .zt {
    width: 40%;
  }

  .cz {
    width: 7%;
  }

  .header {
    font-size: 16px;
    font-family: SourceHanSansSC-Bold, SourceHanSansSC;
    font-weight: bold;
    color: #AAB7C3;
    text-align: center;
    background: rgba(3, 24, 66, 0.4);
    padding: 0 0 10px 0;

  }

  .content {
    height: 120px;
    overflow: auto;

    .list {
      height: 20px;
      padding: 10px 0;
      background: #2A459F;

    }

    .list2 {
      background: rgba(3, 24, 66, 0.4);
    }
  }
}
</style>