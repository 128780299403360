<template>
  <div ref="move" class="box">
        <div class="iconfont icon-guanbi close" @click="close"></div>
        <div class="title" @mouseenter="mouseDown" @mouseleave="dragmove">飞行参数模拟</div>
        <div class="select">
            <div class="left">飞行视角：</div>
            <div class="right">
                <el-radio v-model="radio" label="1">跟随视角</el-radio>
                <el-radio v-model="radio" label="2">俯视视角</el-radio>
            </div>
        </div>
        <div class="suggest">设置飞行速度（建议1-25m/s）</div>
        <div class="speed-select">
            <el-slider class="scroll" v-model="value" :min="1" :max="25"></el-slider>
            <span>飞行速度 {{value}}m/s</span>
        </div>
        <div class="bottom">
            <el-button type="primary" @click="simulationStatusHandle">{{simulationStatus?'暂停模拟':'继续模拟'}}</el-button>
            <el-button type="primary" @click="restart">重新模拟</el-button>
        </div>
  </div>
</template>

<script>
import interact from 'interactjs'
import API from "@/api";
import { transformCartesian2WGS84 } from "@/utils/cesium";
let x = 0  //  x轴的移动大小
let y = 0  //  y轴的移动大小
export default {
    props:{
        popupStates: {
            type: Function ,
            default: ()=>{},
        },
        routeSimulationData: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            radio: '1',
            value: 5,
            simulationStatus: true,
            saveFlyLine: null, // 存储飞行模拟航线，便于销毁
            uav: null, // 存储飞机模型，便于销毁
            index: 0, // 记住下标
            lerp_arr: [], // 飞行轨迹存储
            timer: null,
        }
    },
    methods: {
        moveElement(){  // 元素移动函数
            interact(this.$refs.move)
                .on('dragmove', function (event) {
                    x += event.dx
                    y += event.dy
                    event.target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
                })
        },
        mouseDown() { //鼠标进入头部开启拖动事件
            interact(this.$refs.move)
                .draggable({
                    listeners: {
                        move(event) {
                            const target = event.target
                            const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
                            const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

                            target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

                            target.setAttribute('data-x', x)
                            target.setAttribute('data-y', y)
                        },
                    },
                    modifiers: [
                        interact.modifiers.snap({
                            targets: [
                            interact.snappers.grid({ x: 2, y: 2 }) // 设置每次移动的像素
                            ],
                            range: Infinity,
                            relativePoints: [ { x: 0, y: 0 } ]
                        }),
                        interact.modifiers.restrict({  // 设置只能在父元素上移动
                            restriction: this.$refs.move.parentNode.parentNode.parentNode.parentNode,
                            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                            endOnly: true
                        })
                    ],
                    inertia: true
                })
                
        },
        dragmove() { //鼠标离开头部元素取消拖动事件
            interact(this.$refs.move).draggable(false)
        },
        close() { // 关闭弹框
            this.popupStates(false)
        },
        simulationStatusHandle() { // 暂停飞行或继续飞行
            this.simulationStatus = !this.simulationStatus
            if(this.simulationStatus){
                this.changeSpeed(this.value)
            } else {
                this.changeSpeed('stop')
            }
        },
        restart() { // 重新开始模拟
            this.value = 5
            this.simulationStatus = true
            this.changeSpeed('restart')
        },

        // 渲染航线
        renderRoute(val) {
            this.index = 0;
            //------------------------------------------------------航线
            let { points } = JSON.parse(val.flightCourseJson);
            let poi = [];
            points.map((item) => {
                poi.push(Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt));
            });
            this.saveFlyLine = new MMCM.DrawAirwayHandler({
                viewer: viewer,
                positions: poi,
                config: {
                isReadonly: true, // 是否可以操作
                },
            });
            // ------------------------------------------------------飞机
            let positions = points.map((item) => {
                return Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt);
            });
            let pos = positions.map(
                (item) => new Cesium.Cartesian3(item.x, item.y, item.z)
            );
            pos.reduce(
                (total, item, index) =>
                index > 0
                    ? (total += Cesium.Cartesian3.distance(pos[index - 1], item))
                    : total,
                0
            );

            const lerp = (start, end, c) => {
                let arr = [];
                const count = parseInt(`${c}`);
                for (let i = 0; i < count; i++) {
                arr.push(
                    Cesium.Cartesian3.lerp(
                    start,
                    end,
                    i / count,
                    new Cesium.Cartesian3()
                    )
                );
                }
                return arr;
            };

            pos.forEach((item, index) => {
                if (index > 0) {
                const dist = Cesium.Cartesian3.distance(pos[index - 1], pos[index]);
                this.lerp_arr.push(...lerp(pos[index - 1], pos[index], dist));
                }
            });

            this.uav = viewer.entities.add({
                position: this.lerp_arr[this.index],
                model: {
                // 模型路径
                uri: "uav/uav_v2.glb",
                minimumPixelSize: 100,
                },
            });
            viewer.flyTo(this.uav);
            viewer.trackedEntity = this.uav;
            this.value = val.speed
            this.changeSpeed(this.value);
        },
        calcuAngle(pointA, pointB) {  // fei
            const transform = Cesium.Transforms.eastNorthUpToFixedFrame(pointA);
            const positionvector = Cesium.Cartesian3.subtract(
                pointB,
                pointA,
                new Cesium.Cartesian3()
            );
            const vector = Cesium.Matrix4.multiplyByPointAsVector(
                Cesium.Matrix4.inverse(transform, new Cesium.Matrix4()),
                positionvector,
                new Cesium.Cartesian3()
            );
            const direction = Cesium.Cartesian3.normalize(
                vector,
                new Cesium.Cartesian3()
            );
            const heading =
                Math.atan2(direction.y, direction.x) - Cesium.Math.PI_OVER_TWO;
            return Cesium.Math.TWO_PI - Cesium.Math.zeroToTwoPi(heading);
        },
        // 改变速度
        changeSpeed(speed) {
        if (speed === "stop") {
            clearInterval(this.timer);
        } else if (speed === "restart") {
            this.index = 0;
            if (this.timer) {
            clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
            this.index++;
            if (this.lerp_arr[this.index]) {
                this.uav.position = this.lerp_arr[this.index];
                let heading = this.calcuAngle(
                this.lerp_arr[this.index - 1],
                this.lerp_arr[this.index]
                );
                heading = Cesium.Math.toRadians(
                Number((heading * 180) / Math.PI - 90)
                );
                const hpr = new Cesium.HeadingPitchRoll(heading, 0, 0);
                let ort = Cesium.Transforms.headingPitchRollQuaternion(
                this.lerp_arr[this.index],
                hpr
                );
                this.uav.orientation = ort;

            }
            }, 1000 / 5);
        } else {
            if (this.timer) {
            clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
            this.index++;
            if (this.lerp_arr[this.index]) {
                this.uav.position = this.lerp_arr[this.index];
                let heading = this.calcuAngle(
                this.lerp_arr[this.index - 1],
                this.lerp_arr[this.index]
                );
                heading = Cesium.Math.toRadians(
                Number((heading * 180) / Math.PI - 90)
                );
                const hpr = new Cesium.HeadingPitchRoll(heading, 0, 0);
                let ort = Cesium.Transforms.headingPitchRollQuaternion(
                this.lerp_arr[this.index],
                hpr
                );
                this.uav.orientation = ort;

            }
            }, 1000 / speed);
        }
        },
        //改变飞行视角
        changeView(view) {
        const aa = transformCartesian2WGS84(this.lerp_arr[this.index]);
        if(view === '1') {
            const center = Cesium.Cartesian3.fromDegrees(aa.lng, aa.lat, aa.alt);
            const heading = Cesium.Math.toRadians(20.0);
            const pitch = Cesium.Math.toRadians(-20.0);
            const range = 40;
            viewer.camera.lookAt(center, new Cesium.HeadingPitchRange(heading, pitch, range));
        } else {
            viewer.camera.setView({
                destination: Cesium.Cartesian3.fromDegrees(
                aa.lng,
                aa.lat,
                aa.alt + 3000,
                ),
            });
        }
        
        },
        // 移除飞行模拟
        clearFlySimulation() {
            viewer.entities.remove(this.uav);
            this.uav = null;
            this.lerp_arr = [];
            if (this.saveFlyLine) {
                this.saveFlyLine.destroy();
            }
        },
    },
    watch: {
        value(val) {  // 监视速度的变化
            if(this.simulationStatus){
                this.changeSpeed(val)
            }
        },
        radio(val) { // 监视视角的变化
            this.changeView(val)
        }
    },
    mounted() {
        this.moveElement()
        this.renderRoute(this.routeSimulationData)
    },
    beforeDestroy() {
        this.clearFlySimulation()  // 销毁航线模拟
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
    
}
</script>

<style lang="scss" scoped>
::v-deep{
    .el-radio__input.is-checked+.el-radio__label {
        color: #44b1fa;
    }
    .el-radio__input.is-checked .el-radio__inner {
        border-color: #44b1fa;
        background: #44b1fa;
    }
    .el-radio{
        color: #ddd;
    }
}
.box{
    z-index: 1000;
    position: absolute;
    width: 500px;
    top: 100px;
    right: 40px;
    background: rgba(8, 37, 79, 0.8);
    border-radius: 2px;
    border: 2px solid #44b1fa;
    color: #44b1fa;
    padding: 20px;
    .title{
        font-size: 18px;
        margin-bottom: 16px;
        text-align: center;
        font-weight: bolder;
        border-bottom: 2px solid #44b1fa;
        padding-bottom: 10px;
    }
    .close{
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 18px;
        cursor: pointer;
    }
    .select{
        display: flex;
        justify-content: left;
        align-items: center;
        .left{
            font-size: 16px;
            margin-right: 20px;
            font-weight: bold;
        }
    }
    .suggest {
        font-size: 18px;
        margin-top: 20px;
    }
    .speed-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .scroll {
            width: 350px;
        }
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 70px 0 70px;
    }
}
</style>