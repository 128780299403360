<template>
  <!-- 左右两侧数据面板里面每个模块的 外壳 -->
  <div class="cpt-module-box" :style="`width:${width};height:${height};`">
    <div class="hd pr ">
      <div class="title">
        {{ title }} <div v-if="changeSelectFlag ||changeFlag" class="select  pa w380 top0 f12 cp jcc" style="right:-124px">
          <div class="button" @click="changeSelect(1)" :class="select == 1 ? 'button-active' : ''">{{changeFlag ?'六旋翼':'任务排行榜'}}</div>
          <div class="button" @click="changeSelect(2)" :class="select == 2 ? 'button-active' : ''">{{changeFlag ?'四旋翼':'飞手排行榜'}}</div>
        </div>
      </div>
    </div>
    <div class="bd">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "0px",
    },
    height: {
      type: String,
      default: "0px",
    },
    changeSelectFlag:{
      type: Boolean,
      default:false,
    },
    changeFlag:{
      type: Boolean,
      default:false,
    }
  },
  data() {
    return {
      select: 1
    }
  },
  methods: {
    changeSelect(num) {
      this.select = num
      this.$emit('changeSelect', num)
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-module-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  // overflow: hidden;

  .hd {
    background: 50% url(./img/7.png) no-repeat;
    width: 100%;
    background-size: 100%;
    height: 36px;
    margin-bottom: 28px;

    .title {
      font-size: 18px;
      font-weight: 600;
      font-family: Barlow;
      color: #FFFFFF;
      line-height: 30px;
      margin-left: 60px;
      text-shadow: 0px 0px 6px #00CBFF;

      ;
    }


  }

  .bd {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .select {
    height: 32px;
    width: 100%;
    margin-bottom: 10px;

    .button {
      box-sizing: border-box;
      background: linear-gradient(270deg, #032251 0%, rgba(10, 58, 129, 0.88) 46%, rgba(0, 0, 0, 0) 100%);
      width: 23.33%;
      height: 100%;
      text-align: center;
      border: 1px solid #47ABF4;
      font-size: 14px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: rgba(148, 179, 194, 0.7);
      line-height: 32px;
      cursor: pointer;
    }

    .button-active {
      background: linear-gradient(90deg, #37A5EE 0%, #265AD5 50%, #266EF5 100%);
      box-shadow: 0px 3px 6px 1px rgba(54, 86, 148, 0.35);
      border-radius: 4px 0px 0px 4px;
      opacity: 0.95;
      border: 1px solid #4DCEFF;
      color: #FFFFFF;
    }
  }
}
</style>