var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ModuleBox',{staticClass:"number-of-tasks",attrs:{"title":"隐患数据","width":"100%","height":"50%"}},[_c('div',{staticClass:"out"},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"selectBox"},[_c('div',{staticClass:"dec iconfont icon-jigoumingcheng"}),_c('div',{staticClass:"dec2"}),_c('el-cascader',{staticStyle:{"margin-right":"10px"},attrs:{"popper-class":"input-orgname","placeholder":"请选择所属机构","options":_vm.selectList,"show-all-levels":false,"props":{
          children: 'child',
          label: 'name',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
          empty: false
        },"clearable":""},on:{"change":function($event){return _vm.updataflyValue()}},model:{value:(_vm.organId),callback:function ($$v) {_vm.organId=$$v},expression:"organId"}})],1),_c('div',{staticClass:"top hwb-flex"},[_c('div',{staticClass:"left hwb-flex"},[_c('div',{staticClass:"text"},[_vm._v("总隐患数")]),_c('div',{staticClass:"num hwb-flex"},[_c('div',{staticClass:"one-num"},[_vm._v("0")]),_c('div',{staticClass:"one-num"},[_vm._v("0")]),_c('div',{staticClass:"one-num"},[_vm._v("0")]),_c('div',{staticClass:"one-num"},[_vm._v("0")]),_c('div',{staticClass:"one-num"},[_vm._v("3")])]),_c('div',{staticClass:"text"},[_vm._v("条")])]),_c('div',{staticClass:"right"})]),_c('div',{staticClass:"bottom"},_vm._l((_vm.data),function(item){return _c('div',{key:item.name,staticClass:"slider-list hwb-flex-column"},[_c('div',{staticClass:"one-scroll-text hwb-flex"},[_c('div',{staticClass:"one-scroll-text-left"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"one-scroll-text-right",style:({color:item.color})},[_vm._v(_vm._s(item.num))])]),_c('div',{staticClass:"slider"},[_c('div',{staticClass:"tiao"},[_c('div',{staticClass:"have",style:({width: (item.num/item.sum)*100 + '%',background:item.bg})}),_c('div',{staticClass:"dec-circular",style:({left:'calc(' +(item.num/item.sum)*100 + '% - 4px)'})})])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }