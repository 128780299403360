<template>
  <div style="height:100%">
    <TaskOverview />
    <SceneProportion />
    <OperationalOverview />
  </div>
</template>

<script>
import TaskOverview from "./task-overview.vue"; // 任务概览
import SceneProportion from "./scene.vue"; // 场景应用占比
import OperationalOverview from "./operational-overview.vue"; // 政务量运行概览
export default {
  components: { TaskOverview, SceneProportion, OperationalOverview },
}
</script>

<style lang="less" scoped>

</style>