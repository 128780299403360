<template>
  <div class="application_3D">
    <Menu @on-Click="showList" :iconList="list" />
    <div class="dispatch-menu">
      <component @closeDemand="closeDemand" :is="type[showListType]"> </component>
    </div>
  </div>
</template>
  
<script>
import Menu from "@/components/newmenu/menu";
import Layer from "../components/layer/index.vue";
import Tagging from "../components/tagging/index.vue";
import Tag from "../components/Tag/index.vue";
import Programme from "../components/Programme/index.vue";



export default {
  components: { Menu },
  data() {
    return {
      list:
        [
          {
            id: 1,
            name: '图层',
          },
          {
            id: 2,
            name: '标签',
          },
          {
            id: 3,
            name: '标注',
          },
          // {
          //   id: 4,
          //   name: '方案',
          // }
        ],
      showListType: 1, // 控制展示左侧哪个导航
      type: {
        1: Layer,
        2: Tag,
        3: Tagging,
        4: Programme,
      }
    }
  },

  methods: {
    closeDemand() {
      this.showListType = false
    },
    showList(item) {
      this.showListType = item.id
      this.$forceUpdate()
    },
  },
  created() {
    if (!window.viewer.scene.globe.show) {
      window.plate_data_source.show = false
      window.viewer.scene.globe.show = true
    }
  },
}
</script>
  
<style lang="scss" scoped>
.application_3D {
  position: absolute;
  top: 76px;
  left: 0;
  z-index: 1;
  width: 108px;
  height: 100%;
}
</style>