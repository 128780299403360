<template>
  <div class="hwb-flex list" :class="index%2 === 0?'':'list2'">
    <div class="mc" style="color:#fff">
      <el-tooltip class="item" effect="dark" :content="source.taskTitle" placement="top-start">
        <span>{{(source.taskTitle && source.taskTitle.length>6) ? source.taskTitle.slice(0,7)+'...':source.taskTitle}}</span>
      </el-tooltip>
    </div>
    <div class="lx" :style="{color:source.taskCateName === '常态任务'? '#6AFF64' : '#FFBD36'}">{{source.taskCateName}}</div>
    <div class="zt" :style="{color:source.disposeStatus === 1?'#00D2FF':'#FFBD36'}">{{source.disposeStatus === 1? '待派单':'待审批'}}</div>
    <!-- <div @click="approvalDistribution(source)" class="iconfont  cz" :class="source.disposeStatus === 1? 'icon-daipaidan':'icon-shenpi'" style="color:#70F6F9"></div> -->
    <div class="cz"><img style="margin:0 auto;" src="./img/approval.png" @click="approvalDistribution(source)"  /></div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default() {
        return 0
      }
    },
    approvalDistribution: {
      type: Function,
      default(){
        return ()=>{}
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.mc{
    width: 35%;
  }
  .lx{
    width: 27%;
  }
  .zt{
    width: 25%;
  }
  .cz{
    width: 13%;
  }
.list{
  height: 20px;
  padding: 10px 0;
  // background: linear-gradient(270deg, rgba(132,179,242,0) 0%, rgba(132,179,242,0.3) 52%, rgba(132,179,242,0) 100%);
  background: #2A459F;
}
.list2{
  // background: rgba(3,24,66,0.4);
  background: transparent;
}
</style>