<template>
  <ModuleBox title="地图标注" width="100%" style="" height="67%" class="home-tagging ">
    <div class="body ofh">
      <div class="top">
        <div class="text">
          <div v-for="item in list" :key="item.id" class="written mr15"
            :style="`color: ${item.color}; border-right: solid 1px ${item.color};`">
            <span class="f14" :class="`iconfont ${item.icon}`" :style="`color: ${item.color}; margin-right: 4px`"></span>{{
            item.title }} {{ item.num }}
          </div>
        </div>
        <div class="seach">
          <el-input placeholder="请输入标注名称" size="mini" v-model="search" class="input-with-name" clearable>
            <el-button slot="append" icon="el-icon-search" style="padding: 0;" @click="seachFn"></el-button>
          </el-input>
        </div>
      </div>
      <div class="bottom">
        <div class="content-out">
          <div class="box" v-for="(item,i) in records" :key="i">
            <div class="box-body">
              <div class="content">
                <div class="w120 h50 ">
                  <img class="wih100 ht100 dib" :src="item.img||img"></img>
                </div>
                <div class="content-text">
                  <div class="name">{{item.title}}</div>
                  <div class="grade mt4"><span>等级：{{item.level}}</span><span
                      style="margin-left: 20px;">{{item.addtime}}</span></div>
                  <div class="type text_ellipsis ">
                    <div class='mr4 mt5 p3 cf f12' style="backgroundColor:#1F79E2FF;color:#fff"
                      v-for="(tag,j) in item.tagList">{{tag.name}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="address" @click="showArea(item)">
                <span class="iconfont icon-weizhi f14" :class="item.addressStatus ? 'f569':'cf'"></span>
                {{item.address}}
              </div>
            </div>

          </div>

        </div>
        <!-- <div class="content-out" v-else>
          <div class="box">
            <div class="box-body">
              <div class="content">
                <div class="img"></div>
                <div class="content-text">
                  <div class="name">模拟观潮保卫</div>
                  <div class="grade"><span>等级 ：1</span><span style="margin-left: 20px;">2022-8-20</span></div>
                  <div class="type">
                    <div class="button">人员</div>
                    <div class="button">设备</div>
                  </div>
                </div>
              </div>
              <div class="address">
                <span class="iconfont icon-weizhi"></span>
                浙江省嘉兴市海宁市翁金公路
              </div>
            </div>
          </div>


          <div class="box">
            <div class="box-body">
              <div class="content">
                <div class="img1"></div>
                <div class="content-text">
                  <div class="name">浙大校区模拟部署</div>
                  <div class="grade"><span>等级 ：1</span><span style="margin-left: 20px;">2022-8-20</span></div>
                  <div class="type">
                    <div class="button">河道污染</div>
                    <div class="button">排污</div>
                    <div class="button">占压</div>
                  </div>
                </div>
              </div>
              <div class="address">
                <span class="iconfont icon-weizhi"></span>
                海宁市海昌街道光耀小区北一区020号
              </div>
            </div>
          </div>

          <div class="box">
            <div class="box-body">
              <div class="content">
                <div class="img3"></div>
                <div class="content-text">
                  <div class="name">市政中心模拟部署</div>
                  <div class="grade"><span>等级 ：1</span><span style="margin-left: 20px;">2022-8-12</span></div>
                  <div class="type">
                    <div class="button">人员</div>
        
                    <div class="button">设备</div>
                    <div class="button">车辆</div>
                  </div>
                </div>
              </div>
              <div class="address">
                <span class="iconfont icon-weizhi"></span>
                海洲西路226号市行政中心3号楼
              </div>
            </div>
          </div>

        </div> -->

      </div>
    </div>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import API from "@/api";
let markerArr = []
import {
  createPoint,
  createLine,
  createPolygon,
} from '@/utils/Cesium/CreateLabel'
export default {
  components: {
    ModuleBox
  },
  data() {
    return {
      list: [],
      records: [],//标注数据
      img: require('@/assets/img/acquiescent.png'),
      search: null,
      VUE_APP_IMGIP: ""
    }
  },
  created() {
    const { VUE_APP_IMGIP } = process.env;
    this.VUE_APP_IMGIP = VUE_APP_IMGIP
    this.totalMarkByTypeFn()
    this.getmarkerListsFn()
  },
  methods: {
    showArea(item) {
      if (window.plate_data_source.show = true) {
        viewer.scene.globe.show = true
        window.plate_data_source.show = false
      }
      item.addressStatus = !item.addressStatus
      let marker = markerArr.filter((e) => e.markerId == item.markerId)
      if (marker.length > 0) {
        viewer.entities.remove(marker[0].entities)
        markerArr = markerArr.filter(e => e.markerId != item.markerId)
        return
      }
      let state;
      let positions = JSON.parse(item.geom)
      if (item.type == 1) {
        state = 'point'
      } else if (item.type == 2) {
        state = 'line'
      } else if (item.type == 3) {
        state = 'polygon'
      }
      let data = {
        positions,
        image: this.VUE_APP_IMGIP + item.icon,
        title: item.title,
        state,
        color: item.style,
        methodState: 'add',
        width: item.length,
        polyCenter: Cesium.BoundingSphere.fromPoints(positions).center,
        outlineColor: '#238ff3',
        markerId: item.markerId
      }
      data = this.createLabelEntities(viewer, data, state)
      markerArr.push(data)
    },
    //  创建标注实例
    createLabelEntities(viewer, data, state) {
      let entities
      if (state == 'point') {
        entities = createPoint(viewer, {
          position: data.positions[0],
          image: data.image,
          title: data.title,
        })
      } else if (state == 'line') {
        entities = createLine(viewer, {
          polyCenter: data.polyCenter,
          positions: data.positions,
          width: data.width,
          color: data.color,
          title: data.title,
        })
      } else {
        entities = createPolygon(viewer, {
          polyCenter: data.polyCenter,
          positions: data.positions,
          color: data.color,
          title: data.title,
          outlineColor: data.outlineColor,
        })
      }
      data.entities = entities
      viewer.flyTo(entities)
      return data
    },
    async totalMarkByTypeFn() {
      let res = await API.TASK.totalMarkByType()
      let dataList = []
      if (res.code == 200) {
        res.data.forEach(e => {
          if (e.type == 1) {
            dataList.push({
              id: 1,
              title: '点标注',
              num: e.level,
              color: 'rgba(0, 210, 255, 1)',
              icon: 'icon-dianbiaozhu'
            })
          } else if (e.type == 2) {
            dataList.push({
              id: 2,
              title: '线标注',
              num: e.level,
              color: 'rgba(255, 189, 54, 1)',
              icon: 'icon-xianbiaozhu'
            })
          } else {
            dataList.push({
              id: 3,
              title: '面标注',
              num: e.level,
              color: 'rgba(87, 209, 83, 1)',
              icon: 'icon-mianbiaozhu'
            })
          }
        })
        this.list = dataList
      }
    },
    // 获取标注列表
    async getmarkerListsFn(type) {
      let res = await API.MODEL.getmarkerList({
        pageNo: 1,
        pageSize: 1000,
        title: this.search || null
      })
      if (res.code == 200) {
        this.records = res.data.records.map((item) => ({
          ...item,
          addressStatus: false
        }))
        this.records.sort((a,b)=>{
          return new Date(b.addtime) - new Date(a.addtime)
        })
      }
    },
    seachFn() {
      this.getmarkerListsFn()
    }
  },
  beforeDestroy() {
    markerArr.forEach(element => {
      viewer.entities.remove(element.entities)

    });
    markerArr = []
  }
}
</script>

<style lang="scss" scoped>
 ::v-deep .el-icon-search{
  color: #FFFFFF;
}
.f569 {
  color: #00f569
}

.home-tagging {
  ::v-deep .top {
    .text {
      display: flex;
      justify-content: space-between;

      .written {
        width: 116px;
        height: 20px;

        &:last-child {
          border-right: none !important
        }
      }
    }

    .seach {
      padding-top: 15px;

      .input-with-name {
        .el-input__inner {
          background: rgba(5, 16, 39, 0.4);
          border: 1px solid #1F79E2;
        }

        .el-input-group__append {
          background-color: #1F79E2;
          border: none;
          text-align: center;
        }
      }
    }
  }

  .body {
    height: 100%;
  }

  .bottom {
    overflow-y: auto;
    height: calc(100% - 100px);
    margin-bottom: 10px;

    margin-top: 24px;

    .content-out {
      overflow-y: auto;
      height: 100%;
    }

    .box {
      min-height: 111px;
      max-height: 200px;
      background: rgba(0, 5, 24, 0.25);
      margin-bottom: 10px;
      overflow: hidden;

      &-body {
        margin: 16px 0 16px 16px;

        .content {
          display: flex;

          .img {
            background: url("~@/assets/three/gc.png") no-repeat;
            background-size: cover;
            min-width: 120px;
            width: 120px;
            height: 80px;
            border: 1px solid #979797;
          }

          .img1 {
            background: url("~@/assets/three/zd.png") no-repeat;
            background-size: cover;
            min-width: 120px;
            width: 120px;
            height: 80px;

            border: 1px solid #979797;
          }

          .img3 {
            background: url("~@/assets/three/szzx.png") no-repeat;
            background-size: cover;
            min-width: 120px;
            width: 120px;
            height: 80px;
            border: 1px solid #979797;
          }

          &-text {
            margin-left: 14px;

            .name {
              height: 19px;
              font-size: 16px;
              font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
              font-weight: 800;
              color: #FFFFFF;
              line-height: 16px;
              overflow: hidden;
              white-space: none;
              text-overflow: ellipsis;
            }

            .grade {
              width: 100%;
              font-size: 12px;
              font-family: SourceHanSansSC-Regular, SourceHanSansSC;
              font-weight: 400;
              color: #C8DFFFFF;
            }

            .type {
              flex-wrap: wrap;
              display: flex;

              .button {
                width: 68px;
                height: 28px;
                background: #1F79E2;
                border-radius: 4px;
                margin-right: 8px;
                margin-top: 8px;


                font-size: 12px;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 28px;
              }
            }
          }
        }



        .address {
          width: 300px;
          height: 24px;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
        }
      }
    }
  }
}
</style>