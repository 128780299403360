<template>
  <div @mouseenter="timeSelectIsShow = true" @mouseleave="timeSelectIsShow = false"
    class="iconfont icon-shaixuan time-icon">
    <div v-if="timeSelectIsShow" class="time-select hwb-flex-column">
      <div class="time-select-text" :class="timeHasSelect === 1?'select':''" @click="selectTime(1)">日</div>
      <div class="time-select-text" :class="timeHasSelect === 2?'select':''" @click="selectTime(2)">周</div>
      <div class="time-select-text" :class="timeHasSelect === 3?'select':''" @click="selectTime(3)">月</div>
      <div class="time-select-text" :class="timeHasSelect === 4?'select':''" @click="selectTime(4)">年</div>
    </div>
    <div class="text">{{getText(timeHasSelect)}}</div>
  </div>
</template>

<script>
export default {
  // props: {
  //   timeHasSelect: {
  //     type: Number,
  //     default: 4
  //   }
  // },
  data() {
    return {
      timeSelectIsShow: false,
      timeHasSelect:4,
    }
  },
  methods: {
    getText(cate) {
      switch (cate) {
        case 1:
          return '日';
          break;
        case 2:
          return '周';
          break;
        case 3:
          return '月';
          break;
        case 4:
          return '年';
          break;
      }
    },
    selectTime(time) {
      this.timeHasSelect = time
      this.timeSelectIsShow = false
      this.$emit('on-selectTime', time)
    },
  }
}
</script>

<style lang="scss" scoped>
.time-icon {
  padding: 4px 20px 4px 17px;
  position: relative;
  z-index: 1000;
  text-align: center;
  color: #9fc4ff;

  .text {
    color: #9fc4ff;
    position: absolute;
    top: 10px;
    left: 40px;
    font-size: 14px;
  }

  .time-select {
    box-sizing: border-box;
    padding: 10px 0;
    position: absolute;
    top: 16px;
    margin-top: 14px;
    left: 0px;
    width: 50px;
    height: 120px;
    background: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-family: AppleSystemUIFont;
    color: #DAE9FF;

    .select {
      width: 100%;
      background: #1F4DCB;
    }

    .time-select-text {
      padding: 4px 0;

      &:hover {
        width: 100%;
        background: #1F4DCB;
      }
    }
  }
}
</style>