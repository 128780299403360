<template>
  <div style="height:100%">
    <airspaceManagement v-if="haves.includes(1)"/>
    <equipmentProportion v-if="haves.includes(2)"/>
  </div>
</template>

<script>
import airspaceManagement from "./airspace-management.vue"; // 空域管理
import equipmentProportion from "./equipment-management.vue"; // 设备管理
export default {
  components: { airspaceManagement, equipmentProportion },
      data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:detectionCountermeasures:airspaceControl") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:detectionCountermeasures:deviceManagement") > -1) {
      this.haves.push(2)
    }
    }
}
</script>

<style lang="less" scoped>

</style>