<template>
  <div style="height:100%">
    <!-- <Distribute v-if="haves.includes(1)"/> -->
    <Equipment v-if="haves.includes(1)"></Equipment>
    <taskScene  v-if="haves.includes(2)"></taskScene>
    <!-- <NumberOfTasks v-if="haves.includes(2)"/> -->
    <Approval v-if="haves.includes(3)"/>
    
  </div>
</template>

<script>
import Approval from "./approval.vue"; // 各单位任务审批统计
import Distribute from "./distribute.vue"; // 各单位任务派发统计
import NumberOfTasks from "./number-of-tasks.vue"; // 各单位任务统计数
import Equipment from "@/components/home/left-box/home/equipment.vue"; // 资源统计

import taskScene from "@/components/home/left-box/home/taskScene.vue"; // 各单位任务统计


export default {
  components: { Approval, Distribute, NumberOfTasks,taskScene,Equipment},
    data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:tasks:flightDataStatistics") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:tasks:taskDataStatistics") > -1) {
      this.haves.push(2)
    }
    if (permissionList.indexOf("hn:tasks:taskApprovalStatistics") > -1) {
      this.haves.push(3)
    }
    }
}
</script>

<style lang="less" scoped>

</style>