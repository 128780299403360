var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',{staticClass:"cpt-task-box",attrs:{"width":"470px","height":"250px"}},[_c('div',{staticClass:"cpt-task-inner-box"},[_c('div',{staticClass:"task-header"},[_c('span',{staticClass:"iconfont icon-biaoti"}),_c('span',{staticClass:"title"},[_vm._v("飞行统计")])]),_c('div',{staticClass:"task-filter"},[_c('div',{staticClass:"date-group"},[_c('div',{class:_vm.type === 3 ? 'active date-item date-week' : 'date-item date-week',on:{"click":function($event){return _vm.updatActive(3)}}},[_vm._v(" 周 ")]),_c('div',{class:_vm.type === 2
              ? 'active date-item date-month'
              : 'date-item date-month',on:{"click":function($event){return _vm.updatActive(2)}}},[_vm._v(" 月 ")]),_c('div',{class:_vm.type === 1 ? 'active date-item date-year' : 'date-item date-year',on:{"click":function($event){return _vm.updatActive(1)}}},[_vm._v(" 年 ")])]),_c('div',{staticClass:"select-group"},[_c('el-cascader',{staticClass:"input-orgname",attrs:{"placeholder":"请选择所属单位","options":_vm.selectList,"show-all-levels":false,"props":{
          children: 'child',
          label: 'name',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
          empty: false
        }},on:{"change":function($event){return _vm.updataflyValue()}},model:{value:(_vm.filter.organId),callback:function ($$v) {_vm.$set(_vm.filter, "organId", $$v)},expression:"filter.organId"}}),_c('el-select',{attrs:{"size":"mini","placeholder":"全部","clearable":""},on:{"change":function($event){return _vm.updataflyValue()}},model:{value:(_vm.flyValue),callback:function ($$v) {_vm.flyValue=$$v},expression:"flyValue"}},_vm._l((this.flylist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.deviceName,"value":item.id}})}),1)],1)]),_c('div',{staticClass:"task-chart",attrs:{"id":"task-chart"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }