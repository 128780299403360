<template>
  <ModuleBox
    title="任务运行概览"
    width="100%"
    height="32%"
    class="cpt-device-module-box"
  >
    <div class="out">
      <div class="container">
        <div class="slider-list hwb-flex" v-for="item in data" :key="item.name">
          <div>{{item.name}}</div>
          <div class="slider ">
            <div class="tiao">
              <div class="have" :style="{width: (item.num/item.sum)*100 + '%'}"></div>
            </div>
            <div class="num">{{item.num}}</div>
          </div>
        </div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import API from "@/api";

export default {
  components: { ModuleBox },
  data() {
    return {
      data: [
        {name:'公安局', num: 93, sum: 200},
        {name:'水利局', num: 42, sum: 200},
        {name:'应急管理局', num: 40, sum: 200},
        {name:'生态创建办', num: 18, sum: 200},
        {name:'综合行政执法局', num: 4, sum: 200},
        {name:'自然资源与规划局', num: 3, sum: 200},
      ]
    };
  },
  methods: {
    
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.cpt-device-module-box {
  .out{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .container{
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;
      .slider-list{
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C1D9FF;
        margin-bottom: 18px;
        .slider{
          width: 64%;
          position: relative;
          .tiao{
            width: 90%;
            background-color: rgba(30, 67, 112, 1);
            height: 5px;
            .have{
              width: 50%;
              height: 100%;
              background: linear-gradient(270deg, #77B3FF 0%, #1062ED 100%);
            }
          }
          .num{
            position: absolute;
            top: -5px;
            right: -2px;
          }
        }
      }
    }
  }
  
}
</style>