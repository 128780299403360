<template>
  <ModuleBox title="数据分析" width="100%" height="29vh" class="analysis">
    <div class="body pr">
      <!-- top:-26px; -->
   <div class="top pa w159  right0" style="top:-5px; " @click="details('预警信息')">
    <TimeSelect  @on-selectTime="selectTime" />
   </div>
      <div id="analysis-box" class="analysis-box mt12  " style="height:20vh">
      </div>
    </div>

    <Dialog class="Details" v-if="showDetails" :title="title" :width="800" @on-close="closeDetails">
      <div class="select" v-show="title=='预警信息'">
        <el-cascader popper-class="input-orgname" placeholder="请选择所属机构" v-model="search_form.organizationId"
                :options="allOrganizationselectList" :show-all-levels="false"  :props="{
                  children: 'child',
                  label: 'name',
                  value: 'id',
                  checkStrictly: true,
                  emitPath: false,
                  empty: false
                }" style="margin-right:5px;" >
              </el-cascader>
                    <div class="btn" @click="reset">重置</div>
                    <div class="btn" @click="searchData">查询</div>
                </div>
      <!-- 数据推送 -->
      <Table :cellName="dataList" :dataSource="dataValue">
        <template #effect={row}>
          <div>
            {{row.effect == '0' ? '无效':'有效'}}
          </div>
        </template>
        <template #distCode={row}>
          <div class="cp" style="color:#147799" @click="showImg(row)">
            查看图片
          </div>
        </template>
      </Table>
    </Dialog>
        <el-dialog class="mt200" :append-to-body="true" title="预警图片" :visible.sync="dialogVisible" width="30%"
      :before-close="beforeClose">
      <img class="wih100 h300" :src="img" />
    </el-dialog>

  </ModuleBox>
</template>

<script>
// 
let chart
import { Chart, registerTheme } from "@antv/g2";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import { mapGetters, mapActions } from "vuex"
import highcharts from "highcharts";
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import API from "@/api";
import getgetAllOrganization from "@/api/mechanismPullDown.js"; // 获取机构下拉js
export default {
  components: { ModuleBox, TimeSelect , Dialog , Table },
  data() {
    return {
            search_form:{
        warningTypeId:null,
        organizationId:1
      },
      allOrganizationselectList:null,
      type: {//	day日, week周, month月，year年
        1: "day",
        2: "week",
        3: "month",
        4: "year",
      },
      timeHasSelect: 4, // 时间选择
      selectList: null,
      organId: null,
      chart: null,
      categories: [],
      data: [
        { name: 'PTU空地协同巡查', status: '总量', num: 188 },
        { name: '大型活动...', status: '总量', num: 277 },
        { name: '动物保护', status: '总量', num: 42 },
        { name: '耕地种植情况核查', status: '总量', num: 57 },
        { name: '河道及重点区域生态环境监测', status: '总量', num: 58 },
        { name: '河道巡查', status: '总量', num: 13 },
        { name: '交通巡查', status: '总量', num: 15 },
        { name: '排水口巡查', status: '总量', num: 10 },
        { name: '森林防火', status: '总量', num: 48 },
        { name: '森林防火巡查', status: '总量', num: 54 },
        { name: '室外赌场抓捕', status: '总量', num: 51 },
        { name: '偷种罂粟巡查', status: '总量', num: 24 },
        { name: '违法用地巡查', status: '总量', num: 57 },
        { name: '文明养犬巡查', status: '总量', num: 66 },
        { name: '现场勘查', status: '总量', num: 134 },
        { name: '新发违建管控', status: '总量', num: 60 },
        { name: '重大疑难交通事故现场勘查', status: '总量', num: 10 },
      ],
      dataList:[],
      dataValue:[],
      showDetails:false,
      title:'',
      dialogVisible:false,
      img:null,
      WarningTypeList:null
    }
  },
  async mounted() {
    await this.getAnalyse(this.timeHasSelect)
    await this.getWarningListFn()
    await this.getWarningTypeListFn()
    this.getAllOrganization()
    console.log(this.allOrganizationselectList);
    this.allOrganizationselectList = this.removeChaoyingId(JSON.parse((JSON.stringify(this.allOrganizationselectList))))
    this.init()
  },
    computed: {
    ...mapGetters(["dbox_data"]),
  },
  methods: {
    ...getgetAllOrganization,
        showImg(row) {
      this.img = row.warningPhotoStorage
      this.dialogVisible = true
    },
        beforeClose() {
      this.dialogVisible = false
    },
        reset(){
      this.search_form={
        warningTypeId:null,
        organizationId:1
      }
      this.searchData()
    },
    searchData(){
      this.details('预警信息')
    },
        getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          if ( data[i].id == 60 ) {//删除潮鹰机构
            data.splice(i,1)
          }
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i] && data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    removeChaoyingId(data) {
    return this.getTreeData(data); 
  },
    closeDetails() {
      this.showDetails = false
    },
     async details(title) {
      this.title = title
      if (title == '预警信息') {
        this.dataList = [
          { title: '单位名称', name: 'organizationName', },
          { title: '预警类型', name: 'warningTypeName' },
          { title: '预警时间', name: 'warningTime' },
          { title: '预警状态', name: 'effect', slot: true },
          { title: '预警上报设备', name: 'deviceHardId' },
          { title: '飞手信息', name: 'flightUser' },
          { title: '操作', name: 'distCode', slot: true },
        ]
        this.getWarningListFn()
      }
      this.showDetails = true
    },
        async getWarningListFn() {
      let res = await API.HOMEDATA.getWarningList(this.search_form)
      if (res.code == 200) {
        this.dataValue = res.data
      }
    },
       async getWarningTypeListFn(){
      let res = await API.HOMEDATA.getList()
      if (res.code == 200) {
        this.WarningTypeList= res.data
      }
    },
    async getAnalyse(time) {
      let res = await API.TASK.analyse({ type: this.type[time] })
    
      if (res.code == 200) {
        let data = [{
          name: '总量',
          data: [],
          stack: 'value',
          color: {
            linearGradient: {
              y1: 1,
              y2: 1
            },
            stops: [
              [0, '#FFD21F'],
              [1, '#FFD21F']]
          }
        },
        ]
        let titleList = []
        for (const iterator of res.data) {
          titleList.push(iterator.warningTypeName )
          // data.push({
          //   name: iterator.warningTypeName,
          //   status:
          //     '总量',
          //   num: iterator.warningIsSumTask
          // })
          data[0].data.push(iterator.warningIsSumTask)

        }
        this.data = data
        this.categories = titleList
      }
    },
    // getAllOrganization() {
    //   // let data  = this.dbox_data
    //   let data = [
    //     {
    //       child: [
    //         {
    //           id: 8,
    //           name: "公关办",
    //         },
    //         {
    //           id: 9,
    //           name: "情指中心",
    //         },
    //         {
    //           id: 10,
    //           name: "科通科",
    //         },
    //         {
    //           id: 11,
    //           name: "政治安全保卫大队",
    //         },
    //         {
    //           id: 12,
    //           name: "刑事侦查大队",
    //         },
    //         {
    //           id: 13,
    //           name: "巡特警大队",
    //         },
    //         {
    //           id: 14,
    //           name: "食药环侦大队",
    //         },
    //         {
    //           id: 15,
    //           name: "交通警察大队",
    //         },
    //         {
    //           id: 16,
    //           name: "禁毒大队",
    //         },
    //       ],
    //       id: 2,
    //       name: "公安局",
    //     }, {
    //       child: [
    //       ],
    //       id: 3,
    //       name: "综合执法局",
    //     },
    //     {
    //       child: [

    //       ],
    //       id: 4,
    //       name: "应急管理局",
    //     },
    //     {
    //       child: [

    //       ],
    //       id: 5,
    //       name: "自然资源和规划局",
    //     },
    //     {
    //       child: [
    //         {
    //           id: 17,
    //           name: "河湖中心",
    //         },
    //         {
    //           id: 18,
    //           name: "水行政执法",
    //         },
    //       ],
    //       id: 6,
    //       name: "水利局",
    //     },
    //     {
    //       child: [

    //       ],
    //       id: 7,
    //       name: "生态办",
    //     },
    //   ]
    //   this.selectList = this.getTreeData(data);
    //   // this.organId = this.selectList[this.selectList.length-1].id
    // },
    // getTreeData(data) {
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].child && data[i].child.length < 1) {
    //       // children若为空数组，则将children设为undefined
    //       data[i].child = undefined;
    //     } else {
    //       // children若不为空数组，则继续 递归调用 本方法
    //       if (data[i].child && data[i].child.length < 1) {
    //         this.getTreeData(data[i].child);
    //       }
    //     }
    //   }
    //   return data;
    // },
    updataflyValue() {
      console.log('updataflyValue');
    },
    init() {
      let _this = this
      chart = highcharts.chart('analysis-box', {
        chart: {
          type: 'column',
          options3d: {
            enabled: true,//显示图表是否设置为3D， 我们将其设置为 true
            alpha: 0,  //图表视图旋转角度
            beta: 0,  //图表视图旋转角度
            viewDistance: 20,   //图表的合计深度，默认为100
            depth: 14 //定义图表的浏览长度
          },
          marginTop:10,
          marginRight: 0,
          marginLeft: 30,
          backgroundColor: '#203c749e',
          color: '#ffffff'
        },

        credits: {
          enabled: false //不显示LOGO 
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: this.categories,
          gridLineWidth: 1,
          gridLineColor: '',
          labels: {
            style: {
              color: '#ffffff'
            },
          }
        },
        title: null,
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: null,
          gridLineWidth: 1,
          gridLineDashStyle: 'Dash',
          gridLineColor: '#ddd',
          labels: {
            style: {
              color: '#ffffff'
            }}
        },
        style: {
          color: '#ffffff'
        },
        tooltip: {
          headerFormat: '<b>{point.key}</b><br>',
          pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
        },
        plotOptions: {
          series: {
            stacking: '默认normal，百分比percent', /*默认normal，百分比percent*/
            dataLabels: {
              enabled: false,
              style: {
                color: '#ffffff',//字体颜色
                textOutline: 'none' //去掉文字阴影
              }
            },
            enableMouseTracking: true,
            events: {
                    //控制图标的图例legend不允许切换
                    // legendItemClick: function (event) {
                    //   return false;//return true则表示允许切换
                    // },
                    click(event) {
                      console.log(event, 'event');
                      let index = event.point.category
                      let num = event.point.options.y
                      _this.handClickDetails(index, num)
                    }
          }
          }
        },
        series: this.data
      });
    },
   async handClickDetails(key, num) {
    this.WarningTypeList.forEach(element => {
      if (element.name == key) {
       this.search_form.warningTypeId = element.id
      }
    })
    // await this.getWarningListFn()
    // console.log(this.search_form.warningTypeId);
    // console.log(this.dataValue);
      this.details('预警信息')
    },
    async selectTime(data) {
      this.timeHasSelect = data
      await this.getAnalyse(this.timeHasSelect)

      chart.destroy(); // 清理所有图形
      this.init()

    }
  },

}
</script>

<style lang="scss" scoped>
    .btn {
    padding: 5px 10px;
    display: inline-block;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}
.Details {
  .select {
    display: flex;
    align-items: center;

    .selectBtn {
      cursor: pointer;
      width: 86px;
      height: 30px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
    }
  }
    ::v-deep .el-cascader .el-input {
    .el-input__inner {
      background-color: #000;
      border: 0;
      height: 30px;
      line-height: 30px;
      color: #fff;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }
  }
.analysis {
  .body {
    .top {
      display: flex;
      // justify-content: space-between;
      justify-content: flex-end;

      .selectBox {
        position: relative;
        ::v-deep {
          .el-cascader .el-input {
            .el-input__inner {
              padding-left: 36px;
              width: 190px;
              height: 32px;
              background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
              border: 1px solid;
              border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
              backdrop-filter: blur(4px);
              font-size: 16px;
              font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
              font-weight: 400;
              color: #65ABE9;
              line-height: 24px;
            }

            .el-input .el-select__caret {
              line-height: 0;
            }
          }
        }

        .dec {
          position: absolute;
          top: 9px;
          left: 10px;
          z-index: 100;
          font-size: 16px;
          color: #00BAFF;
        }

        .dec2 {
          position: absolute;
          top: 9px;
          left: 29px;
          width: 1px;
          height: 20px;
          background: linear-gradient(180deg, rgba(54, 124, 255, 0) 0%, #4CB4FF 51%, rgba(54, 124, 255, 0) 100%);
          z-index: 100;
        }
      }
    }

    .analysis-box {
      padding-top: 10px;
    }
  }
}
</style>