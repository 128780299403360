<template>
  <Box width="411px "  height="100%" class="cpt-left-box"
    :class="LeftisShow?'leftShow':'leftHiddle'">
<!-- 
    <div class="bigBox" v-if="selectData === 1">
      <HomeLeft />
    </div> -->
    <!-- 天空卫士 -->
    <div class="bigBox" v-if="selectData === 1">
      <FlyingCommission />
    </div>
    <div class="bigBox" v-else-if="selectData === 3">
      <Uav />
    </div>
    <!-- 三维模型 -->
    <div class="bigBox" v-else-if="selectData === 4">
      <detectionReaction />
    </div>
    <div class="bigBox" v-else-if="selectData === 5">
      <Dimensional />
    </div>
    <div class="bigBox" v-else-if="selectData === 6">
      <dataAnalysis />
    </div>
    <div class="icon" @click="ShowBox" :class="this.LeftisShow?'show':'hiddle'"></div>
  </Box>
</template>

<script>
import Box from "../common/box";
import HomeLeft from "./home"
import TaskModule from "./model-box/index.vue"; // 任务模块
import FlyingCommission from "./flying-commission/index.vue" // 飞行任务
import Uav from "./uav-management"
import Dimensional from "./three-dimensional"
import dataAnalysis from "./data-analysis/index.vue" // 数据分析
import detectionReaction from "./detection-reaction/index.vue" // 侦测反制

export default {
  props: {
    selectData: {
      type: Number,
      default: 1
    }
  },
  components: { Box, TaskModule, HomeLeft, FlyingCommission, Uav, Dimensional, dataAnalysis, detectionReaction },
  data() {
    return {
      LeftisShow: true
    }
  },
  methods: {
    ShowBox() {
      this.LeftisShow = !this.LeftisShow
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-box {
  overflow: inherit;
}

.cpt-left-box {
  position: absolute;
  left: 0px;
  top: 0px ;
  padding-top:100px;
  z-index: 1;
  // padding: 0 16px;
  padding-left:66px;
  // background: linear-gradient(to right, rgba(3, 24, 66,0.7), rgba(3, 24, 66, 0.5), rgba(3, 24, 66, 0.4), rgba(3, 24, 66, 0.3), rgba(3, 24, 66, 0));
  background: linear-gradient(90deg, #032251 0%, rgba(10,58,129,0.88) 46%, rgba(0,0,0,0) 100%);
  transition: all 400ms linear;


  .bigBox {
    height: 100%;
  }

  .icon {
    position: absolute;
    right: -22px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 72px;
    background: url('~@/assets/home/right-btn.png') no-repeat;
    cursor: pointer;

    &.hiddle {
      background: url('~@/assets/home/left-btn.png') no-repeat;
    }
  }

  &.leftShow {
    // left: 16px;
  }

  &.leftHiddle {
    left: -411px;
  }
}
</style>