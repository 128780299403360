<template>
  <div class="homeRight">
    <!-- <Warning v-if="haves.includes(1)"/> -->
    <Distribute v-if="haves.includes(1)"/>
    <!-- <Analysis v-if="haves.includes(2)"/>
    <Model v-if="haves.includes(3)"/> -->
    <airspacemanagementTJ></airspacemanagementTJ>
    <airspacemanagementZY></airspacemanagementZY>
  </div>
</template>

<script>
import Warning from "./warning.vue"
import Analysis from "./analysis.vue"
import Model from "./model.vue"
import Distribute from "@/components/home/left-box/flying-commission/distribute"; // 各单位任务统计数
import airspacemanagementTJ from "@/components/home/right-box/detection-reaction/airspace-managementTJ"; // 各单位任务统计数

import airspacemanagementZY from "@/components/home/right-box/detection-reaction/airspace-managementZY"; // 各单位任务统计数

export default {
  components:{
    Warning,
    Analysis,
    Model,
    Distribute,
    airspacemanagementTJ,
    airspacemanagementZY
  },
      data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:skyDefenders:detectEarlyWarningTrend") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:skyDefenders:dataAnalysis") > -1) {
      this.haves.push(2)
    }
    if (permissionList.indexOf("hn:skyDefenders:3DApplication") > -1) {
      this.haves.push(3)
    }
    }
}
</script>

<style lang="scss" scoped>
    .homeRight {
        height: 100%;
    }
</style>