<template>
  <ModuleBox title="侦测预警趋势" width="100%" height="30vh" class="waring">
    <div class="body">
      <div class="top">
        <div class="text">
          <div v-for="item in list" :key="item.id" class="written mr10"
            :style="`color: ${item.color}; border-right: solid 1px ${item.color};`">
            {{ item.title }} {{ item.num }}
          </div>
        </div>
        <div class="select">
        </div>
      </div>
      <div id="container" class="container">
      </div>
    </div>
  </ModuleBox>
</template>

<script>
let warChart;
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import { Chart } from "@antv/g2";
export default {
  components: { ModuleBox, TimeSelect },
  data() {
    return {
      timeHasSelect: 1, // 时间选择
      list: [
        {
          id: 1,
          title: '累计发现',
          num: 52,
          color: 'rgba(0, 210, 255, 1)'

        },
        {
          id: 2,
          title: '累计入侵',
          num: 12,
          color: 'rgba(255, 189, 54, 1)'
        },
        {
          id: 3,
          title: '入侵总时长',
          num: '60min',
          color: 'rgba(87, 209, 83, 1)'
        }
      ],
      yeatData: [
        // {  '累计发现':3, year: '一月', '累计入侵': 0, '入侵总时长': 0 },
        // {  '累计发现':2, year: '二月', '累计入侵': 0, '入侵总时长': 0 },
        // {  '累计发现':8, year: '三月', '累计入侵': 2, '入侵总时长': 6 },
        // {'累计发现':4, year: '四月', '累计入侵': 0 , '入侵总时长': 0},
        // {  '累计发现':7, year: '五月', '累计入侵': 3, '入侵总时长': 13 },
        // {  '累计发现':9, year: '六月', '累计入侵': 4, '入侵总时长': 24 },
        // {  '累计发现':6, year: '七月', '累计入侵': 0, '入侵总时长': 0 },
        // { '累计发现':6, year: '八月', '累计入侵': 3, '入侵总时长': 17 },
        // {  '累计发现':7, year: '九月', '累计入侵': 0, '入侵总时长': 0 },

        { country: '累计发现', year: '一月', value: 3 },
        { country: '累计发现', year: '二月', value: 2 },
        { country: '累计发现', year: '三月', value: 8 },
        { country: '累计发现', year: '四月', value: 4 },
        { country: '累计发现', year: '五月', value: 7 },
        { country: '累计发现', year: '六月', value: 9 },
        { country: '累计发现', year: '七月', value: 6 },
        { country: '累计发现', year: '八月', value: 6 },
        { country: '累计发现', year: '九月', value: 7 },
        { country: '累计发现', year: '十月', value: 6 },
        { country: '累计发现', year: '十一月', value: 7 },
        { country: '累计发现', year: '十二月', value: 0 },
        { country: '累计入侵', year: '一月', value: 0 },
        { country: '累计入侵', year: '二月', value: 0 },
        { country: '累计入侵', year: '三月', value: 2 },
        { country: '累计入侵', year: '四月', value: 0 },
        { country: '累计入侵', year: '五月', value: 3 },
        { country: '累计入侵', year: '六月', value: 4 },
        { country: '累计入侵', year: '七月', value: 0 },
        { country: '累计入侵', year: '八月', value: 3 },
        { country: '累计入侵', year: '九月', value: 0 },
        { country: '累计入侵', year: '十月', value: 2 },
        { country: '累计入侵', year: '十一月', value: 1 },
        { country: '累计入侵', year: '十二月', value: 0 },
        { country: '入侵总时长', year: '一月', value: 0 },
        { country: '入侵总时长', year: '二月', value: 0 },
        { country: '入侵总时长', year: '三月', value: 6 },
        { country: '入侵总时长', year: '四月', value: 0 },
        { country: '入侵总时长', year: '五月', value: 13 },
        { country: '入侵总时长', year: '六月', value: 24 },
        { country: '入侵总时长', year: '七月', value: 0 },
        { country: '入侵总时长', year: '八月', value: 17 },
        { country: '入侵总时长', year: '九月', value: 0 },
        { country: '入侵总时长', year: '十月', value: 12 },
        { country: '入侵总时长', year: '十一月', value: 0 },
        { country: '入侵总时长', year: '十二月', value: 0 },
      ],
      monthData: [
        { country: '累计发现', year: '一月', value: 100 },
        { country: '累计发现', year: '二月', value: 200 },
        { country: '累计发现', year: '三月', value: 300 },
        { country: '累计发现', year: '四月', value: 400 },
        { country: '累计发现', year: '五月', value: 500 },
        { country: '累计发现', year: '六月', value: 400 },
        { country: '累计发现', year: '七月', value: 300 },
        { country: '累计发现', year: '八月', value: 300 },
        { country: '累计发现', year: '九月', value: 400 },
        { country: '累计发现', year: '十月', value: 500 },
        { country: '累计发现', year: '十一月', value: 400 },
        { country: '累计发现', year: '十二月', value: 300 },
        { country: '累计入侵', year: '一月', value: 300 },
        { country: '累计入侵', year: '二月', value: 400 },
        { country: '累计入侵', year: '三月', value: 500 },
        { country: '累计入侵', year: '四月', value: 600 },
        { country: '累计入侵', year: '五月', value: 500 },
        { country: '累计入侵', year: '六月', value: 600 },
        { country: '累计入侵', year: '七月', value: 700 },
        { country: '累计入侵', year: '八月', value: 600 },
        { country: '累计入侵', year: '九月', value: 500 },
        { country: '累计入侵', year: '十月', value: 600 },
        { country: '累计入侵', year: '十一月', value: 700 },
        { country: '累计入侵', year: '十二月', value: 600 },
        { country: '入侵总时长', year: '一月', value: 500 },
        { country: '入侵总时长', year: '二月', value: 400 },
        { country: '入侵总时长', year: '三月', value: 300 },
        { country: '入侵总时长', year: '四月', value: 700 },
        { country: '入侵总时长', year: '五月', value: 200 },
        { country: '入侵总时长', year: '六月', value: 500 },
        { country: '入侵总时长', year: '七月', value: 300 },
        { country: '入侵总时长', year: '八月', value: 400 },
        { country: '入侵总时长', year: '九月', value: 300 },
        { country: '入侵总时长', year: '十月', value: 700 },
        { country: '入侵总时长', year: '十一月', value: 200 },
        { country: '入侵总时长', year: '十二月', value: 0 },
        { country: '入侵总时长', year: '十二月', value: 0 },
        { country: '入侵总时长', year: '十二月', value: 0 }

      ],
      dayData: [
        { country: '累计发现', year: '1', value: 502 },
        { country: '累计发现', year: '2', value: 635 },
        { country: '累计发现', year: '3', value: 809 },
        { country: '累计发现', year: '4', value: 5268 },
        { country: '累计发现', year: '5', value: 4400 },
        { country: '累计发现', year: '6', value: 3634 },
        { country: '累计发现', year: '7', value: 947 },
        { country: '累计发现', year: '8', value: 502 },
        { country: '累计发现', year: '9', value: 635 },
        { country: '累计发现', year: '10', value: 809 },
        { country: '累计发现', year: '11', value: 5268 },
        { country: '累计发现', year: '12', value: 4400 },
        { country: '累计发现', year: '13', value: 3634 },
        { country: '累计发现', year: '14', value: 947 },
        { country: '累计发现', year: '15', value: 502 },
        { country: '累计发现', year: '16', value: 635 },
        { country: '累计发现', year: '17', value: 809 },
        { country: '累计发现', year: '18', value: 5268 },
        { country: '累计发现', year: '19', value: 4400 },
        { country: '累计发现', year: '20', value: 3634 },
        { country: '累计发现', year: '21', value: 947 },
        { country: '累计发现', year: '22', value: 502 },
        { country: '累计发现', year: '23', value: 635 },
        { country: '累计发现', year: '24', value: 809 },
        { country: '累计发现', year: '25', value: 5268 },
        { country: '累计发现', year: '26', value: 4400 },
        { country: '累计发现', year: '27', value: 3634 },
        { country: '累计发现', year: '28', value: 947 },
        { country: '累计发现', year: '29', value: 4400 },
        { country: '累计发现', year: '30', value: 3634 },
        { country: '累计入侵', year: '1', value: 402 },
        { country: '累计入侵', year: '2', value: 635 },
        { country: '累计入侵', year: '3', value: 809 },
        { country: '累计入侵', year: '4', value: 4268 },
        { country: '累计入侵', year: '5', value: 4400 },
        { country: '累计入侵', year: '6', value: 2634 },
        { country: '累计入侵', year: '7', value: 2947 },
        { country: '累计入侵', year: '8', value: 502 },
        { country: '累计入侵', year: '9', value: 635 },
        { country: '累计入侵', year: '10', value: 809 },
        { country: '累计入侵', year: '11', value: 5268 },
        { country: '累计入侵', year: '12', value: 4400 },
        { country: '累计入侵', year: '13', value: 3634 },
        { country: '累计入侵', year: '14', value: 947 },
        { country: '累计入侵', year: '15', value: 502 },
        { country: '累计入侵', year: '16', value: 635 },
        { country: '累计入侵', year: '17', value: 3809 },
        { country: '累计入侵', year: '18', value: 5268 },
        { country: '累计入侵', year: '19', value: 4400 },
        { country: '累计入侵', year: '20', value: 3634 },
        { country: '累计入侵', year: '21', value: 947 },
        { country: '累计入侵', year: '22', value: 502 },
        { country: '累计入侵', year: '23', value: 635 },
        { country: '累计入侵', year: '24', value: 809 },
        { country: '累计入侵', year: '25', value: 5268 },
        { country: '累计入侵', year: '26', value: 400 },
        { country: '累计入侵', year: '27', value: 3634 },
        { country: '累计入侵', year: '28', value: 947 },
        { country: '累计入侵', year: '29', value: 400 },
        { country: '累计入侵', year: '30', value: 3634 },
        { country: '入侵总时长', year: '1', value: 4502 },
        { country: '入侵总时长', year: '2', value: 635 },
        { country: '入侵总时长', year: '3', value: 809 },
        { country: '入侵总时长', year: '4', value: 2268 },
        { country: '入侵总时长', year: '5', value: 4400 },
        { country: '入侵总时长', year: '6', value: 3634 },
        { country: '入侵总时长', year: '7', value: 4947 },
        { country: '入侵总时长', year: '8', value: 502 },
        { country: '入侵总时长', year: '9', value: 635 },
        { country: '入侵总时长', year: '10', value: 809 },
        { country: '入侵总时长', year: '11', value: 268 },
        { country: '入侵总时长', year: '12', value: 4400 },
        { country: '入侵总时长', year: '13', value: 3634 },
        { country: '入侵总时长', year: '14', value: 947 },
        { country: '入侵总时长', year: '15', value: 502 },
        { country: '入侵总时长', year: '16', value: 3635 },
        { country: '入侵总时长', year: '17', value: 809 },
        { country: '入侵总时长', year: '18', value: 5268 },
        { country: '入侵总时长', year: '19', value: 1400 },
        { country: '入侵总时长', year: '20', value: 4634 },
        { country: '入侵总时长', year: '21', value: 947 },
        { country: '入侵总时长', year: '22', value: 2502 },
        { country: '入侵总时长', year: '23', value: 635 },
        { country: '入侵总时长', year: '24', value: 809 },
        { country: '入侵总时长', year: '25', value: 4268 },
        { country: '入侵总时长', year: '26', value: 4400 },
        { country: '入侵总时长', year: '27', value: 3634 },
        { country: '入侵总时长', year: '28', value: 2947 },
        { country: '入侵总时长', year: '29', value: 400 },
        { country: '入侵总时长', year: '30', value: 3634 },
      ],
      weekData: [
        { country: '累计发现', year: '第一周', value: 502 },
        { country: '累计发现', year: '第二周', value: 1502 },
        { country: '累计发现', year: '第三周', value: 2502 },
        { country: '累计发现', year: '第四周', value: 3502 },
        { country: '累计入侵', year: '第一周', value: 2000 },
        { country: '累计入侵', year: '第二周', value: 1000 },
        { country: '累计入侵', year: '第三周', value: 3000 },
        { country: '累计入侵', year: '第四周', value: 1520 },
        { country: '入侵总时长', year: '第一周', value: 3000 },
        { country: '入侵总时长', year: '第二周', value: 2000 },
        { country: '入侵总时长', year: '第三周', value: 1530 },
        { country: '入侵总时长', year: '第四周', value: 2500 }
      ],
      id: 1,
      value: null
    }
  },
  methods: {
    init() {
      const data = this.yeatData;
      warChart = new Chart({
        container: 'container',
        autoFit: true,
        height: 160,
        width:400,
        padding: [10, 20, 30,40] // 上右下左
      });

      warChart.data(data);
      warChart.axis("sales", {//Y轴样式
                grid:{
                  left: 35,
                    line:{
                    type:"line",
                    style:{
                        // fill:'#ff0000',
                        stroke:"#fff",
                        opacity:1,
                    }
                    },
                },
                label:{
                    style:{
                    fill:"#fff",//文字颜色
                    fontFamily: "Microsoft YaHei",//文字字体
                    fontWeight: 400,//文字粗细
                    fontSize: 12,//文字大小
                    }
                },
                line:{
                    style:{
                    stroke:"#fff",//坐标轴颜色
                    }
                },
                tickLine: {
                    style:{
                    stroke:"#fff",//刻度线颜色
                    }
                },
                subTickLine:{
                    style:{
                    stroke:"#fff",//小刻度颜色
                    }
                }
            });


      warChart.scale('year', {
        // type: 'linear',
        // tickInterval: 1,
      });
      warChart.axis('year', {
        title: null,
      //   axisLabel: {     //加上这个强制显示
      //     interval: 0,
      //     formatter: function (value, index) {
      //             console.log(value,'value');
      //           return value;
      //   },
      // },
        label: {
          style: {
            fill: '#fff',
          },
          formatter: function (val) {
            console.log('val', val);
            return val.split("").join("\n");
          },
          textStyle: {
            textAlign: 'start', // 文本对齐方向，可取值为： start middle end
            fill: '#404040', // 文本的颜色
            fontSize: '8', // 文本大小
            textBaseline: 'middle' // 文本基准线，可取 top middle bottom，默认为middle
          }, // 文本样式，支持回调
          // autoRotate: true, // 是否需要自动旋转，默认为 true
        }
      });
      warChart.axis('value', {
        label: {
          // 使用 formatter 回调函数
          formatter: function (val) {
            return val
          },
        },

      });
      warChart.scale('value', {
        nice: true,
      });
      warChart.legend(false);

      warChart.tooltip({
        showCrosshairs: true,
        shared: true,
      });
      warChart
        .area()
        .adjust('stack')
        .position('year*value')
        .color('country', ['#00d2ff', '#ffbd36', '#57d153'])
      warChart
        .line()
        .adjust('stack')
        .position('year*value')
        .color('country', ['#00d2ff', '#ffbd36', '#57d153']);

      warChart.interaction('element-highlight');

      warChart.render();
    },
    getList() {
      if (this.id >= 4) {
        this.id = 1
      } else {
        this.id = this.id + 1
      }
      this.getType(this.id)
      warChart.changeData(this.value)
    },
    getType(value) {
      switch (value) {
        case 4:
          return this.value = this.yeatData, this.list.map(item => {
            if (item.id === 1) {
              item.num = 52
            } else if (item.id === 2) {
              item.num = 12
            } else if (item.id === 3) {
              item.num = '60min'
            }
          })
        case 3:
          return this.value = this.monthData, this.list.map(item => {
            if (item.id === 1) {
              item.num = 52
            } else if (item.id === 2) {
              item.num = 3
            } else if (item.id === 3) {
              item.num = '31min'
            }
          })
        case 2:
          return this.value = this.dayData, this.list.map(item => {
            if (item.id === 1) {
              item.num = 12
            } else if (item.id === 2) {
              item.num = 10
            } else if (item.id === 3) {
              item.num = '12min'
            }
          })
        case 1:
          return this.value = this.weekData, this.list.map(item => {
            if (item.id === 1) {
              item.num = 5
            } else if (item.id === 2) {
              item.num = 3
            } else if (item.id === 3) {
              item.num = '1min'
            }
          })
        default:
          break;
      }
    },
    selectTime(time) {
      this.timeHasSelect = time
      this.getType(time)
      warChart.changeData(this.value)
    },
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.waring {
  .body {
    .top {
      height: 20px;
      display: flex;
      justify-content: space-between;

      .text {
        height: 20px;
        line-height: 20px;
        display: flex;
        font-size: 12px;
        justify-content: space-around;
        width: 100%;

        .written {
          // width: 116px;
          height: 20px;
          padding-right: 10px;

          &:last-child {
            border-right: none !important
          }
        }

      }

      .select {
        width: 50px;
        margin-top: -4px;
      }
    }

    .container {
      padding-top: 10px;
    }

  }
}
</style>