<template>
  <ModuleBox
    title="预警信息"
    width="100%"
    height="100%"
    class="number-of-tasks"
  >
    <div class="out">
      <div style="width: 100%;height:100%">
        <!-- 头部机构类型选择 -->
        <div class="hwb-flex" style="margin:0 0 22px 0;">
          <!-- 选择框 -->
          <div class="select-outbox hwb-flex">
            <!-- 机构 -->
            <div class="selectBox">
              <!-- <div class="dec iconfont icon-jigoumingcheng"></div>
              <div class="dec2"></div> -->
              <!-- <el-select
                v-model="typeValue"
                placeholder="请选择机构名称"
                clearable
              >
                <el-option
                  v-for="item in WarningTypeList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select> -->
              <el-cascader
                popper-class="input-orgname"
                placeholder="请选择所属机构"
                v-model="organId"
                :options="allOrganizationselectList"
                :show-all-levels="false"
                @change="updataflyValue()"
                :props="{
                  children: 'child',
                  label: 'name',
                  value: 'id',
                  checkStrictly: true,
                  emitPath: false,
                  empty: false
                }"
                style="margin-right:10px;"
                clearable
              >
              </el-cascader>
            </div>
            <!-- 类型 -->
            <!-- <div class="selectBox">
              <div class="dec3"></div>
              <div class="dec2"></div>
              <el-select
                v-model="taskTypeValue"
                placeholder="请选择预警地区"
                clearable
              >
                <el-option
                  v-for="item in warningPositionList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select>
            </div> -->
          </div>
          <!-- 右侧时间筛选 -->
          <div class="right">
            <TimeSelect @on-selectTime="selectTime"/>
          </div>
        </div>
        <!-- 预警有无效选择 -->
        <div class="legend hwb-flex">
          <div class="dec1"></div>
          <div class="dec2"></div>
          <div class="cgk public hwb-flex"><img src="./img/jingao.png" class="dec" /><div class="shu-label" style="width:40px;cursor: pointer;" @click="selectyjxx(0)">预警总数</div><div class="num">{{yjxx.yjzs}}</div></div>
          <div class="spk public hwb-flex"><img src="./img/youxiao.png" class="dec" /><div class="shu-label" style="width:40px;cursor: pointer;" @click="selectyjxx(1)">有效预警</div><div class="num">{{yjxx.yjyx}} </div></div>
          <div class="tpk public hwb-flex"><img src="./img/wuxiao.png" class="dec" /><div class="shu-label" style="width:40px;cursor: pointer;" @click="selectyjxx(2)">无效预警</div><div class="num">{{yjxx.yjwx}} </div></div>
        </div>
        <!-- 下面 -->
        <div class="bottom">
          <!-- 一条样式 -->
          <div class="slider-list hwb-flex-column" v-for="(item,index) in data" :key="item.warningTypeName">
            <div class="one-scroll-text hwb-flex">
                  <el-tooltip class="item" effect="dark" :content="item.warningTypeName" placement="top">
                <div class="one-scroll-text-left">{{item.warningTypeName}}</div>
                </el-tooltip>
              <div class="one-scroll-text-right hwb-flex">{{item.warningIsSumTask}}<span class="ci">次</span></div>
            </div>
            <div class="slider ">
              <div class="tiao">
                <div class="have" :style="{width: (item.warningIsSumTask/yjxx.yjzs)*100 + '%',background: 'linear-gradient(270deg, #00EFFF 0%, #00527D 100%)'}"></div>
                <!-- 圆点 -->
                <div class="dec-circular-border" :style="{left:'calc(' +(item.warningIsSumTask/yjxx.yjzs)*100 + '% - 8px)'}">
                  <div class="dec-circular" ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
              <Dialog class="Details" v-if="showDetails" :title="title" :width="1200" @on-close="closeDetails">
    <Table :cellName="dataList1" :dataSource="dataValue" />
            <div class="DemandList_Footer">
        <el-pagination
          class="cpt-table-pagination"
          @current-change="onChangePage"
          :current-page="currentPage || 1"
          layout="total , prev, pager, next, jumper"
          :total="total || 0"
          :page-size="size || 10"
        />
      </div>
    </Dialog>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
// import { Chart, registerTheme } from "@antv/g2";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
// import yujingdata from './JSONVAL/yujingdata.json'
import API from "@/api";
import getgetAllOrganization from "@/api/mechanismPullDown.js"; // 获取机构下拉js
import {mapGetters} from 'vuex'
export default {
  components: { ModuleBox, TimeSelect ,Table,Dialog},
  data() {
    return {
      organId:null,
      allOrganizationselectList:null,
      typeValue:null,
      taskTypeValue:null,
      warningPositionList: [
        { id: 1, title: "许村镇" },
        { id: 2, title: "长安镇" },
        { id: 3, title: "周王庙镇" },
        { id: 4, title: "盐官镇" },
        { id: 5, title: "丁桥镇" },
        { id: 6, title: "斜桥镇" },
        { id: 7, title: "袁花镇" },
        { id: 8, title: "黄湾镇" },
        { id: 9, title: "硖石街道" },
        { id: 10, title: "海洲街道" },
        { id: 11, title: "海昌街道" },
        { id: 12, title: "马桥街道" },
      ],
      WarningTypeList: [
        {
          id:1,
          title:'打架斗殴'
        },
        {
          id:2,
          title:'聚众赌博'
        },
        {
          id:3,
          title:'抢夺抢劫'
        },
        {
          id:4,
          title:'山地起火'
        },
        {
          id:5,
          title:'占道摆摊'
        },
        {
          id:6,
          title:'飙车超车'
        }
      ],
      data: [],
      dataList1:[
      ],
      dataValue:[
      ],
      showDetails:false,
      title:'',
      value:'',
      currentPage:1,
      total:0,
      size:10,
      type:4,
      timeType:null,
      yjxx:{
        yjzs:null,
        yjyx:null,
        yjwx:null,
      }
    };
  },
  computed: {
    ...mapGetters(["dbox_data"]),
  },
  methods: {
    ...getgetAllOrganization,
    updataflyValue(){
      this.async_get_list()
    },
    is(i){
      if (i==0) {
       return 'linear-gradient(270deg, #FFE992 0%, #E8C75F 31%, #FCCD62 66%, #F5CF41 100%)' 
      }else if (i==1) {
        return 'linear-gradient(270deg, #FFFFFF 0%, #E3FCFF 31%, #E2EDFF 66%, #D2E2EC 100%)'
      } else if(i==2){
       return 'linear-gradient(270deg, #FFDB92 0%, #E8A95F 31%, #CC862C 66%, #EB9C35 100%)' 
      }else{
        return 'linear-gradient(270deg, #ACF4FF 0%, #5FE8E3 31%, #2DD3D3 67%, #00FFDA 100%)'
      }
    },
    on(i){
      if (i==0) {
       return '#F5CF41' 
      }else if (i==1) {
        return '#FBFBFB'
      } else if(i==2){
       return '#EB9C35' 
      }else{
        return 'rgb(0, 255, 255)'
      }
    },
    cha(v){
    },
    selectyjxx(v){
      console.log(v,'v');
      if (v==0) {  //所有预警
        this.status=null
      } else if(v==1) { //有效预警
      this.status=1
      }else if(v==2){ //无效预警
      this.status=0
      }
      this.async_get_list()
      // this.setShuju()
    },
     onChangePage(val){
      let sm=(val-1)*10-1<0?0:(val-1)*10
      let bi=val*10
      if (this.type==4) {
        if (this.status==0) {
        this.dataValue=this.yjzs.slice(sm,bi)
      }else if (this.status==1) {
        this.dataValue=this.yxyj.slice(sm,bi)
      } else if(this.status==2){
        this.dataValue=this.wxyj.slice(sm,bi)
      }
      } else if(this.type==1){
       if (this.status==0) {
        this.dataValue=this.ri.slice(sm,bi)
      }else if (this.status==1) {
        this.dataValue=this.riyxyj.slice(sm,bi)
      } else if(this.status==2){
        this.dataValue=this.riwxyj.slice(sm,bi)
      }
      }else if(this.type==2){
        if (this.status==0) {
        this.dataValue=this.zhou.slice(sm,bi)
      }else if (this.status==1) {
        this.dataValue=this.zhouyxyj.slice(sm,bi)
      } else if(this.status==2){
        this.dataValue=this.zhouwxyj.slice(sm,bi)
      }
      }else if(this.type==3){
        if (this.status==0) {
        this.dataValue=this.yue.slice(sm,bi)
      }else if (this.status==1) {
        this.dataValue=this.yueyxyj.slice(sm,bi)
      } else if(this.status==2){
        this.dataValue=this.yuewxyj.slice(sm,bi)
      }
      }
      },
    details(title,value){
        this.dataList1=[
        {title:'序号',name:'num',width:'100px'},
        {title:'所属任务',name:'rw',width:'200px'},
        {title:'预警类型',name:'lx'},
        {title:'是否有效预警',name:'yj',width:'100px'},
        {title:'预警时间',name:'sj'},
        {title:'任务范围',name:'fw',width:'200px'},
        {title:'飞手信息',name:'fs'},
        ]
      if (this.type==4) {
        if (this.status==0) {
        this.dataValue=this.yjzs.slice(0,10)
        this.total=this.yjzs.length
      }else if (this.status==1) {
        this.dataValue=this.yxyj.slice(0,10)
        this.total=this.yxyj.length
      } else if(this.status==2){
        this.dataValue=this.wxyj.slice(0,10)
        this.total=this.wxyj.length
      }
      } else if(this.type==1){
        if (this.status==0) {
        this.dataValue=this.ri.slice(0,10)
        this.total=this.ri.length
      }else if (this.status==1) {
        this.dataValue=this.riyxyj.slice(0,10)
        this.total=this.riyxyj.length
      } else if(this.status==2){
        this.dataValue=this.riwxyj.slice(0,10)
        this.total=this.riwxyj.length
      }
      }else if(this.type==2){
        if (this.status==0) {
        this.dataValue=this.zhou.slice(0,10)
        this.total=this.zhou.length
      }else if (this.status==1) {
        this.dataValue=this.zhouyxyj.slice(0,10)
        this.total=this.zhouyxyj.length
      } else if(this.status==2){
        this.dataValue=this.zhouwxyj.slice(0,10)
        this.total=this.zhouwxyj.length
      }
      }else if(this.type==3){
        if (this.status==0){
        this.dataValue=this.yue.slice(0,10)
        this.total=this.yue.length
      }else if (this.status==1){
        this.dataValue=this.yueyxyj.slice(0,10)
        this.total=this.yueyxyj.length
      } else if(this.status==2){
        this.dataValue=this.yuewxyj.slice(0,10)
        this.total=this.yuewxyj.length
      }
      }
      this.title=title
      this.value=value
      this.showDetails=true
      console.log(this.showDetails);
    },
    closeDetails(){
    this.showDetails=false
    },
    selectTime(time) {
      this.type=time
      if (time==1) {
      this.timeType='day'
    }else if (time==2) {
      this.timeType='week'
    }else if (time==3) {
      this.timeType='month'
    }else if (time==4) {
      this.timeType='year'
    }  
    this.async_get_list()
      // this.setShuju()
    },

   async async_get_list(){
    console.log(this.status,'this.status')
      const params={
      timeType:this.timeType,
      organizationId:this.organId,
      effect:this.status
    }
    let res = await API.HOMEDATA.warning(params)
    if (res.code==200) {
       
    let newAgeArr2 = JSON.parse(JSON.stringify(res.data.data.sort((a,b) =>  b.warningIsSumTask - a.warningIsSumTask )))
    // let arr1=['灾后恢复','拥堵路段巡查','文明养犬','森林火情','森林防火巡查','森林防火工作','露天禁烧','垃圾环保督察','河道巡查']
    // newAgeArr2.forEach(value => {
    //   for (let index = 0; index < arr1.length; index++) {
    //     if (value && value.warningTypeName==arr1[index]) {
    //       arr1.splice(index)
    //     }
    // }
    // });
    // for (let index = 0; index < arr1.length; index++) {
    //   if (newAgeArr2[index] && newAgeArr2[index].warningTypeName!=arr1[index]) {
    //       newAgeArr2.push({
    //       warningIsSumTask:0,
    //       warningTypeName:arr1[index]
    //     })
    //     }else if(!res.data.data.length){
    //       newAgeArr2.push({
    //       warningIsSumTask:0,
    //       warningTypeName:arr1[index]
    //     })
    //     }
    // }
    console.log(newAgeArr2);
    this.yjxx={
        yjzs:res.data.total,
        yjyx:res.data.effect,
        yjwx:res.data.uneffect,
      }
    this.data=newAgeArr2
    }else{
      this.$message.error(res.message)
    }
    }
  },
  mounted() {
    this.getAllOrganization()
  },
 async created(){
    // this.initdata()
    this.selectTime(4)
  }
};
</script>

<style lang="scss" scoped>
   .DemandList_Footer {//列表底部
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .cpt-table-pagination {//右下分页组件样式
      height: 50px;
      display: flex;
      align-items: center;
      ::v-deep {
        button {
          background: transparent;
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          border-radius: 4px;
          line-height: 40px;
          margin-right: 8px;
          box-sizing: border-box;
          color: #b6d4ff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;

          &:disabled {
            background: transparent;
            color: #b6d4ff;
            opacity: 0.3;
          }
        }
        .el-pagination__total {
          font-size: 18px;
          color: #b6d4ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          text-align: center;
          height: 40px;
          line-height: 40px;
          display: inline-block;
          margin: 0px;
        }
        .el-pager {
      height:30px;

          .number {
            background: transparent;
            font-size: 12px;
            color: #b6d4ff;
            text-align: center;
            font-weight: lighter;
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            // border: 1px solid #488cff;
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
            border-radius: 4px;
            margin-right: 8px;
            box-sizing: border-box;
            &.active {
              opacity: 0.8;
              // background-image: linear-gradient(
              //   180deg,
              //   rgba(44, 123, 255, 0.5) 0%,
              //   rgba(72, 140, 255, 0) 100%
              // );
              background-color: #2647ee;
              color: #ffffff;
            }
          }
          .more {
            background: transparent;
            // line-height: 40px;
            width: 40px;
            // height: 40px;

            box-sizing: border-box;
            // border: 1px solid #629fff;
            border-radius: 4px;
            margin-right: 8px;
            font-size: 20px;
            color: #b6d4ff;
          }
        }
        .el-pagination__jump {
          font-size: 18px;
          color: #b6d4ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          width: 150px;
          height: 40px;
          margin-left: 6px;
          line-height: 40px;
          display: flex;
          align-items: center;
          .el-input {
            box-sizing: border-box;
            height: 100%;
            width: 84px;
            .el-input__inner {
              width: 60px;
              height: 30px;
              border-radius: 2px;
              border: 1px solid #b6d4ff;
              margin-top: 8px;
              // border: 1px solid #629fff;
              border-radius: 4px;
              background: transparent;
              // height: 100%;
              box-sizing: border-box;
              padding: 0;
              font-size: 18px;
              color: #90b2ec;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }
          }
        }
      }

      &.mini {
        height: 100%;
        display: flex;
        align-items: center;
        ::v-deep {
          button {
            height: 20px;
            width: 20px;
            line-height: 20px;
            background: transparent;
            border: none;
            color: #4d7592;
            min-width: auto;
            &.btn-next {
              margin-left: 8px;
            }
          }
          .el-pagination__total {
            font-size: 14px;
            color: #4d7592;
          }
          .el-pagination__sizes {
            display: none;
          }

          .el-pager {
            height:30px;
            .number {
              height: 100%;
              width: auto;
              line-height: 20px;
              background: transparent;
              border: none;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
              min-width: 20px;
              margin-right: 0;
              &.active {
                opacity: 1;
                color: #0099ff;
              }
            }
            .more {
              height: 100%;
              width: 20px;
              line-height: 20px;
              font-size: 12px;
              border: none;
              min-width: auto;
              margin-right: 0px;
              &::before {
                line-height: 20px;
              }
            }
          }

          .el-pagination__jump {
            height: 100%;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;

            .el-input {
              .el-input__inner {
                border: 1px solid #4d7592;
                border-radius: 2px;
                width: auto;
                min-width: 20px;
                font-size: 14px;
                color: #4d7592;
                letter-spacing: 0;
                text-align: center;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
.number-of-tasks{
  width: 100%;
  .out{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .select-outbox{
      align-items: center;
      width: 88%;
      .selectBox {
        text-align: left;
        position: relative;
        height: 32px;
        .dec{
          position: absolute;
          top: 7px;
          left: 10px;
          z-index: 100;
          font-size: 16px;
          color: #00BAFF;
        }
        .dec3{
          position: absolute;
          top: 8px;
          left: 8px;
          z-index: 100;
          background: url("./img/dq.svg") no-repeat center center;
          width: 16px;
          height: 16px;
        }
        .dec2{
          position: absolute;
          top: 7px;
          left: 29px;
          width: 1px;
          height: 20px;
          background: linear-gradient(180deg, rgba(54,124,255,0) 0%, #4CB4FF 51%, rgba(54,124,255,0) 100%);
          z-index: 100;
        }
        ::v-deep{
          .el-select {
            .el-input__inner{
              padding-left: 36px;
              width: 184px;
              height: 32px;
              background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
              border: 1px solid;
              border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
              backdrop-filter: blur(4px);
              font-size: 16px;
              font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
              font-weight: 400;
              color: #65ABE9;
              line-height: 24px;
            }
            .el-input .el-select__caret{
              line-height: 0;
            }
          }
          .el-cascader{
            line-height: 32px;
          }
          .el-cascader .el-input {
            .el-input__inner{
              // padding-left: 36px;
              padding-left: 6px;
              width: 125px;
              height: 27px;
              // background: linear-gradient(180deg, #0A378C 0%, #12206A 35%, #0F265A 66%, #0A378C 100%);
              background: #0B3595;
              border: 1px solid;
              // border-image: linear-gradient(180deg, rgba(73, 171, 255, 1), rgba(60, 91, 166, 0)) 1 1;
              backdrop-filter: blur(4px);
              font-size: 12px;
              font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
              font-weight: 400;
              color: #65ABE9;
              line-height: 27px;
              border: 1px solid #4C85E9;
            }
            .el-input .el-select__caret{
              line-height: 0;
            }
            .el-input__icon{
              line-height: 27px;
            }
            .el-icon-arrow-down{
              font-size: 12px;
            }
            
          } 
        }
      }
    }
    .legend{
      margin-bottom: 20px;
      position: relative;
      .dec1{
        position: absolute;
        top: 2px;
        left: 138px;
        width: 1px;
        height: 20px;
        background: linear-gradient(180deg, rgba(54,124,255,0) 0%, #4CB4FF 51%, rgba(54,124,255,0) 100%);
        z-index: 100;
      }
      .dec2{
        position: absolute;
        top: 2px;
        left: 288px;
        width: 1px;
        height: 20px;
        background: linear-gradient(180deg, rgba(54,124,255,0) 0%, #4CB4FF 51%, rgba(54,124,255,0) 100%);
        z-index: 100;
      }
      .public{
        width: 30%;
        .shu-label{
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          line-height: 15px;
        }
        .num{
          // font-size: 20px;
          // padding-left: 4px;
          // font-family: DINAlternate-Bold, DINAlternate;
          font-size: 18px;
          font-family: 'UnidreamLED';
          font-weight: 400;
          color: #FF4A4A;
          text-shadow: 0px 0px 16px rgba(42,127,255,0.9);
        }
      }
      .cgk{
        color: #FF4A4A;
        .dec{
          color: #FF4A4A;
        }
        .num{
          color: #FF4A4A;
        }
      }
      .spk{
        color: #05DE79;
        .dec{
          color: #05DE79;
        }
        .num{
          color: #05DE79;
        }
      }
      .tpk{
        color: #FFBB00;
        .dec{
          color: #FFBB00;
        }
        .num{
          color: #FFBB00;
        }
      }
    }
    .bottom{
      padding: 0 8px 0 0;
      margin-bottom: 50px;
      // max-height: 740px;
      max-height: 84%;
      overflow: auto;
      flex: 1;
      cursor: pointer;
      .slider-list{
        width: 100%;
        margin-bottom: 22px;
        padding: 16px 12px 15px 17px;
        width: 301px;
        height: 31px;
        background: rgba(81, 149, 245, 0.25);
        &:last-child{
          // padding-bottom: 0;
          margin-bottom: 0;
        }
        .slider{
          width: 100%;
          .tiao{
            background: rgba(76,118,193,0.38);
            height: 3px;
            position: relative;
            .dec-circular-border{
              width: 14px;
              height: 14px;
              border: 1px solid rgba(21, 154, 255,0.3);
              border-radius: 50%;
              position: absolute;
              top: -7px;
              display: flex;
              align-items: center;
              justify-content: center;
              .dec-circular{
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background: #DFFFFE;
              box-shadow: 0px 0px 16px #fff;
            }
            }
            .have{
              height: 100%;
            }
          }
        }
        .one-scroll-text{
          // padding: 0 0 12px 0;
          width: 100%;
          .one-scroll-text-left{
            width: 200px;
            font-size: 14px;
            font-family: AppleSystemUIFont;
            color: #C8DFFF;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:1;
            overflow:hidden;
            text-overflow:ellipsis;
          }
          .one-scroll-text-right{
            // width: 60px;
            // font-size: 18px;
            // font-family: 'DINAlternate-Bold', 'DINAlternate';
            // line-height: 30px;
            // color: #C8DFFF;
            width: 60px;
            font-size: 18px;
            font-family: TeX Gyre Adventor-Bold Italic, TeX Gyre Adventor;
            font-weight: normal;
            color: #C8DFFF;
            line-height: 28px;
            .ci{
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              color: #fff;
              height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>