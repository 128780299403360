<template>
  <Box width="470px" height="250px" class="cpt-task-box">
    <div class="cpt-task-inner-box">
      <div class="task-header">
        <span class="iconfont icon-biaoti"></span>
        <span class="title">飞行统计</span>
      </div>
      <div class="task-filter">
        <div class="date-group">
          <div
            :class="
              type === 3 ? 'active date-item date-week' : 'date-item date-week'
            "
            @click="updatActive(3)"
          >
            周
          </div>
          <div
            :class="
              type === 2
                ? 'active date-item date-month'
                : 'date-item date-month'
            "
            @click="updatActive(2)"
          >
            月
          </div>
          <div
            :class="
              type === 1 ? 'active date-item date-year' : 'date-item date-year'
            "
            @click="updatActive(1)"
          >
            年
          </div>
        </div>
        <div class="select-group">
          <el-cascader
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="filter.organId"
          :options="selectList"
          :show-all-levels="false"
          @change="updataflyValue()"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
        >
        </el-cascader>
          <el-select
            size="mini"
            v-model="flyValue"
            placeholder="全部"
            @change="updataflyValue()"
            clearable
          >
            <el-option
              v-for="item in this.flylist"
              :key="item.id"
              :label="item.deviceName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div id="task-chart" class="task-chart"></div>
    </div>
  </Box>
</template>

<script>
let chart;
import { Chart } from "@antv/g2";
import Box from "../common/box";
import API from "@/api";
import {mapGetters,mapActions} from "vuex"
export default {
  computed:{
    ...mapGetters(["dbox_data"])
  },
  components: { Box },
  data() {
    return {
      filter: {
        organId: null,
      },
      type: 3,
      flylist: [],
      flyValue: "",
      selectList:null,
      dataList: null,
    };
  },
  methods: {
    ...mapActions("user",["Dbox"]),
    getAllOrganization() {
      let data  = this.dbox_data
      this.selectList = this.getTreeData(data);
      this.filter.organId = this.selectList[this.selectList.length-1].id
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    // 初始化视图
    async init_viewer() {
      await this.getdataList()
      this.newChart()
    },

    // 获取飞行机数据
    async getflylist() {
      const list = await API.HOME.drone();
      this.flylist = list.data.reverse()
    },

    // 更新年月周
    async updatActive(num) {
      this.type = num;
      await this.getdataList()
      this.dataList.sort((a, b) => {
      return a.date > b.date ? 1 : -1;
      })
      
      chart.changeData(this.dataList)
      
    },

    // 更新地区与无人机
    async updataflyValue() {
      await this.getdataList()
      chart.changeData(this.dataList)
    },
    getTime(time) {
      // 转换为式分秒
      let h = parseInt(time / 60 / 60 )
      // h = h < 10 ? '0' + h : h
      let m = parseInt(time / 60 % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(time % 60)
      s = s < 10 ? '0' + s : s
      // 作为返回值返回
      return h +':' + m + ':' + s 
    },

    // 初始化图表
    newChart (){
      chart = new Chart({
        container: "task-chart",
        autoFit: true,
        height: 470,
        padding: [10, 28, 60, 38], // 上，右，下，左
      });
      chart.data(this.dataList);
      chart.scale({
        date: {
          range: [0, 1],
        },
        temperature: {
          nice: true,
        },
      });

      chart.axis("temperature", {
        label: {
          formatter: (val) => {
            return val;
          },
        },
      });
      // 隐藏y轴水平线
      chart.axis('values', {
        grid: null
      });
      chart.legend({
        itemName: {
          style: {
            fill: '#FFFFFF',
          }
        },
      })
      chart.tooltip({
        showCrosshairs: true,
        shared: true,
        enterable: true,
  // 自定义 item 模板：每项记录的默认模板，自定义模板时必须包含各个 dom 节点的 class。
  itemTpl: `
    <li class="g2-tooltip-list-item">
      <span class="g2-tooltip-marker" style="background-color: {color};"></span>
      <span class="g2-tooltip-name">{showType}</span>:<span class="g2-tooltip-value">{showValue}</span>
    </li>
  `
      });

      chart.line().position("date*values").color("types").shape("smooth").tooltip('types*values*typeTime', (types, values,typeTime) => {
        return {
          showType: types,
          showValue: types === '飞行总时间' && values > 0  ? typeTime :values
        }
      });

      chart.point().position("date*values").color("types").shape("circle");
      chart.render();
    },

    // 获取图表数据
    async getdataList() {
      const datalist = await API.HOME.getFlightCount({
        organId: this.filter.organId,
        deviceId: this.flyValue,
        type: this.type,
      });
      let list = datalist.data
      let data = [];
      list.map((item) => item.map((value) => data.push(value)));
      data.map((item) =>
        item.value === "0" || item.value === "0.0000"
          ? (item.values = 0)
          : (item.values = Number(item.value))
      );
      data.map((item) => (item.date = item.date + " "));
      data.map((item) => {
        switch (item.type) {
          case 1:
            return (item.types = "飞行总架次(pcs)");
            break;
          case 2:
            return (item.types = "飞行总距离(KM)");
            break;
          case 3:
            return (item.types = "飞行总时间");
            break;
          default:
            null;
        }
      });
      data.map(item => {
        if(item.type === 2 && item.values !== 0) {
          item.values = (item.values / 1000).toFixed(2)
        }
        if(item.type === 3) {
          item.typeTime = this.getTime(item.values) + " "
        }
      })
      this.dataList = data
    }
  },    
  mounted() {
    this.getAllOrganization()
    this.Dbox()
    this.getflylist();
    this.init_viewer();
  },
};
</script>

<style lang="scss" scoped>
.cpt-task-box {
  position: absolute;
  right: 480px;
  bottom: 38px;
  z-index: 1;
  .cpt-task-inner-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .task-header {
      height: 40px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      font-size: 18px;
      color: #e0e6f2;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      box-sizing: border-box;
      position: relative;
      &::before {
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0px;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(3, 233, 244, 0.5), transparent);
        animation: animate1 4s linear infinite;
      }
      .title {
        margin-left: 9px;
      }
    }
    .task-filter {
      height: 20px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      margin: 8px 0;
      flex-shrink: 0;
      .date-group {
        border: 1px solid #2c6cef;
        border-radius: 2px;
        overflow: hidden;
        display: flex;
        box-sizing: border-box;
        .date-item {
          box-sizing: border-box;
          width: 36px;
          height: 100%;
          flex-shrink: 0;
          font-size: 12px;
          color: #e0e6f2;
          letter-spacing: 0;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #2c6cef;
          cursor: pointer;
          &:last-child {
            border-right: 0;
          }
          &.active {
            background: #2c6cef;
          }
        }
      }
      .select-group {
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        ::v-deep .el-cascader {
          width: 100px;
          margin-top: -20px;
          height: 20px;
            .el-input {
            height: 20px;
            .el-input__inner {
              height: 20px;
              line-height: 20px;
              background: #020f33;
              border: 1px solid #214884;
              box-sizing: border-box;
              border-radius: 0;
              color: #e0e6f2;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        ::v-deep .el-select {
          margin-left: 20px;
          box-sizing: border-box;
          width: 84px;
          height: 20px;
          margin-right: 7px;
          &:last-child {
            margin-right: 0;
          }
          .el-input {
            box-sizing: border-box;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .el-input__inner {
              height: 20px;
              line-height: 20px;
              background: #020f33;
              border: 1px solid #214884;
              box-sizing: border-box;
              border-radius: 0;
              color: #e0e6f2;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .el-input__suffix {
              .el-input__suffix-inner {
                .el-input__icon {
                  line-height: 20px;
                }
                .el-select__caret {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    .task-chart {
      flex: 1;
    }
    .dataList {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
<style>
  .el-scrollbar.el-cascader-menu {
  max-height: 400px;
  overflow-y: auto;
}
</style>