import { Position } from "@antv/attr";
let num = 0
import API from "@/api";
import liuxuanyi from "@/assets/images/liuxuanyi.svg";
const { VUE_APP_WS_URL: WS_URL } = process.env;
let modelList = []
let entityList = []
export default {
    dataParser(data, flag) {
        const { uavDeviceID, uavDeviceName, uavInfo } = data;
        const position = Cesium.Cartesian3.fromDegrees(
            Number(uavInfo.longitude),
            Number(uavInfo.latitude),
            Number(uavInfo.height)
        );
        const hpr = new Cesium.HeadingPitchRoll(
            Cesium.Math.toRadians(uavInfo.yaw + flag ? -170 : 90),
            Cesium.Math.toRadians(uavInfo.pitch),
            Cesium.Math.toRadians(uavInfo.roll)
        );
        const orientation = Cesium.Transforms.headingPitchRollQuaternion(
            position,
            hpr
        );
        return {
            hId: uavDeviceID,
            name: uavDeviceName,
            position,
            orientation,
            entity_id: uavDeviceID
        };
    },
    removeEntities() {
        if (entityList.length > 0) {
            entityList.forEach(element => {
                window.viewer.entities.remove(element)
            })
        }
    },
    async initUavmodel() {
        const res = await API.DEVICE.organTree();
        if (res.data && res.data[1]) {
            const deviceTypes = res.data[1].deviceTypes
            const wrj = deviceTypes.filter(item => item.goodsName === '入云龙')
            const deviceList = wrj[0].devices
            deviceList.forEach(async item => {
                let uav_data = await API.UAV.GetLastUavData({
                    deviceHardId: item.deviceHardId,
                });
                let hId = item.deviceHardId;
                if (uav_data.data) {
                    uav_data = uav_data.data;
                    uav_data.uavDeviceID = item.deviceHardId;
                    uav_data.uavDeviceName = item.deviceName;
                    const data = this.uav_dataParser(uav_data);
                    data.goodsName = item.goodsName;
                    if (!this.uavMap[hId]) {
                        this.uavMap[hId] = {
                            position: null,
                            orientation: null,
                            positions: [],
                        };
                    }

                    if (data.position) {
                        this.uavMap[hId].position = data.position;
                        this.uavMap[hId].orientation = data.orientation;
                        this.uavMap[hId].positions.push(data.position.clone());
                        this.uav_add_flyModel(data);
                    }
                }
            });
        }
    },
    add_flyModel(data, type) {
        const entitieList = window.viewer.entities
        let model = entitieList.getById(`${data.entity_id}model`)
        let line = entitieList.getById(`${data.entity_id}line`)
        if (!line && !model) {
            let entitie = window.viewer.entities.add({
                id: data.entity_id + 'model',
                position: new Cesium.CallbackProperty(() => data.position, false), // 图标的经纬度位置
                billboard: {
                    image: liuxuanyi, // 图标的路径
                    scale: 1,
                },
                label: {
                    text: data.name || '-',
                    font: '13px sans-serif',
                    // fillColor: Cesium.Color.fromCssColorString('#1ecbee'),
                    fillColor: Cesium.Color.WHITE,
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 1,
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    pixelOffset: new Cesium.Cartesian2(0, -20)
                }
            });
            let entitieline = window.viewer.entities.add({
                id: data.entity_id + 'line',
                polyline: {
                    positions: new Cesium.CallbackProperty(() => {
                        return data.positions
                    }, false),
                    width: 1,
                    material: Cesium.Color.RED
                }
            });
            entityList.push(entitieline)
            entityList.push(entitie)
        }

    },
    // 初始化ws
    init_ws() {
        let { user_info_hn, uav_control } = this;
        let username = user_info_hn.username;
        let token = user_info_hn["mmc-identity"] + user_info_hn["FLYINGSESSIONID"];
        let appId = user_info_hn.appid;
        this.ws = new WebSocket(WS_URL);
        this.ws.onopen = () => {
            this.ws.send(
                JSON.stringify({
                    type: 100,
                    systemCode: "mmc",
                    state: 1,
                    username,
                    token,
                    appId,
                })
            );
        };

        this.ws.onmessage = (e) => {
            const data = JSON.parse(e.data);
            if (data.deviceData && data.deviceHardId) {
                let index = this.yc_deviceSerial.indexOf(data.deviceHardId);
                const device_data = JSON.parse(data.deviceData);
                if (device_data) {
                    // 无人机数据
                    if (this.uavMap[device_data.deviceHardId] && device_data.data) {
                        if (device_data.data.uavInfo) {
                            const data = this.uav_dataParser(
                                {
                                    uavDeviceID: device_data.deviceHardId,
                                    uavDeviceName: device_data.deviceHardId,
                                    uavInfo: device_data.data.uavInfo,
                                },
                                index !== -1
                            );
                            if (!this.uavMap[data.hId]) {
                                this.uavMap[data.hId] = {};
                                this.uavMap[data.hId].positions = [];
                            }
                            this.uavMap[data.hId].position = data.position;
                            this.uavMap[data.hId].orientation = data.orientation;
                            this.uavMap[data.hId].positions.push(data.position.clone());
                        }
                    }
                }
            }
        };
        this.ws.onclose = () => { };
    },
    add_model(data, type) {
        const entitieList = window.viewer.entities
        let model = entitieList.getById(`${data.entity_id}model`)
        let line = entitieList.getById(`${data.entity_id}line`)
        if (!line && !model) {
            let entitie = window.viewer.entities.add({
                id: data.entity_id + 'model',
                position: new Cesium.CallbackProperty(() => data.position, false), // 图标的经纬度位置
                billboard: {
                    image: liuxuanyi, // 图标的路径
                    scale: 1,
                },
                label: {
                    text: data.name || '-',
                    font: '13px sans-serif',
                    // fillColor: Cesium.Color.fromCssColorString('#1ecbee'),
                    fillColor: Cesium.Color.WHITE,
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 1,
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    pixelOffset: new Cesium.Cartesian2(0, -20)
                }
            });
            let entitieline = window.viewer.entities.add({
                id: data.entity_id + 'line',
                polyline: {
                    positions: new Cesium.CallbackProperty(() => {
                        return data.positions
                    }, false),
                    width: 1,
                    material: Cesium.Color.RED
                }
            });
            entities.push(entitieline)
            entities.push(entitie)
        }

    },
}