<template>
  <ModuleBox title="数据推送" width="100%" height="40vh" class="number-of-tasks">
    <div class="out">
      <div style="width: 100%">
        <!-- 头部总数展示 -->
        <div class="top hwb-flex">
          <!-- 左侧任务次数统计 -->
          <div class="left hwb-flex cp" @click="details('预警信息')">
            <div class="text f12 w120">推送数量总计</div>
            <div class="num hwb-flex">
              <div class="one-num">{{totalval[0]}}</div>
              <div class="one-num">{{totalval[1]}}</div>
              <div class="one-num">{{totalval[2]}}</div>
              <div class="one-num">{{totalval[3]}}</div>
              <div class="one-num">{{totalval[4]}}</div>
            </div>
            <div class="text ml12">条</div>
          </div>
          <!-- 右侧时间筛选 -->
          <div class="right">
            <TimeSelect @on-selectTime="selectTime" />
          </div>
        </div>
        <!-- 图表 -->
        <div class="chart hwb-flex">
          <!-- 中间竖线装饰 -->
          <!-- <div class="l-dec"></div> -->
          <div id="pata-push-box">
            <!-- 饼图 -->
            <div id="pata-push"></div>
            <!-- 饼图中间数据 -->
            <div class="push-box">
              <div class="middle-box">
                <div class="middle-box-top">推送数量总计</div>
                <div class="middle-box-bottom"><span class="zongshu">{{1*totalval.join('')}}</span> <span
                    class="danwei">条</span></div>
              </div>
            </div>
          </div>
          <!-- 文字描述 -->
          <div class="right" @click="details('数据推送详情')">
            <!-- 一条数据 -->
            <div class="one-data hwb-flex" v-for="(item,index) in data" :key="index">
              <div style="display:flex;align-items:center">
                <div class="dec" :style="{backgroundColor:item.color}"></div>
                <div class="text" :style="{color:item.color}">{{item.anotherName}}</div>
              </div>
              <div style="display:flex;align-items:center;">
                <div class="sum">{{item.distCode}}</div>
                <div class="text-l">条</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog class="Details" v-if="showDetails" :title="title" :width="800" @on-close="closeDetails">
      <div class="select" v-show="title=='预警信息'">
        <el-cascader popper-class="input-orgname" placeholder="请选择所属机构" v-model="search_form.organizationId"
                :options="allOrganizationList" :show-all-levels="false"  :props="{
                  children: 'child',
                  label: 'name',
                  value: 'id',
                  checkStrictly: true,
                  emitPath: false,
                  empty: false
                }" style="margin-right:5px;" >
              </el-cascader>
                    <el-select v-model="search_form.warningTypeId	" size="mini" clearable placeholder="请选择预警类型"
                        class="button">
                        <el-option v-for="item in WarningTypeList" :key="item.id" :label="item.name"
                            :value="item.id" />
                    </el-select>
               
                    <div class="btn" @click="reset">重置</div>
                    <div class="btn" @click="searchData">查询</div>


                </div>
      <!-- 数据推送 -->
      <Table :cellName="dataList" :dataSource="dataValue">
        <template #effect={row}>
          <div>
            {{row.effect == '0' ? '无效':'有效'}}
          </div>
        </template>
        <template #distCode={row}>
          <div class="cp" style="color:#147799" @click="showImg(row)">
            查看图片
          </div>
        </template>
      </Table>
    </Dialog>
    <el-dialog class="mt200" :append-to-body="true" title="预警图片" :visible.sync="dialogVisible" width="30%"
      :before-close="beforeClose">
      <img class="wih100 h300" :src="img" />
    </el-dialog>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import TimeSelect from "@/components/home/common/time-select/index.vue"; // 时间选择组件
import { Chart } from "@antv/g2";
import Table from '@/components/common/Table-sj/index.vue'; // 弹框组件
import Dialog from '@/components/common/new-dialog/index.vue'; // 弹框组件
import getgetAllOrganization from "@/api/mechanismPullDown.js"; // 获取机构下拉js
import API from "@/api";
import { mapGetters } from 'vuex'

let chart;
export default {
  components: { ModuleBox, TimeSelect, Table, Dialog },
  data() {
    return {
      allOrganizationList:null,
      allOrganizationselectList:null,
      search_form:{
        warningTypeId:null,
        organizationId:1
      },
      year: "year",
      data: [],
      dataList: [
        { title: '机构名称', name: 'anotherName', },
        { title: '推送条数', name: 'distCode' },
      ],
      WarningTypeList:[],
      dataValue: [],
      showDetails: false,
      title: '',
      dialogVisible: false,
      selectList: [],
      img: "",
      organId: null,
      totalval: [],
      colorData: [
        '#0ECBFF',
        '#1C7DD1',
        '#7D30CF',
        '#48CD82',
        '#D79163',
        '#E9483B']
    };
  },
  computed: {
    ...mapGetters(["dbox_data"]),
  },
  methods: {
    ...getgetAllOrganization,
    reset(){
      this.search_form={
        warningTypeId:null,
        organizationId:1
      }
      this.searchData()
    },
    searchData(){
      this.details('预警信息')
    },
    updataflyValue() {

    },
    showImg(row) {
      this.img = row.warningPhotoStorage
      this.dialogVisible = true
    },
    beforeClose() {
      this.dialogVisible = false
    },
  
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          if ( data[i].id == 60 ) {//删除潮鹰机构
            data.splice(i,1)
          }
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i] && data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    removeChaoyingId(data) {
    return this.getTreeData(data); 
  },
    async details(title) {
      this.title = title
      if (title == '预警信息') {
        this.dataList = [
          { title: '单位名称', name: 'organizationName', },
          { title: '预警类型', name: 'warningTypeName' },
          { title: '预警时间', name: 'warningTime' },
          { title: '预警状态', name: 'effect', slot: true },
          { title: '预警上报设备', name: 'deviceHardId' },
          { title: '飞手信息', name: 'flightUser' },
          { title: '操作', name: 'distCode', slot: true },
        ]
        this.getWarningListFn()
      } else {
        this.dataList = [
          { title: '机构名称', name: 'anotherName', },
          { title: '推送条数', name: 'distCode' },
        ]
        let res = await API.HOMEDATA.warningPush(this.time)
        if (res.code == 200) {
          this.dataValue = res.data.organization.sort(this.sortArray('distCode'))
        }
      }
      this.showDetails = true
    },
   async getWarningTypeListFn(){
      let res = await API.HOMEDATA.getList()
      if (res.code == 200) {
        this.WarningTypeList= res.data
      }
    },
    async getWarningListFn() {
      let res = await API.HOMEDATA.getWarningList(this.search_form)
      if (res.code == 200) {
        this.dataValue = res.data
      }
    },
    closeDetails() {
      this.showDetails = false
    },
    async selectTime(time) {
      // console.log(time);
      if (time == 1) {
        time = 'day'
      } else if (time == 2) {
        time = 'week'
      } else if (time == 3) {
        time = 'month'
      } else if (time == 4) {
        time = 'year'
      }
      this.time = time
      await this.async_get_list(time)
      chart.destroy()
      this.initChart_new()
    },
    // drawChart() { //南丁格尔玫瑰图
    //   chart = new Chart({
    //     container: 'pata-push',
    //     autoFit: true,
    //     height: 200,
    //   });
    //   chart.legend(false); // 关闭图例
    //   chart.scale('distCode', {
    //     formatter: (val) => {
    //       val = (val * 100 )+ '%';
    //       return val;
    //     },
    //   });
    //   chart.data(this.data);
    //   chart.coordinate('polar');
    //   chart.axis(false);
    //   chart.tooltip({
    //     showMarkers: false
    //   });
    //   chart.interaction('element-highlight');
    //   chart
    //     .interval()
    //     .position('anotherName*distCode')
    //     .color('anotherName',['#59EFA9','#FFB21C','#FF563B','#4FA6F4','#7B87F9','#00FFFF'])
    //     .style({
    //       lineWidth: 1,
    //       stroke: '#fff',
    //     });
    //   chart.render();
    // },
    // initChart() {//饼图
    //   chart = new Chart({
    //     container: 'pata-push',
    //     autoFit: true,
    //     width: 190,
    //     height: 190,
    //   });

    //   chart.coordinate('theta', {
    //     radius: 0.75,
    //   });

    //   chart.data(this.data);

    //   chart.scale('distCode', {
    //     formatter: (val) => {
    //       val = ((val / this.totalval.join('')) * 100).toFixed(1) + '%';
    //       return val;
    //     },
    //   });

    //   chart.tooltip({
    //     showTitle: false,
    //     showMarkers: false,
    //   });

    //   chart
    //     .interval()
    //     .position('distCode')
    //     .color('anotherName', ['#0ECBFF', '#1C7DD1', '#7D30CF', '#48CD82', '#D79163', '#E9483B'])
    //     .adjust('stack');

    //   chart.legend(false)
    //   chart.interaction('element-active');

    //   chart.render();
    // },
    initChart_new() {
      chart = new Chart({
        container: 'pata-push',
        autoFit: true,
        height: 200,
        width: 200
      });
      chart.data(this.data);
      chart.scale('distCode', {
        formatter: (val) => {
          val = val * 100 + '%';
          return val;
        },
      });
      chart.coordinate('theta', {
        radius: 0.75,
        innerRadius: 0.8,
      });
      chart.legend(false)
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
        itemTpl: '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
      });

      chart
        .interval()
        .adjust('stack')
        .position('distCode')
        .color('anotherName', ['#0ECBFF', '#1C7DD1', '#7D30CF', '#48CD82', '#D79163', '#E9483B'])
        .tooltip('anotherName*distCode', (anotherName, distCode) => {
          distCode = ((distCode / this.totalval.join('')) * 100).toFixed(1) + '%';
          return {
            name: anotherName,
            value: distCode,
          };
        });

      chart.interaction('element-active');

      chart.render();
    },
    async async_get_list(time) {
      let res = await API.HOMEDATA.warningPush(time)
      if (res.code == 200) {
        this.dataValue = res.data.organization.sort(this.sortArray('distCode'))
        res.data.organization = res.data.organization.slice(0, 6)
        res.data.organization.forEach((v, i) => {
          v.color = this.colorData[i]
        });
        this.data = res.data.organization
        // console.log(String(res.data.total).padStart(5,0).split(''));
        this.totalval = String(res.data.total).padStart(5, 0).split('')  //将总数转换成可以渲染的数组
      } else {
        this.$message.error(res.message)
      }
    },
    sortArray(prop) {//数组排序
      return (a, b) => {
        let value1 = a[prop]
        let value2 = b[prop]
        return value2 - value1
      }
    }
  },
  mounted() {
    // this.drawChart()
    this.getAllOrganization()
    this.allOrganizationList = this.removeChaoyingId(JSON.parse((JSON.stringify(this.allOrganizationselectList))))
    this.getWarningTypeListFn()
    this.initChart_new()
    this.selectTime(4) //默认选中年
  },
};
</script>

<style lang="scss" scoped>
.Details {
  .select {
    display: flex;
    align-items: center;

    .selectBtn {
      cursor: pointer;
      width: 86px;
      height: 30px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      text-align: center;
    }
  }

  ::v-deep .el-cascader .el-input {
    .el-input__inner {
      background-color: #000;
      border: 0;
      height: 30px;
      line-height: 30px;
      color: #fff;
    }

    .el-input .el-select__caret {
      line-height: 0;
    }
  }
}

.number-of-tasks {
  width: 100%;

  .out {
    width: 100%;
    height: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;

    .top {

      // margin-bottom: 26px;
      .left {
        width: 66%;

        .text {
          font-size: 14px;
          font-family: AppleSystemUIFont;
          color: #DAE9FF;
        }

        .num {
          width: 150px;
          margin-left: 20px;
          .one-num {
            width: 16px;
            height: 27px;
            background: linear-gradient(180deg, #01a0ff 0%, #01a0ff 50%, #1784FA 50%, #1784FA 100%);
            font-family: Rajdhani-SemiBold, Rajdhani;
            border-radius: 1px;
            font-size: 22px;
            color: #FFFFFF;
            line-height: 27px;
            text-align: center;
          }
        }
      }

      .right {
        text-align: center;
      }
    }

    .chart {
      width: 100%;
      height: 240px;
      position: relative;
      margin-top: 40px;
      .l-dec {
        position: absolute;
        top: 36px;
        left: 176px;
        width: 1px;
        height: 162px;
        background: linear-gradient(180deg, rgba(54, 124, 255, 0) 0%, #C6DAFF 51%, rgba(54, 124, 255, 0) 100%);
      }

      #pata-push-box {
        position: relative;
      }

      .push-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 2px solid #0e97ff;
        width: 125px;
        height: 125px;
        background: linear-gradient(180deg, #0044b5 0%, #001043 100%);
        display: flex;
        justify-content: center;
        align-items: center;

        .middle-box {

          .middle-box-top {
            color: #84d1f7;
          }

          .middle-box-bottom {
            margin-top: 10px;
            text-align: center;
            color: #84d1f7;

            .zongshu {
              font-size: 30px;
            }

            .danwei {
              font-size: 16px;
            }
          }
        }
      }

      .right {
        cursor: pointer;
        width: 45%;
        height: 100%;
        overflow: auto;
        margin-top: 40px;

        .one-data {
          width: 100%;
          margin-bottom: 6px;

          .dec {
            width: 10px;
            height: 10px;
            margin-right: 10px;
          }

          .text {
            font-size: 14px;
          }

          .sum {
            // font-size: 26px;
            // font-family: DINAlternate-Bold, DINAlternate;
            // font-weight: bold;
            padding: 0 10px 0 0px;
            font-size: 25px;
            font-family: Rajdhani-SemiBold, Rajdhani;
            font-weight: 600;
            color: #92C6F5;
            text-shadow: 0px 0px 6px #2479E9;
          }

          .text-l {
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .left {
        width: 40%;
        height: 150px;
      }
    }
  }
}
.select {
        margin: 0px 0 24px 0;

        ::v-deep {
            .el-input__inner {
                background-color: #000;
                border: 0;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
            }
        }

        .button {
            margin-right: 10px;
            width: 210px;
        }

        .search-button {
            display: inline-block;
            width: 54px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
        }


    }
    .btn {
    padding: 5px 10px;
    display: inline-block;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}

.btn:hover {
    background: rgba(38, 71, 238, 0.91);

}
</style>