<template>
  <ModuleBox title="重点区域" width="100%" height="60vh" class="right-shot ofh">
    <div class="body">
      <div v-for="item in dataSource" :key="item.id">
        <div class="box">
          <div class="w120 h112">
            <el-image
              class="w120 h88 dib"
              :src="VUE_APP_IMGIP + item.image"
            ></el-image>
          </div>
          <div class="right">
            <div class="top f16">
              <div class="name">
                {{ item.name }}
              </div>
              <div>
                <span class="iconfont icon-sanwei f12"></span>
                <span style="color: #00c2ffff" class="f12">{{
                  item.type
                }}</span>
              </div>
            </div>
            <div class="bottom jcsb">
              <div class="f12" style="color: #ffffff00">{{ item.addtime }}</div>
              <div class="cp mr10">
                <span
                  v-if="item.isShow === false"
                  title="查看"
                  class="iconfont icon-yanjing_yincang_o"
                  @click="chang(item, true)"
                  style="margin-right: 4px"
                ></span>
                <span
                  v-else
                  title="隐藏"
                  class="iconfont icon-yulanhangxian"
                  @click="chang(item, false)"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
let modelList = [];
import { mapGetters } from "vuex";
let dataSources = [];
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import API from "@/api";
export default {
  components: {
    ModuleBox,
  },
  data() {
    return {
      list: [],
      dataSource: [],
      VUE_APP_IMGIP: "",
    };
  },
  created() {
    const { VUE_APP_IMGIP } = process.env;
    this.VUE_APP_IMGIP = VUE_APP_IMGIP;
  },
  mounted() {
    this.async_get_list();
  },
  watch: {
    "$route.fullPath"(newData) {
      if (newData == "/home?id=6") {
        this.addDataToGlobe("map.geojson", "规划区");
      } else {
        this.removeDataSources("规划区");
      }
    },
    deep: true,
  },
  computed: {
    ...mapGetters(["user_info_hn,element_"]),
  },
  methods: {
    chang(data, type) {
      console.log(data, "chang");
      if (!data.status) {
        return this.$message({ type: "warning", message: "暂无访问权限" });
      }
      // 获取点击的数据下标，实时改变table数据
      let index = this.dataSource.findIndex((item) => item.id === data.id);
      this.$set(this.dataSource, index, { ...data, isShow: type });
      this.flyTo3D(data, type);
    },

    async async_get_list() {
      let { data } = await API.MODEL.List({
        ...this.search,
        pageNo: 1,
        pageSize: 100000,
      });
      this.dataSource.push(...data.records);
      // 每条数据添加一个isShow来控制图标显隐藏
      this.dataSource.map((item) => (item.isShow = false));
    },
    // 三维模型跳转
    async flyTo3D(data, isShow) {
      this.list = data;
      // 显示
      if (isShow) {
        // 记录三维访问次数
        await API.MODEL.serviceMapDataClick({
          id: data.id,
        });
        window.plate_data_source && (window.plate_data_source.show = false);
        viewer.scene.globe.show = true;
        // 根据id判断modelList中是否已经创建实例，是就改变属性显示并跳转，否就创建模型实例
        if (modelList[data.id]) {
          modelList[data.id].show = true;
          viewer.flyTo(modelList[data.id]);
        } else {
          if (data.type == "二维影像") {
            let imageryProvider = new Cesium.ImageryLayer(
              new Cesium.UrlTemplateImageryProvider({
                url: data.url,
              })
            );
            viewer.imageryLayers.add(imageryProvider);
            modelList[data.id] = imageryProvider;
            modelList[data.id].show = true;
            return;
          }
          let resource = new Cesium.Resource({
            url: data.url,
            retryCallback: (resource, error) => {
              if (!error.statusCode || error.statusCode === 404) {
                console.log("服务数据加载失败");
              }
              return false;
            },
            retryAttempts: 1,
          });

          let tileSetModel = new Cesium.Cesium3DTileset({
            id: data.id,
            url: resource, //数据路径
            skipLevelOfDetail: true,
            baseScreenSpaceError: 1024,
            maximumScreenSpaceError: 4, // 数值加大，能让最终成像变模糊
            skipScreenSpaceErrorFactor: 16,
            skipLevels: 1,
            immediatelyLoadDesiredLevelOfDetail: false,
            loadSiblings: true, // 如果为true则不会在已加载完概况房屋后，自动从中心开始超清化房屋
            cullWithChildrenBounds: true,
            cullRequestsWhileMoving: true,
            cullRequestsWhileMovingMultiplier: 10, // 值越小能够更快的剔除
            preloadWhenHidden: true,
            preferLeaves: true,
            maximumMemoryUsage: 512, // 内存分配变小有利于倾斜摄影数据回收，提升性能体验
            progressiveResolutionHeightFraction: 0.5, // 数值偏于0能够让初始加载变得模糊
            // dynamicScreenSpaceErrorDensity: 0.1, // 数值加大，能让周边加载变快
            dynamicScreenSpaceErrorDensity: 1, // 数值加大，能让周边加载变快
            dynamicScreenSpaceErrorFactor: 1, // 不知道起了什么作用没，反正放着吧先
            dynamicScreenSpaceError: true, // 根据测试，有了这个后，会在真正的全屏加载完之后才清晰化房屋
            show: true,
            preferLeaves: true,
          });
          modelList[data.id] = viewer.scene.primitives.add(tileSetModel);
          tileSetModel.readyPromise.then((tileset) => {
            //3dtile模型的边界球体
            var boundingSphere = tileset.boundingSphere;
            //迪卡尔空间直角坐标=>地理坐标（弧度制）
            var cartographic_original = Cesium.Cartographic.fromCartesian(
              boundingSphere.center
            );
            //地理坐标（弧度制）=>迪卡尔空间直角坐标
            var Cartesian3_original = Cesium.Cartesian3.fromRadians(
              cartographic_original.longitude,
              cartographic_original.latitude,
              cartographic_original.height
            );
            var Cartesian3_offset = Cesium.Cartesian3.fromRadians(
              cartographic_original.longitude,
              cartographic_original.latitude,
              1
            );
            //获得地面和offset的转换
            var translation = Cesium.Cartesian3.subtract(
              Cartesian3_offset,
              Cartesian3_original,
              new Cesium.Cartesian3()
            );
            //修改模型矩阵
            tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
            // viewer.scene.primitives.add(tileset);
            viewer.zoomTo(
              tileset,
              new Cesium.HeadingPitchRange(
                0.5,
                -0.2,
                tileset.boundingSphere.radius * 1.0
              )
            );
          });
          viewer.flyTo(modelList[data.id]);
          modelList[data.id].viewFrom = new Cesium.Cartesian3(0, 0, 1000);
        }
      } else {
        if (data.type == "二维影像") {
          let imageryLayer = modelList[data.id];
          modelList[data.id].show = false;
          modelList.splice(data.id, 1);
          viewer.imageryLayers.remove(imageryLayer, false);
        } else {
          // 隐藏模型实例
          viewer.scene.primitives.remove(modelList[data.id]);
          modelList[data.id].show = false;
          modelList.splice(data.id, 1);
        }
      }
    },
    addDataToGlobe(text, NAME, color) {
      // 添加
      let xq1 = Cesium.GeoJsonDataSource.load(text, {
        stroke: Cesium.Color.PINK,
        fill: Cesium.Color.RED.withAlpha(0.5),
        strokeWidth: 3,
      });
      xq1.then((buffer) => {
        viewer.dataSources.add(buffer);
        buffer.name = NAME;
        dataSources.push(buffer.name);
      });
    },
    removeDataSources(name) {
      viewer.dataSources.remove(viewer.dataSources.getByName(name)[0]);
    },
  },
  beforeDestroy() {
    this.removeDataSources("规划区");
    if (modelList && modelList.length > 0) {
      modelList.forEach((element) => {
        viewer.scene.primitives.remove(element);
      });
    }
    // window.modelList = modelList;
    console.log(modelList, "modelList");
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.right-shot {
  .body {
    height: 99%;
    overflow: auto;

    .box {
      height: 100px;
      background: rgba(0, 5, 24, 0.25);
      backdrop-filter: blur(2px);
      display: flex;
      margin-bottom: 10px;

      .left {
        background: url("~@/assets/three/szf.png") no-repeat;
        min-width: 112px;
        width: 112px;
        height: 112px;
      }

      .left1 {
        background: url("~@/assets/three/hcz.png") no-repeat;
        min-width: 112px;
        width: 112px;
        height: 112px;
      }

      .left2 {
        background: url("~@/assets/three/bwg.png") no-repeat;
        min-width: 112px;
        width: 112px;
        height: 112px;
      }

      .left3 {
        background: url("~@/assets/three/xsgy.png") no-repeat;
        min-width: 112px;
        width: 112px;
        height: 112px;
      }

      .right {
        margin-left: 24px;
        height: 100%;
        width: 100%;

        .top {
          margin-top: 10px;
          height: 29px;
          font-size: 20px;
          font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
          font-weight: 800;
          color: #ffffff;
          line-height: 29px;

          .name {
            white-space: nowrap;
            width: 194px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .center {
          margin-top: 7px;

          height: 24px;
          font-size: 16px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #ffbd36;
          line-height: 24px;
        }

        .bottom {
          margin-top: 30px;
          height: 19px;
          font-size: 16px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #aab7c3;
          line-height: 19px;
        }
      }
    }
  }
}
</style>
