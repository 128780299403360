<template>
  <div style="height: 100%">
    <Keeprecord  v-if="haves.includes(1)"/>
    <Region  v-if="haves.includes(2)"/>
    <Ranking v-if="haves.includes(3)" />
  </div>
</template>

<script>
import Keeprecord from "./keeprecord.vue"
import Region from "./region.vue"
import Ranking from "./ranking.vue"
export default {
    components: {
        Keeprecord,
        Region,
        Ranking
    },
            data() {
      return {
        haves: [],
      };
    },
    created(){
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:civilUAV:filing") > -1) {
      this.haves.push(1)
    }
    if (permissionList.indexOf("hn:civilUAV:hotspotArea") > -1) {
      this.haves.push(2)
    }
    if (permissionList.indexOf("hn:civilUAV:flyerActivity") > -1) {
      this.haves.push(3)
    }
    }
}
</script>

<style>

</style>