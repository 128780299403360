<template>
  <ModuleBox
    title="空域管理"
    width="100%"
    height="63%"
    class="number-of-tasks"
  >
    <div class="out">
      <div style="width: 100%">
        <!-- 区域选择 -->
        <div class="select hwb-flex">
          <div class="button" @click="changeSelect(1)" :class="select == 1?'button-active':''">禁飞区</div>
          <div class="button" @click="changeSelect(2)"  :class="select == 2?'button-active':''">限高区</div>
          <div class="button" @click="changeSelect(3)" :class="select == 3?'button-active':''">临时禁飞区</div>
        </div>
        <!-- 表头 -->
        <div class="header hwb-flex">
          <div class="mc">区域名称</div>
          <div class="lx">限高高度</div>
          <div class="zt">区域状态</div>
          <div class="cz">操作</div>
        </div>
        <!-- 内容 -->
        <div class="content" id="airspaceCarousel">
          <div class="hwb-flex list" :class="index%2 === 0?'':'list2'" v-for="(item,index) in data" :key="index">
            <div class="mc" style="color:#fff">{{item.name.length>7 ? item.name.slice(0,8)+'...':item.name}}</div>
            <div class="lx" style="color:#fff">{{item.height}}m</div>
            <div class="zt" :style="{color:item.status === '有效'? '#6AFF64' : '#FF3636'}">{{item.status}}</div>
            <div class="iconfont icon-yulanhangxian1 cz" style="color:#70F6F9"></div>
          </div>
        </div>
        <!-- 地图 -->
        <div class="map">
          <Drawky/>
        </div>
      </div>
    </div>
  </ModuleBox>
</template>

<script>
import ModuleBox from "@/components/home/common/hwb-module-box/box.vue";
import Drawky from "@/components/drawky/index2.vue";
import API from "@/api";

export default {
  components: { ModuleBox, Drawky },
  data() {
    return {
      select: 2,
      data: [
        {
          name:'嘉兴机场',
          height: 120,
          status: '有效',
        },
        {
          name:'海宁市观湖景区',
          height: 144,
          status: '有效',
        },
        {
          name:'海宁市行政中心',
          height: 217,
          status: '无效',
        },
        {
          name:'浙大国际校区',
          height: 327,
          status: '有效',
        },
        {
          name:'海宁市行政中心',
          height: 139,
          status: '无效',
        },
        {
          name:'海宁市行政中心',
          height: 139,
          status: '有效',
        }
      ],
      timer:null,
      moveStatus:false
    };
  },
  methods: {
          CarouselBind(){
      document.getElementById('airspaceCarousel').addEventListener('mouseout',()=>{
        this.moveStatus=false
        this.Carousel()
      })
      document.getElementById('airspaceCarousel').addEventListener('mouseover',()=>{
        this.moveStatus=true
        this.CarouselStop()
      })
    },
    Carousel(){
     if (this.timer) {
      this.CarouselStop()
     } 
     this.timer = setInterval(() => {
        document.getElementById('airspaceCarousel').scrollTop++
        if (document.getElementById('airspaceCarousel').scrollTop==(this.data.length-5)*40 && !this.moveStatus) {
          document.getElementById('airspaceCarousel').scrollTop=0
        }
      }, 60);
    },
    CarouselStop(){
      clearInterval(this.timer)
    },
    changeSelect(select) {
      this.select = select
    }
  },
  mounted() {
    this.CarouselBind()
    this.Carousel()
  },
      beforeDestroy(){
      this.CarouselStop()
    }
};
</script>

<style lang="scss" scoped>
.number-of-tasks{
  width: 100%;
  .out{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .select{
      height: 32px;
      width: 100%;
      margin-bottom: 10px;
      .button{
        box-sizing: border-box;
        background: linear-gradient(270deg, #032251 0%, rgba(10, 58, 129, 0.88) 46%, rgba(0, 0, 0, 0) 100%);
        width: 33.33%;
        height: 100%;
        text-align: center;
        border: 1px solid #47ABF4;
        font-size: 14px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: rgba(148,179,194,0.7);
        line-height: 32px;
        cursor: pointer;
      }
      .button-active{
        background: linear-gradient(90deg, #37A5EE 0%, #265AD5 50%, #266EF5 100%);
        box-shadow: 0px 3px 6px 1px rgba(54, 86, 148, 0.35);
        border-radius: 4px 0px 0px 4px;
        opacity: 0.95;
        border: 1px solid #4DCEFF;
        color: #FFFFFF;
      }
    }
    .mc{
      width: 35%;
    }
    .lx{
      width: 27%;
    }
    .zt{
      width: 25%;
    }
    .cz{
      width: 13%;
    }
    .header{
      font-size: 16px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #AAB7C3;
      text-align: center;
      background: rgba(3,24,66,0.4);
      padding:0 0 10px 0;
      
    }
    .content{
      height: 200px;
      overflow: auto;
      .list{
        height: 20px;
        padding: 10px 0;
        background: linear-gradient(270deg, rgba(132,179,242,0) 0%, rgba(132,179,242,0.3) 52%, rgba(132,179,242,0) 100%);
        
      }
      .list2{
        background: rgba(3,24,66,0.4);
      }
    }
    .map{
      position: relative;
      height: 240px;
      border-radius: 6px;
      margin-top: 10px;
      overflow: hidden;
    }
  }
}
</style>